import { Component, OnInit,ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material';
import { NgModule,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiService } from 'src/app/api.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";


@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserlistComponent implements OnInit {
 // dataSource = ELEMENT_DATA;
 columnsToDisplay = ['Name','Email','DOR','Role','IsApproved','IsVerified'];
 expandedElement: PeriodicElement | null;
 displayedColumns: string[] = [ 'No','Notification', 'Count'];
 dataSource = new MatTableDataSource(ELEMENT_DATA);

 final_datas = [];



 @ViewChild(MatPaginator) paginator: MatPaginator;

 ngAfterViewInit() {
   this.dataSource.paginator = this.paginator;
 }


 applyFilter(value: string) {
   this.dataSource.filter = value.trim().toLowerCase();
 }

 onchange(data){

   var str = data;
   var res = str.split("-");

   let a = {user_id : this.getFromLocal("user_id"),time:res[3]}
   this._apiService.updatenotifiaction(a).subscribe(
     (data:any) => {

       // for(let a = 0 ; a < )







     }
     );

 }



 constructor( @Inject(LOCAL_STORAGE) private storage: WebStorageService,private http: HttpClient,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder) {


  }

 ngOnInit() {

  this.getnotification();

 }


 saveInLocal(key, val): void {

   this.storage.set(key, val);
 }

 getFromLocal(key): any {

   return this.storage.get(key);
 }

 approve(data){
  let a  = {
    ids : data
  }
  this._apiService.approve(a).subscribe(
    (data:any) => {
      alert("Approved");
      this.getnotification();
    }
    );
 }

 unapprove(data){
  let a  = {
    ids : data
  }
  this._apiService.unapprove(a).subscribe(
    (data:any) => {
      alert("Approved");
      this.getnotification();
    }
    );
 }

 getnotification(){
   this.final_datas = [];
   let a = {user_id : this.getFromLocal("user_id")}
   this._apiService.getUserlist().subscribe(
     (data:any) => {
       console.log(data);
       let notifi = [];
       data.forEach(element => {
         let a = {
           '_id' : element._id,
           'Name' : element.name,
           'Email' : element.email,
           'DOR' : element.dateOfRegistration,
           'Role' : element.role,
           'IsApproved' : element.isApproved,
           'IsVerified' :  element.isVerified,
         }
         notifi.push(a)
         this.final_datas.push(a);
       });
       this.dataSource = new MatTableDataSource(notifi);
       this.ngAfterViewInit();
     }
     );
 }

}


export interface PeriodicElement {


 // Time: string;
 // alert_type: string;
 // date: string;
 // description: string;
 // month: string;
 // statu: string;
 // title: string;
 // type: string;
 // user_id: string;



 // Time: string;
 // alert_type: number;
 // date: number;
 // description: string;
 // month: string;
 // statu: string;
 // title: string;
 // type: string;
 // user_id: string;


}

const ELEMENT_DATA: PeriodicElement[] = [];


import { Component, OnInit, HostBinding, ElementRef, AfterViewInit, NgZone,Inject } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit, AfterViewInit {



  @HostBinding('class') componentCssClass;
  theme: string;

  private clientId = '741694169993-i0j38sh3q21tab93ilk3gvuv03qvn5d7.apps.googleusercontent.com';

  private scope = [
    'profile',
    'email',
    'https://www.googleapis.com/auth/plus.me',
    'https://www.googleapis.com/auth/contacts.readonly',
    'https://www.googleapis.com/auth/admin.directory.user.readonly'
  ].join(' ');

  public auth2: any;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', Validators.required)
  });

  errorMessage: string;

  constructor(public overlayContainer: OverlayContainer, private element: ElementRef,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private _apiService: ApiService, private _authService: AuthService, private _router: Router,
    private _ngZone: NgZone, private _route: ActivatedRoute) {



  }

  ngOnInit() {
    this.overlayContainer.getContainerElement().classList.add('transparent-theme');
    this._route.queryParams.subscribe(
      query => {
        if (query.ref === 'pwd_reset') {
          alert('Password Successfully Reset');
        }
        if (query.ref === 'email_confirmed') {
          alert('Email Confirmed Successfully');
        }
      }
    );
    // if (this._authService.getStoredToken() !== null) {
    //   this._apiService.validateToken(this._authService.getStoredToken()).subscribe(
    //     data => {
    //       if (data['message'] === 'ok') {
    //         this._router.navigateByUrl('/dashboard/home');
    //       } else {
    //         this.errorMessage = data['message'];
    //       }
    //     }
    //   );
    // }
    // this.googleInit();
    this.theme = 'transparent-theme';
    this.errorMessage = '';
  }

  ngAfterViewInit() {
  }

  setTheme(theme) {
    this.overlayContainer.getContainerElement().classList.remove(this.theme);
    this.theme = theme;
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }

  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.clientId,
        cookiepolicy: 'single_host_origin',
        scope: this.scope
      });
      this.attachSignin(this.element.nativeElement.querySelector('#googleBtn'));
    });
  }

  loginRedirect(response) {
    console.log(response);
    if (response['message'] === 'ok') {
      this._authService.storeToken(response['token']);
      this._ngZone.run(() => this._router.navigateByUrl('/dashboard/home')).then();
    } else {
      this.errorMessage = response['message'];
    }
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        this._apiService.googleLogin(googleUser.getAuthResponse().id_token).subscribe(
          response => {

            this.loginRedirect(response);
          }
        );
      }, function (error) {

      });
  }

  login() {

    // this._router.navigateByUrl('/dashboard/home')
    if (this.loginForm.valid) {
      this._apiService.login(this.loginForm.value).subscribe(
        response => {
          this.loginRedirect(response);
        }
      );
    }
    // this._router.navigateByUrl('/dashboard/home');
  }

  forgotPassword() {
    this._ngZone.run(() => this._router.navigateByUrl('/forgotpassword')).then();
  }



  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }



}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

declare var Stripe: any;

@Component({
  selector: 'app-wallet-recharge',
  templateUrl: './wallet-recharge.component.html',
  styleUrls: ['./wallet-recharge.component.sass']
})
export class WalletRechargeComponent implements OnInit {

  tokenObject: Object;
  userObject: Object;

  query: string;

  amount: number;

  stripe: any;

  constructor(
    private _authService: AuthService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private location: Location) { }

  ngOnInit() {
    this._route.queryParams.subscribe(
      query => {
        if (query.status === 'success') {
          this.query = 'success'
        }
        if (query.status === 'error') {
          this.query = 'error'
        }
      }
    );
    this.tokenObject = this._authService.getStoredTokenPayload();
    this._apiService.getUserById(this.tokenObject['id']).subscribe(
      user => {
        this.userObject = user;
      }
    );
    this.stripe = Stripe(
      'pk_live_kHUsn6zvYm1A4VJFsducGzlg00JGpRlTru',
      // 'pk_test_lqkURf2OzRVHdgXadrPnYCHT00SWndtxZV',
      {
        betas: ['checkout_beta_4']
      }
    );
  }

  onSubmit() {
    const data = {
      name: 'Airbots Wallet Recharge',
      amount: this.amount * 100,
      currency: 'usd',
      quantity: 1,
      description: 'Recharge of wallet for Airbots.AI'
    };

    // this._apiService.addFunds(this.userObject['_id'], data).subscribe(
    //   response => {
    //     window.location = response['url'];
    //   }
    // );

    this._apiService.pay(this.tokenObject['id'], data).subscribe(
      session => {
        console.log(session);
        this.stripe.redirectToCheckout({
          sessionId: session['id'],
        }).then(function (result) {
          // Display result.error.message to your customer
          console.log(result);
        });
      }
    );

  }

  onCancel() {
    this.location.back();
  }

}

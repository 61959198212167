import { Component, OnInit ,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ApiService } from 'src/app/api.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { formatDate } from '@angular/common';



@Component({
  selector: 'app-all-payment-history',
  templateUrl: './all-payment-history.component.html',
  styleUrls: ['./all-payment-history.component.sass']
})
export class AllPaymentHistoryComponent implements OnInit {

  current : any;
  paymentsummarylist:any;
  paymentsummarytotalcost:number;
  address:string;
  username:string;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder) {
    this.current = new Date().getFullYear()+'-'+("0" + (new Date().getMonth() + 1)).slice(-2)
    let startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let enddate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    let sdate = {
      "_d":startdate
    }
    let edate = {
      "_d":enddate
    }
    let a = {
      startDate:sdate,
      endDate:edate
    }
  
    this.current = a;
    
    this.paymentsearch();
    this.preferencedetails();
  }
  ngOnInit() {
  }

  public onDate(event): void {
    this.paymentsearch();
  }


  paymentsearch(){
    this.saveInLocal("payment_date", this.current);
    let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
     
    this._apiService.pdfdate = this.current;
    let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate}
    this._apiService.searchgetpaymentsummary(a).subscribe(
      (data:any) => {

        if(data.length == 0){
          this.paymentsummarylist =[];
          this.paymentsummarytotalcost = 0;
        }else{
          this.paymentsummarytotalcost = 0;
          var groups = data.reduce(function(obj,item){
            obj[item.date] = obj[item.date] || [];
            obj[item.date].push({date:item.date,cardnumber:item.cardnumber,cost:item.cost});
            return obj;
        }, {});
        this.paymentsummarylist = Object.keys(groups).map(function(key){
            return {team: key,total:0,name: groups[key]};
        });
  
  var result = [];
  data.reduce(function(res, value) {
    if (!res[value.date]) {
      res[value.date] = { date: value.date, cost: 0 };
      result.push(res[value.date])
    }
    res[value.date].cost += +value.cost;
    return res;
  }, {});
  
  for(let a = 0 ; a < this.paymentsummarylist.length ; a ++){
    for(let b = 0 ; b < result.length ; b ++){
      if(this.paymentsummarylist[a].team == result[b].date){
          this.paymentsummarylist[a].total = +result[b].cost
      }
    }
  }
          data.forEach(element => {
            this.paymentsummarytotalcost = this.paymentsummarytotalcost + +element.cost
          });
        }
      }
      );
  
  
  
  
  
  }


  preferencedetails(){
    let a = {user_id:this.getFromLocal("user_id")}
    this._apiService.getperferencDetails(a).subscribe(
      (data:any) => {

        if(data.length == 0){
        }else{
        // this.preference = data;   

        this.address = data[0].address1 +", " + data[0].state + ", " + data[0].city + " - " +data[0].postalcode +", "+data[0].country;
        this.username = data[0].business_name;
        }
      }
      );
  }



  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }

}

import { Component, OnInit } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass']
})
export class ProfileComponent implements OnInit {

  responsive: boolean;
  navmode: string;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _router: Router
  ) { }

  ngOnInit() {
    this._breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.loadResponsiveLayout();
      } else {
        this.loadDesktopLayout();
      }
    });
  }

  loadResponsiveLayout() {
    this.responsive = true;
    this.navmode = 'over';
  }

  loadDesktopLayout() {
    this.responsive = false;
    this.navmode = 'side';
  }

}

import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ApiService } from 'src/app/api.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-all-product-usage',
  templateUrl: './all-product-usage.component.html',
  styleUrls: ['./all-product-usage.component.sass']
})
export class AllProductUsageComponent implements OnInit {
  usagesummarylist:any;
  usagesummarytotalcost:number;

  accordionVisible:boolean = false;
  current : any;
  value:any = [];

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder) {
    this.current = new Date().getFullYear()+'-'+("0" + (new Date().getMonth() + 1)).slice(-2)
    let startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let enddate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    let sdate = {
      "_d":startdate
    }
    let edate = {
      "_d":enddate
    }
    let a = {
      startDate:sdate,
      endDate:edate
    }
  
    this.current = a;
    this.usagesummary();
  }

  ngOnInit() {
  }

  usagesummary(){
    this.saveInLocal("payment_date", this.current);
    let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    this.usagesummarylist = [];
    let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate }
    this._apiService.searchgetusasgesummary(a).subscribe(
      (data:any) => {
        if(data.length == 0){
          this.usagesummarylist = [];
          this.usagesummarytotalcost = 0;
        }else{
          this.usagesummarytotalcost = 0;
          var groups = data.reduce(function(obj,item){
            obj[item.product] = obj[item.product] || [];
            obj[item.product].push({product:item.product,platform:item.platform,cost:item.cost});
            return obj;
        }, {});
        this.usagesummarylist = Object.keys(groups).map(function(key){
            return {team: key,total:0,name: groups[key]};
        });

  var result = [];
  data.reduce(function(res, value) {
    if (!res[value.product]) {
      res[value.product] = { product: value.product, cost: 0 };
      result.push(res[value.product])
    }
    res[value.product].cost += +value.cost;
    return res;
  }, {});

  for(let a = 0 ; a < this.usagesummarylist.length ; a ++){
    for(let b = 0 ; b < result.length ; b ++){
      if(this.usagesummarylist[a].team == result[b].product){
          this.usagesummarylist[a].total = +result[b].cost
      }
    }
  }
          data.forEach(element => {
            this.usagesummarytotalcost = this.usagesummarytotalcost + +element.cost
          });
          // this.usagesummarylist = data;
        }
      }
      );
  }

  accordion(event){
    if(event == 1){
      this.accordionVisible = true;

    }else{
      this.accordionVisible = false;
    }
  }



  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }



}

import { Component, OnInit,NgModule,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ApiService } from 'src/app/api.service';
import { FlagjsonService } from 'src/app/flagjson.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { formatDate } from '@angular/common';

import {MatRadioModule} from '@angular/material/radio';

import { Router, ActivatedRoute } from '@angular/router';





@NgModule({
  imports:[BrowserAnimationsModule,MatRadioModule]
})
@Component({
  selector: 'app-billing-overview',
  templateUrl: './billing-overview.component.html',
  styleUrls: ['./billing-overview.component.sass']
})





export class BillingOverviewComponent implements OnInit {

  user_id:string;

  amountcolor:string;
  funactioncard_id:string;
  fundaction:fundaction;
  active = 1;
  walleamout: number;

  promocodevalue:string;

  errormsg:string;
  successmsg:string;

  registerForm: FormGroup;
  submitted = false;
  panelOpenState = false;


  addfunddetails:addfunddetails;
  amountType = 1;
  amountTypeSetting : boolean = true;
  current : any;
  current1 : any;
  current2: any;
  fundcost:number;

  popup11:any;


  showDialog = true;
  closable = true;  
  visibleChange = true;
  visible = false;
  visibleAutorecharge = false;
  visibleSelectPayment = false

  popup1visible:boolean = true;
  preference:preference;
  carddetailslist:any;
  usagesummarylist:any;
  usagesummarytotalcost:number;
  paymentsummarylist:any;
  paymentsummarytotalcost:number;



  ///config////
  configprimarynumber : string;
  addconfig:addconfig;
  flagslist:any;





  constructor(private _router: Router, @Inject(LOCAL_STORAGE) private storage: WebStorageService,private http: HttpClient,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder,private _flags :FlagjsonService) {
    this.flagslist = this._flags.FlagjsonServicez;
    this.fundaction = new fundaction();
    this.preference = new preference();
    this.addconfig = new addconfig();
    this.addfunddetails = new addfunddetails();
    this.fundaction.payment_type = "Payment Method"
    this.user_id = this.getFromLocal("user_id");
  }



  ngOnInit() {
    this.preferencedetails();
    this.cardlist();
    
    let startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let enddate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    let sdate = {
      "_d":startdate
    }
    let edate = {
      "_d":enddate
    }
    let a = {
      startDate:sdate,
      endDate:edate
    }
  
    this.current = a;

    this.usagesummary();
    this.paymentsearch();
    this.fetchconfig();
    this.walletcheck();
  }








amountTypeChange(data){
  this.fundaction.payment_type = data;
  if(data == 'Payment'){   
    this.active = 1;
    this.amountTypeSetting = true;
    this.fundaction.payment_type = "Payment Method"
  

  }else{    
    this.active = 0;     

    this.amountTypeSetting = false;
    this.fundaction.payment_type = "Promocode"
  }
}





preferencedetails(){
  let a = {user_id:this.getFromLocal("user_id")}
  this._apiService.getperferencDetails(a).subscribe(
    (data:any) => {

      if(data.length == 0){
      }else{
      // this.preference = data;
      this.preference.Business_tax_id = data[0].Business_tax_id;
      this.preference.address1 = data[0].address1;
      this.preference.address2 = data[0].address2;
      this.preference.business_name = data[0].business_name;
      this.preference.city = data[0].city;
      this.preference.country = data[0].country;
      this.preference.postalcode = data[0].postalcode;
      this.preference.state = data[0].state;

      }
    }
    );
}

usagesummary(){

  let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
  let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
  this.usagesummarylist = [];
  let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate }
  this._apiService.searchgetusasgesummary(a).subscribe(
    (data:any) => {
      if(data.length == 0){
        this.usagesummarylist = [];
        this.usagesummarytotalcost = 0;
      }else{
        this.usagesummarytotalcost = 0;
        var groups = data.reduce(function(obj,item){
          obj[item.product] = obj[item.product] || [];
          obj[item.product].push({product:item.product,platform:item.platform,cost:item.cost});
          return obj;
      }, {});
      this.usagesummarylist = Object.keys(groups).map(function(key){
          return {team: key,total:0,name: groups[key]};
      });

var result = [];
data.reduce(function(res, value) {
  if (!res[value.product]) {
    res[value.product] = { product: value.product, cost: 0 };
    result.push(res[value.product])
  }
  res[value.product].cost += +value.cost;
  return res;
}, {});

for(let a = 0 ; a < this.usagesummarylist.length ; a ++){
  for(let b = 0 ; b < result.length ; b ++){
    if(this.usagesummarylist[a].team == result[b].product){
        this.usagesummarylist[a].total = +result[b].cost
    }
  }
}
        data.forEach(element => {
          this.usagesummarytotalcost = this.usagesummarytotalcost + +element.cost
        });
        // this.usagesummarylist = data;
      }
    }
    );

}


paymentsearch(){

  let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
  let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');

  this._apiService.pdfdate = this.current;
  let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate}
  this._apiService.searchgetpaymentsummary(a).subscribe(
    (data:any) => {
      if(data.length == 0){
        this.paymentsummarylist =[];
        this.paymentsummarytotalcost = 0;
      }else{
        this.paymentsummarytotalcost = 0;
        var groups = data.reduce(function(obj,item){
          obj[item.date] = obj[item.date] || [];
          obj[item.date].push({date:item.date,cardnumber:item.cardnumber,cost:item.cost});
          return obj;
      }, {});
      this.paymentsummarylist = Object.keys(groups).map(function(key){
          return {team: key,total:0,name: groups[key]};
      });

var result = [];
data.reduce(function(res, value) {
  if (!res[value.date]) {
    res[value.date] = { date: value.date, cost: 0 };
    result.push(res[value.date])
  }
  res[value.date].cost += +value.cost;
  return res;
}, {});

for(let a = 0 ; a < this.paymentsummarylist.length ; a ++){
  for(let b = 0 ; b < result.length ; b ++){
    if(this.paymentsummarylist[a].team == result[b].date){
        this.paymentsummarylist[a].total = +result[b].cost
    }
  }
}
        data.forEach(element => {
          this.paymentsummarytotalcost = this.paymentsummarytotalcost + +element.cost
        });
      }
    }
    );
}






cardlist(){
  let a = {user_id: this.getFromLocal("user_id")}
  this._apiService.getCardDetailsList(a).subscribe(
    (data:any) => {
     if(data.length == 0 ){
       this.carddetailslist = [];
     }else {
      this.carddetailslist = [];
      
      data.forEach(element => {

        this._apiService.fetchcarddetails({token:element.card_id}).subscribe(
                    (data:any) => {
         
                   let b = {
                         id:element._id,
                        //  type: data.card.brand,
                         card_id:element.card_id,
                         country:data.metadata.country,
                         ccvno: "",
                         expdate : data.metadata.exp_month +"/"+ data.metadata.exp_year,
                         cardnumbers:data.metadata.card_no.substr(data.metadata.card_no.length - 4),
                         cardnumber : " **** **** **** " + data.metadata.card_no.substr(data.metadata.card_no.length - 4),
                         name : element.cardname,
                         address:element.address,
                         status: element.status
                       }
                       if(element.status == "Primary"){
                        this.fundaction.payment_card_number  = " **** **** **** " + data.metadata.card_no.substr(data.metadata.card_no.length - 4);
                        this.fundaction.payment_card_id = element.card_id;
                        this.fundaction.payment_country =data.metadata.country;
                       }
                       this.carddetailslist.push(b);
                      }
                      );
      });
    }
  }
  );
}


walletcheck(){
  let a = {user_id : this.getFromLocal("user_id")}
  this._apiService.fetchwalletamount(a).subscribe(
    (data:any) => {

      this.walleamout = data;
      if(this.walleamout < 0)
      {
        this.amountcolor = "red";
      }
      else{
        this.amountcolor = "Green";
      }
    }
    );
}


reset(){
  setTimeout(() =>{ this.errormsg =""},2000);
  setTimeout(() =>{ this.successmsg =""},2000);
}


insertconfig(rechageamount,balancefall){
  if(this.carddetailslist.length == 0)
  {

    this.errormsg ="There is No Card Found. Please Add the Card details in Payment Method";
    this.reset()
  }else if(rechageamount == undefined){
    alert("Enter At least $1 Amount in Recharge Balance")
  }else if(balancefall == undefined){
    alert("Enter At least $1 Amount in Balance falls below")
  }else if(+rechageamount < 1){
    alert("Enter At least $1 Amount in Recharge Balance")
  }else if(+balancefall < 1){
    alert("Enter At least $1 Amount in Balance falls below")
  }else{
    let a = {
      card_id : this.addconfig.cardnumber ,
      autorecharge: this.addconfig.autorecharge ,
      rechageamount:rechageamount ,
      balancefall:balancefall ,
       }
    this._apiService.insertconfig(a).subscribe(
      (data:any) => {
         this.successmsg = "Update Successfully";
         this.reset()
         setTimeout(() => {
          this.close()
       }, 3000)
        //  this.close();
      }
      );
  }
}


fetchconfig(){
  let a = {user_id : this.getFromLocal("user_id")}
  this._apiService.fetchconfig(a).subscribe(
    (data:any) => {
      if(data.length == 0 ){
      }else{
      this._apiService.fetchcarddetails({token:data[0].pcard_id}).subscribe(
        (datas:any) => {
      console.log(datas);
      this.addfunddetails.cardnumber = " **** **** **** " + datas.metadata.card_no.substr(datas.metadata.card_no.length - 4),
      this.addconfig.cardnumber = data[0].pcard_id
      this.addconfig.autorecharge = data[0].autorecharge
      this.addconfig.rechageamount = data[0].rechageamount
      this.addconfig.balancefall = data[0].balancefall
    }
    );
    }
  }
    );
}








open(){

  // this.showDialog =true;
// this.closable = true;
  this.visible = true;
  // this.visibleChange = !true;

}
openAutorecharge(){

  // this.showDialog =true;
// this.closable = true;
  this.visibleAutorecharge = true;
  // this.visibleChange = !true;

}
openSelectpayment(){
  this.visible = false;
  this.visibleAutorecharge = false;
  this.visibleSelectPayment = true;

  if(this.carddetailslist.length == 0)
  {

    this.errormsg = "There is No Card Found. Please Add the Card details in Payment Method";
    this.reset()

    this._router.navigateByUrl('/dashboard/billing/paymentmethods');
  }
}
close() {
  this.visible = false;
  this.visibleAutorecharge = false;
  this.visibleSelectPayment = false;
  this.visibleChange = this.visible;
}



onchange(data){
  this.fundaction.payment_card_number = data;
this.carddetailslist.forEach(element => {
  if(this.fundaction.payment_card_number == element.cardnumber){
    this.fundaction.payment_card_id = element.card_id;
    this.fundaction.payment_country = element.country;
  }
 });

 this.close()
}

configon(data){
  this.addconfig.autorecharge = data;
}


pay(){
  if(this.fundaction.payment_type == "Promocode"){
    this.promocode();
  }else if(this.fundaction.payment_card_id == undefined){

    this.errormsg = "There is No Card Found. Please Add the Card details in Payment Method";
    this.reset()
  }
  else if (+this.fundaction.payment_amount < 1){
    this.errormsg = "Enter At least $1 Amount"
    this.reset()
  }else if (this.fundaction.payment_amount == undefined){
    this.errormsg = "Enter At least $1 Amount"
    this.reset()

  }else{
    let code = this.flagslist = this._flags.countrycode;
    for(let a = 0 ; a < code.length ; a ++){
      if(code[a].country == this.fundaction.payment_country){
          this.fundaction.currency_code = code[a].currency_code;
          console.log(this.fundaction.currency_code);
          this.fundaction.user_id = this.getFromLocal("user_id");
                console.log(this.fundaction);
                this._apiService.insertpayment(this.fundaction).subscribe(
                  (data:any) => {
                    this.errormsg = data.Result;
                    this.reset()
                     this.ngOnInit();
                     setTimeout(() => {
                      this.close()
                   }, 3000)
                  }
                );
      }
    }

  }




}

promocode(){


  if(this.promocodevalue == undefined){

    this.errormsg = "Enter PromoCode";
    this.reset()
  }else if(this.promocodevalue.length == 0){
    this.errormsg = "Enter PromoCode";
    this.reset()

  }else{
    let a = {
      user_id:this.getFromLocal("user_id"),
      promoCode:this.promocodevalue
    }
    this._apiService.promocodeinster(a).subscribe(
      (data:any) => {
         this.errormsg = data.Result;
         this.reset()
         this.ngOnInit();
         setTimeout(() => {
            this.close()
         }, 3000)
        //  this.close()
      }
    );
  }
}




saveInLocal(key, val): void {

  this.storage.set(key, val);
}

getFromLocal(key): any {

  return this.storage.get(key);
}



}





class preference {
  user_id:string;
  country:string;
  business_name:string;
  Business_tax_id:string;
  address1:string;
  address2:string;
  city:string;
  state:string;
  postalcode:string;
}



class addconfig {
  cardnumber:string;
  autorecharge:string;
  rechageamount:string;
  balancefall:string;
}



class addfunddetails {
  product:string;
  platfrom:string;
  status:string;
  user_id:string;
  cost:string;
  cardnumber:string;
  cardname:string;
  carttype:string;
  expdate:string;
  ccvno:string;
  refid:string;
}



class fundaction {
  payment_card_number:string;
  payment_type:string;
  payment_card_id:string;
  payment_amount:string;
  payment_country:string;
  currency_code:string;
  user_id : string;
}







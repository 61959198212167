import { Injectable } from '@angular/core';
import { AuthInterceptor } from './auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class FlagjsonService {

  FlagjsonServicez = 
  [{"id":4,"name":"Afghanistan","alpha2":"../assets/flags/af.png","alpha3":"afg"},
  {"id":8,"name":"Albania","alpha2":"../assets/flags/al.png","alpha3":"alb"},
  {"id":12,"name":"Algeria","alpha2":"../assets/flags/dz.png","alpha3":"dza"},
  {"id":20,"name":"Andorra","alpha2":"../assets/flags/ad.png","alpha3":"and"},
  {"id":24,"name":"Angola","alpha2":"../assets/flags/ao.png","alpha3":"ago"},
  {"id":28,"name":"Antigua and Barbuda","alpha2":"../assets/flags/ag.png","alpha3":"atg"},
  {"id":32,"name":"Argentina","alpha2":"../assets/flags/ar.png","alpha3":"arg"},
  {"id":51,"name":"Armenia","alpha2":"../assets/flags/am.png","alpha3":"arm"},
  {"id":36,"name":"Australia","alpha2":"../assets/flags/au.png","alpha3":"aus"},
  {"id":40,"name":"Austria","alpha2":"../assets/flags/at.png","alpha3":"aut"},
  {"id":31,"name":"Azerbaijan","alpha2":"../assets/flags/az.png","alpha3":"aze"},
  {"id":44,"name":"Bahamas","alpha2":"../assets/flags/bs.png","alpha3":"bhs"},
  {"id":48,"name":"Bahrain","alpha2":"../assets/flags/bh.png","alpha3":"bhr"},
  {"id":50,"name":"Bangladesh","alpha2":"../assets/flags/bd.png","alpha3":"bgd"},
  {"id":52,"name":"Barbados","alpha2":"../assets/flags/bb.png","alpha3":"brb"},
  {"id":112,"name":"Belarus","alpha2":"../assets/flags/by.png","alpha3":"blr"},
  {"id":56,"name":"Belgium","alpha2":"../assets/flags/be.png","alpha3":"bel"},
  {"id":84,"name":"Belize","alpha2":"../assets/flags/bz.png","alpha3":"blz"},
  {"id":204,"name":"Benin","alpha2":"../assets/flags/bj.png","alpha3":"ben"},
  {"id":64,"name":"Bhutan","alpha2":"../assets/flags/bt.png","alpha3":"btn"},
  {"id":68,"name":"Bolivia ","alpha2":"../assets/flags/bo.png","alpha3":"bol"},
  {"id":70,"name":"Bosnia and Herzegovina","alpha2":"../assets/flags/ba.png","alpha3":"bih"},
  {"id":72,"name":"Botswana","alpha2":"../assets/flags/bw.png","alpha3":"bwa"},
  {"id":76,"name":"Brazil","alpha2":"../assets/flags/br.png","alpha3":"bra"},
  {"id":96,"name":"Brunei Darussalam","alpha2":"../assets/flags/bn.png","alpha3":"brn"},
  {"id":100,"name":"Bulgaria","alpha2":"../assets/flags/bg.png","alpha3":"bgr"},
  {"id":854,"name":"Burkina Faso","alpha2":"../assets/flags/bf.png","alpha3":"bfa"},
  {"id":108,"name":"Burundi","alpha2":"../assets/flags/bi.png","alpha3":"bdi"},
  {"id":132,"name":"Cabo Verde","alpha2":"../assets/flags/cv.png","alpha3":"cpv"},
  {"id":116,"name":"Cambodia","alpha2":"../assets/flags/kh.png","alpha3":"khm"},
  {"id":120,"name":"Cameroon","alpha2":"../assets/flags/cm.png","alpha3":"cmr"},
  {"id":124,"name":"Canada","alpha2":"../assets/flags/ca.png","alpha3":"can"},
  {"id":140,"name":"Central African Republic","alpha2":"../assets/flags/cf.png","alpha3":"caf"},
  {"id":148,"name":"Chad","alpha2":"../assets/flags/td.png","alpha3":"tcd"},
  {"id":152,"name":"Chile","alpha2":"../assets/flags/cl.png","alpha3":"chl"},
  {"id":156,"name":"China","alpha2":"../assets/flags/cn.png","alpha3":"chn"},
  {"id":170,"name":"Colombia","alpha2":"../assets/flags/co.png","alpha3":"col"},
  {"id":174,"name":"Comoros","alpha2":"../assets/flags/km.png","alpha3":"com"},
  {"id":178,"name":"Congo","alpha2":"../assets/flags/cg.png","alpha3":"cog"},
  {"id":180,"name":"Congo,","alpha2":"../assets/flags/cd.png","alpha3":"cod"},
  {"id":188,"name":"Costa Rica","alpha2":"../assets/flags/cr.png","alpha3":"cri"},
  {"id":384,"name":"Côte d'Ivoire","alpha2":"../assets/flags/ci.png","alpha3":"civ"},
  {"id":191,"name":"Croatia","alpha2":"../assets/flags/hr.png","alpha3":"hrv"},
  {"id":192,"name":"Cuba","alpha2":"../assets/flags/cu.png","alpha3":"cub"},
  {"id":196,"name":"Cyprus","alpha2":"../assets/flags/cy.png","alpha3":"cyp"},
  {"id":203,"name":"Czechia","alpha2":"../assets/flags/cz.png","alpha3":"cze"},
  {"id":208,"name":"Denmark","alpha2":"../assets/flags/dk.png","alpha3":"dnk"},
  {"id":262,"name":"Djibouti","alpha2":"../assets/flags/dj.png","alpha3":"dji"},
  {"id":212,"name":"Dominica","alpha2":"../assets/flags/dm.png","alpha3":"dma"},
  {"id":214,"name":"Dominican Republic","alpha2":"../assets/flags/do.png","alpha3":"dom"},
  {"id":218,"name":"Ecuador","alpha2":"../assets/flags/ec.png","alpha3":"ecu"},
  {"id":818,"name":"Egypt","alpha2":"../assets/flags/eg.png","alpha3":"egy"},
  {"id":222,"name":"El Salvador","alpha2":"../assets/flags/sv.png","alpha3":"slv"},
  {"id":226,"name":"Equatorial Guinea","alpha2":"../assets/flags/gq.png","alpha3":"gnq"},
  {"id":232,"name":"Eritrea","alpha2":"../assets/flags/er.png","alpha3":"eri"},
  {"id":233,"name":"Estonia","alpha2":"../assets/flags/ee.png","alpha3":"est"},
  {"id":748,"name":"Eswatini","alpha2":"../assets/flags/sz.png","alpha3":"swz"},
  {"id":231,"name":"Ethiopia","alpha2":"../assets/flags/et.png","alpha3":"eth"},
  {"id":242,"name":"Fiji","alpha2":"../assets/flags/fj.png","alpha3":"fji"},
  {"id":246,"name":"Finland","alpha2":"../assets/flags/fi.png","alpha3":"fin"},
  {"id":250,"name":"France","alpha2":"../assets/flags/fr.png","alpha3":"fra"},
  {"id":266,"name":"Gabon","alpha2":"../assets/flags/ga.png","alpha3":"gab"},
  {"id":270,"name":"Gambia","alpha2":"../assets/flags/gm.png","alpha3":"gmb"},
  {"id":268,"name":"Georgia","alpha2":"../assets/flags/ge.png","alpha3":"geo"},
  {"id":276,"name":"Germany","alpha2":"../assets/flags/de.png","alpha3":"deu"},
  {"id":288,"name":"Ghana","alpha2":"../assets/flags/gh.png","alpha3":"gha"},
  {"id":300,"name":"Greece","alpha2":"../assets/flags/gr.png","alpha3":"grc"},
  {"id":308,"name":"Grenada","alpha2":"../assets/flags/gd.png","alpha3":"grd"},
  {"id":320,"name":"Guatemala","alpha2":"../assets/flags/gt.png","alpha3":"gtm"},
  {"id":324,"name":"Guinea","alpha2":"../assets/flags/gn.png","alpha3":"gin"},
  {"id":624,"name":"Guinea-Bissau","alpha2":"../assets/flags/gw.png","alpha3":"gnb"},
  {"id":328,"name":"Guyana","alpha2":"../assets/flags/gy.png","alpha3":"guy"},
  {"id":332,"name":"Haiti","alpha2":"../assets/flags/ht.png","alpha3":"hti"},
  {"id":340,"name":"Honduras","alpha2":"../assets/flags/hn.png","alpha3":"hnd"},
  {"id":348,"name":"Hungary","alpha2":"../assets/flags/hu.png","alpha3":"hun"},
  {"id":352,"name":"Iceland","alpha2":"../assets/flags/is.png","alpha3":"isl"},
  {"id":356,"name":"India","alpha2":"../assets/flags/in.png","alpha3":"ind"},
  {"id":360,"name":"Indonesia","alpha2":"../assets/flags/id.png","alpha3":"idn"},
  {"id":364,"name":"Iran ","alpha2":"../assets/flags/ir.png","alpha3":"irn"},
  {"id":368,"name":"Iraq","alpha2":"../assets/flags/iq.png","alpha3":"irq"},
  {"id":372,"name":"Ireland","alpha2":"../assets/flags/ie.png","alpha3":"irl"},
  {"id":376,"name":"Israel","alpha2":"../assets/flags/il.png","alpha3":"isr"},
  {"id":380,"name":"Italy","alpha2":"../assets/flags/it.png","alpha3":"ita"},
  {"id":388,"name":"Jamaica","alpha2":"../assets/flags/jm.png","alpha3":"jam"},
  {"id":392,"name":"Japan","alpha2":"../assets/flags/jp.png","alpha3":"jpn"},
  {"id":400,"name":"Jordan","alpha2":"../assets/flags/jo.png","alpha3":"jor"},
  {"id":398,"name":"Kazakhstan","alpha2":"../assets/flags/kz.png","alpha3":"kaz"},
  {"id":404,"name":"Kenya","alpha2":"../assets/flags/ke.png","alpha3":"ken"},
  {"id":296,"name":"Kiribati","alpha2":"../assets/flags/ki.png","alpha3":"kir"},
  {"id":408,"name":"Korea ","alpha2":"../assets/flags/kp.png","alpha3":"prk"},
  {"id":410,"name":"Korea, Republic of","alpha2":"../assets/flags/kr.png","alpha3":"kor"},
  {"id":414,"name":"Kuwait","alpha2":"../assets/flags/kw.png","alpha3":"kwt"},
  {"id":417,"name":"Kyrgyzstan","alpha2":"../assets/flags/kg.png","alpha3":"kgz"},
  {"id":418,"name":"Lao People's","alpha2":"../assets/flags/la.png","alpha3":"lao"},
  {"id":428,"name":"Latvia","alpha2":"../assets/flags/lv.png","alpha3":"lva"},
  {"id":422,"name":"Lebanon","alpha2":"../assets/flags/lb.png","alpha3":"lbn"},
  {"id":426,"name":"Lesotho","alpha2":"../assets/flags/ls.png","alpha3":"lso"},
  {"id":430,"name":"Liberia","alpha2":"../assets/flags/lr.png","alpha3":"lbr"},
  {"id":434,"name":"Libya","alpha2":"../assets/flags/ly.png","alpha3":"lby"},
  {"id":440,"name":"Lithuania","alpha2":"../assets/flags/lt.png","alpha3":"ltu"},
  {"id":442,"name":"Luxembourg","alpha2":"../assets/flags/lu.png","alpha3":"lux"},
  {"id":450,"name":"Madagascar","alpha2":"../assets/flags/mg.png","alpha3":"mdg"},
  {"id":454,"name":"Malawi","alpha2":"../assets/flags/mw.png","alpha3":"mwi"},
  {"id":458,"name":"Malaysia","alpha2":"../assets/flags/my.png","alpha3":"mys"},
  {"id":462,"name":"Maldives","alpha2":"../assets/flags/mv.png","alpha3":"mdv"},
  {"id":466,"name":"Mali","alpha2":"../assets/flags/ml.png","alpha3":"mli"},
  {"id":470,"name":"Malta","alpha2":"../assets/flags/mt.png","alpha3":"mlt"},
  {"id":584,"name":"Marshall Islands","alpha2":"../assets/flags/mh.png","alpha3":"mhl"},
  {"id":478,"name":"Mauritania","alpha2":"../assets/flags/mr.png","alpha3":"mrt"},
  {"id":480,"name":"Mauritius","alpha2":"../assets/flags/mu.png","alpha3":"mus"},
  {"id":484,"name":"Mexico","alpha2":"../assets/flags/mx.png","alpha3":"mex"},
  {"id":583,"name":"Micronesia ","alpha2":"../assets/flags/fm.png","alpha3":"fsm"},
  {"id":498,"name":"Moldova, Republic of","alpha2":"../assets/flags/md.png","alpha3":"mda"},
  {"id":492,"name":"Monaco","alpha2":"../assets/flags/mc.png","alpha3":"mco"},
  {"id":496,"name":"Mongolia","alpha2":"../assets/flags/mn.png","alpha3":"mng"},
  {"id":499,"name":"Montenegro","alpha2":"../assets/flags/me.png","alpha3":"mne"},
  {"id":504,"name":"Morocco","alpha2":"../assets/flags/ma.png","alpha3":"mar"},
  {"id":508,"name":"Mozambique","alpha2":"../assets/flags/mz.png","alpha3":"moz"},
  {"id":104,"name":"Myanmar","alpha2":"../assets/flags/mm.png","alpha3":"mmr"},
  {"id":516,"name":"Namibia","alpha2":"../assets/flags/na.png","alpha3":"nam"},
  {"id":520,"name":"Nauru","alpha2":"../assets/flags/nr.png","alpha3":"nru"},
  {"id":524,"name":"Nepal","alpha2":"../assets/flags/np.png","alpha3":"npl"},
  {"id":528,"name":"Netherlands","alpha2":"../assets/flags/nl.png","alpha3":"nld"},
  {"id":554,"name":"New Zealand","alpha2":"../assets/flags/nz.png","alpha3":"nzl"},
  {"id":558,"name":"Nicaragua","alpha2":"../assets/flags/ni.png","alpha3":"nic"},
  {"id":562,"name":"Niger","alpha2":"../assets/flags/ne.png","alpha3":"ner"},
  {"id":566,"name":"Nigeria","alpha2":"../assets/flags/ng.png","alpha3":"nga"},
  {"id":807,"name":"North Macedonia","alpha2":"../assets/flags/mk.png","alpha3":"mkd"},
  {"id":578,"name":"Norway","alpha2":"../assets/flags/no.png","alpha3":"nor"},
  {"id":512,"name":"Oman","alpha2":"../assets/flags/om.png","alpha3":"omn"},
  {"id":586,"name":"Pakistan","alpha2":"../assets/flags/pk.png","alpha3":"pak"},
  {"id":585,"name":"Palau","alpha2":"../assets/flags/pw.png","alpha3":"plw"},
  {"id":591,"name":"Panama","alpha2":"../assets/flags/pa.png","alpha3":"pan"},
  {"id":598,"name":"Papua New Guinea","alpha2":"../assets/flags/pg.png","alpha3":"png"},
  {"id":600,"name":"Paraguay","alpha2":"../assets/flags/py.png","alpha3":"pry"},
  {"id":604,"name":"Peru","alpha2":"../assets/flags/pe.png","alpha3":"per"},
  {"id":608,"name":"Philippines","alpha2":"../assets/flags/ph.png","alpha3":"phl"},
  {"id":616,"name":"Poland","alpha2":"../assets/flags/pl.png","alpha3":"pol"},
  {"id":620,"name":"Portugal","alpha2":"../assets/flags/pt.png","alpha3":"prt"},
  {"id":634,"name":"Qatar","alpha2":"../assets/flags/qa.png","alpha3":"qat"},
  {"id":642,"name":"Romania","alpha2":"../assets/flags/ro.png","alpha3":"rou"},
  {"id":643,"name":"Russian Federation","alpha2":"../assets/flags/ru.png","alpha3":"rus"},
  {"id":646,"name":"Rwanda","alpha2":"../assets/flags/rw.png","alpha3":"rwa"},
  {"id":659,"name":"Saint Kitts and Nevis","alpha2":"../assets/flags/kn.png","alpha3":"kna"},
  {"id":662,"name":"Saint Lucia","alpha2":"../assets/flags/lc.png","alpha3":"lca"},
  {"id":670,"name":"Saint Vincent","alpha2":"../assets/flags/vc.png","alpha3":"vct"},
  {"id":882,"name":"Samoa","alpha2":"../assets/flags/ws.png","alpha3":"wsm"},
  {"id":674,"name":"San Marino","alpha2":"../assets/flags/sm.png","alpha3":"smr"},
  {"id":678,"name":"Sao Tome and Principe","alpha2":"../assets/flags/st.png","alpha3":"stp"},
  {"id":682,"name":"Saudi Arabia","alpha2":"../assets/flags/sa.png","alpha3":"sau"},
  {"id":686,"name":"Senegal","alpha2":"../assets/flags/sn.png","alpha3":"sen"},
  {"id":688,"name":"Serbia","alpha2":"../assets/flags/rs.png","alpha3":"srb"},
  {"id":690,"name":"Seychelles","alpha2":"../assets/flags/sc.png","alpha3":"syc"},
  {"id":694,"name":"Sierra Leone","alpha2":"../assets/flags/sl.png","alpha3":"sle"},
  {"id":702,"name":"Singapore","alpha2":"../assets/flags/sg.png","alpha3":"sgp"},
  {"id":703,"name":"Slovakia","alpha2":"../assets/flags/sk.png","alpha3":"svk"},
  {"id":705,"name":"Slovenia","alpha2":"../assets/flags/si.png","alpha3":"svn"},
  {"id":90,"name":"Solomon Islands","alpha2":"../assets/flags/sb.png","alpha3":"slb"},
  {"id":706,"name":"Somalia","alpha2":"../assets/flags/so.png","alpha3":"som"},
  {"id":710,"name":"South Africa","alpha2":"../assets/flags/za.png","alpha3":"zaf"},
  {"id":728,"name":"South Sudan","alpha2":"../assets/flags/ss.png","alpha3":"ssd"},
  {"id":724,"name":"Spain","alpha2":"../assets/flags/es.png","alpha3":"esp"},
  {"id":144,"name":"Sri Lanka","alpha2":"../assets/flags/lk.png","alpha3":"lka"},
  {"id":729,"name":"Sudan","alpha2":"../assets/flags/sd.png","alpha3":"sdn"},
  {"id":740,"name":"Suriname","alpha2":"../assets/flags/sr.png","alpha3":"sur"},
  {"id":752,"name":"Sweden","alpha2":"../assets/flags/se.png","alpha3":"swe"},
  {"id":756,"name":"Switzerland","alpha2":"../assets/flags/ch.png","alpha3":"che"},
  {"id":760,"name":"Syrian Arab Republic","alpha2":"../assets/flags/sy.png","alpha3":"syr"},
  {"id":762,"name":"Tajikistan","alpha2":"../assets/flags/tj.png","alpha3":"tjk"},
  {"id":834,"name":"Tanzania, United Republic of","alpha2":"../assets/flags/tz.png","alpha3":"tza"},
  {"id":764,"name":"Thailand","alpha2":"../assets/flags/th.png","alpha3":"tha"},
  {"id":626,"name":"Timor-Leste","alpha2":"../assets/flags/tl.png","alpha3":"tls"},
  {"id":768,"name":"Togo","alpha2":"../assets/flags/tg.png","alpha3":"tgo"},
  {"id":776,"name":"Tonga","alpha2":"../assets/flags/to.png","alpha3":"ton"},
  {"id":780,"name":"Trinidad and Tobago","alpha2":"../assets/flags/tt.png","alpha3":"tto"},
  {"id":788,"name":"Tunisia","alpha2":"../assets/flags/tn.png","alpha3":"tun"},
  {"id":792,"name":"Turkey","alpha2":"../assets/flags/tr.png","alpha3":"tur"},
  {"id":795,"name":"Turkmenistan","alpha2":"../assets/flags/tm.png","alpha3":"tkm"},
  {"id":798,"name":"Tuvalu","alpha2":"../assets/flags/tv.png","alpha3":"tuv"},
  {"id":800,"name":"Uganda","alpha2":"../assets/flags/ug.png","alpha3":"uga"},
  {"id":804,"name":"Ukraine","alpha2":"../assets/flags/ua.png","alpha3":"ukr"},
  {"id":784,"name":"United Arab Emirates","alpha2":"../assets/flags/ae.png","alpha3":"are"},
  {"id":826,"name":"United Kingdom ","alpha2":"../assets/flags/gb.png","alpha3":"gbr"},
  {"id":840,"name":"United States of America","alpha2":"../assets/flags/us.png","alpha3":"usa"},
  {"id":858,"name":"Uruguay","alpha2":"../assets/flags/uy.png","alpha3":"ury"},
  {"id":860,"name":"Uzbekistan","alpha2":"../assets/flags/uz.png","alpha3":"uzb"},
  {"id":548,"name":"Vanuatu","alpha2":"../assets/flags/vu.png","alpha3":"vut"},
  {"id":862,"name":"Venezuela","alpha2":"../assets/flags/ve.png","alpha3":"ven"},
  {"id":704,"name":"Viet Nam","alpha2":"../assets/flags/vn.png","alpha3":"vnm"},
  {"id":887,"name":"Yemen","alpha2":"../assets/flags/ye.png","alpha3":"yem"},
  {"id":894,"name":"Zambia","alpha2":"../assets/flags/zm.png","alpha3":"zmb"},
  {"id":716,"name":"Zimbabwe","alpha2":"../assets/flags/zw.png","alpha3":"zwe"}
  ];



  countrycode = [
    {
        "country": "Afghanistan",
        "currency_code": "AFN"
    },
    {
        "country": "Albania",
        "currency_code": "ALL"
    },
    {
        "country": "Algeria",
        "currency_code": "DZD"
    },
    {
        "country": "American Samoa",
        "currency_code": "USD"
    },
    {
        "country": "Andorra",
        "currency_code": "EUR"
    },
    {
        "country": "Angola",
        "currency_code": "AOA"
    },
    {
        "country": "Anguilla",
        "currency_code": "XCD"
    },
    {
        "country": "Antarctica",
        "currency_code": "XCD"
    },
    {
        "country": "Antigua and Barbuda",
        "currency_code": "XCD"
    },
    {
        "country": "Argentina",
        "currency_code": "ARS"
    },
    {
        "country": "Armenia",
        "currency_code": "AMD"
    },
    {
        "country": "Aruba",
        "currency_code": "AWG"
    },
    {
        "country": "Australia",
        "currency_code": "AUD"
    },
    {
        "country": "Austria",
        "currency_code": "EUR"
    },
    {
        "country": "Azerbaijan",
        "currency_code": "AZN"
    },
    {
        "country": "Bahamas",
        "currency_code": "BSD"
    },
    {
        "country": "Bahrain",
        "currency_code": "BHD"
    },
    {
        "country": "Bangladesh",
        "currency_code": "BDT"
    },
    {
        "country": "Barbados",
        "currency_code": "BBD"
    },
    {
        "country": "Belarus",
        "currency_code": "BYR"
    },
    {
        "country": "Belgium",
        "currency_code": "EUR"
    },
    {
        "country": "Belize",
        "currency_code": "BZD"
    },
    {
        "country": "Benin",
        "currency_code": "XOF"
    },
    {
        "country": "Bermuda",
        "currency_code": "BMD"
    },
    {
        "country": "Bhutan",
        "currency_code": "BTN"
    },
    {
        "country": "Bolivia",
        "currency_code": "BOB"
    },
    {
        "country": "Bosnia and Herzegovina",
        "currency_code": "BAM"
    },
    {
        "country": "Botswana",
        "currency_code": "BWP"
    },
    {
        "country": "Bouvet Island",
        "currency_code": "NOK"
    },
    {
        "country": "Brazil",
        "currency_code": "BRL"
    },
    {
        "country": "British Indian Ocean Territory",
        "currency_code": "USD"
    },
    {
        "country": "Brunei",
        "currency_code": "BND"
    },
    {
        "country": "Bulgaria",
        "currency_code": "BGN"
    },
    {
        "country": "Burkina Faso",
        "currency_code": "XOF"
    },
    {
        "country": "Burundi",
        "currency_code": "BIF"
    },
    {
        "country": "Cambodia",
        "currency_code": "KHR"
    },
    {
        "country": "Cameroon",
        "currency_code": "XAF"
    },
    {
        "country": "Canada",
        "currency_code": "CAD"
    },
    {
        "country": "Cape Verde",
        "currency_code": "CVE"
    },
    {
        "country": "Cayman Islands",
        "currency_code": "KYD"
    },
    {
        "country": "Central African Republic",
        "currency_code": "XAF"
    },
    {
        "country": "Chad",
        "currency_code": "XAF"
    },
    {
        "country": "Chile",
        "currency_code": "CLP"
    },
    {
        "country": "China",
        "currency_code": "CNY"
    },
    {
        "country": "Christmas Island",
        "currency_code": "AUD"
    },
    {
        "country": "Cocos (Keeling) Islands",
        "currency_code": "AUD"
    },
    {
        "country": "Colombia",
        "currency_code": "COP"
    },
    {
        "country": "Comoros",
        "currency_code": "KMF"
    },
    {
        "country": "Congo",
        "currency_code": "XAF"
    },
    {
        "country": "Cook Islands",
        "currency_code": "NZD"
    },
    {
        "country": "Costa Rica",
        "currency_code": "CRC"
    },
    {
        "country": "Croatia",
        "currency_code": "HRK"
    },
    {
        "country": "Cuba",
        "currency_code": "CUP"
    },
    {
        "country": "Cyprus",
        "currency_code": "EUR"
    },
    {
        "country": "Czech Republic",
        "currency_code": "CZK"
    },
    {
        "country": "Denmark",
        "currency_code": "DKK"
    },
    {
        "country": "Djibouti",
        "currency_code": "DJF"
    },
    {
        "country": "Dominica",
        "currency_code": "XCD"
    },
    {
        "country": "Dominican Republic",
        "currency_code": "DOP"
    },
    {
        "country": "East Timor",
        "currency_code": "USD"
    },
    {
        "country": "Ecuador",
        "currency_code": "ECS"
    },
    {
        "country": "Egypt",
        "currency_code": "EGP"
    },
    {
        "country": "El Salvador",
        "currency_code": "SVC"
    },
    {
        "country": "England",
        "currency_code": "GBP"
    },
    {
        "country": "Equatorial Guinea",
        "currency_code": "XAF"
    },
    {
        "country": "Eritrea",
        "currency_code": "ERN"
    },
    {
        "country": "Estonia",
        "currency_code": "EUR"
    },
    {
        "country": "Ethiopia",
        "currency_code": "ETB"
    },
    {
        "country": "Falkland Islands",
        "currency_code": "FKP"
    },
    {
        "country": "Faroe Islands",
        "currency_code": "DKK"
    },
    {
        "country": "Fiji Islands",
        "currency_code": "FJD"
    },
    {
        "country": "Finland",
        "currency_code": "EUR"
    },
    {
        "country": "France",
        "currency_code": "EUR"
    },
    {
        "country": "French Guiana",
        "currency_code": "EUR"
    },
    {
        "country": "French Polynesia",
        "currency_code": "XPF"
    },
    {
        "country": "French Southern territories",
        "currency_code": "EUR"
    },
    {
        "country": "Gabon",
        "currency_code": "XAF"
    },
    {
        "country": "Gambia",
        "currency_code": "GMD"
    },
    {
        "country": "Georgia",
        "currency_code": "GEL"
    },
    {
        "country": "Germany",
        "currency_code": "EUR"
    },
    {
        "country": "Ghana",
        "currency_code": "GHS"
    },
    {
        "country": "Gibraltar",
        "currency_code": "GIP"
    },
    {
        "country": "Greece",
        "currency_code": "EUR"
    },
    {
        "country": "Greenland",
        "currency_code": "DKK"
    },
    {
        "country": "Grenada",
        "currency_code": "XCD"
    },
    {
        "country": "Guadeloupe",
        "currency_code": "EUR"
    },
    {
        "country": "Guam",
        "currency_code": "USD"
    },
    {
        "country": "Guatemala",
        "currency_code": "QTQ"
    },
    {
        "country": "Guinea",
        "currency_code": "GNF"
    },
    {
        "country": "Guinea-Bissau",
        "currency_code": "CFA"
    },
    {
        "country": "Guyana",
        "currency_code": "GYD"
    },
    {
        "country": "Haiti",
        "currency_code": "HTG"
    },
    {
        "country": "Heard Island and McDonald Islands",
        "currency_code": "AUD"
    },
    {
        "country": "Holy See (Vatican City State)",
        "currency_code": "EUR"
    },
    {
        "country": "Honduras",
        "currency_code": "HNL"
    },
    {
        "country": "Hong Kong",
        "currency_code": "HKD"
    },
    {
        "country": "Hungary",
        "currency_code": "HUF"
    },
    {
        "country": "Iceland",
        "currency_code": "ISK"
    },
    {
        "country": "India",
        "currency_code": "INR"
    },
    {
        "country": "Indonesia",
        "currency_code": "IDR"
    },
    {
        "country": "Iran",
        "currency_code": "IRR"
    },
    {
        "country": "Iraq",
        "currency_code": "IQD"
    },
    {
        "country": "Ireland",
        "currency_code": "EUR"
    },
    {
        "country": "Israel",
        "currency_code": "ILS"
    },
    {
        "country": "Italy",
        "currency_code": "EUR"
    },
    {
        "country": "Ivory Coast",
        "currency_code": "XOF"
    },
    {
        "country": "Jamaica",
        "currency_code": "JMD"
    },
    {
        "country": "Japan",
        "currency_code": "JPY"
    },
    {
        "country": "Jordan",
        "currency_code": "JOD"
    },
    {
        "country": "Kazakhstan",
        "currency_code": "KZT"
    },
    {
        "country": "Kenya",
        "currency_code": "KES"
    },
    {
        "country": "Kiribati",
        "currency_code": "AUD"
    },
    {
        "country": "Kuwait",
        "currency_code": "KWD"
    },
    {
        "country": "Kyrgyzstan",
        "currency_code": "KGS"
    },
    {
        "country": "Laos",
        "currency_code": "LAK"
    },
    {
        "country": "Latvia",
        "currency_code": "LVL"
    },
    {
        "country": "Lebanon",
        "currency_code": "LBP"
    },
    {
        "country": "Lesotho",
        "currency_code": "LSL"
    },
    {
        "country": "Liberia",
        "currency_code": "LRD"
    },
    {
        "country": "Libyan Arab Jamahiriya",
        "currency_code": "LYD"
    },
    {
        "country": "Liechtenstein",
        "currency_code": "CHF"
    },
    {
        "country": "Lithuania",
        "currency_code": "LTL"
    },
    {
        "country": "Luxembourg",
        "currency_code": "EUR"
    },
    {
        "country": "Macao",
        "currency_code": "MOP"
    },
    {
        "country": "North Macedonia",
        "currency_code": "MKD"
    },
    {
        "country": "Madagascar",
        "currency_code": "MGF"
    },
    {
        "country": "Malawi",
        "currency_code": "MWK"
    },
    {
        "country": "Malaysia",
        "currency_code": "MYR"
    },
    {
        "country": "Maldives",
        "currency_code": "MVR"
    },
    {
        "country": "Mali",
        "currency_code": "XOF"
    },
    {
        "country": "Malta",
        "currency_code": "EUR"
    },
    {
        "country": "Marshall Islands",
        "currency_code": "USD"
    },
    {
        "country": "Martinique",
        "currency_code": "EUR"
    },
    {
        "country": "Mauritania",
        "currency_code": "MRO"
    },
    {
        "country": "Mauritius",
        "currency_code": "MUR"
    },
    {
        "country": "Mayotte",
        "currency_code": "EUR"
    },
    {
        "country": "Mexico",
        "currency_code": "MXN"
    },
    {
        "country": "Micronesia, Federated States of",
        "currency_code": "USD"
    },
    {
        "country": "Moldova",
        "currency_code": "MDL"
    },
    {
        "country": "Monaco",
        "currency_code": "EUR"
    },
    {
        "country": "Mongolia",
        "currency_code": "MNT"
    },
    {
        "country": "Montserrat",
        "currency_code": "XCD"
    },
    {
        "country": "Morocco",
        "currency_code": "MAD"
    },
    {
        "country": "Mozambique",
        "currency_code": "MZN"
    },
    {
        "country": "Myanmar",
        "currency_code": "MMR"
    },
    {
        "country": "Namibia",
        "currency_code": "NAD"
    },
    {
        "country": "Nauru",
        "currency_code": "AUD"
    },
    {
        "country": "Nepal",
        "currency_code": "NPR"
    },
    {
        "country": "Netherlands",
        "currency_code": "EUR"
    },
    {
        "country": "Netherlands Antilles",
        "currency_code": "ANG"
    },
    {
        "country": "New Caledonia",
        "currency_code": "XPF"
    },
    {
        "country": "New Zealand",
        "currency_code": "NZD"
    },
    {
        "country": "Nicaragua",
        "currency_code": "NIO"
    },
    {
        "country": "Niger",
        "currency_code": "XOF"
    },
    {
        "country": "Nigeria",
        "currency_code": "NGN"
    },
    {
        "country": "Niue",
        "currency_code": "NZD"
    },
    {
        "country": "Norfolk Island",
        "currency_code": "AUD"
    },
    {
        "country": "North Korea",
        "currency_code": "KPW"
    },
    {
        "country": "Northern Ireland",
        "currency_code": "GBP"
    },
    {
        "country": "Northern Mariana Islands",
        "currency_code": "USD"
    },
    {
        "country": "Norway",
        "currency_code": "NOK"
    },
    {
        "country": "Oman",
        "currency_code": "OMR"
    },
    {
        "country": "Pakistan",
        "currency_code": "PKR"
    },
    {
        "country": "Palau",
        "currency_code": "USD"
    },
    {
        "country": "Palestine",
        "currency_code": null
    },
    {
        "country": "Panama",
        "currency_code": "PAB"
    },
    {
        "country": "Papua New Guinea",
        "currency_code": "PGK"
    },
    {
        "country": "Paraguay",
        "currency_code": "PYG"
    },
    {
        "country": "Peru",
        "currency_code": "PEN"
    },
    {
        "country": "Philippines",
        "currency_code": "PHP"
    },
    {
        "country": "Pitcairn",
        "currency_code": "NZD"
    },
    {
        "country": "Poland",
        "currency_code": "PLN"
    },
    {
        "country": "Portugal",
        "currency_code": "EUR"
    },
    {
        "country": "Puerto Rico",
        "currency_code": "USD"
    },
    {
        "country": "Qatar",
        "currency_code": "QAR"
    },
    {
        "country": "Reunion",
        "currency_code": "EUR"
    },
    {
        "country": "Romania",
        "currency_code": "RON"
    },
    {
        "country": "Russian Federation",
        "currency_code": "RUB"
    },
    {
        "country": "Rwanda",
        "currency_code": "RWF"
    },
    {
        "country": "Saint Helena",
        "currency_code": "SHP"
    },
    {
        "country": "Saint Kitts and Nevis",
        "currency_code": "XCD"
    },
    {
        "country": "Saint Lucia",
        "currency_code": "XCD"
    },
    {
        "country": "Saint Pierre and Miquelon",
        "currency_code": "EUR"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "currency_code": "XCD"
    },
    {
        "country": "Samoa",
        "currency_code": "WST"
    },
    {
        "country": "San Marino",
        "currency_code": "EUR"
    },
    {
        "country": "Sao Tome and Principe",
        "currency_code": "STD"
    },
    {
        "country": "Saudi Arabia",
        "currency_code": "SAR"
    },
    {
        "country": "Scotland",
        "currency_code": "GBP"
    },
    {
        "country": "Senegal",
        "currency_code": "XOF"
    },
    {
        "country": "Seychelles",
        "currency_code": "SCR"
    },
    {
        "country": "Sierra Leone",
        "currency_code": "SLL"
    },
    {
        "country": "Singapore",
        "currency_code": "SGD"
    },
    {
        "country": "Slovakia",
        "currency_code": "EUR"
    },
    {
        "country": "Slovenia",
        "currency_code": "EUR"
    },
    {
        "country": "Solomon Islands",
        "currency_code": "SBD"
    },
    {
        "country": "Somalia",
        "currency_code": "SOS"
    },
    {
        "country": "South Africa",
        "currency_code": "ZAR"
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "currency_code": "GBP"
    },
    {
        "country": "South Korea",
        "currency_code": "KRW"
    },
    {
        "country": "South Sudan",
        "currency_code": "SSP"
    },
    {
        "country": "Spain",
        "currency_code": "EUR"
    },
    {
        "country": "SriLanka",
        "currency_code": "LKR"
    },
    {
        "country": "Sudan",
        "currency_code": "SDG"
    },
    {
        "country": "Suriname",
        "currency_code": "SRD"
    },
    {
        "country": "Svalbard and Jan Mayen",
        "currency_code": "NOK"
    },
    {
        "country": "Swaziland",
        "currency_code": "SZL"
    },
    {
        "country": "Sweden",
        "currency_code": "SEK"
    },
    {
        "country": "Switzerland",
        "currency_code": "CHF"
    },
    {
        "country": "Syria",
        "currency_code": "SYP"
    },
    {
        "country": "Tajikistan",
        "currency_code": "TJS"
    },
    {
        "country": "Tanzania",
        "currency_code": "TZS"
    },
    {
        "country": "Thailand",
        "currency_code": "THB"
    },
    {
        "country": "The Democratic Republic of Congo",
        "currency_code": "CDF"
    },
    {
        "country": "Togo",
        "currency_code": "XOF"
    },
    {
        "country": "Tokelau",
        "currency_code": "NZD"
    },
    {
        "country": "Tonga",
        "currency_code": "TOP"
    },
    {
        "country": "Trinidad and Tobago",
        "currency_code": "TTD"
    },
    {
        "country": "Tunisia",
        "currency_code": "TND"
    },
    {
        "country": "Turkey",
        "currency_code": "TRY"
    },
    {
        "country": "Turkmenistan",
        "currency_code": "TMT"
    },
    {
        "country": "Turks and Caicos Islands",
        "currency_code": "USD"
    },
    {
        "country": "Tuvalu",
        "currency_code": "AUD"
    },
    {
        "country": "Uganda",
        "currency_code": "UGX"
    },
    {
        "country": "Ukraine",
        "currency_code": "UAH"
    },
    {
        "country": "United Arab Emirates",
        "currency_code": "AED"
    },
    {
        "country": "United Kingdom",
        "currency_code": "GBP"
    },
    {
        "country": "United States",
        "currency_code": "USD"
    },
    {
        "country": "United States Minor Outlying Islands",
        "currency_code": "USD"
    },
    {
        "country": "Uruguay",
        "currency_code": "UYU"
    },
    {
        "country": "Uzbekistan",
        "currency_code": "UZS"
    },
    {
        "country": "Vanuatu",
        "currency_code": "VUV"
    },
    {
        "country": "Venezuela",
        "currency_code": "VEF"
    },
    {
        "country": "Vietnam",
        "currency_code": "VND"
    },
    {
        "country": "Virgin Islands, British",
        "currency_code": "USD"
    },
    {
        "country": "Virgin Islands, U.S.",
        "currency_code": "USD"
    },
    {
        "country": "Wales",
        "currency_code": "GBP"
    },
    {
        "country": "Wallis and Futuna",
        "currency_code": "XPF"
    },
    {
        "country": "Western Sahara",
        "currency_code": "MAD"
    },
    {
        "country": "Yemen",
        "currency_code": "YER"
    },
    {
        "country": "Yugoslavia",
        "currency_code": null
    },
    {
        "country": "Zambia",
        "currency_code": "ZMW"
    },
    {
        "country": "Zimbabwe",
        "currency_code": "ZWD"
    }
]


constructor() {
}



}

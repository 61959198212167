import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData, CurrencyPipe } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { A11yModule } from '@angular/cdk/a11y';
import { ngf, ngfDrop, ngfModule, ngfSelect } from 'angular-file';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIn from '@angular/common/locales/en-IN';
registerLocaleData(localeIn);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignCreateComponent } from './campaign-create/campaign-create.component';
import { SignupComponent } from './signup/signup.component';
import { SignupProfileComponent } from './signup-profile/signup-profile.component';
import { SearchPipe } from './search.pipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { PaymentsComponent } from './payments/payments.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthInterceptor } from './auth.interceptor';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WalletRechargeComponent } from './wallet-recharge/wallet-recharge.component';
import { CampaignInfoComponent } from './campaign-info/campaign-info.component';
import { StatusComponent } from './status/status.component';
import { ReportsComponent } from './reports/reports.component';
import { BillingComponent } from './billing/billing.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';



import { BillingOverviewComponent } from './billing-section/billing-overview/billing-overview.component';
import { BillingPreferenceComponent } from './billing-section/billing-preference/billing-preference.component';
import { BillingMethodComponent } from './billing-section/billing-method/billing-method.component';
import { BillingPricingComponent } from './billing-section/billing-pricing/billing-pricing.component';
import { AllProductUsageComponent } from './billing-section/all-product-usage/all-product-usage.component';
import { AllPaymentHistoryComponent } from './billing-section/all-payment-history/all-payment-history.component';
import { RecieptPdfComponent } from './billing-section/pdf-forms/reciept-pdf/reciept-pdf.component';
import { GraphComponent } from './billing-section/graph/graph.component';
import { PaymentsummarypdfComponent } from './billing-section/pdf-forms/paymentsummarypdf/paymentsummarypdf.component';
import { UsagesummarypdfComponent } from './billing-section/pdf-forms/usagesummarypdf/usagesummarypdf.component';
import { GalleryimageComponent } from './createsection/galleryimage/galleryimage.component';
import { GalleryimageuploadComponent } from './createsection/galleryimageupload/galleryimageupload.component';
import { StorageServiceModule } from 'angular-webstorage-service';
import { NotificationsComponent } from './notifications/notifications.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { UserlistComponent } from './admin/userlist/userlist.component';
import { AdmindashboardComponent } from './admin/admindashboard/admindashboard.component';
import { AdminDashboardListComponent } from './admin/admin-dashboard-list/admin-dashboard-list.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    CampaignComponent,
    CampaignCreateComponent,
    SignupComponent,
    SignupProfileComponent,
    SearchPipe,
    HomeComponent,
    ProfileComponent,
    ProfileInfoComponent,
    PaymentsComponent,
    SettingsComponent,
    ForgotPasswordComponent,
    EmailVerifyComponent,
    ResetPasswordComponent,
    WalletRechargeComponent,
    CampaignInfoComponent,
    StatusComponent,
    ReportsComponent,
    BillingComponent,
    AccountSetupComponent,
    BillingOverviewComponent,
    BillingPreferenceComponent,
    BillingMethodComponent,
    BillingPricingComponent,
    AllProductUsageComponent,
    AllPaymentHistoryComponent,
    RecieptPdfComponent,
    GraphComponent,
    PaymentsummarypdfComponent,
    UsagesummarypdfComponent,
    GalleryimageComponent,
    GalleryimageuploadComponent,
    NotificationsComponent,
    UserlistComponent,
    AdmindashboardComponent,
    AdminDashboardListComponent
  ],
  imports: [
    NgxChartsModule,
    NgxDaterangepickerMd.forRoot(),
    StorageServiceModule,
    PDFExportModule,
    BrowserModule,
    AppRoutingModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    DragDropModule,
    ScrollingModule,
    CdkTableModule,
    CdkTreeModule,
    A11yModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSortModule,
    ngfModule,
    SatDatepickerModule,
    SatNativeDateModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

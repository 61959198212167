


import { Component, OnInit ,Inject} from '@angular/core';
import { AuthService } from '../../auth.service';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import * as shape from 'd3-shape';
import { formatDate } from '@angular/common';







//var CanvasJS = require('./canvasjs.min');
 
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.sass']
})
 
export class GraphComponent implements OnInit {
  nocampaigns:any;
datass = [
  {cost:"3000",date : "Mar 01",platform:"Google"},
  {cost:"4000",date : "Mar 01",platform:"FaceBook"},
  {cost:"5000",date : "Mar 01",platform:"Twitter"},


  {cost:"13000",date : "Mar 02",platform:"Google"},
  {cost:"24000",date : "Mar 02",platform:"FaceBook"},
  {cost:"35000",date : "Mar 02",platform:"Twitter"},


  {cost:"4000",date : "Mar 03",platform:"Google"},
  {cost:"6000",date : "Mar 03",platform:"FaceBook"},
  {cost:"6000",date : "Mar 03",platform:"Twitter"},


  {cost:"12000",date : "Mar 04",platform:"Google"},
  {cost:"11000",date : "Mar 04",platform:"FaceBook"},
  {cost:"9000",date : "Mar 04",platform:"Twitter"},


  {cost:"1000",date : "Mar 05",platform:"Google"},
  {cost:"4000",date : "Mar 05",platform:"FaceBook"},
  {cost:"5000",date : "Mar 05",platform:"Twitter"},


  {cost:"3000",date : "Mar 06",platform:"Google"},
  {cost:"4000",date : "Mar 06",platform:"FaceBook"},
  {cost:"5000",date : "Mar 06",platform:"Twitter"},


  {cost:"3000",date : "Mar 07",platform:"Google"},
  {cost:"4000",date : "Mar 07",platform:"FaceBook"},
  {cost:"5000",date : "Mar 07",platform:"Twitter"},


]




  tokenObject: Object;
  userObject: Object;

  primaryData: Object;
  metricsData: Object;
  analyticsData: Object;

  pageload: boolean;

  isCampaignNull = false;

  curve = shape.curveMonotoneX;


  timePeriodArray = [
    {
      name: 'Last 7 Days',
      value: 'LAST_7_DAYS'
    },
    {
      name: 'Last 14 Days',
      value: 'LAST_14_DAYS'
    },
    {
      name: 'Last 30 Days',
      value: 'LAST_30_DAYS'
    },
    {
      name: 'Last Business Week',
      value: 'LAST_BUSINESS_WEEK'
    },
    {
      name: 'This Month',
      value: 'THIS_MONTH'
    },
    {
      name: 'Last Month',
      value: 'LAST_MONTH'
    },
  ];

  timePeriod = { name: 'Last 30 Days', value: 'LAST_30_DAYS' };
  platforms = {name:"Google", value:"Google", imgPath:"https://airbots.ai/demo/airbots-saas-platform/assets/platforms/gad.jpg"};

  colorScheme = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A']
  };

  colorSchemeSecondary = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A', '#03A9F4']
  };

  colorSchemeGlobal = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A', '#03A9F4']
  };

  chartColors = {
    Google: '#673AB7',
    FaceBook: '#E91E63',
    Twitter: '#FFC107',
  };

  primaryAvailableDates = [];
  secondaryAvailableDates = [];
  primaryColorLegend = {
    Google: '2px solid #673AB7',
    FaceBook: '2px solid #E91E63',
    Twitter: '2px solid #FFC107',
  };


  campaignsList = [];

  data1ToggleStore = [];
  data3ToggleStore = [];

  data1 = [
    {
      'name': 'Google',
      'series': [
        {
          'name': 'Mar 27',
          'value': 23
        },
        {
          'name': 'Mar 28',
          'value': 25
        },
        {
          'name': 'Mar 29',
          'value': 20
        },
        {
          'name': 'Mar 30',
          'value': 23
        },
        {
          'name': 'Mar 31',
          'value': 25
        },
        {
          'name': 'Mar 26',
          'value': 20
        },
      ]
    },
    {
      'name': 'FaceBook',
      'series': [
        {
          'name': 'Mar 27',
          'value': 12000
        },
        {
          'name': 'Mar 28',
          'value': 12340
        },
        {
          'name': 'Mar 29',
          'value': 8230
        },
        {
          'name': 'Mar 30',
          'value': 12000
        },
        {
          'name': 'Mar 31',
          'value': 12340
        },
        {
          'name': 'Mar 26',
          'value': 8230
        },
      ]
    },
    {
      'name': 'Twitter',
      'series': [
        {
          'name': 'Mar 27',
          'value': 8034
        },
        {
          'name': 'Mar 28',
          'value': 10230
        },
        {
          'name': 'Mar 29',
          'value': 7345
        },
        {
          'name': 'Mar 30',
          'value': 10004
        },
        {
          'name': 'Mar 31',
          'value': 9804
        },
        {
          'name': 'Mar 26',
          'value': 6500
        },
      ]
    }
  ];
  current:any;
  dollarRate: number;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService, private _router: Router) {
    this.current = new Date().getFullYear()+'-'+("0" + (new Date().getMonth() + 1)).slice(-2)
    let startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let enddate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    let sdate = {
      "_d":startdate
    }
    let edate = {
      "_d":enddate
    }
    let a = {
      startDate:sdate,
      endDate:edate
    }
  
    this.current = a;

   }

  ngOnInit() {
    this.pageload = true;
    this.saveInLocal("payment_date", this.current);
    let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate }
    this._apiService.searchgetusasgesummary(a).subscribe(
      (data:any) => {
var result = [];
data.reduce(function(res, value) {
  if (!res[value.platform]) {
    res[value.platform] = { platform: value.platform, date:value.date, cost: 0 };
    result.push(res[value.platform])
  }
  res[value.platform].cost += +value.cost;
  return res;
}, {});
console.log(result);
        console.log(data);
        if(data.length == 0){

        }else{
          //  this.updateChartData(this.datass);
          this.updateChartData(result);
        }
       
      }
      );
  }


  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }



  togglePrimary(key) {
    let flag = true;
    this.data1.forEach((element) => {
      if (element.name === key) {
        this.data1ToggleStore.push(element);
        this.data1.splice(this.data1.indexOf(element), 1);
        this.colorScheme.domain.splice(this.colorScheme.domain.indexOf(this.chartColors[key]), 1);
        flag = false;
        this.primaryColorLegend[key] = '2px solid #ddd';
        this.data1.forEach((el, i) => {
          this.primaryColorLegend[el.name] = '2px solid ' + this.chartColors[el.name];
        });
      }
    });
    if (flag) {
      this.data1ToggleStore.forEach(ele => {
        if (ele.name === key) {
          this.data1.push(ele);
          this.colorScheme.domain.push(this.chartColors[ele.name]);
          this.data1ToggleStore.splice(this.data1ToggleStore.indexOf(ele), 1);
          this.primaryColorLegend[key] = '2px solid' + this.chartColors[ele.name];
        }
      });
    }
    // this.data1 = [...this.data1];
  }



  axisFormatPrimary = (val) => {
    if (this.primaryAvailableDates.length > 15) {
      if (this.primaryAvailableDates.indexOf(val) % 3 === 0) {
        return val;
      } else {
        return '';
      }
    } else {
      return val;
    }
  }

  axisFormatSecondary = (val) => {
    if (this.secondaryAvailableDates.length > 15) {
      if (this.secondaryAvailableDates.indexOf(val) % 3 === 0) {
        return val;
      } else {
        return '';
      }
    } else {
      return val;
    }
  }



  // updateChartData(arrayList, dates)
  updateChartData(arrayList) {
    this.primaryAvailableDates = [];
    const Google = {
      name: 'Google',
      series: []
    };

    const FaceBook = {
      name: 'FaceBook',
      series: []
    };

    const Twitter = {
      name: 'Twitter',
      series: []
    };
    arrayList.forEach(element => {
      const d = new Date(element.date);
      const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
          if(element.platform == 'Google'){
            Google.series.push({ name: str, value: +element.cost});
          }else  if(element.platform == 'Facebook'){
            FaceBook.series.push({ name: str, value: +element.cost });
          }else  if(element.platform == 'Twitter'){
            Twitter.series.push({ name: str, value: +element.cost});
          }
    });
    this.data1 = [Google, FaceBook, Twitter];
    console.log(this.data1);
    this.pageload = false;

  }







}

 
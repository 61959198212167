import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignCreateComponent } from './campaign-create/campaign-create.component';
import { SignupComponent } from './signup/signup.component';
import { SignupProfileComponent } from './signup-profile/signup-profile.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { PaymentsComponent } from './payments/payments.component';
import { SettingsComponent } from './settings/settings.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { WalletRechargeComponent } from './wallet-recharge/wallet-recharge.component';
import { CampaignInfoComponent } from './campaign-info/campaign-info.component';
import { StatusComponent } from './status/status.component';
import { ReportsComponent } from './reports/reports.component';
import { BillingComponent } from './billing/billing.component';
import { AccountSetupComponent } from './account-setup/account-setup.component';
import { BillingOverviewComponent } from './billing-section/billing-overview/billing-overview.component';
import { BillingPreferenceComponent } from './billing-section/billing-preference/billing-preference.component';
import { BillingMethodComponent } from './billing-section/billing-method/billing-method.component';
import { BillingPricingComponent } from './billing-section/billing-pricing/billing-pricing.component';
import { AllPaymentHistoryComponent } from './billing-section/all-payment-history/all-payment-history.component';
import { AllProductUsageComponent } from './billing-section/all-product-usage/all-product-usage.component';
import { RecieptPdfComponent } from './billing-section/pdf-forms/reciept-pdf/reciept-pdf.component';
import { GraphComponent } from './billing-section/graph/graph.component';
import { PaymentsummarypdfComponent } from './billing-section/pdf-forms/paymentsummarypdf/paymentsummarypdf.component';
import { UsagesummarypdfComponent } from './billing-section/pdf-forms/usagesummarypdf/usagesummarypdf.component';

import { GalleryimageComponent } from './createsection/galleryimage/galleryimage.component';
import { GalleryimageuploadComponent } from './createsection/galleryimageupload/galleryimageupload.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AdmindashboardComponent } from './admin/admindashboard/admindashboard.component';
import { UserlistComponent } from './admin/userlist/userlist.component';
import { AdminDashboardListComponent } from './admin/admin-dashboard-list/admin-dashboard-list.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'register',
    component: SignupComponent
  },
  {
    path: 'register/profile/:id',
    component: SignupProfileComponent
  },
  {
    path: 'email-verify',
    component: EmailVerifyComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'status/:id', component: StatusComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'billing', component: BillingComponent },
      { path: 'campaign', component: CampaignComponent },
      { path: 'campaign/create', component: CampaignCreateComponent },
      { path: 'campaign/:id', component: CampaignInfoComponent },
      { path: 'notification', component: NotificationsComponent},

      {
        path: 'billing',
        component: BillingComponent,
        children: [
          { path: 'billingoverview', component: BillingOverviewComponent },
          { path: 'billingprefernece', component: BillingPreferenceComponent },
          { path: 'paymentmethods', component: BillingMethodComponent },
          { path: 'pricing', component: BillingPricingComponent },
          { path: 'allpayments', component: AllPaymentHistoryComponent },
          { path: 'allproducts', component: AllProductUsageComponent },
          { path: 'paymentsummarypdf', component: PaymentsummarypdfComponent },
          { path: 'usagesummarypdf', component: UsagesummarypdfComponent },
        ]
      },

          { path: 'graph', component: GraphComponent },
          { path: 'Gallery', component: GalleryimageComponent },
          { path: 'Upload', component: GalleryimageuploadComponent },

      {
        path: 'profile',
        component: ProfileComponent,
        children: [
          { path: 'info', component: ProfileInfoComponent },
          { path: 'payments', component: PaymentsComponent },
          { path: 'payments/recharge', component: WalletRechargeComponent },
        ]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: 'profile', component: ProfileInfoComponent },
          { path: 'payments', component: PaymentsComponent },

        ]
      },
      { path: 'account-setup', component: AccountSetupComponent }
    ]
  },




  {
    path: 'admin',
    component: AdmindashboardComponent,
    children: [
      { path: 'userdetails', component: UserlistComponent },
      { path: 'dashboard', component: AdminDashboardListComponent },
    ]
  }




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

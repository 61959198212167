import { Component, OnInit,ViewChild } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material';
import { NgModule,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiService } from 'src/app/api.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class NotificationsComponent implements OnInit {
  // dataSource = ELEMENT_DATA;
  columnsToDisplay = ['Alert','Title','Type','Date','Status'];
  expandedElement: PeriodicElement | null;
  displayedColumns: string[] = [ 'No','Notification', 'Count'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  applyFilter(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  onchange(data){

    var str = data;
    var res = str.split("-");

    let a = {user_id : this.getFromLocal("user_id"),time:res[3]}
    this._apiService.updatenotifiaction(a).subscribe(
      (data:any) => {

        // for(let a = 0 ; a < )







      }
      );

  }



  constructor( @Inject(LOCAL_STORAGE) private storage: WebStorageService,private http: HttpClient,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder) {


   }

  ngOnInit() {

   this.getnotification();

  }


  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }

  getnotification(){
    let a = {user_id : this.getFromLocal("user_id")}
    this._apiService.getnotificationcount(a).subscribe(
      (data:any) => {
        let notifi = [];
        data.forEach(element => {
          let a = {
            'Alert' : element.alert_type,
            'Title' : element.title,
            'Description' : element.description,
            'Type' : element.type,
            'Date' : element.date +"-" + element.Time,
            'Link' :  element.link_text,
            'Time' :  element.Time,
            'Status': element.statu
          }
          notifi.push(a)
        });
        this.dataSource = new MatTableDataSource(notifi);

        this.ngAfterViewInit();

      }
      );
  }

}


export interface PeriodicElement {


  // Time: string;
  // alert_type: string;
  // date: string;
  // description: string;
  // month: string;
  // statu: string;
  // title: string;
  // type: string;
  // user_id: string;



  // Time: string;
  // alert_type: number;
  // date: number;
  // description: string;
  // month: string;
  // statu: string;
  // title: string;
  // type: string;
  // user_id: string;

}
const ELEMENT_DATA: PeriodicElement[] = [];

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-campaign-info',
  templateUrl: './campaign-info.component.html',
  styleUrls: ['./campaign-info.component.sass']
})
export class CampaignInfoComponent implements OnInit {

  campID: object;
  campaignObject: object;
  tokenObject: object;

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _router: Router) { }

  ngOnInit() {
    this._route.params.subscribe(
      params => {
        this.campID = params.id;
        this._api.getCampaignById(this.campID).subscribe(
          data => {
            this.campaignObject = data;
          }
        );
      }
    );
  }

  placeSearchCampaign() {
    this._api.createSearchCampaign(this.campaignObject).subscribe(
      data => {
        this._snackBar.open('Campaign Created', 'Close', {
          duration: 2000,
        });
        this.ngOnInit();
      }
    );
  }

  placeImageCampaign() {
    this._api.createImageCampaign(this.campaignObject).subscribe(
      data => {
        this._snackBar.open('Campaign Created', 'Close', {
          duration: 2000,
        });
        this.ngOnInit();
      }
    );
  }

  createTextAdGroup() {
    let cid = '';
    this.campaignObject['placements'].google.campaigns.forEach(element => {
      if (element.campaign_type === 'text') {
        cid = element.campaign_id;
        this._api.createTextAdgroup(cid, this.campaignObject).subscribe(
          adata => {
            this.ngOnInit();
            this._snackBar.open('Text Ad Group Created', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  createImageAdGroup() {
    let cid = '';
    this.campaignObject['placements'].google.campaigns.forEach(element => {
      if (element.campaign_type === 'image') {
        cid = element.campaign_id;
        this._api.createImageAdgroup(cid, this.campaignObject).subscribe(
          adata => {
            this.ngOnInit();
            this._snackBar.open('Image Ad Group Created', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  startTextAd() {
    let adid = '';
    this.campaignObject['placements'].google.adgroups.forEach(element => {
      if (element.adType === 'text') {
        adid = element.adgroup_id;
        this._api.startAd(adid, this.campaignObject['placements'].google.textAd.text_ad_id).subscribe(
          data => {
            this._snackBar.open('Text Ad Started Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  startImageAd() {
    let adid = '';
    this.campaignObject['placements'].google.adgroups.forEach(element => {
      if (element.adType === 'image') {
        adid = element.adgroup_id;
        this._api.startAd(adid, this.campaignObject['placements'].google.imageAd.image_ad_id).subscribe(
          data => {
            this._snackBar.open('Image Ad Started Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  startTextCampaign() {
    let cid = '';
    this.campaignObject['placements'].google.campaigns.forEach(element => {
      if (element.campaign_type === 'text') {
        cid = element.campaign_id;
        this._api.startCampaign(this.tokenObject['id'], cid).subscribe(
          data => {
            this._snackBar.open('Search Campaign Started Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  startImageCampaign() {
    let cid = '';
    this.campaignObject['placements'].google.campaigns.forEach(element => {
      if (element.campaign_type === 'image') {
        cid = element.campaign_id;
        this._api.startCampaign(this.tokenObject['id'], cid).subscribe(
          data => {
            this._snackBar.open('Display Campaign Started Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  pauseImageCampaign() {
    let cid = '';
    this.campaignObject['placements'].google.campaigns.forEach(element => {
      if (element.campaign_type === 'image') {
        cid = element.campaign_id;
        this._api.pauseCampaign(this.tokenObject['id'], cid).subscribe(
          data => {
            this._snackBar.open('Display Campaign Paused Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  pauseTextCampaign() {
    let cid = '';
    this.campaignObject['placements'].google.campaigns.forEach(element => {
      if (element.campaign_type === 'text') {
        cid = element.campaign_id;
        this._api.pauseCampaign(this.tokenObject['id'], cid).subscribe(
          data => {
            this._snackBar.open('Search Campaign Paused Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  placeTextAd() {
    this.tokenObject = this._authService.getStoredTokenPayload();
    const obj = this.campaignObject['adTypes'].text;
    obj.url = this.campaignObject['redirectUrl'];
    obj._id = this.campaignObject['_id'];
    let adid = '';
    this.campaignObject['placements'].google.adgroups.forEach(element => {
      if (element.adType === 'text') {
        adid = element.adgroup_id;
        this._api.placeTextAd(this.tokenObject['id'], adid, obj).subscribe(
          data => {
            console.log(data);
            this._snackBar.open('Text Ad Placed Successfully', 'Close', {
              duration: 2000,
            });
          }
        );
      }
    });
  }

  placeImageAd() {
    this.tokenObject = this._authService.getStoredTokenPayload();
    this.campaignObject['adTypes'].banner.forEach((ele, index) => {
      const obj = ele;
      obj.redirectUrl = this.campaignObject['redirectUrl'];
      obj.url = ele.filename;
      obj._id = this.campaignObject['_id'];
      let adid = '';
      this.campaignObject['placements'].google.adgroups.forEach(element => {
        if (element.adType === 'image') {
          adid = element.adgroup_id;
          this._api.placeImageAd(this.tokenObject['id'], adid, obj).subscribe(
            data => {
              this._snackBar.open('Image Ad ' + index + 'Placed Successfully', 'Close', {
                duration: 2000,
              });
            }
          );
        }
      });
    });

  }

  duplicate() {
    this._router.navigate(['/dashboard/campaign/create'], { queryParams: { id: this.campID } });
  }

}

import { Component, OnInit, HostBinding, ElementRef, NgZone } from '@angular/core';
import { ApiService } from '../api.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { OverlayContainer } from '@angular/cdk/overlay';
declare const gapi: any;


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit {

  @HostBinding('class') componentCssClass;
  theme: string;

  private clientId = '741694169993-i0j38sh3q21tab93ilk3gvuv03qvn5d7.apps.googleusercontent.com';

  private scope = [
    'profile',
    'email',
    'https://www.googleapis.com/auth/plus.me',
    'https://www.googleapis.com/auth/contacts.readonly',
    'https://www.googleapis.com/auth/admin.directory.user.readonly'
  ].join(' ');

  public auth2: any;

  signUpForm: FormGroup;

  cpassword: string;
  passwordMatch: boolean;
  error: string;
  tnc: boolean;

  constructor(
    public overlayContainer: OverlayContainer,
    private _apiService: ApiService,
    private _router: Router,
    private _authService: AuthService,
    private element: ElementRef,
    private _ngZone: NgZone,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.passwordMatch = true;
    this.error = '';
    // this.googleInit();
    this.signUpForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,
      Validators.pattern(/^([\w-\.]+@(?!gmail.com)(?!rediff.com)(?!rediffmail.com)(?!yahoo.co.in)(?!hotmail.com)(?!yahoo.com)(?!ymail.com)([\w-]+\.)+[\w-]{2,})?$/)]),
      password: new FormControl('',
        [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]),
      cpassword: new FormControl('', Validators.required)
    }, { validator: this.checkIfMatchingPasswords('password', 'cpassword') });
  }

  signup() {

    console.log(this.signUpForm.value);
    this._apiService.signup(this.signUpForm.value).subscribe(
      data => {
        console.log(data);
        if (data['message']) {
          this.error = data['message'];
        } else {
          this._router.navigateByUrl('/register/profile/' + data['_id']);
        }
      }
    );
    
    // if (this.signUpForm.valid) {
    //   if (this.tnc) {
    //     this.error = '';
    //     this._apiService.signup(this.signUpForm.value).subscribe(
    //       data => {
    //         console.log(data);
    //         if (data['message']) {
    //           this.error = data['message'];
    //         } else {
    //           this._router.navigateByUrl('/register/profile/' + data['_id']);
    //         }
    //       }
    //     );
    //   } else {
    //     this.error = 'Please Accept Terms and Conditions';
    //   }
    //   // this._router.navigateByUrl('/register/profile/' + 'wololo');
    // }
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notSame: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: this.clientId,
        cookiepolicy: 'single_host_origin',
        scope: this.scope
      });
      this.attachSignin(this.element.nativeElement.querySelector('#googleBtn'));
    });
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        const profile = googleUser.getBasicProfile();
        this._apiService.googleSignup(googleUser.getAuthResponse().id_token).subscribe(
          data => {
            // console.log(response);
            // this.loginRedirect(response);
            if (data['message']) {
              this.error = data['message'];
            } else {
              this._ngZone.run(() => this._router.navigateByUrl('/register/profile/' + data['_id']));
            }
          }
        );
      }, function (error) {
        console.log(JSON.stringify(error, undefined, 2));
      });
  }

}

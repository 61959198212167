import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ApiService } from 'src/app/api.service';
import { FlagjsonService } from 'src/app/flagjson.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";









@Component({
  selector: 'app-billing-method',
  templateUrl: './billing-method.component.html',
  styleUrls: ['./billing-method.component.sass']
})




export class BillingMethodComponent implements OnInit {
  showDialog = true;
  closable = true;
  visible = false;
  visibleChange = true;
  carddetails:carddetails;
  changestatus:changestatus;
  paymentconfigpri:paymentconfigpri;
  paymentconfigsec:paymentconfigsec;
  flagicon:any;
  flagname:any;
  carddetailslist:any;
  primarycard_number : string;
  secondarycard_number : string;
  registerForm: FormGroup;
  submitted = false;
  flagslist:any;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder,private _flags :FlagjsonService) {
  this.carddetails = new carddetails();
  this.flagslist = this._flags.FlagjsonServicez;
  this.changestatus = new changestatus();
  this.paymentconfigpri = new paymentconfigpri();
  this.paymentconfigsec = new paymentconfigsec();
  this.cardlist();
  this.flagicon =  this.flagslist[0].alpha2;
  this.flagname =  this.flagslist[0].name;
  this.carddetails.country = this.flagslist[0].name;
}


onchange(data){
}


cardlist(){
  let a = {user_id: this.getFromLocal("user_id")}

  this._apiService.getCardDetailsList(a).subscribe(
    (data:any) => {
     if(data.length == 0 ){
     }else {
      this.carddetailslist = [];
      data.forEach(element => {
              if(element.status == "Primary"){
               this._apiService.fetchcarddetails({token:element.card_id}).subscribe(
                  (data:any) => {
               
                    this.primarycard_number = element.card_id
                    this.paymentconfigpri.user_id =  this.getFromLocal("user_id")
                    this.paymentconfigpri.pcard_id = element.card_id
                    this.paymentconfigpri.cardnumber = " **** **** **** " + data.metadata.card_no.substr(data.metadata.card_no.length - 4)
                    this.configcardpri();
                  }
                );
              }else if(element.status == "Secondary"){
                this._apiService.fetchcarddetails({token:element.card_id}).subscribe(
                  (data:any) => {
            
                    this.secondarycard_number = element.card_id
                    this.paymentconfigsec.user_id =  this.getFromLocal("user_id")
                    this.paymentconfigsec.scard_id = element.card_id
                    this.paymentconfigsec.cardnumber = " **** **** **** " + data.metadata.card_no.substr(data.metadata.card_no.length - 4)
                    this.configcardsec();
                  }
                  );
                   }
                   this._apiService.fetchcarddetails({token:element.card_id}).subscribe(
                    (data:any) => {
                      console.log(data);
                   let b = {
                         id:element._id,
                        //  type: data.card.brand,
                         card_id:element.card_id,
                         ccvno: "",
                         expdate : data.metadata.exp_month +"/"+ data.metadata.exp_year,
                         cardnumbers:data.metadata.card_no.substr(data.metadata.card_no.length - 4),
                         cardnumber : " **** **** **** " + data.metadata.card_no.substr(data.metadata.card_no.length - 4),
                         name : element.cardname,
                         address:element.address,
                         status: element.status
                       }
                       this.carddetailslist.push(b);
                      }
                      );
      });
    }
  }
  );
  this.close()
}
   
  ngOnInit() {

    this.registerForm = this.formBuilder.group({
      countrys : [],
      cardnames: ['',[ Validators.required,Validators.minLength(3)]],
      cardnumbers: ['',[ Validators.required,Validators.maxLength(16),Validators.minLength(10)]],
      expdates: ['',[ Validators.required,Validators.minLength(7)]],
      ccvnos: ['', [ Validators.required,Validators.minLength(3)]],
      addresss: ['', [ Validators.required,Validators.minLength(3)]],
      postalcodes: ['', [ Validators.required,Validators.minLength(3)]]
  });


  this.carddetails.paymenttype = "CREDIT CARD"
  this.carddetails.cardtype = "Visa"
  this.carddetails.user_id = this.getFromLocal("user_id");
}
// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;
  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
      
  }
  this.addcarddetials()  
}




addcarddetials(){
   if(+this.carddetails.cardnumber > 9999999999999999){
     alert("Invaild  CardNumber Number Must be 16 digits Only")
   }else if(+this.carddetails.ccvno > 999){
    alert("CVV Number Must Be 3 Digits")
   }else if(+this.carddetails.ccvno < 100){
    alert("CVV Number Must Be 3 Digits")
   }else if(this.carddetails.expdate.length > 7){
    alert("Expiry Date Format must be (ex):12/2020")
   }
   else{
    var array = this.carddetails.expdate.split('/');
    let currentyear = new Date(new Date().getFullYear());
    if(+array[1] < +currentyear){
      alert("Invalid Expired Year")
    }else if(+array[0] > 12 ){
      alert("Invalid Expired Month")
    }else{
      let format = /^\d{2}\/\d{4}$/;
      if (format.test(this.carddetails.expdate)) {
  var array = this.carddetails.expdate.split('/');
  let code = this.flagslist = this._flags.countrycode;
  for(let a = 0 ; a < code.length ; a ++){
       if(this.carddetails.country == code[a].country){
         this.carddetails.currency_code = code[a].currency_code;
       }

  }
  let datass =  {
    "user_id":  this.getFromLocal("user_id"),
    "cardnumber": ""+this.carddetails.cardnumber,
    "month": +array[0],
    "year": +array[1],
    "ccvno": ""+this.carddetails.ccvno,
    "cardname":  ""+this.carddetails.cardname,
    "address":""+this.carddetails.address,
    "postalcode":  ""+this.carddetails.postalcode,
    "country": ""+this.carddetails.country ,
    "email":this.getFromLocal("user_email"),
    "currency_code":this.carddetails.currency_code
  }

  this._apiService.addindcard(datass).subscribe(
    (data:any) => {
      alert(data.Result)
      this.cardlist();
    }
  );
      }else{
        alert("Expiry Date Format must be (ex):12/2020")
      }
    }
   }

}



updatecarddetails(cardnumber,crntsts,status){ 
 if(status == "Primary"){
  let changes = {  
    card_id:this.primarycard_number,
    status:""
  }
  this._apiService.changecardStatus(changes).subscribe(
    data => {
   
      this.changestatus.card_id = cardnumber;
      this.changestatus.status = status;
      this._apiService.changecardStatus(this.changestatus).subscribe(
        data => {
          alert("Card Status Changed Succesffuly");
          this.cardlist();
        }
      );
    }
  );
 }else {
  let changes = {
    card_id:this.secondarycard_number,
    status:""
  }
  this._apiService.changecardStatus(changes).subscribe(
    data => {
      
      this.changestatus.card_id = cardnumber;
      this.changestatus.status = status;
      this._apiService.changecardStatus(this.changestatus).subscribe(
        data => {
          alert("Card Status Changed Succesffuly");
          this.cardlist();
        }
      );
    }
  );
 }
}

configcardpri(){
  this._apiService.insertconfigcardpri(this.paymentconfigpri).subscribe(
    (data:any) => {
    }
    );
}

configcardsec(){
  this._apiService.insertconfigcardsec(this.paymentconfigsec).subscribe(
    (data:any) => {
    }
    );  
}


onClick(e){
  this.flagicon =  this.flagslist[0].alpha2;
  this.flagname =  this.flagslist[0].name;
  this.flagslist.forEach(element => {
    if(element.name == e){
      this.flagicon = element.alpha2;
      this.flagname = element.name;
      this.carddetails.country = element.name
    }
  });
}


removecards(data,status){
  if(status == "Primary"){
    alert("You can't able to delete the Primary Card")
  }else{
    let a = {id:data}
  
    this._apiService.removecards(a).subscribe(
      (data:any) => {
        alert("Card Removed Successfully");
         this.cardlist();
      }
      );
  }
  }

  open(){
     this.visible = true;
  }
  close() {
    this.visible = false;
    this.visibleChange = this.visible;
    this.carddetails.postalcode = "";
    this.carddetails.cardname = "";
    this.carddetails.cardnumber = "";
    this.carddetails.expdate = "";
    this.carddetails.ccvno = "";
    this.carddetails.address = "";
  }





  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }
}


class carddetails{
  paymenttype:string;
  country:string;
  cardname:string;
  cardnumber:string;
  cardtype:string;
  expdate:string;
  ccvno:string;
  address:string;
  postalcode:string;
  user_id:string;
  currency_code:string;
}

class changestatus{
  card_id:string;
  status:string;
}

class paymentconfigpri{
  user_id:string;
  pcard_id: string;
  cardnumber:string;
  
}


class paymentconfigsec{
  user_id:string;
  scard_id: string;
  cardnumber:string;
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.sass']
})
export class ProfileInfoComponent implements OnInit {

  tokenObject: object;
  userObject: object;
  isEditVisible: boolean;

  editProfileForm: FormGroup;

  constructor(private _authService: AuthService, private _apiService: ApiService, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.isEditVisible = false;
    this.editProfileForm = this._formBuilder.group({
      profile: new FormGroup({
        companyName: new FormControl('', Validators.required),
        companyWebsite: new FormControl('', Validators.required),
        typeOfBusiness: new FormControl([], Validators.required),
        country: new FormControl('', Validators.required)
      }),
      name: new FormControl('', Validators.required),
      email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.email]),

    });
    this.tokenObject = this._authService.getStoredTokenPayload();
    if (this.tokenObject !== null) {
      this._apiService.getUserById(this.tokenObject['id']).subscribe(
        data => {
          this.userObject = data;
          // console.log(this.userObject);
        }
      );
    }
  }

  editProfile() {
    this.isEditVisible = true;
    this.editProfileForm.patchValue(this.userObject);
  }

  onCancel() {
    this.isEditVisible = false;
  }

  onSubmit() {
    this._apiService.updateUserById(this.tokenObject['id'], this.editProfileForm.value).subscribe(
      data => {
        this.ngOnInit();
      }
    );
  }

}

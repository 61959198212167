import { Component, OnInit, HostBinding } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-signup-profile',
  templateUrl: './signup-profile.component.html',
  styleUrls: ['./signup-profile.component.sass']
})
export class SignupProfileComponent implements OnInit {
  
  @HostBinding('class') componentCssClass;
  theme: string;

  countryList = ['Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Anguilla', 'Antigua & Barbuda'
    , 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas'
    , 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda'
    , 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'British Virgin Islands'
    , 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde'
    , 'Cayman Islands', 'Chad', 'Chile', 'China', 'Colombia', 'Congo', 'Cook Islands', 'Costa Rica'
    , 'Cote D Ivoire', 'Croatia', 'Cruise Ship', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark'
    , 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea'
    , 'Estonia', 'Ethiopia', 'Falkland Islands', 'Faroe Islands', 'Fiji', 'Finland', 'France'
    , 'French Polynesia', 'French West Indies', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana'
    , 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guam', 'Guatemala', 'Guernsey', 'Guinea'
    , 'Guinea Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India'
    , 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 'Jamaica', 'Japan'
    , 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Kuwait', 'Kyrgyz Republic', 'Laos', 'Latvia'
    , 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macau'
    , 'Macedonia', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Mauritania'
    , 'Mauritius', 'Mexico', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco'
    , 'Mozambique', 'Namibia', 'Nepal', 'Netherlands', 'Netherlands Antilles', 'New Caledonia'
    , 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Palestine', 'Panama'
    , 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal'
    , 'Puerto Rico', 'Qatar', 'Reunion', 'Romania', 'Russia', 'Rwanda', 'Saint Pierre & Miquelon'
    , 'Samoa', 'San Marino', 'Satellite', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles'
    , 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'South Africa', 'South Korea', 'Spain', 'Sri Lanka'
    , 'St Kitts & Nevis', 'St Lucia', 'St Vincent', 'St. Lucia', 'Sudan'
    , 'Suriname', 'Swaziland', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan'
    , 'Tanzania', 'Thailand', 'Timor L\'Este', 'Togo', 'Tonga', 'Trinidad & Tobago', 'Tunisia'
    , 'Turkey', 'Turkmenistan', 'Turks & Caicos', 'Uganda', 'Ukraine', 'United Arab Emirates'
    , 'United Kingdom', 'United States', 'United States Minor Outlying Islands', 'Uruguay'
    , 'Uzbekistan', 'Venezuela', 'Vietnam', 'Virgin Islands (US)', 'Yemen', 'Zambia', 'Zimbabwe'];

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  userId: object;

  profileForm = new FormGroup({
    companyName: new FormControl('', Validators.required),
    companyWebsite: new FormControl(''),
    typeOfBusiness: new FormControl([], Validators.required),
    country: new FormControl('', Validators.required)
  });

  surveyForm = new FormGroup({
    what: new FormControl('', Validators.required),
    when: new FormControl('', Validators.required),
    how: new FormControl('', Validators.required),
  });

  constructor(public overlayContainer: OverlayContainer, private _apiService: ApiService, private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit() {
    this.theme = 'default-theme';
    this.overlayContainer.getContainerElement().classList.remove("transparent-theme");
    this.overlayContainer.getContainerElement().classList.add(this.theme);
    this.componentCssClass = this.theme;
    this._route.params.subscribe(
      params => {
        this.userId = params.id;
      }
    );
    if (window.localStorage) {
      if (!localStorage.getItem('secondLoad')) {
        localStorage['secondLoad'] = true;
        window.location.reload();
      }
      else
        localStorage.removeItem('secondLoad');
    }
  }

  submitProfile(stepper: MatStepper) {
    this._apiService.completeProfileSignup(this.userId, this.profileForm.value).subscribe(
      res => {
        console.log(res);
        this._apiService.completeSurveySignup(this.userId, this.surveyForm.value).subscribe(
          resp => {
            stepper.next();
          }
        );
      }
    );
    // this._router.navigateByUrl('/dashboard/home');
  }

}

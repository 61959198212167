import { Component, OnInit ,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { ApiService } from 'src/app/api.service';
import { FlagjsonService } from 'src/app/flagjson.service';
import {MatMenuModule} from '@angular/material/menu';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-billing-preference',
  templateUrl: './billing-preference.component.html',
  styleUrls: ['./billing-preference.component.sass']
})
export class BillingPreferenceComponent implements OnInit {


  
 
 





  preference:preference;
  registerForm: FormGroup;
  submitted = false;
  flagslist:any;
  flagicon:any;
  flagname:any;

  constructor(private _router: Router,@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService, private formBuilder: FormBuilder,private _flags :FlagjsonService) {
    this.preference = new preference();
    this.preferencedetails();
    this.flagslist = this._flags.FlagjsonServicez;


    this.flagicon =  this.flagslist[0].alpha2;
    this.flagname =  this.flagslist[0].name;
    this.preference.country = this.flagslist[0].name;
  
  }
  

  ngOnInit() {


    this.registerForm = this.formBuilder.group({
      business_names: ['', Validators.required],
      Business_tax_ids: ['', Validators.required],
      address1s: ['', Validators.required],
      address2s: ['', Validators.required],
      citys: ['', Validators.required],
      states: ['', Validators.required],
      postalcodes: ['', Validators.required]
  });


  }

  // convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;
  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }
  this.insertprefernecdetails()  
}


onClick(e){
  this.flagslist.forEach(element => {
    if(element.name == e){
      this.flagicon = element.alpha2;
      this.flagname = element.name;
      this.preference.country = element.name
    }
  });
}


  preferencedetails(){
    let a = {user_id:this.getFromLocal("user_id")}
    this._apiService.getperferencDetails(a).subscribe(
      (data:any) => {

        if(data.length == 0){
        }else{
        // this.preference = data;   
        this.preference.business_tax_id = data[0].business_tax_id;
        this.preference.address1 = data[0].address1;
        this.preference.address2 = data[0].address2;
        this.preference.business_name = data[0].business_name;
        this.preference.city = data[0].city;
        this.preference.country = data[0].country;
        this.preference.postalcode = data[0].postalcode;
        this.preference.state = data[0].state;
        this.flagslist.forEach(element => {
          if(element.name == data[0].country){
            this.flagicon = element.alpha2;
            this.flagname = element.name;
            this.preference.country = element.name
          }
        });

        }
      }
      );
  }

  insertprefernecdetails(){
    this.preference.user_id = this.getFromLocal("user_id");
    this.preference.postalcode =  ""+this.preference.postalcode;
    this.preference.business_tax_id =  ""+this.preference.business_tax_id;

    this._apiService.addperferenceDetails(this.preference).subscribe(
      (data:any) => {
        alert(data.data);
        this.preferencedetails();
        this._router.navigateByUrl('/dashboard/billing/billingoverview');
      }
      );
  }


  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }



}


class preference {
  user_id:string;
  country:string;
  business_name:string;
  business_tax_id:string;
  address1:string;
  address2:string;
  city:string;
  state:string;
  postalcode:string;
}



import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  tokenObject: Object;
  userObject: Object;

  primaryData: Object;
  metricsData: Object;
  analyticsData: Object;

  pageload: boolean;

  isCampaignNull = false;

  curve = shape.curveMonotoneX;

  plateformArray = [
    {
      name: 'Google',
      value: 'Google',
      imgPath:"https://airbots.ai/demo/airbots-saas-platform/assets/platforms/gad.jpg"
    },
    {
      name: 'Facebook',
      value: 'facebook',
      imgPath:"https://airbots.ai/demo/airbots-saas-platform/assets/platforms/fb.jpg"
    },
    {
      name: 'Twitter',
      value: 'twitter',
      imgPath:"https://airbots.ai/demo/airbots-saas-platform/assets/platforms/tw.jpg"
    }
  ]
  timePeriodArray = [
    {
      name: 'Last 7 Days',
      value: 'LAST_7_DAYS'
    },
    {
      name: 'Last 14 Days',
      value: 'LAST_14_DAYS'
    },
    {
      name: 'Last 30 Days',
      value: 'LAST_30_DAYS'
    },
    {
      name: 'Last Business Week',
      value: 'LAST_BUSINESS_WEEK'
    },
    {
      name: 'This Month',
      value: 'THIS_MONTH'
    },
    {
      name: 'Last Month',
      value: 'LAST_MONTH'
    },
  ];

  timePeriod = { name: 'Last 30 Days', value: 'LAST_30_DAYS' };
  platforms = {name:"Google", value:"Google", imgPath:"https://airbots.ai/demo/airbots-saas-platform/assets/platforms/gad.jpg"};

  colorScheme = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A']
  };

  colorSchemeSecondary = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A', '#03A9F4']
  };

  colorSchemeGlobal = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A', '#03A9F4']
  };

  chartColors = {
    Cost: '#673AB7',
    Impressions: '#E91E63',
    Clicks: '#FFC107',
    Conversions: '#8BC34A'
  };

  chartColorsSecondary = {
    Bounces: '#673AB7',
    New_Users: '#E91E63',
    Page_Views: '#FFC107',
    Sessions: '#8BC34A',
    Session_Duration: '#03A9F4'
  };

  metricsArray = [
    {
      name: 'Cost',
      value: 100,
      sign: '$',
      data: []
    },
    {
      name: 'Impressions',
      value: 30,
      sign: '',
      data: []
    },
    {
      name: 'Clicks',
      value: 200,
      sign: '',
      data: []
    },
    {
      name: 'Conversions',
      value: 140,
      sign: '',
      data: []
    },
    {
      name: 'eCPA',
      value: 330,
      sign: '$',
      data: []
    },
    {
      name: 'Revenue',
      value: 500,
      sign: '$',
      data: []
    },
    {
      name: 'ROA',
      value: 30,
      sign: '',
      data: []
    },
  ];

  primaryAvailableDates = [];
  secondaryAvailableDates = [];
  primaryColorLegend = {
    Cost: '2px solid #673AB7',
    Impressions: '2px solid #E91E63',
    Clicks: '2px solid #FFC107',
    Conversions: '2px solid #8BC34A'
  };

  secondaryColorLegend = {
    Bounces: '2px solid #673AB7',
    New_Users: '2px solid #E91E63',
    Page_Views: '2px solid #FFC107',
    Sessions: '2px solid #8BC34A',
    Session_Duration: '2px solid #03A9F4'
  };

  campaignsList = [];

  data1ToggleStore = [];
  data3ToggleStore = [];

  data1 = [
    {
      'name': 'Cost',
      'series': [
        {
          'name': 'Mar 27',
          'value': 23
        },
        {
          'name': 'Mar 28',
          'value': 25
        },
        {
          'name': 'Mar 29',
          'value': 20
        },
        {
          'name': 'Mar 30',
          'value': 23
        },
        {
          'name': 'Mar 31',
          'value': 25
        },
        {
          'name': 'Mar 26',
          'value': 20
        },
      ]
    },
    {
      'name': 'Impressions',
      'series': [
        {
          'name': 'Mar 27',
          'value': 12000
        },
        {
          'name': 'Mar 28',
          'value': 12340
        },
        {
          'name': 'Mar 29',
          'value': 8230
        },
        {
          'name': 'Mar 30',
          'value': 12000
        },
        {
          'name': 'Mar 31',
          'value': 12340
        },
        {
          'name': 'Mar 26',
          'value': 8230
        },
      ]
    },
    {
      'name': 'Clicks',
      'series': [
        {
          'name': 'Mar 27',
          'value': 8034
        },
        {
          'name': 'Mar 28',
          'value': 10230
        },
        {
          'name': 'Mar 29',
          'value': 7345
        },
        {
          'name': 'Mar 30',
          'value': 10004
        },
        {
          'name': 'Mar 31',
          'value': 9804
        },
        {
          'name': 'Mar 26',
          'value': 6500
        },
      ]
    },
    {
      'name': 'Conversions',
      'series': [
        {
          'name': 'Mar 27',
          'value': 9000
        },
        {
          'name': 'Mar 28',
          'value': 5000
        },
        {
          'name': 'Mar 29',
          'value': 8888
        },
        {
          'name': 'Mar 30',
          'value': 9999
        },
        {
          'name': 'Mar 31',
          'value': 7898
        },
        {
          'name': 'Mar 26',
          'value': 7900
        },
      ]
    },
  ];

  data3 = [
    {
      'name': 'Session',
      'series': [
        {
          'name': 'Mar 27',
          'value': 23
        },
        {
          'name': 'Mar 28',
          'value': 25
        },
        {
          'name': 'Mar 29',
          'value': 20
        },
        {
          'name': 'Mar 30',
          'value': 23
        },
        {
          'name': 'Mar 31',
          'value': 25
        },
        {
          'name': 'Mar 26',
          'value': 20
        },
      ]
    },
    {
      'name': 'Bounce Rates',
      'series': [
        {
          'name': 'Mar 27',
          'value': 1
        },
        {
          'name': 'Mar 28',
          'value': 3
        },
        {
          'name': 'Mar 29',
          'value': 4
        },
        {
          'name': 'Mar 30',
          'value': 2
        },
        {
          'name': 'Mar 31',
          'value': 1
        },
        {
          'name': 'Mar 26',
          'value': 8
        },
      ]
    },
    {
      'name': 'New Visitors',
      'series': [
        {
          'name': 'Mar 27',
          'value': 5
        },
        {
          'name': 'Mar 28',
          'value': 11
        },
        {
          'name': 'Mar 29',
          'value': 14
        },
        {
          'name': 'Mar 30',
          'value': 8
        },
        {
          'name': 'Mar 31',
          'value': 21
        },
        {
          'name': 'Mar 26',
          'value': 6
        },
      ]
    },
    {
      'name': 'Session Duration',
      'series': [
        {
          'name': 'Mar 27',
          'value': 25
        },
        {
          'name': 'Mar 28',
          'value': 20
        },
        {
          'name': 'Mar 29',
          'value': 29
        },
        {
          'name': 'Mar 30',
          'value': 18
        },
        {
          'name': 'Mar 31',
          'value': 27
        },
        {
          'name': 'Mar 26',
          'value': 23
        },
      ]
    },
  ];

  data4 = [
    {
      'name': 'Search',
      'value': 40632
    },
    {
      'name': 'Display',
      'value': 49737
    },
  ];

  data2 = [
    {
      'name': 'Cost',
      'series': [
        {
          'name': 'Search',
          'value': 23
        },
        {
          'name': 'Display',
          'value': 25
        },
        {
          'name': 'E-Mail',
          'value': 28
        },
      ]
    },
    {
      'name': 'Imps',
      'series': [
        {
          'name': 'Search',
          'value': 12000
        },
        {
          'name': 'Display',
          'value': 12340
        },
        {
          'name': 'E-Mail',
          'value': 12340
        },
      ]
    },
    {
      'name': 'Clicks',
      'series': [
        {
          'name': 'Search',
          'value': 8034
        },
        {
          'name': 'Display',
          'value': 10230
        },
        {
          'name': 'E-Mail',
          'value': 10230
        },
      ]
    },
    {
      'name': 'Conv',
      'series': [
        {
          'name': 'Search',
          'value': 9000
        },
        {
          'name': 'Display',
          'value': 5000
        },
        {
          'name': 'E-Mail',
          'value': 5000
        },
      ]
    },
    {
      'name': 'Rev',
      'series': [
        {
          'name': 'Search',
          'value': 9000
        },
        {
          'name': 'Display',
          'value': 5000
        },
        {
          'name': 'E-Mail',
          'value': 5000
        },
      ]
    },
    {
      'name': 'ROAs',
      'series': [
        {
          'name': 'Search',
          'value': 9000
        },
        {
          'name': 'Display',
          'value': 5000
        },
        {
          'name': 'E-Mail',
          'value': 5000
        },
      ]
    },
  ];

  dollarRate: number;

  constructor(private _authService: AuthService, private _apiService: ApiService, private _router: Router) { }

  ngOnInit() {
    this.pageload = true;
    this.tokenObject = this._authService.getStoredTokenPayload();
    this._apiService.getDollarRate().subscribe(
      rates => {
        // this.dollarRate = rates['rates'].INR;
        // this._apiService.getUserCampaigns(this.tokenObject['id']).subscribe(
        //   user => {
        //     this.userObject = user;
        //     console.log("User Dtetails=="+user);
        //     this.getCampaignArray(user['campaigns']);
        //   }
        // );
      }
    );
  }

  setTimePeriodVal(obj) {
    this.timePeriod = obj;
    this.setTimePeriod();
  }
  setSocailVal(obj)
  {
    this.platforms = obj;
    console.log(this.platforms)
  }
  getCampaignArray(campaigns) {
    this.campaignsList = [];
    campaigns.forEach((element, index) => {
      if (element.placements) {
        if (element.placements.google) {
          if (element.placements.google.campaigns) {
            if (element.placements.google.campaigns.length > 0) {
              element.placements.google.campaigns.forEach((el, i) => {
                this.campaignsList.push(el.campaign_id);
              });
            }
          }
        }
      }
    });
    this.setTimePeriod();
  }

  setTimePeriod() {
    this.pageload = true;
    this.data3ToggleStore = [];
    this.data1ToggleStore = [];
    if (this.campaignsList.length > 0) {
      this._apiService.getSampleData(this.campaignsList, this.timePeriod.value).subscribe(
        data => {
          this.primaryData = data;
          this.formatData();
        }
      );
      this._apiService.getSampleMetricData(this.campaignsList, this.timePeriod.value).subscribe(
        data1 => {
          this.metricsData = data1;
          this._apiService.getGoogleAnalyticsData('61970332', this.timePeriod.value, 'YESTERDAY').subscribe(
            data => {
              this.analyticsData = data;
              this.formatAnalyticsData(data1);
            }
          );
        }
      );

      // this._apiService.getGoogleAnalyticsData('195117556', this.timePeriod.value, 'YESTERDAY').subscribe(


    } else {
      this.isCampaignNull = true;
      this.pageload = false;
    }
  }

  togglePrimary(key) {
    let flag = true;
    this.data1.forEach((element) => {
      if (element.name === key) {
        this.data1ToggleStore.push(element);
        this.data1.splice(this.data1.indexOf(element), 1);
        this.colorScheme.domain.splice(this.colorScheme.domain.indexOf(this.chartColors[key]), 1);
        flag = false;
        this.primaryColorLegend[key] = '2px solid #ddd';
        this.data1.forEach((el, i) => {
          this.primaryColorLegend[el.name] = '2px solid ' + this.chartColors[el.name];
        });
      }
    });
    if (flag) {
      this.data1ToggleStore.forEach(ele => {
        if (ele.name === key) {
          this.data1.push(ele);
          this.colorScheme.domain.push(this.chartColors[ele.name]);
          this.data1ToggleStore.splice(this.data1ToggleStore.indexOf(ele), 1);
          this.primaryColorLegend[key] = '2px solid' + this.chartColors[ele.name];
        }
      });
    }
    // this.data1 = [...this.data1];
  }

  toggleSecondary(key) {
    let flag = true;
    this.data3.forEach((element) => {
      if (element.name === key) {
        this.data3ToggleStore.push(element);
        this.data3.splice(this.data3.indexOf(element), 1);
        this.colorSchemeSecondary.domain.splice(this.colorSchemeSecondary.domain.indexOf(this.chartColorsSecondary[key]), 1);
        flag = false;
        this.secondaryColorLegend[key] = '2px solid #ddd';
        this.data3.forEach((el, i) => {
          this.secondaryColorLegend[el.name] = '2px solid ' + this.chartColorsSecondary[el.name];
        });
      }
    });
    if (flag) {
      this.data3ToggleStore.forEach(ele => {
        if (ele.name === key) {
          this.data3.push(ele);
          this.colorSchemeSecondary.domain.push(this.chartColorsSecondary[ele.name]);
          this.data3ToggleStore.splice(this.data3ToggleStore.indexOf(ele), 1);
          this.secondaryColorLegend[key] = '2px solid' + this.chartColorsSecondary[ele.name];
        }
      });
    }
    // this.data3 = [...this.data3];
  }

  axisFormatPrimary = (val) => {
    if (this.primaryAvailableDates.length > 15) {
      if (this.primaryAvailableDates.indexOf(val) % 3 === 0) {
        return val;
      } else {
        return '';
      }
    } else {
      return val;
    }
  }

  axisFormatSecondary = (val) => {
    if (this.secondaryAvailableDates.length > 15) {
      if (this.secondaryAvailableDates.indexOf(val) % 3 === 0) {
        return val;
      } else {
        return '';
      }
    } else {
      return val;
    }
  }

  computeMetrics(darray, revenue) {
    let cost = 0;
    let conv = 0;
    let imps = 0;
    let clicks = 0;

    const search = {
      cost: 0,
      conv: 0,
      imps: 0,
      clicks: 0,
    };

    const display = {
      cost: 0,
      conv: 0,
      imps: 0,
      clicks: 0,
    };

    if (darray.length > 0) {
      darray.forEach(element => {
        cost += element.cost_micros / 1000000;
        conv += element.conversions;
        imps += element.impressions;
        clicks += element.clicks;
        if (element.ctype === 2) {
          search.cost += element.cost_micros / 1000000;
          search.conv += element.conversions;
          search.imps += element.impressions;
          search.clicks += element.clicks;
        }
        if (element.ctype === 3) {
          display.cost += element.cost_micros / 1000000;
          display.conv += element.conversions;
          display.imps += element.impressions;
          display.clicks += element.clicks;
        }
      });
    }

    this.metricsArray.forEach((element, index) => {
      if (element.name === 'Cost') {
        this.metricsArray[index].value = cost / this.dollarRate;
      }
      if (element.name === 'Clicks') {
        this.metricsArray[index].value = clicks;
      }
      if (element.name === 'Impressions') {
        this.metricsArray[index].value = imps;
      }
      if (element.name === 'Conversions') {
        this.metricsArray[index].value = conv;
      }
      if (element.name === 'eCPA') {
        this.metricsArray[index].value = revenue / (cost / conv);
      }
      if (element.name === 'Revenue') {
        this.metricsArray[index].value = revenue / this.dollarRate;
      }
      if (element.name === 'ROA') {
        this.metricsArray[index].value = revenue / cost;
      }
    });

    this.data4 = [
      {
        'name': 'Search',
        'value': search.conv
      },
      {
        'name': 'Display',
        'value': display.conv
      },
    ];

    this.data2 = [
      {
        'name': 'Cost',
        'series': [
          {
            'name': 'Search',
            'value': search.cost
          },
          {
            'name': 'Display',
            'value': display.cost
          },
        ]
      },
      {
        'name': 'Imps',
        'series': [
          {
            'name': 'Search',
            'value': search.imps
          },
          {
            'name': 'Display',
            'value': display.imps
          },
        ]
      },
      {
        'name': 'Clicks',
        'series': [
          {
            'name': 'Search',
            'value': search.clicks
          },
          {
            'name': 'Display',
            'value': display.clicks
          },
        ]
      },
      {
        'name': 'Conv',
        'series': [
          {
            'name': 'Search',
            'value': search.conv
          },
          {
            'name': 'Display',
            'value': display.conv
          },
        ]
      },
    ];

  }

  getMetricsAverage(m1, m2, count) {
    const m3 = {};
    for (const key in m1) {
      if (m1.hasOwnProperty(key)) {
        const element = m1[key];
        // m3[key] = ((element * count) + m2[key]) / (count + 1);
        m3[key] = ((element) + m2[key]);
      }
    }
    return m3;
  }

  updateChartData(arrayList, dates) {
    this.primaryAvailableDates = [];
    const cost = {
      name: 'Cost',
      series: []
    };

    const imps = {
      name: 'Impressions',
      series: []
    };

    const clicks = {
      name: 'Clicks',
      series: []
    };

    const conv = {
      name: 'Conversions',
      series: []
    };

    arrayList.forEach(element => {
      const d = new Date(element.date);
      const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
      cost.series.push({ name: str, value: element.metrics.cost_micros / (this.dollarRate * 1000000) });
      imps.series.push({ name: str, value: element.metrics.impressions });
      clicks.series.push({ name: str, value: element.metrics.clicks });
      conv.series.push({ name: str, value: element.metrics.conversions });
    });

    setTimeout(() => {
      this.data1 = [cost, imps, clicks, conv];
      this.metricsArray.forEach(element => {
        if (element.name === 'Cost') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(cost);
        }
        if (element.name === 'Clicks') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(clicks);
        }
        if (element.name === 'Impressions') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(imps);
        }
        if (element.name === 'Conversions') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(conv);
        }
      });
      dates.forEach(element => {
        const d = new Date(element);
        const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
        this.primaryAvailableDates.push(str);
      });
      // console.log(this.primaryAvailableDates);
      this.pageload = false;
    }, 3000);
  }

  updateAnalyticsChartData(arrayList, dates) {
    this.secondaryAvailableDates = [];
    const bounces = {
      name: 'Bounces',
      series: []
    };

    const newUsers = {
      name: 'New_Users',
      series: []
    };

    const pviews = {
      name: 'Page_Views',
      series: []
    };

    const sessions = {
      name: 'Sessions',
      series: []
    };

    const sessiondur = {
      name: 'Session_Duration',
      series: []
    };

    const revenue = {
      name: 'Revenue',
      series: []
    };

    arrayList.forEach(element => {
      const d = new Date(element.date);
      const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
      bounces.series.push({ name: str, value: element.metrics.bounces });
      newUsers.series.push({ name: str, value: element.metrics.newUsers });
      pviews.series.push({ name: str, value: element.metrics.pageviewsPerSession });
      sessiondur.series.push({ name: str, value: element.metrics.sessionDuration });
      sessions.series.push({ name: str, value: element.metrics.sessions });
      revenue.series.push({ name: str, value: element.metrics.transactionRevenue });
    });

    setTimeout(() => {
      this.data3 = [bounces, newUsers, pviews, sessions, sessiondur];
      this.metricsArray.forEach(element => {
        if (element.name === 'Revenue') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(revenue);
        }
      });
      dates.forEach(element => {
        const d = new Date(element);
        const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
        this.secondaryAvailableDates.push(str);
      });
      // console.log(this.primaryAvailableDates);
      this.pageload = false;
    }, 3000);
  }

  formatData() {
    const availableDates = [];
    const objAgg = [];
    for (const key in this.primaryData) {
      if (this.primaryData.hasOwnProperty(key)) {
        const element = this.primaryData[key];
        if (!availableDates.includes(element.date)) {
          availableDates.push(element.date);
          objAgg.push({
            date: element.date,
            metrics: element.metrics,
            count: 1
          });
        } else {
          objAgg.forEach(ele => {
            if (ele.date === element.date) {
              objAgg[objAgg.indexOf(ele)].metrics = this.getMetricsAverage(ele.metrics, element.metrics, ele.count);
              objAgg[objAgg.indexOf(ele)].count = ele.count + 1;
            }
          });
        }
      }
    }
    objAgg.sort((a, b) => {
      const d1 = new Date(a.date);
      const d2 = new Date(b.date);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    availableDates.sort((a, b) => {
      const d1 = new Date(a);
      const d2 = new Date(b);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    this.updateChartData(objAgg, availableDates);
  }

  formatAnalyticsData(data) {
    const availableDates = [];
    const objAgg = [];
    this.analyticsData['daywise'].forEach(element => {
      if (!availableDates.includes(element.date)) {
        availableDates.push(element.date);
        objAgg.push({
          date: element.date,
          metrics: element.metrics,
          count: 1
        });
      } else {
        objAgg.forEach(ele => {
          if (ele.date === element.date) {
            objAgg[objAgg.indexOf(ele)].metrics = this.getMetricsAverage(ele.metrics, element.metrics, ele.count);
            objAgg[objAgg.indexOf(ele)].count = ele.count + 1;
          }
        });
      }
    });
    objAgg.sort((a, b) => {
      const d1 = new Date(a.date);
      const d2 = new Date(b.date);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    availableDates.sort((a, b) => {
      const d1 = new Date(a);
      const d2 = new Date(b);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    this.updateAnalyticsChartData(objAgg, availableDates);
    this.computeMetrics(data, this.analyticsData['totals'].transactionRevenue);
  }

}

import { Component, OnInit, HostBinding } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

  @HostBinding('class') componentCssClass;
  theme: string;

  password: string;
  cpassword: string;
  message: string;
  hash: string;
  ref: string;

  resetPwdForm: FormGroup;

  passwordMatch: boolean;

  constructor(public overlayContainer: OverlayContainer, private _apiService: ApiService, private _route: ActivatedRoute,
    private _router: Router, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.theme = 'default-theme';
    this.overlayContainer.getContainerElement().classList.remove("transparent-theme");
    this.overlayContainer.getContainerElement().classList.add(this.theme);
    this.componentCssClass = this.theme;

    this._route.queryParams.subscribe(
      query => {
        this.hash = query.hash_code;
        this.ref = query.ref;
      }
    );
    this.resetPwdForm = this._formBuilder.group({
      password: new FormControl('',
        [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]),
      cpassword: new FormControl('', Validators.required)
    }, { validator: this.checkIfMatchingPasswords('password', 'cpassword') });
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notSame: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  reset() {
    console.log(this.resetPwdForm.valid);
    if (this.resetPwdForm.valid) {
      console.log(this.hash);
      this._apiService.resetPassword({
        email: this.ref,
        hash_code: this.hash,
        password: this.resetPwdForm.value.password
      }).subscribe(
        data => {
          this._router.navigate(['/login'], { queryParams: { ref: 'pwd_reset' } });
        }
      );
    }
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.controls.password.value;
    const confirmPass = group.controls.cpassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  checkPasswordMatch(event: any) {
    console.log(event.target.value);
    console.log(this.cpassword);
    console.log(this.resetPwdForm.value.password);
    if (this.resetPwdForm.value.cpassword.toString() === this.resetPwdForm.value.password.toString()) {
      console.log('true');
      this.passwordMatch = true;
    } else {
      console.log('false');
      this.passwordMatch = false;
    }
  }

}

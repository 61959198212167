import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(array: any, query: string): any {
    if (!query || query === '') {
      return array;
    }
    return array.filter((element) => {
      return element.name.toLowerCase().match(query.toLowerCase());
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-galleryimage',
  templateUrl: './galleryimage.component.html',
  styleUrls: ['./galleryimage.component.sass']
})
export class GalleryimageComponent implements OnInit {
  width:any;
  height:any;
  size:any;
  pictures = [{img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              {img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              {img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              {img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              {img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              {img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              {img:"http://bestjquery.com/tutorial/hover-effect/demo164/images/img-3.jpg"},
              ]
  
  constructor( private router: Router) { }

  ngOnInit() {
  }
upload(){
  this.router.navigate(['/dashboard/Upload'])
}

getSize(event){
//   console.log(event)
//   let image: any = event.target.firstelement.files[0];
//   this.size = image.size;
//   let fr = new FileReader();
//   fr.onload = () => { // when file has loaded
//     var img = new Image();
//     img.onload = () => {
//       this.width = img.width;
//       this.height = img.height;

//       console.log(this.width, this.height);
// }
// }
}


}
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatSnackBar } from '@angular/material';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { ExportService } from '../export.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

export interface CampaignObject {
  id: string;
  name: string;
  obj: object;
  adobj: object;
  description: string;
  average_cpm: string;
  average_cpe: string;
  average_cpc: string;
  average_cpv: string;
  bounce_rate: string;
  clicks: string;
  conversions: string;
  cost_micros: string;
  ctr: string;
  impressions: string;
  currency: string;
  status: string;
  date: string;
  statusChangeProgress: object;
}

let CAMPAIGN_DATA: CampaignObject[] = [];
let CAMPAIGN_DATA_STORE: CampaignObject[] = [];

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.sass']
})
export class CampaignComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  placeholderUrl = 'https://via.placeholder.com/75x75.png?text=Text+Ad';

  displayedColumns: string[] = [
    'name',
    'date',
    'platforms',
    'status',
    'actions'
  ];

  dataSource = new MatTableDataSource(CAMPAIGN_DATA);

  over: boolean;

  campaigns: object;
  tokenObject: object;

  placed = [];
  placedList = [];

  objectives = [
    'Clear Filter',
    'Product and Brand Awareness',
    'Lead Generation',
    'Web Engagement',
    'App Promotion',
    'Sales'
  ];

  typesList = [
    { name: 'Clear Filter', value: 'clear' },
    { name: 'Text', value: ['text'] },
    { name: 'Image', value: ['banner'] },
    { name: 'Text and Image', value: ['text', 'banner'] },
  ];

  dollarRate: number;

  dateRangeFilter = new FormGroup({
    date: new FormControl()
  });

  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _exportService: ExportService,
    private _router: Router) { }

  ngOnInit() {
    this.over = false;
    CAMPAIGN_DATA = [];
    this.tokenObject = this._authService.getStoredTokenPayload();
    const userid = this.tokenObject['id'];
    this._apiService.getDollarRate().subscribe(
      rate => {
        // this.dollarRate = rate['rates'].INR;
        this._apiService.getUserCampaigns(userid).subscribe(
          user => {
            user['campaigns'].forEach(element => {
              if (element.placements) {
                if (element.placements.google) {
                  element.placements.google.campaigns.forEach(ele => {
                    this.placed.push({
                      cid: ele.campaign_id,
                      name: element.campaignName,
                      id: element._id,
                      date: element.dateCreated,
                      description: element.description,
                      adobj: element.adTypes,
                      obj: element
                    });
                    this.placedList.push(
                      ele.campaign_id
                    );
                  });
                } else {
                  CAMPAIGN_DATA.push({
                    id: element._id,
                    obj: element,
                    adobj: element.adTypes,
                    name: element.campaignName,
                    description: element.description,
                    average_cpm: '0',
                    average_cpe: '0',
                    average_cpc: '0',
                    average_cpv: '0',
                    bounce_rate: '0',
                    clicks: '0',
                    conversions: '0',
                    cost_micros: '0',
                    ctr: '0',
                    impressions: '0',
                    currency: '-',
                    status: element.status,
                    date: element.dateCreated,
                    statusChangeProgress: {
                      twitter: false,
                      facebook: false,
                      google: false,
                    }
                  });
                  this.setMatSource();
                }
              }
            });
            if (this.placedList.length > 0) {
              this._apiService.getCampaignReport(this.placedList).subscribe(
                data => {
                  for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                      const element = data[key];

                      let placedCamp = {};

                      this.placed.forEach(placedCampaign => {
                        if (placedCampaign['cid'] === element['campaign'].id.toString()) {
                          placedCamp = placedCampaign;
                        }
                      });

                      const microsdollar = this.dollarRate * 1000000;

                      CAMPAIGN_DATA.push({
                        id: placedCamp['id'],
                        name: placedCamp['name'],
                        adobj: placedCamp['adobj'],
                        obj: placedCamp['obj'],
                        description: placedCamp['description'],
                        average_cpm: (element['metrics'].average_cpm / microsdollar).toString(),
                        average_cpe: (element['metrics'].average_cpe / microsdollar).toString(),
                        average_cpc: (element['metrics'].average_cpc / microsdollar).toString(),
                        average_cpv: (element['metrics'].average_cpv / microsdollar).toString(),
                        bounce_rate: element['metrics'].bounce_rate,
                        clicks: element['metrics'].clicks,
                        conversions: element['metrics'].conversions,
                        cost_micros: (element['metrics'].cost_micros / microsdollar).toString(),
                        ctr: element['metrics'].ctr,
                        impressions: element['metrics'].impressions,
                        currency: element['locales'].currency,
                        status: element['campaign'].status,
                        date: placedCamp['date'],
                        statusChangeProgress: {
                          twitter: false,
                          facebook: false,
                          google: false,
                        }
                      });
                    }
                  }
                  this.setMatSource();
                }
              );
            }
            CAMPAIGN_DATA_STORE = CAMPAIGN_DATA;
          }
        );
      });
    this.over = true;

    this.dateRangeFilter.valueChanges.subscribe(
      val => this.setDateRangeFilter()
    );
  }

  setMatSource() {
    this.over = false;
    this.dataSource = new MatTableDataSource(CAMPAIGN_DATA);
    this.dataSource.sort = this.sort;
    this.over = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  export() {
    this._exportService.exportFile(CAMPAIGN_DATA, 'campaigns');
  }

  duplicate(id) {
    this._router.navigate(['/dashboard/campaign/create'], { queryParams: { id: id } });
  }

  showActivity(id) {
    this._router.navigate(['/dashboard/reports'], { queryParams: { id: id } });
  }

  pauseCampaign(id) {
    this._apiService.getCampaignById(id).subscribe(
      campaignObject => {
        campaignObject['placements'].google.campaigns.forEach(element => {
          this._apiService.pauseCampaign(id, element.campaign_id).subscribe(
            data => {
              this._snackBar.open('Campaign Paused Successfully', 'Close', {
                duration: 2000,
              });
              this.ngOnInit();
            }
          );
        });
      }
    );
  }

  startCampaign(id) {
    this._apiService.getCampaignById(id).subscribe(
      campaignObject => {
        campaignObject['placements'].google.campaigns.forEach(element => {
          this._apiService.startCampaign(id, element.campaign_id).subscribe(
            data => {
              this._snackBar.open('Campaign Started Successfully', 'Close', {
                duration: 2000,
              });
              this.ngOnInit();
            }
          );
        });
      }
    );
  }

  stopCampaign(id) {
    this._apiService.getCampaignById(id).subscribe(
      campaignObject => {
        campaignObject['placements'].google.campaigns.forEach(element => {
          this._apiService.stopCampaign(id, element.campaign_id).subscribe(
            data => {
              this._snackBar.open('Campaign Stopped Successfully', 'Close', {
                duration: 2000,
              });
              this.ngOnInit();
            }
          );
        });
      }
    );
  }

  setObjectiveFilter(item) {
    if (item !== 'Clear Filter') {
      CAMPAIGN_DATA = [];
      CAMPAIGN_DATA_STORE.forEach(e => {
        if (e.obj['objective'].includes(item)) {
          CAMPAIGN_DATA.push(e);
        }
      });
      this.setMatSource();
    } else {
      CAMPAIGN_DATA = CAMPAIGN_DATA_STORE;
      this.setMatSource();
    }
  }

  setTypesFilter(val) {
    console.log(val);
    if (val !== 'clear') {
      CAMPAIGN_DATA = [];
      CAMPAIGN_DATA_STORE.forEach(e => {
        if (e.obj['adTypesList'].length === val.length) {
          let flag = 0;
          e.obj['adTypesList'].forEach(element => {
            if (val.includes(element)) {
              flag = 1;
            } else {
              flag = 0;
            }
          });
          if (flag === 1) {
            CAMPAIGN_DATA.push(e);
          }
        }
      });
      this.setMatSource();
    } else {
      CAMPAIGN_DATA = CAMPAIGN_DATA_STORE;
      this.setMatSource();
    }
  }

  setDateRangeFilter() {
    // console.log(this.range);
    const sDate = this.dateRangeFilter.value.date.begin;
    const eDate = this.dateRangeFilter.value.date.end;
    CAMPAIGN_DATA = [];
    CAMPAIGN_DATA_STORE.forEach(e => {
      const d = new Date(e.date);
      if (d >= sDate && d <= eDate) {
        CAMPAIGN_DATA.push(e);
      }
    });
    this.setMatSource();
  }

  updateFacebookStatus(index, id, status) {
    CAMPAIGN_DATA[index].statusChangeProgress['facebook'] = true;
    this._apiService.updateFacebookStatus(id, status).subscribe(
      data => {
        setTimeout(() => {
          this.ngOnInit();
          this._snackBar.open('Facebook Status Updated Successfully', 'Close', {
            duration: 2000,
          });
        }, 2000);
      }
    );
  }

  updateTwitterStatus(index, id, status) {
    CAMPAIGN_DATA[index].statusChangeProgress['twitter'] = true;
    this._apiService.getUserById(this.tokenObject['id']).subscribe(
      user => {
        this._apiService.updateTwitterStatus(id, status, user['twitter'].tokens).subscribe(
          data => {
            setTimeout(() => {
              this.ngOnInit();
              this._snackBar.open('Twitter Status Updated Successfully', 'Close', {
                duration: 2000,
              });
            }, 2000);
          }
        );
      });
  }

}

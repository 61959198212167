import { Component,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";

@Component({
  selector: 'app-billing-pricing',
  templateUrl: './billing-pricing.component.html',
  styleUrls: ['./billing-pricing.component.sass']
})
export class BillingPricingComponent  {

  public signUpForm: FormGroup;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,public form: FormBuilder) { }



  public buildForm() {
    this.signUpForm = this.form.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }


  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }


  public ngOnInit() {
    this.buildForm();
  }

}

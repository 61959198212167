import { Component, OnInit,NgModule,Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { formatDate } from '@angular/common';

@NgModule({
  bootstrap:    [],
  declarations: [],
  imports:      [BrowserModule]
})
@Component({
  selector: 'app-paymentsummarypdf',
  templateUrl: './paymentsummarypdf.component.html',
  styleUrls: ['./paymentsummarypdf.component.sass']
})
export class PaymentsummarypdfComponent implements OnInit {
  current : any;
  paymentsummarylist:any;
  paymentsummarytotalcost:number;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService) {
   this.current = this.getFromLocal("payment_date")    
   this.paymentsearch();

  }

  ngOnInit() {
  }



  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }


  paymentsearch(){
    this.saveInLocal("payment_date", this.current);
    let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
     
    this._apiService.pdfdate = this.current;
    let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate}
    this._apiService.searchgetpaymentsummary(a).subscribe(
      (data:any) => {

        if(data.length == 0){
          this.paymentsummarylist =[];
          this.paymentsummarytotalcost = 0;
        }else{
          this.paymentsummarytotalcost = 0;
          var groups = data.reduce(function(obj,item){
            obj[item.date] = obj[item.date] || [];
            obj[item.date].push({date:item.date,cardnumber:item.cardnumber,cost:item.cost});
            return obj;
        }, {});
        this.paymentsummarylist = Object.keys(groups).map(function(key){
            return {team: key,total:0,name: groups[key]};
        });
  
  var result = [];
  data.reduce(function(res, value) {
    if (!res[value.date]) {
      res[value.date] = { date: value.date, cost: 0 };
      result.push(res[value.date])
    }
    res[value.date].cost += +value.cost;
    return res;
  }, {});
  
  for(let a = 0 ; a < this.paymentsummarylist.length ; a ++){
    for(let b = 0 ; b < result.length ; b ++){
      if(this.paymentsummarylist[a].team == result[b].date){
          this.paymentsummarylist[a].total = +result[b].cost
      }
    }
  }
          data.forEach(element => {
            this.paymentsummarytotalcost = this.paymentsummarytotalcost + +element.cost
          });
        }
      }
      );
      console.log(this.paymentsummarylist);
  }
  
}

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.sass']
})
export class EmailVerifyComponent implements OnInit {

  constructor(private _apiService: ApiService, private _authService: AuthService, private _router: Router) { }

  ngOnInit() {
  }

  logout() {
    this._apiService.logout(this._authService.getStoredToken()).subscribe(
      data => {
        console.log('Logged Out');
      }
    );
    this._authService.removeStoredToken();
    this._router.navigateByUrl('/login');
  }

}

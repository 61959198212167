import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnInit {

  responsive: boolean;
  navmode: string;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _router: Router
  ) { }

  ngOnInit() {
    this._breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.loadResponsiveLayout();
      } else {
        this.loadDesktopLayout();
      }
      this._router.navigateByUrl('/dashboard/settings/profile');
    });
  }

  loadResponsiveLayout() {
    this.responsive = true;
    this.navmode = 'over';
  }

  loadDesktopLayout() {
    this.responsive = false;
    this.navmode = 'side';
  }

}

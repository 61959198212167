import { Component, OnInit, HostBinding, AfterViewInit, Inject } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthService } from '../../auth.service';
import { ApiService } from '../../api.service';
import { Router } from '@angular/router';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.sass']
})
export class AdmindashboardComponent implements OnInit {

  @HostBinding('class') componentCssClass;
  theme: string;

  notficationcount: Number;
  tokenObject: object;
  userObject: object;
  responsive: boolean;
  isNavOpen: boolean;
  showHelp: boolean;
  navMode: string;
  quickTour = [false, false, false, false, false, false, false];

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public overlayContainer: OverlayContainer,
    private _authService: AuthService,
    private _apiService: ApiService,
    private _router: Router,
    private _breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.showHelp = false;
    this.isNavOpen = true;
    this.responsive = false;
    this._breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.loadResponsiveLayout();
      } else {
        this.loadDesktopLayout();
      }
    });

    this.theme = 'default-theme';
    this.overlayContainer.getContainerElement().classList.remove('transparent-theme');
    this.overlayContainer.getContainerElement().classList.add(this.theme);
    this.componentCssClass = this.theme;
  }


  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }

  loadResponsiveLayout() {
    this.responsive = true;
    this.isNavOpen = false;
  }

  loadDesktopLayout() {
    this.responsive = false;
    this.isNavOpen = true;
  }

  openNav() {
    this.isNavOpen = !this.isNavOpen;
  }

  setTheme() {
    this.overlayContainer.getContainerElement().classList.remove(this.theme);
    if (this.theme === 'default-theme') {
      this.theme = 'dark-theme';
    } else {
      this.theme = 'default-theme';
    }
    this.overlayContainer.getContainerElement().classList.add(this.theme);
    this.componentCssClass = this.theme;
  }

  logout() {
    this._apiService.logout(this._authService.getStoredToken()).subscribe(
      data => {

      }
    );
    this._authService.removeStoredToken();
    this._router.navigateByUrl('/login');
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  showQuickTour(i) {
    this.showHelp = false;
    this.quickTour[i] = true;
    if (i > 0 || i > this.quickTour.length) {
      this.quickTour[i - 1] = false;
    }
  }

  backQuickTour(i) {
    this.showHelp = false;
    this.quickTour[i] = false;
    if (i > 0) {
      this.quickTour[i - 1] = true;
    }
  }


  getnotification() {
    const a = { user_id: this.getFromLocal('user_id') };
    this._apiService.getnotificationcount(a).subscribe(
      (data: any) => {
        this.notficationcount = 0;
        const count = [];
        data.forEach(element => {
          if (element.statu === 'New') {
            count.push(element);
          }
        });
        this.notficationcount = count.length;
      }
    );
  }







  closePopup() {
    this.quickTour = [false, false, false, false, false, false, false];
  }

}




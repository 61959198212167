import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.sass']
})
export class StatusComponent implements OnInit {

  status: string;

  constructor(private _router: Router, private _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.params.subscribe(
      params => {
        switch (params.id) {
          case 'paymentsuccess':
            this.status = 'Payment Successful';
            break;
          case 'paymentfailure':
            this.status = 'Payment Failed';
            break;

          default:
            break;
        }
      }
    );
  }

}

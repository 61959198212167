import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  parseJWT(token) {
    if (token !== null) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64));
    }
    return null;
  }

  getStoredToken() {
    const token = localStorage.getItem('airbotsAuthToken');
    return token;
  }

  getStoredTokenPayload() {
    const token = this.getStoredToken();
    const payload = this.parseJWT(token);
    return payload;
  }

  storeToken(token) {
    localStorage.setItem('airbotsAuthToken', token);
  }

  removeStoredToken() {
    localStorage.removeItem('airbotsAuthToken');
  }

}

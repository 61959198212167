import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  exportFile(campaigns: any, type) {
    type = type || 'any';
    const data = campaigns;
    if (type === 'campaigns') {
      data.forEach((element, index) => {
        delete element.adobj;
        delete element.obj;
      });
    }
    const replacer = (key, value) => {
      return value === null ? '' : value; // specify how you want to handle null values here
    };
    const header = Object.keys(data[0]);
    const csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    const d = new Date();
    const filename = 'export' + d.toLocaleDateString() + '-' + d.toLocaleTimeString() + '.csv';
    saveAs(blob, filename);
  }

}

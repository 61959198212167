import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequest, HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

window.URL = window.URL;

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.sass']
})
export class CampaignCreateComponent implements OnInit {

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;

  apiUrl = environment.apiUrl;

  interestArea = [
    {
      'category': 'Arts & Entertainment',
      'cid': 3,
      'pid': 0
    },
    {
      'category': 'Celebrities & Entertainment News',
      'cid': 184,
      'pid': 3
    },
    {
      'category': 'Comics & Animation',
      'cid': 316,
      'pid': 3
    },
    {
      'category': 'Anime & Manga',
      'cid': 317,
      'pid': 316
    },
    {
      'category': 'Cartoons',
      'cid': 319,
      'pid': 316
    },
    {
      'category': 'Comics',
      'cid': 318,
      'pid': 316
    },
    {
      'category': 'Entertainment Industry',
      'cid': 612,
      'pid': 3
    },
    {
      'category': 'Film & TV Industry',
      'cid': 1116,
      'pid': 612
    },
    {
      'category': 'Film & TV Awards',
      'cid': 1108,
      'pid': 1116
    },
    {
      'category': 'Film & TV Production',
      'cid': 1117,
      'pid': 1116
    },
    {
      'category': 'Recording Industry',
      'cid': 1115,
      'pid': 612
    },
    {
      'category': 'Music Awards',
      'cid': 1113,
      'pid': 1115
    },
    {
      'category': 'Record Labels',
      'cid': 1114,
      'pid': 1115
    },
    {
      'category': 'Events & Listings',
      'cid': 569,
      'pid': 3
    },
    {
      'category': 'Bars, Clubs & Nightlife',
      'cid': 188,
      'pid': 569
    },
    {
      'category': 'Concerts & Music Festivals',
      'cid': 891,
      'pid': 569
    },
    {
      'category': 'Event Ticket Sales',
      'cid': 614,
      'pid': 569
    },
    {
      'category': 'Expos & Conventions',
      'cid': 1531,
      'pid': 569
    },
    {
      'category': 'Film Festivals',
      'cid': 1086,
      'pid': 569
    },
    {
      'category': 'Food & Beverage Events',
      'cid': 1530,
      'pid': 569
    },
    {
      'category': 'Live Sporting Events',
      'cid': 1273,
      'pid': 569
    },
    {
      'category': 'Movie Listings & Theater Showtimes',
      'cid': 1085,
      'pid': 569
    },
    {
      'category': 'Fun & Trivia',
      'cid': 539,
      'pid': 3
    },
    {
      'category': 'Flash-Based Entertainment',
      'cid': 447,
      'pid': 539
    },
    {
      'category': 'Fun Tests & Silly Surveys',
      'cid': 1174,
      'pid': 539
    },
    {
      'category': 'Humor',
      'cid': 182,
      'pid': 3
    },
    {
      'category': 'Live Comedy',
      'cid': 895,
      'pid': 182
    },
    {
      'category': 'Political Humor',
      'cid': 1180,
      'pid': 182
    },
    {
      'category': 'Spoofs & Satire',
      'cid': 1244,
      'pid': 182
    },
    {
      'category': 'Movies',
      'cid': 34,
      'pid': 3
    },
    {
      'category': 'Action & Adventure Films',
      'cid': 1097,
      'pid': 34
    },
    {
      'category': 'Martial Arts Films',
      'cid': 1101,
      'pid': 1097
    },
    {
      'category': 'Superhero Films',
      'cid': 1100,
      'pid': 1097
    },
    {
      'category': 'Western Films',
      'cid': 1099,
      'pid': 1097
    },
    {
      'category': 'Animated Films',
      'cid': 1104,
      'pid': 34
    },
    {
      'category': 'Bollywood & South Asian Film',
      'cid': 360,
      'pid': 34
    },
    {
      'category': 'Classic Films',
      'cid': 1102,
      'pid': 34
    },
    {
      'category': 'Silent Films',
      'cid': 1098,
      'pid': 1102
    },
    {
      'category': 'Comedy Films',
      'cid': 1095,
      'pid': 34
    },
    {
      'category': 'Cult & Indie Films',
      'cid': 1103,
      'pid': 34
    },
    {
      'category': 'DVD & Video Shopping',
      'cid': 210,
      'pid': 34
    },
    {
      'category': 'DVD & Video Rentals',
      'cid': 1145,
      'pid': 210
    },
    {
      'category': 'Documentary Films',
      'cid': 1072,
      'pid': 34
    },
    {
      'category': 'Drama Films',
      'cid': 1094,
      'pid': 34
    },
    {
      'category': 'Family Films',
      'cid': 1291,
      'pid': 34
    },
    {
      'category': 'Horror Films',
      'cid': 615,
      'pid': 34
    },
    {
      'category': 'Movie Memorabilia',
      'cid': 213,
      'pid': 34
    },
    {
      'category': 'Movie Reference',
      'cid': 1106,
      'pid': 34
    },
    {
      'category': 'Movie Reviews & Previews',
      'cid': 1107,
      'pid': 1106
    },
    {
      'category': 'Musical Films',
      'cid': 1105,
      'pid': 34
    },
    {
      'category': 'Romance Films',
      'cid': 1310,
      'pid': 34
    },
    {
      'category': 'Science Fiction & Fantasy Films',
      'cid': 616,
      'pid': 34
    },
    {
      'category': 'Thriller, Crime & Mystery Films',
      'cid': 1096,
      'pid': 34
    },
    {
      'category': 'Music & Audio',
      'cid': 35,
      'pid': 3
    },
    {
      'category': 'CD & Audio Shopping',
      'cid': 217,
      'pid': 35
    },
    {
      'category': 'Classical Music',
      'cid': 586,
      'pid': 35
    },
    {
      'category': 'Country Music',
      'cid': 587,
      'pid': 35
    },
    {
      'category': 'Dance & Electronic Music',
      'cid': 588,
      'pid': 35
    },
    {
      'category': 'Experimental & Industrial Music',
      'cid': 1022,
      'pid': 35
    },
    {
      'category': 'Folk & Traditional Music',
      'cid': 1023,
      'pid': 35
    },
    {
      'category': 'Jazz & Blues',
      'cid': 589,
      'pid': 35
    },
    {
      'category': 'Blues',
      'cid': 1040,
      'pid': 589
    },
    {
      'category': 'Jazz',
      'cid': 42,
      'pid': 589
    },
    {
      'category': 'Music Art & Memorabilia',
      'cid': 218,
      'pid': 35
    },
    {
      'category': 'Music Education & Instruction',
      'cid': 1087,
      'pid': 35
    },
    {
      'category': 'Music Equipment & Technology',
      'cid': 1024,
      'pid': 35
    },
    {
      'category': 'DJ Resources & Equipment',
      'cid': 1025,
      'pid': 1024
    },
    {
      'category': 'Music Recording Technology',
      'cid': 1026,
      'pid': 1024
    },
    {
      'category': 'Musical Instruments',
      'cid': 216,
      'pid': 1024
    },
    {
      'category': 'Drums & Percussion',
      'cid': 1327,
      'pid': 216
    },
    {
      'category': 'Guitars',
      'cid': 1325,
      'pid': 216
    },
    {
      'category': 'Pianos & Keyboards',
      'cid': 1326,
      'pid': 216
    },
    {
      'category': 'Samples & Sound Libraries',
      'cid': 1091,
      'pid': 1024
    },
    {
      'category': 'Music Reference',
      'cid': 1027,
      'pid': 35
    },
    {
      'category': 'Music Composition & Theory',
      'cid': 1028,
      'pid': 1027
    },
    {
      'category': 'Sheet Music',
      'cid': 892,
      'pid': 1027
    },
    {
      'category': 'Song Lyrics & Tabs',
      'cid': 617,
      'pid': 1027
    },
    {
      'category': 'Music Streams & Downloads',
      'cid': 220,
      'pid': 35
    },
    {
      'category': 'Music Videos',
      'cid': 1408,
      'pid': 35
    },
    {
      'category': 'Pop Music',
      'cid': 1021,
      'pid': 35
    },
    {
      'category': 'Radio',
      'cid': 215,
      'pid': 35
    },
    {
      'category': 'Podcasting',
      'cid': 809,
      'pid': 215
    },
    {
      'category': 'Talk Radio',
      'cid': 1186,
      'pid': 215
    },
    {
      'category': 'Religious Music',
      'cid': 1020,
      'pid': 35
    },
    {
      'category': 'Christian & Gospel Music',
      'cid': 585,
      'pid': 1020
    },
    {
      'category': 'Rock Music',
      'cid': 590,
      'pid': 35
    },
    {
      'category': 'Classic Rock & Oldies',
      'cid': 1037,
      'pid': 590
    },
    {
      'category': 'Hard Rock & Progressive',
      'cid': 1035,
      'pid': 590
    },
    {
      'category': 'Indie & Alternative Music',
      'cid': 1038,
      'pid': 590
    },
    {
      'category': 'Metal (Music)',
      'cid': 1036,
      'pid': 590
    },
    {
      'category': 'Punk (Music)',
      'cid': 1041,
      'pid': 590
    },
    {
      'category': 'Soundtracks',
      'cid': 893,
      'pid': 35
    },
    {
      'category': 'Urban & Hip-Hop',
      'cid': 592,
      'pid': 35
    },
    {
      'category': 'Rap & Hip-Hop',
      'cid': 1030,
      'pid': 592
    },
    {
      'category': 'Reggaeton',
      'cid': 1242,
      'pid': 592
    },
    {
      'category': 'Soul & R&B',
      'cid': 1039,
      'pid': 592
    },
    {
      'category': 'Vocals & Show Tunes',
      'cid': 618,
      'pid': 35
    },
    {
      'category': 'World Music',
      'cid': 593,
      'pid': 35
    },
    {
      'category': 'African Music',
      'cid': 1208,
      'pid': 593
    },
    {
      'category': 'Arab & Middle Eastern Music',
      'cid': 1034,
      'pid': 593
    },
    {
      'category': 'East Asian Music',
      'cid': 1033,
      'pid': 593
    },
    {
      'category': 'Latin American Music',
      'cid': 591,
      'pid': 593
    },
    {
      'category': 'Brazilian Music',
      'cid': 1287,
      'pid': 591
    },
    {
      'category': 'Latin Pop',
      'cid': 1285,
      'pid': 591
    },
    {
      'category': 'Salsa & Tropical Music',
      'cid': 1286,
      'pid': 591
    },
    {
      'category': 'Reggae & Caribbean Music',
      'cid': 1031,
      'pid': 593
    },
    {
      'category': 'South Asian Music',
      'cid': 1032,
      'pid': 593
    },
    {
      'category': 'Offbeat',
      'cid': 33,
      'pid': 3
    },
    {
      'category': 'Edgy & Bizarre',
      'cid': 538,
      'pid': 33
    },
    {
      'category': 'Occult & Paranormal',
      'cid': 449,
      'pid': 33
    },
    {
      'category': 'Online Media',
      'cid': 613,
      'pid': 3
    },
    {
      'category': 'Online Image Galleries',
      'cid': 1222,
      'pid': 613
    },
    {
      'category': 'Webcams & Virtual Tours',
      'cid': 575,
      'pid': 613
    },
    {
      'category': 'Performing Arts',
      'cid': 23,
      'pid': 3
    },
    {
      'category': 'Acting & Theater',
      'cid': 894,
      'pid': 23
    },
    {
      'category': 'Broadway & Musical Theater',
      'cid': 1243,
      'pid': 23
    },
    {
      'category': 'Circus',
      'cid': 1591,
      'pid': 23
    },
    {
      'category': 'Dance',
      'cid': 581,
      'pid': 23
    },
    {
      'category': 'Magic',
      'cid': 1592,
      'pid': 23
    },
    {
      'category': 'Opera',
      'cid': 1185,
      'pid': 23
    },
    {
      'category': 'TV & Video',
      'cid': 36,
      'pid': 3
    },
    {
      'category': 'Online Video',
      'cid': 211,
      'pid': 36
    },
    {
      'category': 'Web Series',
      'cid': 1409,
      'pid': 211
    },
    {
      'category': 'TV Commercials',
      'cid': 1055,
      'pid': 36
    },
    {
      'category': 'TV Guides & Reference',
      'cid': 1187,
      'pid': 36
    },
    {
      'category': 'TV Networks & Stations',
      'cid': 359,
      'pid': 36
    },
    {
      'category': 'TV Shows & Programs',
      'cid': 358,
      'pid': 36
    },
    {
      'category': 'TV Comedies',
      'cid': 1047,
      'pid': 358
    },
    {
      'category': 'TV Documentary & Nonfiction',
      'cid': 1411,
      'pid': 358
    },
    {
      'category': 'TV Dramas',
      'cid': 1193,
      'pid': 358
    },
    {
      'category': 'TV Crime & Legal Shows',
      'cid': 1111,
      'pid': 1193
    },
    {
      'category': 'TV Medical Shows',
      'cid': 1194,
      'pid': 1193
    },
    {
      'category': 'TV Soap Operas',
      'cid': 357,
      'pid': 1193
    },
    {
      'category': 'TV Family-Oriented Shows',
      'cid': 1110,
      'pid': 358
    },
    {
      'category': 'TV Game Shows',
      'cid': 1050,
      'pid': 358
    },
    {
      'category': 'TV Reality Shows',
      'cid': 1049,
      'pid': 358
    },
    {
      'category': 'TV Sci-Fi & Fantasy Shows',
      'cid': 1112,
      'pid': 358
    },
    {
      'category': 'TV Talent & Variety Shows',
      'cid': 1410,
      'pid': 358
    },
    {
      'category': 'TV Talk Shows',
      'cid': 1048,
      'pid': 358
    },
    {
      'category': 'Visual Art & Design',
      'cid': 24,
      'pid': 3
    },
    {
      'category': 'Architecture',
      'cid': 477,
      'pid': 24
    },
    {
      'category': 'Art & Craft Supplies',
      'cid': 1361,
      'pid': 24
    },
    {
      'category': 'Art Museums & Galleries',
      'cid': 1521,
      'pid': 24
    },
    {
      'category': 'Arts Education',
      'cid': 1195,
      'pid': 24
    },
    {
      'category': 'Design',
      'cid': 653,
      'pid': 24
    },
    {
      'category': 'Graphic Design',
      'cid': 654,
      'pid': 653
    },
    {
      'category': 'Industrial & Product Design',
      'cid': 655,
      'pid': 653
    },
    {
      'category': 'Interior Design',
      'cid': 656,
      'pid': 653
    },
    {
      'category': 'Painting',
      'cid': 1167,
      'pid': 24
    },
    {
      'category': 'Photographic & Digital Arts',
      'cid': 439,
      'pid': 24
    },
    {
      'category': 'Sculpture',
      'cid': 1412,
      'pid': 24
    },
    {
      'category': 'Autos & Vehicles',
      'cid': 47,
      'pid': 0
    },
    {
      'category': 'Bicycles & Accessories',
      'cid': 1191,
      'pid': 47
    },
    {
      'category': 'BMX Bikes',
      'cid': 1402,
      'pid': 1191
    },
    {
      'category': 'Bike Accessories',
      'cid': 1398,
      'pid': 1191
    },
    {
      'category': 'Bike Frames',
      'cid': 1399,
      'pid': 1191
    },
    {
      'category': 'Bike Helmets & Protective Gear',
      'cid': 1400,
      'pid': 1191
    },
    {
      'category': 'Bike Parts & Repair',
      'cid': 1401,
      'pid': 1191
    },
    {
      'category': 'Cruiser Bikes',
      'cid': 1403,
      'pid': 1191
    },
    {
      'category': 'Cycling Apparel',
      'cid': 1407,
      'pid': 1191
    },
    {
      'category': 'Kid\'s Bikes',
      'cid': 1404,
      'pid': 1191
    },
    {
      'category': 'Mountain Bikes',
      'cid': 1405,
      'pid': 1191
    },
    {
      'category': 'Road Bikes',
      'cid': 1406,
      'pid': 1191
    },
    {
      'category': 'Boats & Watercraft',
      'cid': 1140,
      'pid': 47
    },
    {
      'category': 'Campers & RVs',
      'cid': 1213,
      'pid': 47
    },
    {
      'category': 'Classic Vehicles',
      'cid': 1013,
      'pid': 47
    },
    {
      'category': 'Commercial Vehicles',
      'cid': 1214,
      'pid': 47
    },
    {
      'category': 'Cargo Trucks & Trailers',
      'cid': 1215,
      'pid': 1214
    },
    {
      'category': 'Custom & Performance Vehicles',
      'cid': 806,
      'pid': 47
    },
    {
      'category': 'Hybrid & Alternative Vehicles',
      'cid': 810,
      'pid': 47
    },
    {
      'category': 'Electric & Plug-In Vehicles',
      'cid': 1380,
      'pid': 810
    },
    {
      'category': 'Microcars & City Cars',
      'cid': 1317,
      'pid': 47
    },
    {
      'category': 'Motorcycles',
      'cid': 273,
      'pid': 47
    },
    {
      'category': 'Off-Road Vehicles',
      'cid': 148,
      'pid': 47
    },
    {
      'category': 'Personal Aircraft',
      'cid': 1147,
      'pid': 47
    },
    {
      'category': 'Scooters & Mopeds',
      'cid': 1212,
      'pid': 47
    },
    {
      'category': 'Trucks & SUVs',
      'cid': 610,
      'pid': 47
    },
    {
      'category': 'SUVs',
      'cid': 1057,
      'pid': 610
    },
    {
      'category': 'Trucks',
      'cid': 1056,
      'pid': 610
    },
    {
      'category': 'Vans & Minivans',
      'cid': 1058,
      'pid': 610
    },
    {
      'category': 'Vehicle Brands',
      'cid': 815,
      'pid': 47
    },
    {
      'category': 'Acura',
      'cid': 820,
      'pid': 815
    },
    {
      'category': 'Audi',
      'cid': 821,
      'pid': 815
    },
    {
      'category': 'BMW',
      'cid': 822,
      'pid': 815
    },
    {
      'category': 'Bentley',
      'cid': 1059,
      'pid': 815
    },
    {
      'category': 'Buick',
      'cid': 1060,
      'pid': 815
    },
    {
      'category': 'Cadillac',
      'cid': 823,
      'pid': 815
    },
    {
      'category': 'Chevrolet',
      'cid': 826,
      'pid': 815
    },
    {
      'category': 'Chrysler',
      'cid': 833,
      'pid': 815
    },
    {
      'category': 'Citroën',
      'cid': 834,
      'pid': 815
    },
    {
      'category': 'Dodge',
      'cid': 836,
      'pid': 815
    },
    {
      'category': 'Ferrari',
      'cid': 1061,
      'pid': 815
    },
    {
      'category': 'Fiat',
      'cid': 838,
      'pid': 815
    },
    {
      'category': 'Alfa Romeo',
      'cid': 1413,
      'pid': 838
    },
    {
      'category': 'Ford',
      'cid': 840,
      'pid': 815
    },
    {
      'category': 'GM-Daewoo',
      'cid': 896,
      'pid': 815
    },
    {
      'category': 'GMC',
      'cid': 842,
      'pid': 815
    },
    {
      'category': 'Honda',
      'cid': 843,
      'pid': 815
    },
    {
      'category': 'Hummer',
      'cid': 1062,
      'pid': 815
    },
    {
      'category': 'Hyundai',
      'cid': 845,
      'pid': 815
    },
    {
      'category': 'Isuzu',
      'cid': 1378,
      'pid': 815
    },
    {
      'category': 'Jaguar',
      'cid': 1063,
      'pid': 815
    },
    {
      'category': 'Jeep',
      'cid': 846,
      'pid': 815
    },
    {
      'category': 'Kia',
      'cid': 848,
      'pid': 815
    },
    {
      'category': 'Lamborghini',
      'cid': 1064,
      'pid': 815
    },
    {
      'category': 'Land Rover',
      'cid': 1065,
      'pid': 815
    },
    {
      'category': 'Lexus',
      'cid': 849,
      'pid': 815
    },
    {
      'category': 'Lincoln',
      'cid': 850,
      'pid': 815
    },
    {
      'category': 'Maserati',
      'cid': 1066,
      'pid': 815
    },
    {
      'category': 'Mazda',
      'cid': 851,
      'pid': 815
    },
    {
      'category': 'Mercedes-Benz',
      'cid': 852,
      'pid': 815
    },
    {
      'category': 'Mercury',
      'cid': 853,
      'pid': 815
    },
    {
      'category': 'Mini',
      'cid': 1067,
      'pid': 815
    },
    {
      'category': 'Mitsubishi',
      'cid': 854,
      'pid': 815
    },
    {
      'category': 'Nissan',
      'cid': 855,
      'pid': 815
    },
    {
      'category': 'Infiniti',
      'cid': 1377,
      'pid': 855
    },
    {
      'category': 'Peugeot',
      'cid': 856,
      'pid': 815
    },
    {
      'category': 'Pontiac',
      'cid': 857,
      'pid': 815
    },
    {
      'category': 'Porsche',
      'cid': 858,
      'pid': 815
    },
    {
      'category': 'Renault-Samsung',
      'cid': 859,
      'pid': 815
    },
    {
      'category': 'Rolls-Royce',
      'cid': 1068,
      'pid': 815
    },
    {
      'category': 'SEAT',
      'cid': 1414,
      'pid': 815
    },
    {
      'category': 'Saab',
      'cid': 897,
      'pid': 815
    },
    {
      'category': 'Saturn',
      'cid': 860,
      'pid': 815
    },
    {
      'category': 'Subaru',
      'cid': 861,
      'pid': 815
    },
    {
      'category': 'Suzuki',
      'cid': 1070,
      'pid': 815
    },
    {
      'category': 'Toyota',
      'cid': 863,
      'pid': 815
    },
    {
      'category': 'Scion',
      'cid': 1069,
      'pid': 863
    },
    {
      'category': 'Vauxhall-Opel',
      'cid': 898,
      'pid': 815
    },
    {
      'category': 'Volkswagen',
      'cid': 865,
      'pid': 815
    },
    {
      'category': 'Volvo',
      'cid': 867,
      'pid': 815
    },
    {
      'category': 'Vehicle Codes & Driving Laws',
      'cid': 1294,
      'pid': 47
    },
    {
      'category': 'Drunk Driving Law',
      'cid': 968,
      'pid': 1294
    },
    {
      'category': 'Vehicle & Traffic Safety',
      'cid': 1416,
      'pid': 1294
    },
    {
      'category': 'Vehicle Licensing & Registration',
      'cid': 170,
      'pid': 1294
    },
    {
      'category': 'Driving Instruction & Driver Education',
      'cid': 1415,
      'pid': 170
    },
    {
      'category': 'Vehicle Maintenance',
      'cid': 138,
      'pid': 47
    },
    {
      'category': 'Vehicle Parts & Accessories',
      'cid': 89,
      'pid': 47
    },
    {
      'category': 'Auto Exterior',
      'cid': 1217,
      'pid': 89
    },
    {
      'category': 'Auto Interior',
      'cid': 1218,
      'pid': 89
    },
    {
      'category': 'Engine & Transmission',
      'cid': 1216,
      'pid': 89
    },
    {
      'category': 'Vehicle Fuels & Lubricants',
      'cid': 1269,
      'pid': 89
    },
    {
      'category': 'Vehicle Wheels & Tires',
      'cid': 438,
      'pid': 89
    },
    {
      'category': 'Vehicle Shopping',
      'cid': 473,
      'pid': 47
    },
    {
      'category': 'Fuel Economy & Gas Prices',
      'cid': 1268,
      'pid': 473
    },
    {
      'category': 'Vehicle Specs, Reviews & Comparisons',
      'cid': 1267,
      'pid': 473
    },
    {
      'category': 'Vehicle Shows',
      'cid': 803,
      'pid': 47
    },
    {
      'category': 'Beauty & Fitness',
      'cid': 44,
      'pid': 0
    },
    {
      'category': 'Beauty Pageants',
      'cid': 1219,
      'pid': 44
    },
    {
      'category': 'Body Art',
      'cid': 239,
      'pid': 44
    },
    {
      'category': 'Cosmetic Procedures',
      'cid': 1220,
      'pid': 44
    },
    {
      'category': 'Cosmetic Surgery',
      'cid': 238,
      'pid': 1220
    },
    {
      'category': 'Cosmetology & Beauty Professionals',
      'cid': 147,
      'pid': 44
    },
    {
      'category': 'Face & Body Care',
      'cid': 143,
      'pid': 44
    },
    {
      'category': 'Hygiene & Toiletries',
      'cid': 244,
      'pid': 143
    },
    {
      'category': 'Make-Up & Cosmetics',
      'cid': 234,
      'pid': 143
    },
    {
      'category': 'Perfumes & Fragrances',
      'cid': 242,
      'pid': 143
    },
    {
      'category': 'Skin & Nail Care',
      'cid': 93,
      'pid': 143
    },
    {
      'category': 'Unwanted Body & Facial Hair Removal',
      'cid': 144,
      'pid': 143
    },
    {
      'category': 'Fashion & Style',
      'cid': 185,
      'pid': 44
    },
    {
      'category': 'Fashion Designers & Collections',
      'cid': 98,
      'pid': 185
    },
    {
      'category': 'Fashion Modeling',
      'cid': 1155,
      'pid': 185
    },
    {
      'category': 'Fitness',
      'cid': 94,
      'pid': 44
    },
    {
      'category': 'Bodybuilding',
      'cid': 241,
      'pid': 94
    },
    {
      'category': 'Fitness Equipment & Accessories',
      'cid': 1417,
      'pid': 94
    },
    {
      'category': 'Fitness Instruction & Personal Training',
      'cid': 1418,
      'pid': 94
    },
    {
      'category': 'Gyms & Health Clubs',
      'cid': 1419,
      'pid': 94
    },
    {
      'category': 'Yoga & Pilates',
      'cid': 611,
      'pid': 94
    },
    {
      'category': 'Hair Care',
      'cid': 146,
      'pid': 44
    },
    {
      'category': 'Hair Loss',
      'cid': 235,
      'pid': 146
    },
    {
      'category': 'Spas & Beauty Services',
      'cid': 145,
      'pid': 44
    },
    {
      'category': 'Massage Therapy',
      'cid': 557,
      'pid': 145
    },
    {
      'category': 'Weight Loss',
      'cid': 236,
      'pid': 44
    },
    {
      'category': 'Books & Literature',
      'cid': 22,
      'pid': 0
    },
    {
      'category': 'Book Retailers',
      'cid': 355,
      'pid': 22
    },
    {
      'category': 'Children\'s Literature',
      'cid': 1183,
      'pid': 22
    },
    {
      'category': 'E-Books',
      'cid': 608,
      'pid': 22
    },
    {
      'category': 'Fan Fiction',
      'cid': 540,
      'pid': 22
    },
    {
      'category': 'Literary Classics',
      'cid': 1184,
      'pid': 22
    },
    {
      'category': 'Magazines',
      'cid': 412,
      'pid': 22
    },
    {
      'category': 'Poetry',
      'cid': 565,
      'pid': 22
    },
    {
      'category': 'Writers Resources',
      'cid': 1177,
      'pid': 22
    },
    {
      'category': 'Business & Industrial',
      'cid': 12,
      'pid': 0
    },
    {
      'category': 'Advertising & Marketing',
      'cid': 25,
      'pid': 12
    },
    {
      'category': 'Brand Management',
      'cid': 1548,
      'pid': 25
    },
    {
      'category': 'Marketing',
      'cid': 83,
      'pid': 25
    },
    {
      'category': 'Promotional Items & Corporate Gifts',
      'cid': 1420,
      'pid': 25
    },
    {
      'category': 'Public Relations',
      'cid': 327,
      'pid': 25
    },
    {
      'category': 'Sales',
      'cid': 1547,
      'pid': 25
    },
    {
      'category': 'Telemarketing',
      'cid': 328,
      'pid': 25
    },
    {
      'category': 'Aerospace & Defense',
      'cid': 356,
      'pid': 12
    },
    {
      'category': 'Defense Industry',
      'cid': 669,
      'pid': 356
    },
    {
      'category': 'Space Technology',
      'cid': 668,
      'pid': 356
    },
    {
      'category': 'Agriculture & Forestry',
      'cid': 46,
      'pid': 12
    },
    {
      'category': 'Agricultural Equipment',
      'cid': 748,
      'pid': 46
    },
    {
      'category': 'Aquaculture',
      'cid': 747,
      'pid': 46
    },
    {
      'category': 'Crops & Seed',
      'cid': 749,
      'pid': 46
    },
    {
      'category': 'Farms & Ranches',
      'cid': 1578,
      'pid': 46
    },
    {
      'category': 'Food Production',
      'cid': 621,
      'pid': 46
    },
    {
      'category': 'Forestry',
      'cid': 750,
      'pid': 46
    },
    {
      'category': 'Horticulture',
      'cid': 751,
      'pid': 46
    },
    {
      'category': 'Livestock',
      'cid': 752,
      'pid': 46
    },
    {
      'category': 'Automotive Industry',
      'cid': 1190,
      'pid': 12
    },
    {
      'category': 'Business Education',
      'cid': 799,
      'pid': 12
    },
    {
      'category': 'Business Finance',
      'cid': 1138,
      'pid': 12
    },
    {
      'category': 'Commercial Lending',
      'cid': 1160,
      'pid': 1138
    },
    {
      'category': 'Investment Banking',
      'cid': 1139,
      'pid': 1138
    },
    {
      'category': 'Risk Management',
      'cid': 620,
      'pid': 1138
    },
    {
      'category': 'Venture Capital',
      'cid': 905,
      'pid': 1138
    },
    {
      'category': 'Business Operations',
      'cid': 1159,
      'pid': 12
    },
    {
      'category': 'Business Plans & Presentations',
      'cid': 336,
      'pid': 1159
    },
    {
      'category': 'Human Resources',
      'cid': 157,
      'pid': 1159
    },
    {
      'category': 'Compensation & Benefits',
      'cid': 723,
      'pid': 157
    },
    {
      'category': 'Corporate Training',
      'cid': 331,
      'pid': 157
    },
    {
      'category': 'Payroll Services',
      'cid': 724,
      'pid': 157
    },
    {
      'category': 'Recruitment & Staffing',
      'cid': 330,
      'pid': 157
    },
    {
      'category': 'Management',
      'cid': 338,
      'pid': 1159
    },
    {
      'category': 'Business Process',
      'cid': 721,
      'pid': 338
    },
    {
      'category': 'Project Management',
      'cid': 1360,
      'pid': 338
    },
    {
      'category': 'Strategic Planning',
      'cid': 722,
      'pid': 338
    },
    {
      'category': 'Supply Chain Management',
      'cid': 801,
      'pid': 338
    },
    {
      'category': 'Business Services',
      'cid': 329,
      'pid': 12
    },
    {
      'category': 'Consulting',
      'cid': 1162,
      'pid': 329
    },
    {
      'category': 'Corporate Events',
      'cid': 334,
      'pid': 329
    },
    {
      'category': 'Trade Fairs & Industry Shows',
      'cid': 335,
      'pid': 334
    },
    {
      'category': 'E-Commerce Services',
      'cid': 340,
      'pid': 329
    },
    {
      'category': 'Merchant Services & Payment Systems',
      'cid': 280,
      'pid': 340
    },
    {
      'category': 'Fire & Security Services',
      'cid': 726,
      'pid': 329
    },
    {
      'category': 'Knowledge Management',
      'cid': 800,
      'pid': 329
    },
    {
      'category': 'Office Services',
      'cid': 28,
      'pid': 329
    },
    {
      'category': 'Office & Facilities Management',
      'cid': 337,
      'pid': 28
    },
    {
      'category': 'Office Supplies',
      'cid': 95,
      'pid': 329
    },
    {
      'category': 'Office Furniture',
      'cid': 333,
      'pid': 95
    },
    {
      'category': 'Outsourcing',
      'cid': 718,
      'pid': 329
    },
    {
      'category': 'Physical Asset Management',
      'cid': 719,
      'pid': 329
    },
    {
      'category': 'Quality Control & Tracking',
      'cid': 720,
      'pid': 329
    },
    {
      'category': 'Signage',
      'cid': 1076,
      'pid': 329
    },
    {
      'category': 'Writing & Editing Services',
      'cid': 725,
      'pid': 329
    },
    {
      'category': 'Chemicals Industry',
      'cid': 288,
      'pid': 12
    },
    {
      'category': 'Agrochemicals',
      'cid': 670,
      'pid': 288
    },
    {
      'category': 'Cleaning Agents',
      'cid': 671,
      'pid': 288
    },
    {
      'category': 'Coatings & Adhesives',
      'cid': 672,
      'pid': 288
    },
    {
      'category': 'Dyes & Pigments',
      'cid': 673,
      'pid': 288
    },
    {
      'category': 'Plastics & Polymers',
      'cid': 674,
      'pid': 288
    },
    {
      'category': 'Construction & Maintenance',
      'cid': 48,
      'pid': 12
    },
    {
      'category': 'Building Materials & Supplies',
      'cid': 650,
      'pid': 48
    },
    {
      'category': 'Nails Screws & Fasteners',
      'cid': 829,
      'pid': 650
    },
    {
      'category': 'Plumbing Fixtures & Equipment',
      'cid': 830,
      'pid': 650
    },
    {
      'category': 'Wood & Plastics',
      'cid': 831,
      'pid': 650
    },
    {
      'category': 'Civil Engineering',
      'cid': 651,
      'pid': 48
    },
    {
      'category': 'Construction Consulting & Contracting',
      'cid': 652,
      'pid': 48
    },
    {
      'category': 'Urban & Regional Planning',
      'cid': 686,
      'pid': 48
    },
    {
      'category': 'Energy & Utilities',
      'cid': 233,
      'pid': 12
    },
    {
      'category': 'Electricity',
      'cid': 658,
      'pid': 233
    },
    {
      'category': 'Nuclear Energy',
      'cid': 954,
      'pid': 233
    },
    {
      'category': 'Oil & Gas',
      'cid': 659,
      'pid': 233
    },
    {
      'category': 'Renewable & Alternative Energy',
      'cid': 657,
      'pid': 233
    },
    {
      'category': 'Hydropower',
      'cid': 1424,
      'pid': 657
    },
    {
      'category': 'Solar Power',
      'cid': 1422,
      'pid': 657
    },
    {
      'category': 'Wind Power',
      'cid': 1423,
      'pid': 657
    },
    {
      'category': 'Waste Management',
      'cid': 660,
      'pid': 233
    },
    {
      'category': 'Recycling',
      'cid': 1307,
      'pid': 660
    },
    {
      'category': 'Water Supply & Treatment',
      'cid': 1349,
      'pid': 233
    },
    {
      'category': 'Hospitality Industry',
      'cid': 955,
      'pid': 12
    },
    {
      'category': 'Event Planning',
      'cid': 956,
      'pid': 955
    },
    {
      'category': 'Food Service',
      'cid': 957,
      'pid': 955
    },
    {
      'category': 'Restaurant Supply',
      'cid': 816,
      'pid': 957
    },
    {
      'category': 'Industrial Materials & Equipment',
      'cid': 287,
      'pid': 12
    },
    {
      'category': 'Fluid Handling',
      'cid': 1152,
      'pid': 287
    },
    {
      'category': 'Valves Hoses & Fittings',
      'cid': 839,
      'pid': 1152
    },
    {
      'category': 'Generators',
      'cid': 835,
      'pid': 287
    },
    {
      'category': 'Heavy Machinery',
      'cid': 837,
      'pid': 287
    },
    {
      'category': 'Manufacturing',
      'cid': 49,
      'pid': 12
    },
    {
      'category': 'Factory Automation',
      'cid': 661,
      'pid': 49
    },
    {
      'category': 'Metals & Mining',
      'cid': 606,
      'pid': 12
    },
    {
      'category': 'Precious Metals',
      'cid': 1425,
      'pid': 606
    },
    {
      'category': 'Pharmaceuticals & Biotech',
      'cid': 255,
      'pid': 12
    },
    {
      'category': 'Printing & Publishing',
      'cid': 1176,
      'pid': 12
    },
    {
      'category': 'Document & Printing Services',
      'cid': 332,
      'pid': 1176
    },
    {
      'category': 'Business Cards & Stationary',
      'cid': 1375,
      'pid': 332
    },
    {
      'category': 'Professional & Trade Associations',
      'cid': 1199,
      'pid': 12
    },
    {
      'category': 'Retail Trade',
      'cid': 841,
      'pid': 12
    },
    {
      'category': 'Retail Equipment & Technology',
      'cid': 844,
      'pid': 841
    },
    {
      'category': 'Small Business',
      'cid': 551,
      'pid': 12
    },
    {
      'category': 'Business Formation',
      'cid': 1200,
      'pid': 551
    },
    {
      'category': 'Home Office',
      'cid': 727,
      'pid': 551
    },
    {
      'category': 'MLM & Business Opportunities',
      'cid': 552,
      'pid': 551
    },
    {
      'category': 'Textiles & Nonwovens',
      'cid': 566,
      'pid': 12
    },
    {
      'category': 'Transportation & Logistics',
      'cid': 50,
      'pid': 12
    },
    {
      'category': 'Aviation',
      'cid': 662,
      'pid': 50
    },
    {
      'category': 'Distribution & Logistics',
      'cid': 664,
      'pid': 50
    },
    {
      'category': 'Freight & Trucking',
      'cid': 289,
      'pid': 50
    },
    {
      'category': 'Import & Export',
      'cid': 354,
      'pid': 50
    },
    {
      'category': 'Mail & Package Delivery',
      'cid': 1150,
      'pid': 50
    },
    {
      'category': 'Couriers & Messengers',
      'cid': 663,
      'pid': 1150
    },
    {
      'category': 'Maritime Transport',
      'cid': 665,
      'pid': 50
    },
    {
      'category': 'Moving & Relocation',
      'cid': 291,
      'pid': 50
    },
    {
      'category': 'Packaging',
      'cid': 290,
      'pid': 50
    },
    {
      'category': 'Parking',
      'cid': 1306,
      'pid': 50
    },
    {
      'category': 'Self Storage',
      'cid': 1347,
      'pid': 50
    },
    {
      'category': 'Rail Transport',
      'cid': 666,
      'pid': 50
    },
    {
      'category': 'Urban Transport',
      'cid': 667,
      'pid': 50
    },
    {
      'category': 'Warehousing',
      'cid': 1426,
      'pid': 50
    },
    {
      'category': 'Computers & Electronics',
      'cid': 5,
      'pid': 0
    },
    {
      'category': 'CAD & CAM',
      'cid': 1300,
      'pid': 5
    },
    {
      'category': 'Computer Hardware',
      'cid': 30,
      'pid': 5
    },
    {
      'category': 'Computer Components',
      'cid': 717,
      'pid': 30
    },
    {
      'category': 'Chips & Processors',
      'cid': 741,
      'pid': 717
    },
    {
      'category': 'Computer Memory',
      'cid': 226,
      'pid': 717
    },
    {
      'category': 'Sound & Video Cards',
      'cid': 740,
      'pid': 717
    },
    {
      'category': 'Computer Drives & Storage',
      'cid': 496,
      'pid': 30
    },
    {
      'category': 'CD & DVD Drives & Burners',
      'cid': 1321,
      'pid': 496
    },
    {
      'category': 'CD & DVD Storage Media',
      'cid': 1322,
      'pid': 496
    },
    {
      'category': 'Data Backup & Recovery',
      'cid': 1323,
      'pid': 496
    },
    {
      'category': 'Flash Drives & Memory Cards',
      'cid': 1318,
      'pid': 496
    },
    {
      'category': 'Hard Drives',
      'cid': 1320,
      'pid': 496
    },
    {
      'category': 'Memory Card Readers',
      'cid': 1319,
      'pid': 496
    },
    {
      'category': 'Network Storage',
      'cid': 729,
      'pid': 496
    },
    {
      'category': 'Computer Peripherals',
      'cid': 312,
      'pid': 30
    },
    {
      'category': 'Computer Monitors & Displays',
      'cid': 487,
      'pid': 312
    },
    {
      'category': 'Input Devices',
      'cid': 493,
      'pid': 312
    },
    {
      'category': 'Printers, Copiers & Fax',
      'cid': 1330,
      'pid': 312
    },
    {
      'category': 'Copiers',
      'cid': 1331,
      'pid': 1330
    },
    {
      'category': 'Fax Machines',
      'cid': 1332,
      'pid': 1330
    },
    {
      'category': 'Ink & Toner',
      'cid': 1333,
      'pid': 1330
    },
    {
      'category': 'Printers',
      'cid': 494,
      'pid': 1330
    },
    {
      'category': 'Scanners',
      'cid': 495,
      'pid': 1330
    },
    {
      'category': 'Computer Servers',
      'cid': 728,
      'pid': 30
    },
    {
      'category': 'Desktop Computers',
      'cid': 309,
      'pid': 30
    },
    {
      'category': 'Hardware Modding & Tuning',
      'cid': 739,
      'pid': 30
    },
    {
      'category': 'Laptops & Notebooks',
      'cid': 310,
      'pid': 30
    },
    {
      'category': 'Tablet PCs',
      'cid': 1277,
      'pid': 310
    },
    {
      'category': 'Computer Security',
      'cid': 314,
      'pid': 5
    },
    {
      'category': 'Antivirus & Malware',
      'cid': 315,
      'pid': 314
    },
    {
      'category': 'Hacking & Cracking',
      'cid': 738,
      'pid': 314
    },
    {
      'category': 'Network Security',
      'cid': 344,
      'pid': 314
    },
    {
      'category': 'Consumer Electronics',
      'cid': 78,
      'pid': 5
    },
    {
      'category': 'Audio Equipment',
      'cid': 361,
      'pid': 78
    },
    {
      'category': 'Headphones',
      'cid': 1396,
      'pid': 361
    },
    {
      'category': 'Speakers',
      'cid': 1158,
      'pid': 361
    },
    {
      'category': 'Stereo Systems & Components',
      'cid': 91,
      'pid': 361
    },
    {
      'category': 'Camera & Photo Equipment',
      'cid': 573,
      'pid': 78
    },
    {
      'category': 'Binoculars, Telescopes & Optical Devices',
      'cid': 1384,
      'pid': 573
    },
    {
      'category': 'Cameras & Camcorders',
      'cid': 306,
      'pid': 573
    },
    {
      'category': 'Camcorders',
      'cid': 308,
      'pid': 306
    },
    {
      'category': 'Camera Lenses',
      'cid': 1383,
      'pid': 306
    },
    {
      'category': 'Cameras',
      'cid': 307,
      'pid': 306
    },
    {
      'category': 'Car Electronics',
      'cid': 1188,
      'pid': 78
    },
    {
      'category': 'Car Audio',
      'cid': 230,
      'pid': 1188
    },
    {
      'category': 'Car Video',
      'cid': 1189,
      'pid': 1188
    },
    {
      'category': 'Electronic Accessories',
      'cid': 1192,
      'pid': 78
    },
    {
      'category': 'GPS & Navigation',
      'cid': 794,
      'pid': 78
    },
    {
      'category': 'Gadgets & Portable Electronics',
      'cid': 362,
      'pid': 78
    },
    {
      'category': 'E-Book Readers',
      'cid': 1324,
      'pid': 362
    },
    {
      'category': 'MP3 & Portable Media Players',
      'cid': 227,
      'pid': 362
    },
    {
      'category': 'PDAs & Handhelds',
      'cid': 228,
      'pid': 362
    },
    {
      'category': 'Game Systems & Consoles',
      'cid': 899,
      'pid': 78
    },
    {
      'category': 'Handheld Game Consoles',
      'cid': 1046,
      'pid': 899
    },
    {
      'category': 'Nintendo',
      'cid': 1043,
      'pid': 899
    },
    {
      'category': 'Sony PlayStation',
      'cid': 1044,
      'pid': 899
    },
    {
      'category': 'Xbox',
      'cid': 1045,
      'pid': 899
    },
    {
      'category': 'TV & Video Equipment',
      'cid': 229,
      'pid': 78
    },
    {
      'category': 'DVRs & Set-Top Boxes',
      'cid': 1393,
      'pid': 229
    },
    {
      'category': 'Home Theater Systems',
      'cid': 1157,
      'pid': 229
    },
    {
      'category': 'Projectors & Screens',
      'cid': 1334,
      'pid': 229
    },
    {
      'category': 'Televisions',
      'cid': 305,
      'pid': 229
    },
    {
      'category': 'HDTVs',
      'cid': 1354,
      'pid': 305
    },
    {
      'category': 'LCD TVs',
      'cid': 1356,
      'pid': 305
    },
    {
      'category': 'Plasma TVs',
      'cid': 1355,
      'pid': 305
    },
    {
      'category': 'Projection TVs',
      'cid': 1357,
      'pid': 305
    },
    {
      'category': 'Video Players & Recorders',
      'cid': 492,
      'pid': 229
    },
    {
      'category': 'Blu-Ray Players & Recorders',
      'cid': 1394,
      'pid': 492
    },
    {
      'category': 'DVD Players & Recorders',
      'cid': 1395,
      'pid': 492
    },
    {
      'category': 'Electronics & Electrical',
      'cid': 434,
      'pid': 5
    },
    {
      'category': 'Data Sheets & Electronics Reference',
      'cid': 900,
      'pid': 434
    },
    {
      'category': 'Electromechanical Devices',
      'cid': 743,
      'pid': 434
    },
    {
      'category': 'Electronic Components',
      'cid': 742,
      'pid': 434
    },
    {
      'category': 'Optoelectronics & Fiber',
      'cid': 744,
      'pid': 434
    },
    {
      'category': 'Power Supplies',
      'cid': 745,
      'pid': 434
    },
    {
      'category': 'Test & Measurement',
      'cid': 746,
      'pid': 434
    },
    {
      'category': 'Enterprise Technology',
      'cid': 77,
      'pid': 5
    },
    {
      'category': 'Customer Relationship Management (CRM)',
      'cid': 341,
      'pid': 77
    },
    {
      'category': 'Data Management',
      'cid': 343,
      'pid': 77
    },
    {
      'category': 'Enterprise Resource Planning (ERP)',
      'cid': 342,
      'pid': 77
    },
    {
      'category': 'Networking',
      'cid': 311,
      'pid': 5
    },
    {
      'category': 'Data Formats & Protocols',
      'cid': 488,
      'pid': 311
    },
    {
      'category': 'Network Monitoring & Management',
      'cid': 347,
      'pid': 311
    },
    {
      'category': 'Networking Equipment',
      'cid': 346,
      'pid': 311
    },
    {
      'category': 'VPN & Remote Access',
      'cid': 1279,
      'pid': 311
    },
    {
      'category': 'Programming',
      'cid': 31,
      'pid': 5
    },
    {
      'category': 'C & C++',
      'cid': 731,
      'pid': 31
    },
    {
      'category': 'Development Tools',
      'cid': 730,
      'pid': 31
    },
    {
      'category': 'Java (Programming Language)',
      'cid': 732,
      'pid': 31
    },
    {
      'category': 'Scripting Languages',
      'cid': 733,
      'pid': 31
    },
    {
      'category': 'Windows & .NET',
      'cid': 734,
      'pid': 31
    },
    {
      'category': 'Software',
      'cid': 32,
      'pid': 5
    },
    {
      'category': 'Business & Productivity Software',
      'cid': 498,
      'pid': 32
    },
    {
      'category': 'Accounting & Financial Software',
      'cid': 1341,
      'pid': 498
    },
    {
      'category': 'Calendar & Scheduling Software',
      'cid': 1358,
      'pid': 498
    },
    {
      'category': 'Presentation Software',
      'cid': 1346,
      'pid': 498
    },
    {
      'category': 'Project Management Software',
      'cid': 1359,
      'pid': 498
    },
    {
      'category': 'Spreadsheet Software',
      'cid': 1344,
      'pid': 498
    },
    {
      'category': 'Word Processing Software',
      'cid': 1345,
      'pid': 498
    },
    {
      'category': 'Device Drivers',
      'cid': 225,
      'pid': 32
    },
    {
      'category': 'Educational Software',
      'cid': 804,
      'pid': 32
    },
    {
      'category': 'Freeware & Shareware',
      'cid': 901,
      'pid': 32
    },
    {
      'category': 'Internet Software',
      'cid': 807,
      'pid': 32
    },
    {
      'category': 'Content Management',
      'cid': 808,
      'pid': 807
    },
    {
      'category': 'Download Managers',
      'cid': 1500,
      'pid': 807
    },
    {
      'category': 'Internet Clients & Browsers',
      'cid': 304,
      'pid': 807
    },
    {
      'category': 'Proxying & Filtering',
      'cid': 902,
      'pid': 807
    },
    {
      'category': 'Multimedia Software',
      'cid': 497,
      'pid': 32
    },
    {
      'category': 'Audio & Music Software',
      'cid': 1089,
      'pid': 497
    },
    {
      'category': 'Audio Files Formats & Codecs',
      'cid': 1092,
      'pid': 1089
    },
    {
      'category': 'Desktop Publishing',
      'cid': 1088,
      'pid': 497
    },
    {
      'category': 'Fonts',
      'cid': 805,
      'pid': 1088
    },
    {
      'category': 'Graphics & Animation Software',
      'cid': 486,
      'pid': 497
    },
    {
      'category': 'Media Players',
      'cid': 1090,
      'pid': 497
    },
    {
      'category': 'Photo & Video Software',
      'cid': 577,
      'pid': 497
    },
    {
      'category': 'Video File Formats & Codecs',
      'cid': 1315,
      'pid': 577
    },
    {
      'category': 'Open Source',
      'cid': 313,
      'pid': 32
    },
    {
      'category': 'Operating Systems',
      'cid': 303,
      'pid': 32
    },
    {
      'category': 'Linux & Unix',
      'cid': 736,
      'pid': 303
    },
    {
      'category': 'Mac OS',
      'cid': 735,
      'pid': 303
    },
    {
      'category': 'Mobile OS',
      'cid': 1382,
      'pid': 303
    },
    {
      'category': 'Android OS',
      'cid': 1534,
      'pid': 1382
    },
    {
      'category': 'Apple iOS',
      'cid': 1533,
      'pid': 1382
    },
    {
      'category': 'BlackBerry OS',
      'cid': 1535,
      'pid': 1382
    },
    {
      'category': 'Symbian OS',
      'cid': 1536,
      'pid': 1382
    },
    {
      'category': 'Windows Mobile OS',
      'cid': 1537,
      'pid': 1382
    },
    {
      'category': 'Windows OS',
      'cid': 737,
      'pid': 303
    },
    {
      'category': 'Software Utilities',
      'cid': 224,
      'pid': 32
    },
    {
      'category': 'Finance',
      'cid': 7,
      'pid': 0
    },
    {
      'category': 'Accounting & Auditing',
      'cid': 278,
      'pid': 7
    },
    {
      'category': 'Billing & Invoicing',
      'cid': 1427,
      'pid': 278
    },
    {
      'category': 'Bookkeeping',
      'cid': 1428,
      'pid': 278
    },
    {
      'category': 'Tax Preparation & Planning',
      'cid': 1283,
      'pid': 278
    },
    {
      'category': 'Banking',
      'cid': 37,
      'pid': 7
    },
    {
      'category': 'ATMs & Branch Locations',
      'cid': 1429,
      'pid': 37
    },
    {
      'category': 'Debit & Checking Services',
      'cid': 1430,
      'pid': 37
    },
    {
      'category': 'Money Transfer & Wire Services',
      'cid': 1432,
      'pid': 37
    },
    {
      'category': 'Savings Accounts',
      'cid': 1431,
      'pid': 37
    },
    {
      'category': 'Credit & Lending',
      'cid': 279,
      'pid': 7
    },
    {
      'category': 'Credit Cards',
      'cid': 811,
      'pid': 279
    },
    {
      'category': 'Credit Reporting & Monitoring',
      'cid': 1433,
      'pid': 279
    },
    {
      'category': 'Debt Collection & Repossession',
      'cid': 1434,
      'pid': 279
    },
    {
      'category': 'Debt Management',
      'cid': 812,
      'pid': 279
    },
    {
      'category': 'Loans',
      'cid': 1435,
      'pid': 279
    },
    {
      'category': 'Home Financing',
      'cid': 466,
      'pid': 1435
    },
    {
      'category': 'Home Equity Loans & Lines of Credit',
      'cid': 1445,
      'pid': 466
    },
    {
      'category': 'Home Refinancing',
      'cid': 1446,
      'pid': 466
    },
    {
      'category': 'Mortgages',
      'cid': 1447,
      'pid': 466
    },
    {
      'category': 'Personal Loans',
      'cid': 1436,
      'pid': 1435
    },
    {
      'category': 'Short-Term Loans & Cash Advances',
      'cid': 1437,
      'pid': 1436
    },
    {
      'category': 'Student Loans & College Financing',
      'cid': 813,
      'pid': 1435
    },
    {
      'category': 'Vehicle Financing',
      'cid': 1448,
      'pid': 1435
    },
    {
      'category': 'Auto Financing',
      'cid': 468,
      'pid': 1448
    },
    {
      'category': 'Financial Planning & Management',
      'cid': 903,
      'pid': 7
    },
    {
      'category': 'Asset & Portfolio Management',
      'cid': 1439,
      'pid': 903
    },
    {
      'category': 'Inheritance & Estate Planning',
      'cid': 1438,
      'pid': 903
    },
    {
      'category': 'Retirement & Pension',
      'cid': 619,
      'pid': 903
    },
    {
      'category': 'Grants, Scholarships & Financial Aid',
      'cid': 1282,
      'pid': 7
    },
    {
      'category': 'Government Grants',
      'cid': 1440,
      'pid': 1282
    },
    {
      'category': 'Study Grants & Scholarships',
      'cid': 1441,
      'pid': 1282
    },
    {
      'category': 'Insurance',
      'cid': 38,
      'pid': 7
    },
    {
      'category': 'Health Insurance',
      'cid': 249,
      'pid': 38
    },
    {
      'category': 'Home Insurance',
      'cid': 465,
      'pid': 38
    },
    {
      'category': 'Life Insurance',
      'cid': 1442,
      'pid': 38
    },
    {
      'category': 'Travel Insurance',
      'cid': 1443,
      'pid': 38
    },
    {
      'category': 'Vehicle Insurance',
      'cid': 1444,
      'pid': 38
    },
    {
      'category': 'Auto Insurance',
      'cid': 467,
      'pid': 1444
    },
    {
      'category': 'Investing',
      'cid': 107,
      'pid': 7
    },
    {
      'category': 'Brokerages & Day Trading',
      'cid': 1449,
      'pid': 107
    },
    {
      'category': 'Commodities & Futures Trading',
      'cid': 904,
      'pid': 107
    },
    {
      'category': 'Currencies & Foreign Exchange',
      'cid': 814,
      'pid': 107
    },
    {
      'category': 'Derivatives',
      'cid': 1450,
      'pid': 107
    },
    {
      'category': 'Funds',
      'cid': 1451,
      'pid': 107
    },
    {
      'category': 'Exchange Traded Funds',
      'cid': 1452,
      'pid': 1451
    },
    {
      'category': 'Hedge Funds',
      'cid': 1453,
      'pid': 1451
    },
    {
      'category': 'Mutual Funds',
      'cid': 1454,
      'pid': 1451
    },
    {
      'category': 'Real Estate Investment Trusts',
      'cid': 1455,
      'pid': 107
    },
    {
      'category': 'Socially Responsible Investing',
      'cid': 1456,
      'pid': 107
    },
    {
      'category': 'Stocks & Bonds',
      'cid': 1457,
      'pid': 107
    },
    {
      'category': 'Bonds',
      'cid': 1458,
      'pid': 1457
    },
    {
      'category': 'Exchanges',
      'cid': 1459,
      'pid': 1457
    },
    {
      'category': 'Food & Drink',
      'cid': 71,
      'pid': 0
    },
    {
      'category': 'Beverages',
      'cid': 560,
      'pid': 71
    },
    {
      'category': 'Alcoholic Beverages',
      'cid': 277,
      'pid': 560
    },
    {
      'category': 'Beer',
      'cid': 404,
      'pid': 277
    },
    {
      'category': 'Liquor',
      'cid': 406,
      'pid': 277
    },
    {
      'category': 'Wine',
      'cid': 405,
      'pid': 277
    },
    {
      'category': 'Bottled Water',
      'cid': 1509,
      'pid': 560
    },
    {
      'category': 'Coffee & Tea',
      'cid': 916,
      'pid': 560
    },
    {
      'category': 'Coffee',
      'cid': 1527,
      'pid': 916
    },
    {
      'category': 'Tea',
      'cid': 1528,
      'pid': 916
    },
    {
      'category': 'Juice',
      'cid': 1510,
      'pid': 560
    },
    {
      'category': 'Soft Drinks',
      'cid': 1511,
      'pid': 560
    },
    {
      'category': 'Cooking & Recipes',
      'cid': 122,
      'pid': 71
    },
    {
      'category': 'BBQ & Grilling',
      'cid': 1525,
      'pid': 122
    },
    {
      'category': 'Cuisines',
      'cid': 911,
      'pid': 122
    },
    {
      'category': 'East Asian Cuisine',
      'cid': 912,
      'pid': 911
    },
    {
      'category': 'Chinese Cuisine',
      'cid': 1551,
      'pid': 912
    },
    {
      'category': 'Japanese Cuisine',
      'cid': 1552,
      'pid': 912
    },
    {
      'category': 'Korean Cuisine',
      'cid': 1554,
      'pid': 912
    },
    {
      'category': 'Eastern European Cuisine',
      'cid': 1563,
      'pid': 911
    },
    {
      'category': 'French Cuisine',
      'cid': 1556,
      'pid': 911
    },
    {
      'category': 'German Cuisine',
      'cid': 1564,
      'pid': 911
    },
    {
      'category': 'Latin American Cuisine',
      'cid': 913,
      'pid': 911
    },
    {
      'category': 'Caribbean Cuisine',
      'cid': 1566,
      'pid': 913
    },
    {
      'category': 'Mexican Cuisine',
      'cid': 1560,
      'pid': 913
    },
    {
      'category': 'South American Cuisine',
      'cid': 1565,
      'pid': 913
    },
    {
      'category': 'Mediterranean Cuisine',
      'cid': 914,
      'pid': 911
    },
    {
      'category': 'Greek Cuisine',
      'cid': 1558,
      'pid': 914
    },
    {
      'category': 'Italian Cuisine',
      'cid': 1557,
      'pid': 914
    },
    {
      'category': 'Spanish Cuisine',
      'cid': 1559,
      'pid': 914
    },
    {
      'category': 'Middle Eastern Cuisine',
      'cid': 1532,
      'pid': 911
    },
    {
      'category': 'North American Cuisine',
      'cid': 915,
      'pid': 911
    },
    {
      'category': 'South Asian Cuisine',
      'cid': 1567,
      'pid': 911
    },
    {
      'category': 'Indian Cuisine',
      'cid': 1553,
      'pid': 1567
    },
    {
      'category': 'Southeast Asian Cuisine',
      'cid': 1568,
      'pid': 911
    },
    {
      'category': 'Thai Cuisine',
      'cid': 1555,
      'pid': 1568
    },
    {
      'category': 'Vietnamese Cuisine',
      'cid': 1569,
      'pid': 1568
    },
    {
      'category': 'Vegetarian Cuisine',
      'cid': 825,
      'pid': 911
    },
    {
      'category': 'Culinary Training',
      'cid': 297,
      'pid': 122
    },
    {
      'category': 'Desserts',
      'cid': 1526,
      'pid': 122
    },
    {
      'category': 'Healthy Eating',
      'cid': 1501,
      'pid': 122
    },
    {
      'category': 'Salads',
      'cid': 1529,
      'pid': 122
    },
    {
      'category': 'Soups & Stews',
      'cid': 910,
      'pid': 122
    },
    {
      'category': 'Food',
      'cid': 1512,
      'pid': 71
    },
    {
      'category': 'Baked Goods',
      'cid': 907,
      'pid': 1512
    },
    {
      'category': 'Breakfast Foods',
      'cid': 1513,
      'pid': 1512
    },
    {
      'category': 'Candy & Sweets',
      'cid': 906,
      'pid': 1512
    },
    {
      'category': 'Condiments & Dressings',
      'cid': 1538,
      'pid': 1512
    },
    {
      'category': 'Cooking Fats & Oils',
      'cid': 1539,
      'pid': 1512
    },
    {
      'category': 'Dairy & Eggs',
      'cid': 1514,
      'pid': 1512
    },
    {
      'category': 'Cheese',
      'cid': 1540,
      'pid': 1514
    },
    {
      'category': 'Fruits & Vegetables',
      'cid': 908,
      'pid': 1512
    },
    {
      'category': 'Gourmet & Specialty Foods',
      'cid': 1541,
      'pid': 1512
    },
    {
      'category': 'Grains & Pasta',
      'cid': 1515,
      'pid': 1512
    },
    {
      'category': 'Herbs & Spices',
      'cid': 1516,
      'pid': 1512
    },
    {
      'category': 'Jams, Jellies & Preserves',
      'cid': 1542,
      'pid': 1512
    },
    {
      'category': 'Meat & Seafood',
      'cid': 909,
      'pid': 1512
    },
    {
      'category': 'Beef',
      'cid': 1543,
      'pid': 909
    },
    {
      'category': 'Fish & Seafood',
      'cid': 1544,
      'pid': 909
    },
    {
      'category': 'Pork',
      'cid': 1545,
      'pid': 909
    },
    {
      'category': 'Poultry',
      'cid': 1546,
      'pid': 909
    },
    {
      'category': 'Organic & Natural Foods',
      'cid': 1517,
      'pid': 1512
    },
    {
      'category': 'Snack Foods',
      'cid': 1518,
      'pid': 1512
    },
    {
      'category': 'Food & Grocery Delivery',
      'cid': 1523,
      'pid': 71
    },
    {
      'category': 'Food & Grocery Retailers',
      'cid': 121,
      'pid': 71
    },
    {
      'category': 'Bakeries',
      'cid': 1573,
      'pid': 121
    },
    {
      'category': 'Butchers',
      'cid': 1574,
      'pid': 121
    },
    {
      'category': 'Convenience Stores',
      'cid': 1575,
      'pid': 121
    },
    {
      'category': 'Delicatessens',
      'cid': 1576,
      'pid': 121
    },
    {
      'category': 'Farmers\' Markets',
      'cid': 1577,
      'pid': 121
    },
    {
      'category': 'Restaurants',
      'cid': 276,
      'pid': 71
    },
    {
      'category': 'Catering',
      'cid': 1524,
      'pid': 276
    },
    {
      'category': 'Dining Guides',
      'cid': 917,
      'pid': 276
    },
    {
      'category': 'Fast Food',
      'cid': 918,
      'pid': 276
    },
    {
      'category': 'Burgers',
      'cid': 1562,
      'pid': 918
    },
    {
      'category': 'Fine Dining',
      'cid': 1561,
      'pid': 276
    },
    {
      'category': 'Pizzerias',
      'cid': 1550,
      'pid': 276
    },
    {
      'category': 'Games',
      'cid': 8,
      'pid': 0
    },
    {
      'category': 'Arcade & Coin-Op Games',
      'cid': 919,
      'pid': 8
    },
    {
      'category': 'Board Games',
      'cid': 920,
      'pid': 8
    },
    {
      'category': 'Chess & Abstract Strategy Games',
      'cid': 921,
      'pid': 920
    },
    {
      'category': 'Miniatures & Wargaming',
      'cid': 922,
      'pid': 920
    },
    {
      'category': 'Card Games',
      'cid': 39,
      'pid': 8
    },
    {
      'category': 'Collectible Card Games',
      'cid': 923,
      'pid': 39
    },
    {
      'category': 'Poker & Casino Games',
      'cid': 924,
      'pid': 39
    },
    {
      'category': 'Computer & Video Games',
      'cid': 41,
      'pid': 8
    },
    {
      'category': 'Action & Platform Games',
      'cid': 1311,
      'pid': 41
    },
    {
      'category': 'Adventure Games',
      'cid': 925,
      'pid': 41
    },
    {
      'category': 'Casual Games',
      'cid': 926,
      'pid': 41
    },
    {
      'category': 'Driving & Racing Games',
      'cid': 927,
      'pid': 41
    },
    {
      'category': 'Fighting Games',
      'cid': 928,
      'pid': 41
    },
    {
      'category': 'Gaming Media & Reference',
      'cid': 1343,
      'pid': 41
    },
    {
      'category': 'Game Cheats & Hints',
      'cid': 381,
      'pid': 1343
    },
    {
      'category': 'Music & Dance Games',
      'cid': 929,
      'pid': 41
    },
    {
      'category': 'Shooter Games',
      'cid': 930,
      'pid': 41
    },
    {
      'category': 'Simulation Games',
      'cid': 931,
      'pid': 41
    },
    {
      'category': 'Business & Tycoon Games',
      'cid': 1497,
      'pid': 931
    },
    {
      'category': 'City Building Games',
      'cid': 1496,
      'pid': 931
    },
    {
      'category': 'Life Simulation Games',
      'cid': 1498,
      'pid': 931
    },
    {
      'category': 'Vehicle Simulators',
      'cid': 1495,
      'pid': 931
    },
    {
      'category': 'Sports Games',
      'cid': 932,
      'pid': 41
    },
    {
      'category': 'Sports Management Games',
      'cid': 1499,
      'pid': 932
    },
    {
      'category': 'Strategy Games',
      'cid': 933,
      'pid': 41
    },
    {
      'category': 'Video Game Development',
      'cid': 1491,
      'pid': 41
    },
    {
      'category': 'Video Game Emulation',
      'cid': 1342,
      'pid': 41
    },
    {
      'category': 'Video Game Retailers',
      'cid': 1146,
      'pid': 41
    },
    {
      'category': 'Dice Games',
      'cid': 1492,
      'pid': 8
    },
    {
      'category': 'Educational Games',
      'cid': 1493,
      'pid': 8
    },
    {
      'category': 'Family-Oriented Games & Activities',
      'cid': 1290,
      'pid': 8
    },
    {
      'category': 'Drawing & Coloring',
      'cid': 1397,
      'pid': 1290
    },
    {
      'category': 'Dress-Up & Fashion Games',
      'cid': 1173,
      'pid': 1290
    },
    {
      'category': 'Gambling',
      'cid': 637,
      'pid': 8
    },
    {
      'category': 'Horse & Dog Racing',
      'cid': 698,
      'pid': 637
    },
    {
      'category': 'Lottery & Sweepstakes',
      'cid': 364,
      'pid': 637
    },
    {
      'category': 'Sports Betting',
      'cid': 934,
      'pid': 637
    },
    {
      'category': 'Online Games',
      'cid': 105,
      'pid': 8
    },
    {
      'category': 'Massive Multiplayer',
      'cid': 935,
      'pid': 105
    },
    {
      'category': 'Party Games',
      'cid': 936,
      'pid': 8
    },
    {
      'category': 'Puzzles & Brainteasers',
      'cid': 937,
      'pid': 8
    },
    {
      'category': 'Roleplaying Games',
      'cid': 622,
      'pid': 8
    },
    {
      'category': 'Table Games',
      'cid': 938,
      'pid': 8
    },
    {
      'category': 'Billiards',
      'cid': 939,
      'pid': 938
    },
    {
      'category': 'Table Tennis',
      'cid': 940,
      'pid': 938
    },
    {
      'category': 'Tile Games',
      'cid': 1549,
      'pid': 8
    },
    {
      'category': 'Word Games',
      'cid': 1494,
      'pid': 8
    },
    {
      'category': 'Health',
      'cid': 45,
      'pid': 0
    },
    {
      'category': 'Aging & Geriatrics',
      'cid': 623,
      'pid': 45
    },
    {
      'category': 'Alzheimer\'s Disease',
      'cid': 624,
      'pid': 623
    },
    {
      'category': 'Alternative & Natural Medicine',
      'cid': 499,
      'pid': 45
    },
    {
      'category': 'Acupuncture & Chinese Medicine',
      'cid': 1239,
      'pid': 499
    },
    {
      'category': 'Cleansing & Detoxification',
      'cid': 1238,
      'pid': 499
    },
    {
      'category': 'Health Conditions',
      'cid': 419,
      'pid': 45
    },
    {
      'category': 'AIDS & HIV',
      'cid': 625,
      'pid': 419
    },
    {
      'category': 'Allergies',
      'cid': 626,
      'pid': 419
    },
    {
      'category': 'Arthritis',
      'cid': 628,
      'pid': 419
    },
    {
      'category': 'Cancer',
      'cid': 429,
      'pid': 419
    },
    {
      'category': 'Cold & Flu',
      'cid': 629,
      'pid': 419
    },
    {
      'category': 'Diabetes',
      'cid': 630,
      'pid': 419
    },
    {
      'category': 'Ear Nose & Throat',
      'cid': 1211,
      'pid': 419
    },
    {
      'category': 'Eating Disorders',
      'cid': 571,
      'pid': 419
    },
    {
      'category': 'Endocrine Conditions',
      'cid': 1328,
      'pid': 419
    },
    {
      'category': 'Thyroid Conditions',
      'cid': 1329,
      'pid': 1328
    },
    {
      'category': 'GERD & Digestive Disorders',
      'cid': 638,
      'pid': 419
    },
    {
      'category': 'Genetic Disorders',
      'cid': 941,
      'pid': 419
    },
    {
      'category': 'Heart & Hypertension',
      'cid': 559,
      'pid': 419
    },
    {
      'category': 'Cholesterol Issues',
      'cid': 643,
      'pid': 559
    },
    {
      'category': 'Infectious Diseases',
      'cid': 632,
      'pid': 419
    },
    {
      'category': 'Parasites & Parasitic Diseases',
      'cid': 1262,
      'pid': 632
    },
    {
      'category': 'Vaccines & Immunizations',
      'cid': 1263,
      'pid': 632
    },
    {
      'category': 'Injury',
      'cid': 817,
      'pid': 419
    },
    {
      'category': 'Neurological Disorders',
      'cid': 942,
      'pid': 419
    },
    {
      'category': 'Obesity',
      'cid': 818,
      'pid': 419
    },
    {
      'category': 'Pain Management',
      'cid': 819,
      'pid': 419
    },
    {
      'category': 'Headaches & Migraines',
      'cid': 631,
      'pid': 819
    },
    {
      'category': 'Respiratory Conditions',
      'cid': 824,
      'pid': 419
    },
    {
      'category': 'Asthma',
      'cid': 627,
      'pid': 824
    },
    {
      'category': 'Skin Conditions',
      'cid': 420,
      'pid': 419
    },
    {
      'category': 'Sleep Disorders',
      'cid': 633,
      'pid': 419
    },
    {
      'category': 'Health Education & Medical Training',
      'cid': 254,
      'pid': 45
    },
    {
      'category': 'Health Foundations & Medical Research',
      'cid': 252,
      'pid': 45
    },
    {
      'category': 'Medical Devices & Equipment',
      'cid': 251,
      'pid': 45
    },
    {
      'category': 'Assistive Technology',
      'cid': 1352,
      'pid': 251
    },
    {
      'category': 'Mobility Equipment & Accessories',
      'cid': 1353,
      'pid': 1352
    },
    {
      'category': 'Medical Facilities & Services',
      'cid': 256,
      'pid': 45
    },
    {
      'category': 'Doctors\' Offices',
      'cid': 634,
      'pid': 256
    },
    {
      'category': 'Hospitals & Treatment Centers',
      'cid': 250,
      'pid': 256
    },
    {
      'category': 'Medical Procedures',
      'cid': 635,
      'pid': 256
    },
    {
      'category': 'Medical Tests & Exams',
      'cid': 943,
      'pid': 635
    },
    {
      'category': 'Surgery',
      'cid': 944,
      'pid': 635
    },
    {
      'category': 'Physical Therapy',
      'cid': 500,
      'pid': 256
    },
    {
      'category': 'Medical Literature & Resources',
      'cid': 253,
      'pid': 45
    },
    {
      'category': 'Medical Photos & Illustration',
      'cid': 945,
      'pid': 253
    },
    {
      'category': 'Men\'s Health',
      'cid': 636,
      'pid': 45
    },
    {
      'category': 'Mental Health',
      'cid': 437,
      'pid': 45
    },
    {
      'category': 'Anxiety & Stress',
      'cid': 639,
      'pid': 437
    },
    {
      'category': 'Depression',
      'cid': 640,
      'pid': 437
    },
    {
      'category': 'Learning & Developmental Disabilities',
      'cid': 641,
      'pid': 437
    },
    {
      'category': 'ADD & ADHD',
      'cid': 642,
      'pid': 641
    },
    {
      'category': 'Nursing',
      'cid': 418,
      'pid': 45
    },
    {
      'category': 'Assisted Living & Long Term Care',
      'cid': 649,
      'pid': 418
    },
    {
      'category': 'Nutrition',
      'cid': 456,
      'pid': 45
    },
    {
      'category': 'Special & Restricted Diets',
      'cid': 457,
      'pid': 456
    },
    {
      'category': 'Kosher Foods',
      'cid': 1572,
      'pid': 457
    },
    {
      'category': 'Low Carbohydrate Diets',
      'cid': 1570,
      'pid': 457
    },
    {
      'category': 'Low Fat & Low Cholesterol Diets',
      'cid': 1571,
      'pid': 457
    },
    {
      'category': 'Vitamins & Supplements',
      'cid': 237,
      'pid': 456
    },
    {
      'category': 'Oral & Dental Care',
      'cid': 245,
      'pid': 45
    },
    {
      'category': 'Pediatrics',
      'cid': 645,
      'pid': 45
    },
    {
      'category': 'Pharmacy',
      'cid': 248,
      'pid': 45
    },
    {
      'category': 'Drugs & Medications',
      'cid': 646,
      'pid': 248
    },
    {
      'category': 'Public Health',
      'cid': 947,
      'pid': 45
    },
    {
      'category': 'Health Policy',
      'cid': 1256,
      'pid': 947
    },
    {
      'category': 'Occupational Health & Safety',
      'cid': 644,
      'pid': 947
    },
    {
      'category': 'Poisons & Overdoses',
      'cid': 946,
      'pid': 947
    },
    {
      'category': 'Reproductive Health',
      'cid': 195,
      'pid': 45
    },
    {
      'category': 'Birth Control',
      'cid': 198,
      'pid': 195
    },
    {
      'category': 'Erectile Dysfunction',
      'cid': 202,
      'pid': 195
    },
    {
      'category': 'Infertility',
      'cid': 647,
      'pid': 195
    },
    {
      'category': 'OBGYN',
      'cid': 558,
      'pid': 195
    },
    {
      'category': 'Sex Education & Counseling',
      'cid': 536,
      'pid': 195
    },
    {
      'category': 'Sexual Enhancement',
      'cid': 1236,
      'pid': 195
    },
    {
      'category': 'Sexually Transmitted Diseases',
      'cid': 421,
      'pid': 195
    },
    {
      'category': 'Substance Abuse',
      'cid': 257,
      'pid': 45
    },
    {
      'category': 'Drug & Alcohol Testing',
      'cid': 1351,
      'pid': 257
    },
    {
      'category': 'Drug & Alcohol Treatment',
      'cid': 1350,
      'pid': 257
    },
    {
      'category': 'Smoking & Smoking Cessation',
      'cid': 1237,
      'pid': 257
    },
    {
      'category': 'Steroids & Performance-Enhancing Drugs',
      'cid': 1235,
      'pid': 257
    },
    {
      'category': 'Vision Care',
      'cid': 246,
      'pid': 45
    },
    {
      'category': 'Eye Exams & Optometry',
      'cid': 1502,
      'pid': 246
    },
    {
      'category': 'Eyeglasses & Contacts',
      'cid': 1224,
      'pid': 246
    },
    {
      'category': 'Laser Vision Correction',
      'cid': 1503,
      'pid': 246
    },
    {
      'category': 'Women\'s Health',
      'cid': 648,
      'pid': 45
    },
    {
      'category': 'Hobbies & Leisure',
      'cid': 65,
      'pid': 0
    },
    {
      'category': 'Clubs & Organizations',
      'cid': 189,
      'pid': 65
    },
    {
      'category': 'Fraternal Orders & Service Clubs',
      'cid': 1468,
      'pid': 189
    },
    {
      'category': 'Youth Organizations & Resources',
      'cid': 1469,
      'pid': 189
    },
    {
      'category': 'Contests, Awards & Prizes',
      'cid': 1276,
      'pid': 65
    },
    {
      'category': 'Crafts',
      'cid': 284,
      'pid': 65
    },
    {
      'category': 'Ceramics & Pottery',
      'cid': 1470,
      'pid': 284
    },
    {
      'category': 'Fiber & Textile Arts',
      'cid': 1230,
      'pid': 284
    },
    {
      'category': 'Outdoors',
      'cid': 688,
      'pid': 65
    },
    {
      'category': 'Equestrian',
      'cid': 568,
      'pid': 688
    },
    {
      'category': 'Fishing',
      'cid': 462,
      'pid': 688
    },
    {
      'category': 'Hiking & Camping',
      'cid': 542,
      'pid': 688
    },
    {
      'category': 'Hunting & Shooting',
      'cid': 461,
      'pid': 688
    },
    {
      'category': 'Paintball',
      'cid': 786,
      'pid': 65
    },
    {
      'category': 'Radio Control & Modeling',
      'cid': 787,
      'pid': 65
    },
    {
      'category': 'Model Trains & Railroads',
      'cid': 1590,
      'pid': 787
    },
    {
      'category': 'Recreational Aviation',
      'cid': 999,
      'pid': 65
    },
    {
      'category': 'Special Occasions',
      'cid': 977,
      'pid': 65
    },
    {
      'category': 'Holidays & Seasonal Events',
      'cid': 678,
      'pid': 977
    },
    {
      'category': 'Birthdays & Name Days',
      'cid': 1270,
      'pid': 678
    },
    {
      'category': 'Carnival & Mardi Gras',
      'cid': 1246,
      'pid': 678
    },
    {
      'category': 'Christian Holidays',
      'cid': 1274,
      'pid': 678
    },
    {
      'category': 'Christmas',
      'cid': 1078,
      'pid': 1274
    },
    {
      'category': 'Easter',
      'cid': 1123,
      'pid': 1274
    },
    {
      'category': 'Halloween & October 31st',
      'cid': 1079,
      'pid': 678
    },
    {
      'category': 'Islamic Holidays',
      'cid': 1275,
      'pid': 678
    },
    {
      'category': 'Jewish Holidays',
      'cid': 1124,
      'pid': 678
    },
    {
      'category': 'New Year',
      'cid': 1271,
      'pid': 678
    },
    {
      'category': 'Thanksgiving',
      'cid': 1125,
      'pid': 678
    },
    {
      'category': 'Valentine\'s Day',
      'cid': 1122,
      'pid': 678
    },
    {
      'category': 'Weddings',
      'cid': 293,
      'pid': 977
    },
    {
      'category': 'Water Activities',
      'cid': 1002,
      'pid': 65
    },
    {
      'category': 'Boating',
      'cid': 459,
      'pid': 1002
    },
    {
      'category': 'Diving & Underwater Activities',
      'cid': 1305,
      'pid': 1002
    },
    {
      'category': 'Surf & Swim',
      'cid': 689,
      'pid': 1002
    },
    {
      'category': 'Home & Garden',
      'cid': 11,
      'pid': 0
    },
    {
      'category': 'Bed & Bath',
      'cid': 948,
      'pid': 11
    },
    {
      'category': 'Bathroom',
      'cid': 1365,
      'pid': 948
    },
    {
      'category': 'Bedroom',
      'cid': 1366,
      'pid': 948
    },
    {
      'category': 'Bedding & Bed Linens',
      'cid': 1369,
      'pid': 1366
    },
    {
      'category': 'Beds & Headboards',
      'cid': 1367,
      'pid': 1366
    },
    {
      'category': 'Mattresses',
      'cid': 1368,
      'pid': 1366
    },
    {
      'category': 'Domestic Services',
      'cid': 472,
      'pid': 11
    },
    {
      'category': 'Cleaning Supplies & Services',
      'cid': 949,
      'pid': 472
    },
    {
      'category': 'Gardening & Landscaping',
      'cid': 269,
      'pid': 11
    },
    {
      'category': 'HVAC & Climate Control',
      'cid': 828,
      'pid': 11
    },
    {
      'category': 'Home Appliances',
      'cid': 271,
      'pid': 11
    },
    {
      'category': 'Water Filters & Purifiers',
      'cid': 1371,
      'pid': 271
    },
    {
      'category': 'Home Furnishings',
      'cid': 270,
      'pid': 11
    },
    {
      'category': 'Clocks',
      'cid': 1363,
      'pid': 270
    },
    {
      'category': 'Lamps & Lighting',
      'cid': 272,
      'pid': 270
    },
    {
      'category': 'Rugs & Carpets',
      'cid': 1362,
      'pid': 270
    },
    {
      'category': 'Sofas & Chairs',
      'cid': 1370,
      'pid': 270
    },
    {
      'category': 'Home Improvement',
      'cid': 158,
      'pid': 11
    },
    {
      'category': 'Construction & Power Tools',
      'cid': 950,
      'pid': 158
    },
    {
      'category': 'Doors & Windows',
      'cid': 827,
      'pid': 158
    },
    {
      'category': 'Flooring',
      'cid': 832,
      'pid': 158
    },
    {
      'category': 'House Painting & Finishing',
      'cid': 1232,
      'pid': 158
    },
    {
      'category': 'Locks & Locksmiths',
      'cid': 1421,
      'pid': 158
    },
    {
      'category': 'Plumbing',
      'cid': 1153,
      'pid': 158
    },
    {
      'category': 'Roofing',
      'cid': 1175,
      'pid': 158
    },
    {
      'category': 'Home Storage & Shelving',
      'cid': 1348,
      'pid': 11
    },
    {
      'category': 'Homemaking & Interior Decor',
      'cid': 137,
      'pid': 11
    },
    {
      'category': 'Kitchen & Dining',
      'cid': 951,
      'pid': 11
    },
    {
      'category': 'Cookware & Diningware',
      'cid': 120,
      'pid': 951
    },
    {
      'category': 'Cutlery & Cutting Accessories',
      'cid': 1373,
      'pid': 120
    },
    {
      'category': 'Major Kitchen Appliances',
      'cid': 1293,
      'pid': 951
    },
    {
      'category': 'Dishwashers',
      'cid': 1600,
      'pid': 1293
    },
    {
      'category': 'Kitchen Hoods & Vents',
      'cid': 1604,
      'pid': 1293
    },
    {
      'category': 'Microwaves',
      'cid': 1601,
      'pid': 1293
    },
    {
      'category': 'Ranges, Cooktops & Ovens',
      'cid': 1602,
      'pid': 1293
    },
    {
      'category': 'Refrigerators & Freezers',
      'cid': 1603,
      'pid': 1293
    },
    {
      'category': 'Small Kitchen Appliances',
      'cid': 1292,
      'pid': 951
    },
    {
      'category': 'Blenders',
      'cid': 1605,
      'pid': 1292
    },
    {
      'category': 'Bread Makers',
      'cid': 1606,
      'pid': 1292
    },
    {
      'category': 'Coffee & Espresso Makers',
      'cid': 1607,
      'pid': 1292
    },
    {
      'category': 'Food Processors',
      'cid': 1608,
      'pid': 1292
    },
    {
      'category': 'Toasters & Toaster Ovens',
      'cid': 1609,
      'pid': 1292
    },
    {
      'category': 'Laundry',
      'cid': 1364,
      'pid': 11
    },
    {
      'category': 'Nursery & Playroom',
      'cid': 1372,
      'pid': 11
    },
    {
      'category': 'Pest Control',
      'cid': 471,
      'pid': 11
    },
    {
      'category': 'Swimming Pools & Spas',
      'cid': 952,
      'pid': 11
    },
    {
      'category': 'Yard & Patio',
      'cid': 953,
      'pid': 11
    },
    {
      'category': 'Internet & Telecom',
      'cid': 13,
      'pid': 0
    },
    {
      'category': 'Communications Equipment',
      'cid': 385,
      'pid': 13
    },
    {
      'category': 'Radio Equipment',
      'cid': 1182,
      'pid': 385
    },
    {
      'category': 'Email & Messaging',
      'cid': 394,
      'pid': 13
    },
    {
      'category': 'Spam & Email Filtering',
      'cid': 1522,
      'pid': 394
    },
    {
      'category': 'Text & Instant Messaging',
      'cid': 1379,
      'pid': 394
    },
    {
      'category': 'Voice & Video Chat',
      'cid': 386,
      'pid': 394
    },
    {
      'category': 'Mobile & Wireless',
      'cid': 382,
      'pid': 13
    },
    {
      'category': 'Mobile & Wireless Accessories',
      'cid': 1171,
      'pid': 382
    },
    {
      'category': 'Bluetooth Accessories',
      'cid': 1170,
      'pid': 1171
    },
    {
      'category': 'Mobile Apps & Add-Ons',
      'cid': 1109,
      'pid': 382
    },
    {
      'category': 'Android Apps',
      'cid': 1461,
      'pid': 1109
    },
    {
      'category': 'Ringtones & Mobile Themes',
      'cid': 532,
      'pid': 1109
    },
    {
      'category': 'iOS Apps',
      'cid': 1462,
      'pid': 1109
    },
    {
      'category': 'Mobile Phones',
      'cid': 390,
      'pid': 382
    },
    {
      'category': 'Smart Phones',
      'cid': 1071,
      'pid': 390
    },
    {
      'category': 'Search Engines',
      'cid': 485,
      'pid': 13
    },
    {
      'category': 'People Search',
      'cid': 1234,
      'pid': 485
    },
    {
      'category': 'Service Providers',
      'cid': 383,
      'pid': 13
    },
    {
      'category': 'Cable & Satellite Providers',
      'cid': 501,
      'pid': 383
    },
    {
      'category': 'ISPs',
      'cid': 104,
      'pid': 383
    },
    {
      'category': 'Internet Cafes',
      'cid': 1463,
      'pid': 383
    },
    {
      'category': 'Phone Service Providers',
      'cid': 384,
      'pid': 383
    },
    {
      'category': 'Calling Cards',
      'cid': 389,
      'pid': 384
    },
    {
      'category': 'Teleconferencing',
      'cid': 392,
      'pid': 13
    },
    {
      'category': 'Web Apps & Online Tools',
      'cid': 1142,
      'pid': 13
    },
    {
      'category': 'Web Portals',
      'cid': 301,
      'pid': 13
    },
    {
      'category': 'Web Services',
      'cid': 302,
      'pid': 13
    },
    {
      'category': 'Affiliate Programs',
      'cid': 326,
      'pid': 302
    },
    {
      'category': 'Hosted Data Storage',
      'cid': 1464,
      'pid': 302
    },
    {
      'category': 'Search Engine Optimization & Marketing',
      'cid': 84,
      'pid': 302
    },
    {
      'category': 'Web Design & Development',
      'cid': 422,
      'pid': 302
    },
    {
      'category': 'Web Hosting & Domain Registration',
      'cid': 53,
      'pid': 302
    },
    {
      'category': 'Web Stats & Analytics',
      'cid': 675,
      'pid': 302
    },
    {
      'category': 'Jobs & Education',
      'cid': 958,
      'pid': 0
    },
    {
      'category': 'Education',
      'cid': 74,
      'pid': 958
    },
    {
      'category': 'Academic Conferences & Publications',
      'cid': 1289,
      'pid': 74
    },
    {
      'category': 'Alumni & Reunions',
      'cid': 1015,
      'pid': 74
    },
    {
      'category': 'Colleges & Universities',
      'cid': 372,
      'pid': 74
    },
    {
      'category': 'Fraternities, Sororities & Student Societies',
      'cid': 1465,
      'pid': 372
    },
    {
      'category': 'Distance Learning',
      'cid': 367,
      'pid': 74
    },
    {
      'category': 'Early Childhood Education',
      'cid': 1012,
      'pid': 74
    },
    {
      'category': 'Preschool',
      'cid': 1579,
      'pid': 1012
    },
    {
      'category': 'Homeschooling',
      'cid': 791,
      'pid': 74
    },
    {
      'category': 'Primary & Secondary Schooling (K-12)',
      'cid': 371,
      'pid': 74
    },
    {
      'category': 'Special Education',
      'cid': 1118,
      'pid': 74
    },
    {
      'category': 'Standardized & Admissions Tests',
      'cid': 373,
      'pid': 74
    },
    {
      'category': 'Study Abroad',
      'cid': 1308,
      'pid': 74
    },
    {
      'category': 'Teaching & Classroom Resources',
      'cid': 700,
      'pid': 74
    },
    {
      'category': 'Lesson Plans',
      'cid': 1466,
      'pid': 700
    },
    {
      'category': 'School Supplies & Classroom Equipment',
      'cid': 1467,
      'pid': 700
    },
    {
      'category': 'Training & Certification',
      'cid': 1388,
      'pid': 74
    },
    {
      'category': 'Vocational & Continuing Education',
      'cid': 369,
      'pid': 74
    },
    {
      'category': 'Computer Education',
      'cid': 1229,
      'pid': 369
    },
    {
      'category': 'Internships',
      'cid': 1471,
      'pid': 958
    },
    {
      'category': 'Jobs',
      'cid': 60,
      'pid': 958
    },
    {
      'category': 'Career Resources & Planning',
      'cid': 959,
      'pid': 60
    },
    {
      'category': 'Job Listings',
      'cid': 960,
      'pid': 60
    },
    {
      'category': 'Accounting & Finance Jobs',
      'cid': 1472,
      'pid': 960
    },
    {
      'category': 'Clerical & Administrative Jobs',
      'cid': 1473,
      'pid': 960
    },
    {
      'category': 'Education Jobs',
      'cid': 1474,
      'pid': 960
    },
    {
      'category': 'Executive & Management Jobs',
      'cid': 1475,
      'pid': 960
    },
    {
      'category': 'Government & Public Sector Jobs',
      'cid': 1476,
      'pid': 960
    },
    {
      'category': 'Health & Medical Jobs',
      'cid': 1477,
      'pid': 960
    },
    {
      'category': 'IT & Technical Jobs',
      'cid': 802,
      'pid': 960
    },
    {
      'category': 'Legal Jobs',
      'cid': 1478,
      'pid': 960
    },
    {
      'category': 'Retail Jobs',
      'cid': 1479,
      'pid': 960
    },
    {
      'category': 'Sales & Marketing Jobs',
      'cid': 1481,
      'pid': 960
    },
    {
      'category': 'Temporary & Seasonal Jobs',
      'cid': 1480,
      'pid': 960
    },
    {
      'category': 'Resumes & Portfolios',
      'cid': 961,
      'pid': 60
    },
    {
      'category': 'Law & Government',
      'cid': 19,
      'pid': 0
    },
    {
      'category': 'Government',
      'cid': 76,
      'pid': 19
    },
    {
      'category': 'Courts & Judiciary',
      'cid': 1075,
      'pid': 76
    },
    {
      'category': 'Embassies & Consulates',
      'cid': 962,
      'pid': 76
    },
    {
      'category': 'Executive Branch',
      'cid': 963,
      'pid': 76
    },
    {
      'category': 'Government Contracting & Procurement',
      'cid': 1385,
      'pid': 76
    },
    {
      'category': 'Government Ministries',
      'cid': 1387,
      'pid': 76
    },
    {
      'category': 'Intelligence & Counterterrorism',
      'cid': 1221,
      'pid': 76
    },
    {
      'category': 'Legislative Branch',
      'cid': 964,
      'pid': 76
    },
    {
      'category': 'Lobbying',
      'cid': 1386,
      'pid': 76
    },
    {
      'category': 'Multilateral Organizations',
      'cid': 965,
      'pid': 76
    },
    {
      'category': 'Public Finance',
      'cid': 1161,
      'pid': 76
    },
    {
      'category': 'Public Policy',
      'cid': 1316,
      'pid': 76
    },
    {
      'category': 'Royalty',
      'cid': 702,
      'pid': 76
    },
    {
      'category': 'State & Local Government',
      'cid': 966,
      'pid': 76
    },
    {
      'category': 'Visa & Immigration',
      'cid': 555,
      'pid': 76
    },
    {
      'category': 'Legal',
      'cid': 75,
      'pid': 19
    },
    {
      'category': 'Accident & Personal Injury Law',
      'cid': 427,
      'pid': 75
    },
    {
      'category': 'Bankruptcy',
      'cid': 423,
      'pid': 75
    },
    {
      'category': 'Business & Corporate Law',
      'cid': 1272,
      'pid': 75
    },
    {
      'category': 'Constitutional Law & Civil Rights',
      'cid': 967,
      'pid': 75
    },
    {
      'category': 'Criminal Law',
      'cid': 424,
      'pid': 75
    },
    {
      'category': 'Family Law',
      'cid': 522,
      'pid': 75
    },
    {
      'category': 'Intellectual Property',
      'cid': 426,
      'pid': 75
    },
    {
      'category': 'Labor & Employment Law',
      'cid': 701,
      'pid': 75
    },
    {
      'category': 'Legal Education',
      'cid': 792,
      'pid': 75
    },
    {
      'category': 'Legal Services',
      'cid': 969,
      'pid': 75
    },
    {
      'category': 'Product Liability',
      'cid': 970,
      'pid': 75
    },
    {
      'category': 'Military',
      'cid': 366,
      'pid': 19
    },
    {
      'category': 'Air Force',
      'cid': 1247,
      'pid': 366
    },
    {
      'category': 'Army',
      'cid': 1248,
      'pid': 366
    },
    {
      'category': 'Marines',
      'cid': 1250,
      'pid': 366
    },
    {
      'category': 'Navy',
      'cid': 1249,
      'pid': 366
    },
    {
      'category': 'Veterans',
      'cid': 793,
      'pid': 366
    },
    {
      'category': 'Public Safety',
      'cid': 166,
      'pid': 19
    },
    {
      'category': 'Crime & Justice',
      'cid': 704,
      'pid': 166
    },
    {
      'category': 'Corporate & Financial Crime',
      'cid': 1181,
      'pid': 704
    },
    {
      'category': 'Gangs & Organized Crime',
      'cid': 1312,
      'pid': 704
    },
    {
      'category': 'Prisons & Corrections',
      'cid': 1284,
      'pid': 704
    },
    {
      'category': 'Emergency Services',
      'cid': 168,
      'pid': 166
    },
    {
      'category': 'Law Enforcement',
      'cid': 535,
      'pid': 166
    },
    {
      'category': 'Security Products & Services',
      'cid': 705,
      'pid': 166
    },
    {
      'category': 'Social Services',
      'cid': 508,
      'pid': 19
    },
    {
      'category': 'Counseling Services',
      'cid': 511,
      'pid': 508
    },
    {
      'category': 'Welfare & Unemployment',
      'cid': 706,
      'pid': 508
    },
    {
      'category': 'News',
      'cid': 16,
      'pid': 0
    },
    {
      'category': 'Broadcast & Network News',
      'cid': 112,
      'pid': 16
    },
    {
      'category': 'Business News',
      'cid': 784,
      'pid': 16
    },
    {
      'category': 'Company News',
      'cid': 1179,
      'pid': 784
    },
    {
      'category': 'Company Earnings',
      'cid': 1240,
      'pid': 1179
    },
    {
      'category': 'Mergers & Acquisitions',
      'cid': 1241,
      'pid': 1179
    },
    {
      'category': 'Economy News',
      'cid': 1164,
      'pid': 784
    },
    {
      'category': 'Financial Markets',
      'cid': 1163,
      'pid': 784
    },
    {
      'category': 'Fiscal Policy News',
      'cid': 1165,
      'pid': 784
    },
    {
      'category': 'Gossip & Tabloid News',
      'cid': 507,
      'pid': 16
    },
    {
      'category': 'Scandals & Investigations',
      'cid': 1259,
      'pid': 507
    },
    {
      'category': 'Health News',
      'cid': 1253,
      'pid': 16
    },
    {
      'category': 'Journalism & News Industry',
      'cid': 1204,
      'pid': 16
    },
    {
      'category': 'Local News',
      'cid': 572,
      'pid': 16
    },
    {
      'category': 'Newspapers',
      'cid': 408,
      'pid': 16
    },
    {
      'category': 'Politics',
      'cid': 396,
      'pid': 16
    },
    {
      'category': 'Campaigns & Elections',
      'cid': 398,
      'pid': 396
    },
    {
      'category': 'Left-Wing Politics',
      'cid': 410,
      'pid': 396
    },
    {
      'category': 'Media Critics & Watchdogs',
      'cid': 1203,
      'pid': 396
    },
    {
      'category': 'Opinion & Commentary',
      'cid': 1201,
      'pid': 396
    },
    {
      'category': 'Political Polls & Surveys',
      'cid': 1202,
      'pid': 396
    },
    {
      'category': 'Right-Wing Politics',
      'cid': 409,
      'pid': 396
    },
    {
      'category': 'Sports News',
      'cid': 1077,
      'pid': 16
    },
    {
      'category': 'Technology News',
      'cid': 785,
      'pid': 16
    },
    {
      'category': 'Weather',
      'cid': 63,
      'pid': 16
    },
    {
      'category': 'World News',
      'cid': 1209,
      'pid': 16
    },
    {
      'category': 'Online Communities',
      'cid': 299,
      'pid': 0
    },
    {
      'category': 'Blogging Resources & Services',
      'cid': 504,
      'pid': 299
    },
    {
      'category': 'Microblogging',
      'cid': 1381,
      'pid': 504
    },
    {
      'category': 'Dating & Personals',
      'cid': 55,
      'pid': 299
    },
    {
      'category': 'Matrimonial Services',
      'cid': 546,
      'pid': 55
    },
    {
      'category': 'Personals',
      'cid': 102,
      'pid': 55
    },
    {
      'category': 'Photo Rating Sites',
      'cid': 320,
      'pid': 55
    },
    {
      'category': 'Feed Aggregation & Social Bookmarking',
      'cid': 1482,
      'pid': 299
    },
    {
      'category': 'File Sharing & Hosting',
      'cid': 321,
      'pid': 299
    },
    {
      'category': 'Forum & Chat Providers',
      'cid': 191,
      'pid': 299
    },
    {
      'category': 'Online Goodies',
      'cid': 43,
      'pid': 299
    },
    {
      'category': 'Clip Art & Animated GIFs',
      'cid': 1223,
      'pid': 43
    },
    {
      'category': 'Skins Themes & Wallpapers',
      'cid': 578,
      'pid': 43
    },
    {
      'category': 'Social Network Apps & Add-Ons',
      'cid': 847,
      'pid': 43
    },
    {
      'category': 'Online Journals & Personal Sites',
      'cid': 582,
      'pid': 299
    },
    {
      'category': 'Photo & Video Sharing',
      'cid': 275,
      'pid': 299
    },
    {
      'category': 'Photo & Image Sharing',
      'cid': 978,
      'pid': 275
    },
    {
      'category': 'Video Sharing',
      'cid': 979,
      'pid': 275
    },
    {
      'category': 'Social Networks',
      'cid': 529,
      'pid': 299
    },
    {
      'category': 'Virtual Worlds',
      'cid': 972,
      'pid': 299
    },
    {
      'category': 'People & Society',
      'cid': 14,
      'pid': 0
    },
    {
      'category': 'Disabled & Special Needs',
      'cid': 677,
      'pid': 14
    },
    {
      'category': 'Ethnic & Identity Groups',
      'cid': 56,
      'pid': 14
    },
    {
      'category': 'Africans & Diaspora',
      'cid': 579,
      'pid': 56
    },
    {
      'category': 'African-Americans',
      'cid': 547,
      'pid': 579
    },
    {
      'category': 'Arabs & Middle Easterners',
      'cid': 556,
      'pid': 56
    },
    {
      'category': 'Asians & Diaspora',
      'cid': 1257,
      'pid': 56
    },
    {
      'category': 'East Asians & Diaspora',
      'cid': 549,
      'pid': 1257
    },
    {
      'category': 'South Asians & Diaspora',
      'cid': 528,
      'pid': 1257
    },
    {
      'category': 'Southeast Asians & Pacific Islanders',
      'cid': 580,
      'pid': 1257
    },
    {
      'category': 'Eastern Europeans',
      'cid': 682,
      'pid': 56
    },
    {
      'category': 'Expatriate Communities',
      'cid': 973,
      'pid': 56
    },
    {
      'category': 'Gay-Lesbian-Bisexual-Transgender',
      'cid': 113,
      'pid': 56
    },
    {
      'category': 'Indigenous Peoples',
      'cid': 681,
      'pid': 56
    },
    {
      'category': 'Native Americans',
      'cid': 171,
      'pid': 681
    },
    {
      'category': 'Jewish Culture',
      'cid': 550,
      'pid': 56
    },
    {
      'category': 'Latinos & Latin-Americans',
      'cid': 548,
      'pid': 56
    },
    {
      'category': 'Western Europeans',
      'cid': 683,
      'pid': 56
    },
    {
      'category': 'Family & Relationships',
      'cid': 1131,
      'pid': 14
    },
    {
      'category': 'Etiquette',
      'cid': 1304,
      'pid': 1131
    },
    {
      'category': 'Family',
      'cid': 1132,
      'pid': 1131
    },
    {
      'category': 'Ancestry & Genealogy',
      'cid': 400,
      'pid': 1132
    },
    {
      'category': 'Baby & Pet Names',
      'cid': 1231,
      'pid': 1132
    },
    {
      'category': 'Parenting',
      'cid': 58,
      'pid': 1132
    },
    {
      'category': 'Adoption',
      'cid': 974,
      'pid': 58
    },
    {
      'category': 'Babies & Toddlers',
      'cid': 1374,
      'pid': 58
    },
    {
      'category': 'Baby & Toddler Toys',
      'cid': 1585,
      'pid': 1374
    },
    {
      'category': 'Baby Care & Hygiene',
      'cid': 115,
      'pid': 1374
    },
    {
      'category': 'Baby Food & Formula',
      'cid': 1584,
      'pid': 1374
    },
    {
      'category': 'Child Care',
      'cid': 403,
      'pid': 58
    },
    {
      'category': 'Pregnancy & Maternity',
      'cid': 401,
      'pid': 58
    },
    {
      'category': 'Youth Camps',
      'cid': 402,
      'pid': 58
    },
    {
      'category': 'Friendship',
      'cid': 1134,
      'pid': 1131
    },
    {
      'category': 'Marriage',
      'cid': 1133,
      'pid': 1131
    },
    {
      'category': 'Romance',
      'cid': 1135,
      'pid': 1131
    },
    {
      'category': 'Troubled Relationships',
      'cid': 1260,
      'pid': 1131
    },
    {
      'category': 'Divorce & Separation',
      'cid': 1261,
      'pid': 1260
    },
    {
      'category': 'Men\'s Interests',
      'cid': 594,
      'pid': 14
    },
    {
      'category': 'Men\'s Interests (Mature)',
      'cid': 525,
      'pid': 594
    },
    {
      'category': 'Religion & Belief',
      'cid': 59,
      'pid': 14
    },
    {
      'category': 'Astrology & Divination',
      'cid': 448,
      'pid': 59
    },
    {
      'category': 'Buddhism',
      'cid': 862,
      'pid': 59
    },
    {
      'category': 'Christianity',
      'cid': 864,
      'pid': 59
    },
    {
      'category': 'Hinduism',
      'cid': 866,
      'pid': 59
    },
    {
      'category': 'Islam',
      'cid': 868,
      'pid': 59
    },
    {
      'category': 'Judaism',
      'cid': 869,
      'pid': 59
    },
    {
      'category': 'Pagan & Esoteric Traditions',
      'cid': 1258,
      'pid': 59
    },
    {
      'category': 'Places of Worship',
      'cid': 1296,
      'pid': 59
    },
    {
      'category': 'Scientology',
      'cid': 1251,
      'pid': 59
    },
    {
      'category': 'Self-Help & Motivational',
      'cid': 870,
      'pid': 59
    },
    {
      'category': 'Skeptics & Non-Believers',
      'cid': 975,
      'pid': 59
    },
    {
      'category': 'Spirituality',
      'cid': 101,
      'pid': 59
    },
    {
      'category': 'Theology & Religious Study',
      'cid': 1340,
      'pid': 59
    },
    {
      'category': 'Seniors & Retirement',
      'cid': 298,
      'pid': 14
    },
    {
      'category': 'Social Issues & Advocacy',
      'cid': 54,
      'pid': 14
    },
    {
      'category': 'Charity & Philanthropy',
      'cid': 57,
      'pid': 54
    },
    {
      'category': 'Discrimination & Identity Relations',
      'cid': 1205,
      'pid': 54
    },
    {
      'category': 'Drug Laws & Policy',
      'cid': 1314,
      'pid': 54
    },
    {
      'category': 'Ethics',
      'cid': 1483,
      'pid': 54
    },
    {
      'category': 'Green Living & Environmental Issues',
      'cid': 82,
      'pid': 54
    },
    {
      'category': 'Housing & Development',
      'cid': 1166,
      'pid': 54
    },
    {
      'category': 'Human Rights & Liberties',
      'cid': 1280,
      'pid': 54
    },
    {
      'category': 'Immigration Policy & Border Issues',
      'cid': 1313,
      'pid': 54
    },
    {
      'category': 'Poverty & Hunger',
      'cid': 1127,
      'pid': 54
    },
    {
      'category': 'Privacy Issues',
      'cid': 1281,
      'pid': 54
    },
    {
      'category': 'Reproductive Rights',
      'cid': 976,
      'pid': 54
    },
    {
      'category': 'Same-Sex Marriage',
      'cid': 1301,
      'pid': 54
    },
    {
      'category': 'Work & Labor Issues',
      'cid': 703,
      'pid': 54
    },
    {
      'category': 'Unions & Labor Movement',
      'cid': 1121,
      'pid': 703
    },
    {
      'category': 'Social Sciences',
      'cid': 509,
      'pid': 14
    },
    {
      'category': 'Anthropology',
      'cid': 1484,
      'pid': 509
    },
    {
      'category': 'Archaeology',
      'cid': 1485,
      'pid': 509
    },
    {
      'category': 'Communications & Media Studies',
      'cid': 1302,
      'pid': 509
    },
    {
      'category': 'Public Speaking',
      'cid': 1303,
      'pid': 1302
    },
    {
      'category': 'Demographics',
      'cid': 510,
      'pid': 509
    },
    {
      'category': 'Economics',
      'cid': 520,
      'pid': 509
    },
    {
      'category': 'International Relations',
      'cid': 521,
      'pid': 509
    },
    {
      'category': 'Psychology',
      'cid': 543,
      'pid': 509
    },
    {
      'category': 'Subcultures & Niche Interests',
      'cid': 502,
      'pid': 14
    },
    {
      'category': 'Goth Subculture',
      'cid': 503,
      'pid': 502
    },
    {
      'category': 'Science Fiction & Fantasy',
      'cid': 676,
      'pid': 502
    },
    {
      'category': 'Women\'s Interests',
      'cid': 325,
      'pid': 14
    },
    {
      'category': 'Pets & Animals',
      'cid': 66,
      'pid': 0
    },
    {
      'category': 'Animal Products & Services',
      'cid': 882,
      'pid': 66
    },
    {
      'category': 'Animal Welfare',
      'cid': 883,
      'pid': 882
    },
    {
      'category': 'Pet Food & Supplies',
      'cid': 379,
      'pid': 882
    },
    {
      'category': 'Veterinarians',
      'cid': 380,
      'pid': 882
    },
    {
      'category': 'Pets',
      'cid': 563,
      'pid': 66
    },
    {
      'category': 'Birds',
      'cid': 884,
      'pid': 563
    },
    {
      'category': 'Cats',
      'cid': 885,
      'pid': 563
    },
    {
      'category': 'Dogs',
      'cid': 886,
      'pid': 563
    },
    {
      'category': 'Exotic Pets',
      'cid': 607,
      'pid': 563
    },
    {
      'category': 'Fish & Aquaria',
      'cid': 887,
      'pid': 563
    },
    {
      'category': 'Horses',
      'cid': 888,
      'pid': 563
    },
    {
      'category': 'Rabbits & Rodents',
      'cid': 889,
      'pid': 563
    },
    {
      'category': 'Reptiles & Amphibians',
      'cid': 890,
      'pid': 563
    },
    {
      'category': 'Wildlife',
      'cid': 119,
      'pid': 66
    },
    {
      'category': 'Real Estate',
      'cid': 29,
      'pid': 0
    },
    {
      'category': 'Apartments & Residential Rentals',
      'cid': 378,
      'pid': 29
    },
    {
      'category': 'Bank-Owned & Foreclosed Properties',
      'cid': 1460,
      'pid': 29
    },
    {
      'category': 'Commercial & Investment Real Estate',
      'cid': 1178,
      'pid': 29
    },
    {
      'category': 'Property Development',
      'cid': 687,
      'pid': 29
    },
    {
      'category': 'Property Inspections & Appraisals',
      'cid': 463,
      'pid': 29
    },
    {
      'category': 'Property Management',
      'cid': 425,
      'pid': 29
    },
    {
      'category': 'Real Estate Agencies',
      'cid': 96,
      'pid': 29
    },
    {
      'category': 'Real Estate Listings',
      'cid': 1080,
      'pid': 29
    },
    {
      'category': 'Timeshares & Vacation Properties',
      'cid': 1081,
      'pid': 29
    },
    {
      'category': 'Reference',
      'cid': 533,
      'pid': 0
    },
    {
      'category': 'Directories & Listings',
      'cid': 527,
      'pid': 533
    },
    {
      'category': 'Business & Personal Listings',
      'cid': 377,
      'pid': 527
    },
    {
      'category': 'General Reference',
      'cid': 980,
      'pid': 533
    },
    {
      'category': 'Biographies & Quotations',
      'cid': 690,
      'pid': 980
    },
    {
      'category': 'Calculators & Reference Tools',
      'cid': 691,
      'pid': 980
    },
    {
      'category': 'Dictionaries & Encyclopedias',
      'cid': 692,
      'pid': 980
    },
    {
      'category': 'Educational Resources',
      'cid': 374,
      'pid': 980
    },
    {
      'category': 'Forms Guides & Templates',
      'cid': 693,
      'pid': 980
    },
    {
      'category': 'Legal Forms',
      'cid': 1137,
      'pid': 693
    },
    {
      'category': 'How-To, DIY & Expert Content',
      'cid': 694,
      'pid': 980
    },
    {
      'category': 'Public Records',
      'cid': 1136,
      'pid': 980
    },
    {
      'category': 'Time & Calendars',
      'cid': 695,
      'pid': 980
    },
    {
      'category': 'Geographic Reference',
      'cid': 1084,
      'pid': 533
    },
    {
      'category': 'City & Local Guides',
      'cid': 1014,
      'pid': 1084
    },
    {
      'category': 'Maps',
      'cid': 268,
      'pid': 1084
    },
    {
      'category': 'Traffic & Public Transit',
      'cid': 685,
      'pid': 268
    },
    {
      'category': 'Humanities',
      'cid': 474,
      'pid': 533
    },
    {
      'category': 'History',
      'cid': 433,
      'pid': 474
    },
    {
      'category': 'Military History',
      'cid': 1288,
      'pid': 433
    },
    {
      'category': 'Myth & Folklore',
      'cid': 609,
      'pid': 474
    },
    {
      'category': 'Philosophy',
      'cid': 1093,
      'pid': 474
    },
    {
      'category': 'Language Resources',
      'cid': 108,
      'pid': 533
    },
    {
      'category': 'Foreign Language Resources',
      'cid': 1264,
      'pid': 108
    },
    {
      'category': 'Foreign Language Study',
      'cid': 1266,
      'pid': 1264
    },
    {
      'category': 'Translation Tools & Resources',
      'cid': 1265,
      'pid': 1264
    },
    {
      'category': 'Libraries & Museums',
      'cid': 375,
      'pid': 533
    },
    {
      'category': 'Libraries',
      'cid': 1520,
      'pid': 375
    },
    {
      'category': 'Museums',
      'cid': 1519,
      'pid': 375
    },
    {
      'category': 'Technical Reference',
      'cid': 1233,
      'pid': 533
    },
    {
      'category': 'Science',
      'cid': 174,
      'pid': 0
    },
    {
      'category': 'Astronomy',
      'cid': 435,
      'pid': 174
    },
    {
      'category': 'Biological Sciences',
      'cid': 440,
      'pid': 174
    },
    {
      'category': 'Anatomy',
      'cid': 788,
      'pid': 440
    },
    {
      'category': 'Flora & Fauna',
      'cid': 981,
      'pid': 440
    },
    {
      'category': 'Insects & Entomology',
      'cid': 1278,
      'pid': 981
    },
    {
      'category': 'Genetics',
      'cid': 982,
      'pid': 440
    },
    {
      'category': 'Neuroscience',
      'cid': 1226,
      'pid': 440
    },
    {
      'category': 'Chemistry',
      'cid': 505,
      'pid': 174
    },
    {
      'category': 'Computer Science',
      'cid': 1227,
      'pid': 174
    },
    {
      'category': 'Distributed & Parallel Computing',
      'cid': 1298,
      'pid': 1227
    },
    {
      'category': 'Machine Learning & Artificial Intelligence',
      'cid': 1299,
      'pid': 1227
    },
    {
      'category': 'Earth Sciences',
      'cid': 1168,
      'pid': 174
    },
    {
      'category': 'Atmospheric Science',
      'cid': 1254,
      'pid': 1168
    },
    {
      'category': 'Geology',
      'cid': 443,
      'pid': 1168
    },
    {
      'category': 'Paleontology',
      'cid': 1169,
      'pid': 1168
    },
    {
      'category': 'Water & Marine Sciences',
      'cid': 441,
      'pid': 1168
    },
    {
      'category': 'Ecology & Environment',
      'cid': 442,
      'pid': 174
    },
    {
      'category': 'Climate Change & Global Warming',
      'cid': 1255,
      'pid': 442
    },
    {
      'category': 'Engineering & Technology',
      'cid': 231,
      'pid': 174
    },
    {
      'category': 'Robotics',
      'cid': 1141,
      'pid': 231
    },
    {
      'category': 'Mathematics',
      'cid': 436,
      'pid': 174
    },
    {
      'category': 'Statistics',
      'cid': 1252,
      'pid': 436
    },
    {
      'category': 'Physics',
      'cid': 444,
      'pid': 174
    },
    {
      'category': 'Scientific Equipment',
      'cid': 445,
      'pid': 174
    },
    {
      'category': 'Scientific Institutions',
      'cid': 446,
      'pid': 174
    },
    {
      'category': 'Shopping',
      'cid': 18,
      'pid': 0
    },
    {
      'category': 'Antiques & Collectibles',
      'cid': 64,
      'pid': 18
    },
    {
      'category': 'Apparel',
      'cid': 68,
      'pid': 18
    },
    {
      'category': 'Apparel Services',
      'cid': 1228,
      'pid': 68
    },
    {
      'category': 'Athletic Apparel',
      'cid': 983,
      'pid': 68
    },
    {
      'category': 'Casual Apparel',
      'cid': 984,
      'pid': 68
    },
    {
      'category': 'T-Shirts',
      'cid': 428,
      'pid': 984
    },
    {
      'category': 'Children\'s Clothing',
      'cid': 985,
      'pid': 68
    },
    {
      'category': 'Clothing Accessories',
      'cid': 124,
      'pid': 68
    },
    {
      'category': 'Gems & Jewelry',
      'cid': 350,
      'pid': 124
    },
    {
      'category': 'Rings',
      'cid': 1486,
      'pid': 350
    },
    {
      'category': 'Handbags & Purses',
      'cid': 986,
      'pid': 124
    },
    {
      'category': 'Watches',
      'cid': 987,
      'pid': 124
    },
    {
      'category': 'Costumes',
      'cid': 988,
      'pid': 68
    },
    {
      'category': 'Eyewear',
      'cid': 989,
      'pid': 68
    },
    {
      'category': 'Sunglasses',
      'cid': 1487,
      'pid': 989
    },
    {
      'category': 'Footwear',
      'cid': 697,
      'pid': 68
    },
    {
      'category': 'Athletic Shoes',
      'cid': 1488,
      'pid': 697
    },
    {
      'category': 'Boots',
      'cid': 1489,
      'pid': 697
    },
    {
      'category': 'Casual Shoes',
      'cid': 1490,
      'pid': 697
    },
    {
      'category': 'Formal Wear',
      'cid': 990,
      'pid': 68
    },
    {
      'category': 'Bridal Wear',
      'cid': 1597,
      'pid': 990
    },
    {
      'category': 'Headwear',
      'cid': 991,
      'pid': 68
    },
    {
      'category': 'Men\'s Clothing',
      'cid': 992,
      'pid': 68
    },
    {
      'category': 'Outerwear',
      'cid': 993,
      'pid': 68
    },
    {
      'category': 'Sleepwear',
      'cid': 994,
      'pid': 68
    },
    {
      'category': 'Suits & Business Attire',
      'cid': 1598,
      'pid': 68
    },
    {
      'category': 'Swimwear',
      'cid': 995,
      'pid': 68
    },
    {
      'category': 'Undergarments',
      'cid': 530,
      'pid': 68
    },
    {
      'category': 'Uniforms & Workwear',
      'cid': 996,
      'pid': 68
    },
    {
      'category': 'Women\'s Clothing',
      'cid': 997,
      'pid': 68
    },
    {
      'category': 'Dresses',
      'cid': 1632,
      'pid': 997
    },
    {
      'category': 'Skirts',
      'cid': 1631,
      'pid': 997
    },
    {
      'category': 'Auctions',
      'cid': 292,
      'pid': 18
    },
    {
      'category': 'Classifieds',
      'cid': 61,
      'pid': 18
    },
    {
      'category': 'Consumer Resources',
      'cid': 69,
      'pid': 18
    },
    {
      'category': 'Consumer Advocacy & Protection',
      'cid': 97,
      'pid': 69
    },
    {
      'category': 'Coupons & Discount Offers',
      'cid': 365,
      'pid': 69
    },
    {
      'category': 'Customer Services',
      'cid': 450,
      'pid': 69
    },
    {
      'category': 'Loyalty Cards & Programs',
      'cid': 1309,
      'pid': 450
    },
    {
      'category': 'Technical Support',
      'cid': 567,
      'pid': 450
    },
    {
      'category': 'Warranties & Service Contracts',
      'cid': 451,
      'pid': 450
    },
    {
      'category': 'Identity Theft & Protection',
      'cid': 1504,
      'pid': 69
    },
    {
      'category': 'Product Reviews & Price Comparisons',
      'cid': 353,
      'pid': 69
    },
    {
      'category': 'Price Comparisons',
      'cid': 352,
      'pid': 353
    },
    {
      'category': 'Discount & Outlet Stores',
      'cid': 1505,
      'pid': 18
    },
    {
      'category': 'Entertainment Media',
      'cid': 1143,
      'pid': 18
    },
    {
      'category': 'Entertainment Media Rentals',
      'cid': 1144,
      'pid': 1143
    },
    {
      'category': 'Gifts & Special Event Items',
      'cid': 70,
      'pid': 18
    },
    {
      'category': 'Cards & Greetings',
      'cid': 100,
      'pid': 70
    },
    {
      'category': 'Custom & Personalized Items',
      'cid': 1506,
      'pid': 70
    },
    {
      'category': 'Flowers',
      'cid': 323,
      'pid': 70
    },
    {
      'category': 'Gifts',
      'cid': 99,
      'pid': 70
    },
    {
      'category': 'Party & Holiday Supplies',
      'cid': 324,
      'pid': 70
    },
    {
      'category': 'Green & Eco-Friendly Shopping',
      'cid': 1507,
      'pid': 18
    },
    {
      'category': 'Luxury Goods',
      'cid': 696,
      'pid': 18
    },
    {
      'category': 'Mass Merchants & Department Stores',
      'cid': 73,
      'pid': 18
    },
    {
      'category': 'Photo & Video Services',
      'cid': 576,
      'pid': 18
    },
    {
      'category': 'Stock Photography',
      'cid': 574,
      'pid': 576
    },
    {
      'category': 'Shopping Portals & Search Engines',
      'cid': 531,
      'pid': 18
    },
    {
      'category': 'Swap Meets & Outdoor Markets',
      'cid': 1210,
      'pid': 18
    },
    {
      'category': 'Tobacco Products',
      'cid': 123,
      'pid': 18
    },
    {
      'category': 'Toys',
      'cid': 432,
      'pid': 18
    },
    {
      'category': 'Action Figures',
      'cid': 1580,
      'pid': 432
    },
    {
      'category': 'Building Toys',
      'cid': 1581,
      'pid': 432
    },
    {
      'category': 'Die-cast & Toy Vehicles',
      'cid': 1587,
      'pid': 432
    },
    {
      'category': 'Dolls & Accessories',
      'cid': 1582,
      'pid': 432
    },
    {
      'category': 'Outdoor Toys & Play Equipment',
      'cid': 1588,
      'pid': 432
    },
    {
      'category': 'Puppets',
      'cid': 1589,
      'pid': 432
    },
    {
      'category': 'Ride-On Toys & Wagons',
      'cid': 1586,
      'pid': 432
    },
    {
      'category': 'Stuffed Toys',
      'cid': 1583,
      'pid': 432
    },
    {
      'category': 'Wholesalers & Liquidators',
      'cid': 1225,
      'pid': 18
    },
    {
      'category': 'Sports',
      'cid': 20,
      'pid': 0
    },
    {
      'category': 'College Sports',
      'cid': 1073,
      'pid': 20
    },
    {
      'category': 'Combat Sports',
      'cid': 514,
      'pid': 20
    },
    {
      'category': 'Boxing',
      'cid': 515,
      'pid': 514
    },
    {
      'category': 'Martial Arts',
      'cid': 516,
      'pid': 514
    },
    {
      'category': 'Wrestling',
      'cid': 512,
      'pid': 514
    },
    {
      'category': 'Extreme Sports',
      'cid': 554,
      'pid': 20
    },
    {
      'category': 'Drag & Street Racing',
      'cid': 1206,
      'pid': 554
    },
    {
      'category': 'Stunts & Dangerous Feats',
      'cid': 1207,
      'pid': 554
    },
    {
      'category': 'Fantasy Sports',
      'cid': 998,
      'pid': 20
    },
    {
      'category': 'Individual Sports',
      'cid': 1000,
      'pid': 20
    },
    {
      'category': 'Bowling',
      'cid': 1016,
      'pid': 1000
    },
    {
      'category': 'Cycling',
      'cid': 458,
      'pid': 1000
    },
    {
      'category': 'Golf',
      'cid': 261,
      'pid': 1000
    },
    {
      'category': 'Gymnastics',
      'cid': 519,
      'pid': 1000
    },
    {
      'category': 'Racquet Sports',
      'cid': 262,
      'pid': 1000
    },
    {
      'category': 'Tennis',
      'cid': 1376,
      'pid': 262
    },
    {
      'category': 'Running & Walking',
      'cid': 541,
      'pid': 1000
    },
    {
      'category': 'Skate Sports',
      'cid': 1126,
      'pid': 1000
    },
    {
      'category': 'Track & Field',
      'cid': 518,
      'pid': 1000
    },
    {
      'category': 'Motor Sports',
      'cid': 180,
      'pid': 20
    },
    {
      'category': 'Auto Racing',
      'cid': 1595,
      'pid': 180
    },
    {
      'category': 'Motorcycle Racing',
      'cid': 1596,
      'pid': 180
    },
    {
      'category': 'Sport Scores & Statistics',
      'cid': 1599,
      'pid': 20
    },
    {
      'category': 'Sporting Goods',
      'cid': 263,
      'pid': 20
    },
    {
      'category': 'American Football Equipment',
      'cid': 1611,
      'pid': 263
    },
    {
      'category': 'Baseball Equipment',
      'cid': 1612,
      'pid': 263
    },
    {
      'category': 'Basketball Equipment',
      'cid': 1613,
      'pid': 263
    },
    {
      'category': 'Bowling Equipment',
      'cid': 1615,
      'pid': 263
    },
    {
      'category': 'Combat Sports Equipment',
      'cid': 1616,
      'pid': 263
    },
    {
      'category': 'Boxing Gloves & Gear',
      'cid': 1633,
      'pid': 1616
    },
    {
      'category': 'Martial Arts Equipment',
      'cid': 1634,
      'pid': 1616
    },
    {
      'category': 'Cricket Equipment',
      'cid': 1617,
      'pid': 263
    },
    {
      'category': 'Equestrian Equipment & Tack',
      'cid': 1618,
      'pid': 263
    },
    {
      'category': 'Golf Equipment',
      'cid': 1619,
      'pid': 263
    },
    {
      'category': 'Gymnastics Equipment',
      'cid': 1620,
      'pid': 263
    },
    {
      'category': 'Hockey Equipment',
      'cid': 1621,
      'pid': 263
    },
    {
      'category': 'Ice Skating Equipment',
      'cid': 1622,
      'pid': 263
    },
    {
      'category': 'Roller Skating & Rollerblading Equipment',
      'cid': 1623,
      'pid': 263
    },
    {
      'category': 'Skateboarding Equipment',
      'cid': 1624,
      'pid': 263
    },
    {
      'category': 'Soccer Equipment',
      'cid': 1614,
      'pid': 263
    },
    {
      'category': 'Sports Memorabilia',
      'cid': 1083,
      'pid': 263
    },
    {
      'category': 'Squash & Racquetball Equipment',
      'cid': 1625,
      'pid': 263
    },
    {
      'category': 'Table Tennis Equipment',
      'cid': 1627,
      'pid': 263
    },
    {
      'category': 'Tennis Equipment',
      'cid': 1628,
      'pid': 263
    },
    {
      'category': 'Volleyball Equipment',
      'cid': 1629,
      'pid': 263
    },
    {
      'category': 'Water Sports Equipment',
      'cid': 1626,
      'pid': 263
    },
    {
      'category': 'Winter Sports Equipment',
      'cid': 1630,
      'pid': 263
    },
    {
      'category': 'Skiing Equipment',
      'cid': 1635,
      'pid': 1630
    },
    {
      'category': 'Snowboarding Gear',
      'cid': 1636,
      'pid': 1630
    },
    {
      'category': 'Sports Coaching & Training',
      'cid': 1082,
      'pid': 20
    },
    {
      'category': 'Team Sports',
      'cid': 1001,
      'pid': 20
    },
    {
      'category': 'American Football',
      'cid': 258,
      'pid': 1001
    },
    {
      'category': 'Australian Football',
      'cid': 1508,
      'pid': 1001
    },
    {
      'category': 'Baseball',
      'cid': 259,
      'pid': 1001
    },
    {
      'category': 'Basketball',
      'cid': 264,
      'pid': 1001
    },
    {
      'category': 'Cheerleading',
      'cid': 534,
      'pid': 1001
    },
    {
      'category': 'Cricket',
      'cid': 296,
      'pid': 1001
    },
    {
      'category': 'Handball',
      'cid': 1017,
      'pid': 1001
    },
    {
      'category': 'Hockey',
      'cid': 260,
      'pid': 1001
    },
    {
      'category': 'Rugby',
      'cid': 517,
      'pid': 1001
    },
    {
      'category': 'Soccer',
      'cid': 294,
      'pid': 1001
    },
    {
      'category': 'Volleyball',
      'cid': 699,
      'pid': 1001
    },
    {
      'category': 'Water Sports',
      'cid': 118,
      'pid': 20
    },
    {
      'category': 'Surfing',
      'cid': 1593,
      'pid': 118
    },
    {
      'category': 'Swimming',
      'cid': 1594,
      'pid': 118
    },
    {
      'category': 'Winter Sports',
      'cid': 265,
      'pid': 20
    },
    {
      'category': 'Ice Skating',
      'cid': 1149,
      'pid': 265
    },
    {
      'category': 'Skiing & Snowboarding',
      'cid': 1148,
      'pid': 265
    },
    {
      'category': 'World Sports Competitions',
      'cid': 1198,
      'pid': 20
    },
    {
      'category': 'Olympics',
      'cid': 513,
      'pid': 1198
    },
    {
      'category': 'Travel',
      'cid': 67,
      'pid': 0
    },
    {
      'category': 'Air Travel',
      'cid': 203,
      'pid': 67
    },
    {
      'category': 'Airport Parking & Transportation',
      'cid': 1245,
      'pid': 203
    },
    {
      'category': 'Bus & Rail',
      'cid': 708,
      'pid': 67
    },
    {
      'category': 'Car Rental & Taxi Services',
      'cid': 205,
      'pid': 67
    },
    {
      'category': 'Carpooling & Vehicle Sharing',
      'cid': 1339,
      'pid': 67
    },
    {
      'category': 'Cruises & Charters',
      'cid': 206,
      'pid': 67
    },
    {
      'category': 'Hotels & Accommodations',
      'cid': 179,
      'pid': 67
    },
    {
      'category': 'Luggage & Travel Accessories',
      'cid': 1003,
      'pid': 67
    },
    {
      'category': 'Specialty Travel',
      'cid': 1004,
      'pid': 67
    },
    {
      'category': 'Adventure Travel',
      'cid': 707,
      'pid': 1004
    },
    {
      'category': 'Agritourism',
      'cid': 1389,
      'pid': 1004
    },
    {
      'category': 'Ecotourism',
      'cid': 1005,
      'pid': 1004
    },
    {
      'category': 'Sightseeing Tours',
      'cid': 1390,
      'pid': 1004
    },
    {
      'category': 'Vineyards & Wine Tourism',
      'cid': 1391,
      'pid': 1004
    },
    {
      'category': 'Tourist Destinations',
      'cid': 208,
      'pid': 67
    },
    {
      'category': 'Beaches & Islands',
      'cid': 1074,
      'pid': 208
    },
    {
      'category': 'Historical Sites & Buildings',
      'cid': 1006,
      'pid': 208
    },
    {
      'category': 'Lakes & Rivers',
      'cid': 1120,
      'pid': 208
    },
    {
      'category': 'Mountain & Ski Resorts',
      'cid': 1119,
      'pid': 208
    },
    {
      'category': 'Regional Parks & Gardens',
      'cid': 1007,
      'pid': 208
    },
    {
      'category': 'Theme Parks',
      'cid': 1008,
      'pid': 208
    },
    {
      'category': 'Zoos-Aquariums-Preserves',
      'cid': 1009,
      'pid': 208
    },
    {
      'category': 'Travel Agencies & Services',
      'cid': 1010,
      'pid': 67
    },
    {
      'category': 'Tourist Boards & Visitor Centers',
      'cid': 1392,
      'pid': 1010
    },
    {
      'category': 'Vacation Offers',
      'cid': 1019,
      'pid': 1010
    },
    {
      'category': 'Travel Guides & Travelogues',
      'cid': 1011,
      'pid': 67
    },
    {
      'category': 'World Localities',
      'cid': 5000,
      'pid': 0
    },
    {
      'category': 'Africa',
      'cid': 5001,
      'pid': 5000
    },
    {
      'category': 'Eastern Africa',
      'cid': 5002,
      'pid': 5001
    },
    {
      'category': 'Ethiopia',
      'cid': 5003,
      'pid': 5002
    },
    {
      'category': 'Kenya',
      'cid': 5004,
      'pid': 5002
    },
    {
      'category': 'Nairobi',
      'cid': 5005,
      'pid': 5004
    },
    {
      'category': 'Madagascar',
      'cid': 5621,
      'pid': 5002
    },
    {
      'category': 'Malawi',
      'cid': 5714,
      'pid': 5002
    },
    {
      'category': 'Mozambique',
      'cid': 5634,
      'pid': 5002
    },
    {
      'category': 'Rwanda',
      'cid': 5473,
      'pid': 5002
    },
    {
      'category': 'Somalia',
      'cid': 5591,
      'pid': 5002
    },
    {
      'category': 'Tanzania',
      'cid': 5006,
      'pid': 5002
    },
    {
      'category': 'Uganda',
      'cid': 5007,
      'pid': 5002
    },
    {
      'category': 'Zambia',
      'cid': 5671,
      'pid': 5002
    },
    {
      'category': 'Zimbabwe',
      'cid': 5008,
      'pid': 5002
    },
    {
      'category': 'Harare',
      'cid': 5009,
      'pid': 5008
    },
    {
      'category': 'Middle Africa',
      'cid': 5010,
      'pid': 5001
    },
    {
      'category': 'Angola',
      'cid': 5636,
      'pid': 5010
    },
    {
      'category': 'Cameroon',
      'cid': 5635,
      'pid': 5010
    },
    {
      'category': 'Chad',
      'cid': 5715,
      'pid': 5010
    },
    {
      'category': 'Congo (DR)',
      'cid': 5011,
      'pid': 5010
    },
    {
      'category': 'Kinshasa',
      'cid': 5012,
      'pid': 5011
    },
    {
      'category': 'Northern Africa',
      'cid': 5013,
      'pid': 5001
    },
    {
      'category': 'Algeria',
      'cid': 5014,
      'pid': 5013
    },
    {
      'category': 'Egypt',
      'cid': 5015,
      'pid': 5013
    },
    {
      'category': 'Cairo',
      'cid': 5016,
      'pid': 5015
    },
    {
      'category': 'Libya',
      'cid': 5633,
      'pid': 5013
    },
    {
      'category': 'Morocco',
      'cid': 5017,
      'pid': 5013
    },
    {
      'category': 'Sudan',
      'cid': 5018,
      'pid': 5013
    },
    {
      'category': 'Tunisia',
      'cid': 5019,
      'pid': 5013
    },
    {
      'category': 'Southern Africa',
      'cid': 5020,
      'pid': 5001
    },
    {
      'category': 'Botswana',
      'cid': 5713,
      'pid': 5020
    },
    {
      'category': 'South Africa',
      'cid': 5021,
      'pid': 5020
    },
    {
      'category': 'Cape Town',
      'cid': 5022,
      'pid': 5021
    },
    {
      'category': 'Johannesburg',
      'cid': 5023,
      'pid': 5021
    },
    {
      'category': 'Western Africa',
      'cid': 5024,
      'pid': 5001
    },
    {
      'category': 'Burkina Faso',
      'cid': 5712,
      'pid': 5024
    },
    {
      'category': 'Côte d\'Ivoire',
      'cid': 5637,
      'pid': 5024
    },
    {
      'category': 'Ghana',
      'cid': 5025,
      'pid': 5024
    },
    {
      'category': 'Mali',
      'cid': 5711,
      'pid': 5024
    },
    {
      'category': 'Niger',
      'cid': 5710,
      'pid': 5024
    },
    {
      'category': 'Nigeria',
      'cid': 5026,
      'pid': 5024
    },
    {
      'category': 'Lagos',
      'cid': 5027,
      'pid': 5026
    },
    {
      'category': 'Senegal',
      'cid': 5653,
      'pid': 5024
    },
    {
      'category': 'Asia',
      'cid': 5028,
      'pid': 5000
    },
    {
      'category': 'Central Asia',
      'cid': 5029,
      'pid': 5028
    },
    {
      'category': 'Kazakhstan',
      'cid': 5030,
      'pid': 5029
    },
    {
      'category': 'Kyrgyzstan',
      'cid': 5574,
      'pid': 5029
    },
    {
      'category': 'Tajikistan',
      'cid': 5579,
      'pid': 5029
    },
    {
      'category': 'Turkmenistan',
      'cid': 5580,
      'pid': 5029
    },
    {
      'category': 'Uzbekistan',
      'cid': 5031,
      'pid': 5029
    },
    {
      'category': 'East Asia',
      'cid': 5032,
      'pid': 5028
    },
    {
      'category': 'China',
      'cid': 5033,
      'pid': 5032
    },
    {
      'category': 'Eastern China',
      'cid': 5364,
      'pid': 5033
    },
    {
      'category': 'Jiangsu',
      'cid': 5392,
      'pid': 5364
    },
    {
      'category': 'Nanjing',
      'cid': 5666,
      'pid': 5392
    },
    {
      'category': 'Shanghai',
      'cid': 5040,
      'pid': 5364
    },
    {
      'category': 'Zhejiang',
      'cid': 5393,
      'pid': 5364
    },
    {
      'category': 'Hangzhou',
      'cid': 5039,
      'pid': 5393
    },
    {
      'category': 'North Central China',
      'cid': 5367,
      'pid': 5033
    },
    {
      'category': 'Beijing',
      'cid': 5034,
      'pid': 5367
    },
    {
      'category': 'Hebei',
      'cid': 5401,
      'pid': 5367
    },
    {
      'category': 'Henan',
      'cid': 5399,
      'pid': 5367
    },
    {
      'category': 'Inner Mongolia',
      'cid': 5664,
      'pid': 5367
    },
    {
      'category': 'Shandong',
      'cid': 5400,
      'pid': 5367
    },
    {
      'category': 'Shanxi',
      'cid': 5660,
      'pid': 5367
    },
    {
      'category': 'Tianjin',
      'cid': 5043,
      'pid': 5367
    },
    {
      'category': 'Northeast China',
      'cid': 5363,
      'pid': 5033
    },
    {
      'category': 'Heilongjiang',
      'cid': 5391,
      'pid': 5363
    },
    {
      'category': 'Harbin',
      'cid': 5667,
      'pid': 5391
    },
    {
      'category': 'Jilin',
      'cid': 5643,
      'pid': 5363
    },
    {
      'category': 'Changchun',
      'cid': 5035,
      'pid': 5643
    },
    {
      'category': 'Liaoning',
      'cid': 5390,
      'pid': 5363
    },
    {
      'category': 'Shenyang',
      'cid': 5041,
      'pid': 5390
    },
    {
      'category': 'Northwest China',
      'cid': 5368,
      'pid': 5033
    },
    {
      'category': 'Gansu',
      'cid': 5661,
      'pid': 5368
    },
    {
      'category': 'Ningxia',
      'cid': 5662,
      'pid': 5368
    },
    {
      'category': 'Qinghai',
      'cid': 5663,
      'pid': 5368
    },
    {
      'category': 'Shaanxi',
      'cid': 5402,
      'pid': 5368
    },
    {
      'category': 'Xi\'an',
      'cid': 5046,
      'pid': 5402
    },
    {
      'category': 'Xinjiang',
      'cid': 5403,
      'pid': 5368
    },
    {
      'category': 'South Central China',
      'cid': 5366,
      'pid': 5033
    },
    {
      'category': 'Anhui',
      'cid': 5655,
      'pid': 5366
    },
    {
      'category': 'Chongqing',
      'cid': 5036,
      'pid': 5366
    },
    {
      'category': 'Hubei',
      'cid': 5397,
      'pid': 5366
    },
    {
      'category': 'Wuhan',
      'cid': 5045,
      'pid': 5397
    },
    {
      'category': 'Hunan',
      'cid': 5398,
      'pid': 5366
    },
    {
      'category': 'Jiangxi',
      'cid': 5658,
      'pid': 5366
    },
    {
      'category': 'Sichuan',
      'cid': 5396,
      'pid': 5366
    },
    {
      'category': 'Southeast China',
      'cid': 5365,
      'pid': 5033
    },
    {
      'category': 'Fujian',
      'cid': 5394,
      'pid': 5365
    },
    {
      'category': 'Guangdong',
      'cid': 5395,
      'pid': 5365
    },
    {
      'category': 'Dongguan',
      'cid': 5037,
      'pid': 5395
    },
    {
      'category': 'Guangzhou',
      'cid': 5038,
      'pid': 5395
    },
    {
      'category': 'Shenzhen',
      'cid': 5042,
      'pid': 5395
    },
    {
      'category': 'Hainan',
      'cid': 5665,
      'pid': 5365
    },
    {
      'category': 'Southwest China',
      'cid': 5369,
      'pid': 5033
    },
    {
      'category': 'Guangxi',
      'cid': 5656,
      'pid': 5369
    },
    {
      'category': 'Guizhou',
      'cid': 5659,
      'pid': 5369
    },
    {
      'category': 'Tibet',
      'cid': 5044,
      'pid': 5369
    },
    {
      'category': 'Yunnan',
      'cid': 5657,
      'pid': 5369
    },
    {
      'category': 'Hong Kong',
      'cid': 5047,
      'pid': 5032
    },
    {
      'category': 'Japan',
      'cid': 5048,
      'pid': 5032
    },
    {
      'category': 'Chubu',
      'cid': 5382,
      'pid': 5048
    },
    {
      'category': 'Aichi',
      'cid': 5723,
      'pid': 5382
    },
    {
      'category': 'Nagoya',
      'cid': 5049,
      'pid': 5723
    },
    {
      'category': 'Fukui',
      'cid': 5725,
      'pid': 5382
    },
    {
      'category': 'Gifu',
      'cid': 5730,
      'pid': 5382
    },
    {
      'category': 'Ishikawa',
      'cid': 5729,
      'pid': 5382
    },
    {
      'category': 'Nagano',
      'cid': 5726,
      'pid': 5382
    },
    {
      'category': 'Niigata',
      'cid': 5727,
      'pid': 5382
    },
    {
      'category': 'Shizuoka',
      'cid': 5731,
      'pid': 5382
    },
    {
      'category': 'Toyama',
      'cid': 5724,
      'pid': 5382
    },
    {
      'category': 'Yamanashi',
      'cid': 5728,
      'pid': 5382
    },
    {
      'category': 'Chugoku',
      'cid': 5385,
      'pid': 5048
    },
    {
      'category': 'Hiroshima',
      'cid': 5735,
      'pid': 5385
    },
    {
      'category': 'Okayama',
      'cid': 5736,
      'pid': 5385
    },
    {
      'category': 'Shimane',
      'cid': 5734,
      'pid': 5385
    },
    {
      'category': 'Tottori',
      'cid': 5732,
      'pid': 5385
    },
    {
      'category': 'Yamaguchi',
      'cid': 5733,
      'pid': 5385
    },
    {
      'category': 'Hokkaido',
      'cid': 5381,
      'pid': 5048
    },
    {
      'category': 'Sapporo',
      'cid': 5051,
      'pid': 5381
    },
    {
      'category': 'Kansai',
      'cid': 5383,
      'pid': 5048
    },
    {
      'category': 'Hyogo',
      'cid': 5737,
      'pid': 5383
    },
    {
      'category': 'Kobe',
      'cid': 5406,
      'pid': 5737
    },
    {
      'category': 'Kyoto',
      'cid': 5407,
      'pid': 5383
    },
    {
      'category': 'Mie',
      'cid': 5741,
      'pid': 5383
    },
    {
      'category': 'Nara',
      'cid': 5739,
      'pid': 5383
    },
    {
      'category': 'Osaka',
      'cid': 5050,
      'pid': 5383
    },
    {
      'category': 'Shiga',
      'cid': 5738,
      'pid': 5383
    },
    {
      'category': 'Wakayama',
      'cid': 5740,
      'pid': 5383
    },
    {
      'category': 'Kanto',
      'cid': 5377,
      'pid': 5048
    },
    {
      'category': 'Chiba',
      'cid': 5742,
      'pid': 5377
    },
    {
      'category': 'Gunma',
      'cid': 5745,
      'pid': 5377
    },
    {
      'category': 'Ibaraki',
      'cid': 5746,
      'pid': 5377
    },
    {
      'category': 'Kanagawa',
      'cid': 5744,
      'pid': 5377
    },
    {
      'category': 'Kawasaki',
      'cid': 5764,
      'pid': 5744
    },
    {
      'category': 'Yokohama',
      'cid': 5404,
      'pid': 5744
    },
    {
      'category': 'Saitama',
      'cid': 5743,
      'pid': 5377
    },
    {
      'category': 'Tochigi',
      'cid': 5747,
      'pid': 5377
    },
    {
      'category': 'Tokyo',
      'cid': 5052,
      'pid': 5377
    },
    {
      'category': 'Kyushu',
      'cid': 5380,
      'pid': 5048
    },
    {
      'category': 'Fukuoka',
      'cid': 5405,
      'pid': 5380
    },
    {
      'category': 'Kagoshima',
      'cid': 5752,
      'pid': 5380
    },
    {
      'category': 'Kumamoto',
      'cid': 5753,
      'pid': 5380
    },
    {
      'category': 'Miyazaki',
      'cid': 5751,
      'pid': 5380
    },
    {
      'category': 'Nagasaki',
      'cid': 5749,
      'pid': 5380
    },
    {
      'category': 'Oita',
      'cid': 5750,
      'pid': 5380
    },
    {
      'category': 'Saga',
      'cid': 5748,
      'pid': 5380
    },
    {
      'category': 'Okinawa',
      'cid': 5379,
      'pid': 5048
    },
    {
      'category': 'Shikoku',
      'cid': 5384,
      'pid': 5048
    },
    {
      'category': 'Ehime',
      'cid': 5755,
      'pid': 5384
    },
    {
      'category': 'Kagawa',
      'cid': 5754,
      'pid': 5384
    },
    {
      'category': 'Kochi',
      'cid': 5757,
      'pid': 5384
    },
    {
      'category': 'Tokushima',
      'cid': 5756,
      'pid': 5384
    },
    {
      'category': 'Tohoku',
      'cid': 5378,
      'pid': 5048
    },
    {
      'category': 'Akita',
      'cid': 5759,
      'pid': 5378
    },
    {
      'category': 'Aomori',
      'cid': 5758,
      'pid': 5378
    },
    {
      'category': 'Fukushima',
      'cid': 5763,
      'pid': 5378
    },
    {
      'category': 'Iwate',
      'cid': 5760,
      'pid': 5378
    },
    {
      'category': 'Miyagi',
      'cid': 5762,
      'pid': 5378
    },
    {
      'category': 'Sendai',
      'cid': 5765,
      'pid': 5762
    },
    {
      'category': 'Yamagata',
      'cid': 5761,
      'pid': 5378
    },
    {
      'category': 'Macau',
      'cid': 5355,
      'pid': 5032
    },
    {
      'category': 'Mongolia',
      'cid': 5577,
      'pid': 5032
    },
    {
      'category': 'North Korea',
      'cid': 5053,
      'pid': 5032
    },
    {
      'category': 'South Korea',
      'cid': 5054,
      'pid': 5032
    },
    {
      'category': 'Gangwon',
      'cid': 5672,
      'pid': 5054
    },
    {
      'category': 'Gyeonggi',
      'cid': 5375,
      'pid': 5054
    },
    {
      'category': 'Incheon',
      'cid': 5370,
      'pid': 5375
    },
    {
      'category': 'Jeju',
      'cid': 5371,
      'pid': 5054
    },
    {
      'category': 'North Chungcheong',
      'cid': 5678,
      'pid': 5054
    },
    {
      'category': 'North Gyeongsang',
      'cid': 5674,
      'pid': 5054
    },
    {
      'category': 'Daegu',
      'cid': 5055,
      'pid': 5674
    },
    {
      'category': 'North Jeolla',
      'cid': 5676,
      'pid': 5054
    },
    {
      'category': 'Seoul',
      'cid': 5057,
      'pid': 5054
    },
    {
      'category': 'South Chungcheong',
      'cid': 5677,
      'pid': 5054
    },
    {
      'category': 'Daejeon',
      'cid': 5374,
      'pid': 5677
    },
    {
      'category': 'South Gyeongsang',
      'cid': 5673,
      'pid': 5054
    },
    {
      'category': 'Busan',
      'cid': 5372,
      'pid': 5673
    },
    {
      'category': 'Ulsan',
      'cid': 5376,
      'pid': 5673
    },
    {
      'category': 'South Jeolla',
      'cid': 5675,
      'pid': 5054
    },
    {
      'category': 'Gwangju',
      'cid': 5373,
      'pid': 5675
    },
    {
      'category': 'Taiwan',
      'cid': 5058,
      'pid': 5032
    },
    {
      'category': 'Hsinchu',
      'cid': 5389,
      'pid': 5058
    },
    {
      'category': 'Kaohsiung',
      'cid': 5386,
      'pid': 5058
    },
    {
      'category': 'Taichung',
      'cid': 5388,
      'pid': 5058
    },
    {
      'category': 'Tainan',
      'cid': 5387,
      'pid': 5058
    },
    {
      'category': 'Taipei',
      'cid': 5059,
      'pid': 5058
    },
    {
      'category': 'Russia & CIS',
      'cid': 5593,
      'pid': 5028
    },
    {
      'category': 'Caucasus',
      'cid': 5408,
      'pid': 5593
    },
    {
      'category': 'Armenia',
      'cid': 5537,
      'pid': 5408
    },
    {
      'category': 'Azerbaijan',
      'cid': 5538,
      'pid': 5408
    },
    {
      'category': 'Georgia',
      'cid': 5539,
      'pid': 5408
    },
    {
      'category': 'Russian Federation',
      'cid': 5120,
      'pid': 5593
    },
    {
      'category': 'Far Eastern Russia',
      'cid': 5606,
      'pid': 5120
    },
    {
      'category': 'North Caucasian District',
      'cid': 5766,
      'pid': 5120
    },
    {
      'category': 'Northwestern Russia',
      'cid': 5594,
      'pid': 5120
    },
    {
      'category': 'Saint Petersburg',
      'cid': 5122,
      'pid': 5594
    },
    {
      'category': 'Russian Central District',
      'cid': 5595,
      'pid': 5120
    },
    {
      'category': 'Moscow',
      'cid': 5121,
      'pid': 5595
    },
    {
      'category': 'Russian Southern District',
      'cid': 5603,
      'pid': 5120
    },
    {
      'category': 'Siberian Federal District',
      'cid': 5605,
      'pid': 5120
    },
    {
      'category': 'Urals District',
      'cid': 5607,
      'pid': 5120
    },
    {
      'category': 'Volga District',
      'cid': 5604,
      'pid': 5120
    },
    {
      'category': 'South Asia',
      'cid': 5074,
      'pid': 5028
    },
    {
      'category': 'Afghanistan',
      'cid': 5075,
      'pid': 5074
    },
    {
      'category': 'Bangladesh',
      'cid': 5076,
      'pid': 5074
    },
    {
      'category': 'Bhutan',
      'cid': 5553,
      'pid': 5074
    },
    {
      'category': 'India',
      'cid': 5077,
      'pid': 5074
    },
    {
      'category': 'East India',
      'cid': 5515,
      'pid': 5077
    },
    {
      'category': 'Bihar',
      'cid': 5650,
      'pid': 5515
    },
    {
      'category': 'Chhattisgarh',
      'cid': 5775,
      'pid': 5515
    },
    {
      'category': 'Orissa',
      'cid': 5654,
      'pid': 5515
    },
    {
      'category': 'West Bengal',
      'cid': 5649,
      'pid': 5515
    },
    {
      'category': 'Kolkata',
      'cid': 5082,
      'pid': 5649
    },
    {
      'category': 'North India',
      'cid': 5519,
      'pid': 5077
    },
    {
      'category': 'Delhi',
      'cid': 5080,
      'pid': 5519
    },
    {
      'category': 'Madhya Pradesh',
      'cid': 5652,
      'pid': 5519
    },
    {
      'category': 'Punjab (India)',
      'cid': 5776,
      'pid': 5519
    },
    {
      'category': 'Uttar Pradesh',
      'cid': 5644,
      'pid': 5519
    },
    {
      'category': 'Agra',
      'cid': 5520,
      'pid': 5644
    },
    {
      'category': 'Northeast India',
      'cid': 5514,
      'pid': 5077
    },
    {
      'category': 'South India',
      'cid': 5516,
      'pid': 5077
    },
    {
      'category': 'Andhra Pradesh',
      'cid': 5648,
      'pid': 5516
    },
    {
      'category': 'Hyderabad',
      'cid': 5081,
      'pid': 5648
    },
    {
      'category': 'Karnataka',
      'cid': 5640,
      'pid': 5516
    },
    {
      'category': 'Bangalore',
      'cid': 5078,
      'pid': 5640
    },
    {
      'category': 'Kerala',
      'cid': 5651,
      'pid': 5516
    },
    {
      'category': 'Tamil Nadu',
      'cid': 5639,
      'pid': 5516
    },
    {
      'category': 'Chennai',
      'cid': 5079,
      'pid': 5639
    },
    {
      'category': 'West India',
      'cid': 5517,
      'pid': 5077
    },
    {
      'category': 'Goa',
      'cid': 5518,
      'pid': 5517
    },
    {
      'category': 'Gujarat',
      'cid': 5647,
      'pid': 5517
    },
    {
      'category': 'Maharashtra',
      'cid': 5645,
      'pid': 5517
    },
    {
      'category': 'Mumbai',
      'cid': 5083,
      'pid': 5645
    },
    {
      'category': 'Rajasthan',
      'cid': 5646,
      'pid': 5517
    },
    {
      'category': 'Jaipur',
      'cid': 5521,
      'pid': 5646
    },
    {
      'category': 'Indian Ocean Islands',
      'cid': 5620,
      'pid': 5074
    },
    {
      'category': 'Maldives',
      'cid': 5576,
      'pid': 5620
    },
    {
      'category': 'Réunion',
      'cid': 5702,
      'pid': 5620
    },
    {
      'category': 'Nepal',
      'cid': 5085,
      'pid': 5074
    },
    {
      'category': 'Pakistan',
      'cid': 5086,
      'pid': 5074
    },
    {
      'category': 'Karachi',
      'cid': 5087,
      'pid': 5086
    },
    {
      'category': 'Lahore',
      'cid': 5088,
      'pid': 5086
    },
    {
      'category': 'Sri Lanka',
      'cid': 5089,
      'pid': 5074
    },
    {
      'category': 'Southeast Asia',
      'cid': 5060,
      'pid': 5028
    },
    {
      'category': 'Brunei',
      'cid': 5554,
      'pid': 5060
    },
    {
      'category': 'Cambodia',
      'cid': 5555,
      'pid': 5060
    },
    {
      'category': 'East Timor',
      'cid': 5557,
      'pid': 5060
    },
    {
      'category': 'Indonesia',
      'cid': 5061,
      'pid': 5060
    },
    {
      'category': 'Java (Indonesia)',
      'cid': 5703,
      'pid': 5061
    },
    {
      'category': 'Jakarta',
      'cid': 5062,
      'pid': 5703
    },
    {
      'category': 'Kalimantan',
      'cid': 5704,
      'pid': 5061
    },
    {
      'category': 'Maluku Islands',
      'cid': 5705,
      'pid': 5061
    },
    {
      'category': 'Nusa Tenggara',
      'cid': 5706,
      'pid': 5061
    },
    {
      'category': 'Bali',
      'cid': 5361,
      'pid': 5706
    },
    {
      'category': 'Sulawesi',
      'cid': 5707,
      'pid': 5061
    },
    {
      'category': 'Sumatra',
      'cid': 5708,
      'pid': 5061
    },
    {
      'category': 'Western New Guinea',
      'cid': 5709,
      'pid': 5061
    },
    {
      'category': 'Laos',
      'cid': 5575,
      'pid': 5060
    },
    {
      'category': 'Malaysia',
      'cid': 5063,
      'pid': 5060
    },
    {
      'category': 'Kuala Lumpur',
      'cid': 5064,
      'pid': 5063
    },
    {
      'category': 'Myanmar',
      'cid': 5065,
      'pid': 5060
    },
    {
      'category': 'Philippines',
      'cid': 5066,
      'pid': 5060
    },
    {
      'category': 'Cebu',
      'cid': 5668,
      'pid': 5066
    },
    {
      'category': 'Manila',
      'cid': 5067,
      'pid': 5066
    },
    {
      'category': 'Singapore',
      'cid': 5068,
      'pid': 5060
    },
    {
      'category': 'Thailand',
      'cid': 5069,
      'pid': 5060
    },
    {
      'category': 'Bangkok',
      'cid': 5070,
      'pid': 5069
    },
    {
      'category': 'Chiang Mai',
      'cid': 5810,
      'pid': 5069
    },
    {
      'category': 'Pattaya',
      'cid': 5812,
      'pid': 5069
    },
    {
      'category': 'Southern Thailand',
      'cid': 5811,
      'pid': 5069
    },
    {
      'category': 'Phuket',
      'cid': 5362,
      'pid': 5811
    },
    {
      'category': 'Viet Nam',
      'cid': 5071,
      'pid': 5060
    },
    {
      'category': 'Hanoi',
      'cid': 5072,
      'pid': 5071
    },
    {
      'category': 'Ho Chi Minh City',
      'cid': 5073,
      'pid': 5071
    },
    {
      'category': 'West Asia',
      'cid': 5090,
      'pid': 5028
    },
    {
      'category': 'Bahrain',
      'cid': 5552,
      'pid': 5090
    },
    {
      'category': 'Iran',
      'cid': 5084,
      'pid': 5090
    },
    {
      'category': 'Iraq',
      'cid': 5092,
      'pid': 5090
    },
    {
      'category': 'Israel',
      'cid': 5093,
      'pid': 5090
    },
    {
      'category': 'Jerusalem',
      'cid': 5094,
      'pid': 5093
    },
    {
      'category': 'Palestinian Territories',
      'cid': 5592,
      'pid': 5093
    },
    {
      'category': 'Tel Aviv',
      'cid': 5095,
      'pid': 5093
    },
    {
      'category': 'Jordan',
      'cid': 5096,
      'pid': 5090
    },
    {
      'category': 'Kuwait',
      'cid': 5558,
      'pid': 5090
    },
    {
      'category': 'Lebanon',
      'cid': 5097,
      'pid': 5090
    },
    {
      'category': 'Oman',
      'cid': 5578,
      'pid': 5090
    },
    {
      'category': 'Qatar',
      'cid': 5590,
      'pid': 5090
    },
    {
      'category': 'Saudi Arabia',
      'cid': 5098,
      'pid': 5090
    },
    {
      'category': 'Syria',
      'cid': 5099,
      'pid': 5090
    },
    {
      'category': 'Turkey',
      'cid': 5100,
      'pid': 5090
    },
    {
      'category': 'Aegean Turkey',
      'cid': 5834,
      'pid': 5100
    },
    {
      'category': 'Izmir',
      'cid': 5842,
      'pid': 5834
    },
    {
      'category': 'Muğla',
      'cid': 5845,
      'pid': 5834
    },
    {
      'category': 'Bodrum',
      'cid': 5849,
      'pid': 5845
    },
    {
      'category': 'Pamukkale',
      'cid': 5846,
      'pid': 5834
    },
    {
      'category': 'Black Sea Turkey',
      'cid': 5835,
      'pid': 5100
    },
    {
      'category': 'Central Anatolia',
      'cid': 5836,
      'pid': 5100
    },
    {
      'category': 'Ankara',
      'cid': 5101,
      'pid': 5836
    },
    {
      'category': 'Cappadocia',
      'cid': 5844,
      'pid': 5836
    },
    {
      'category': 'Konya',
      'cid': 5847,
      'pid': 5836
    },
    {
      'category': 'Eastern Anatolia',
      'cid': 5837,
      'pid': 5100
    },
    {
      'category': 'Marmara Region',
      'cid': 5838,
      'pid': 5100
    },
    {
      'category': 'Bursa',
      'cid': 5841,
      'pid': 5838
    },
    {
      'category': 'Istanbul',
      'cid': 5102,
      'pid': 5838
    },
    {
      'category': 'Mediterranean Turkey',
      'cid': 5839,
      'pid': 5100
    },
    {
      'category': 'Adana',
      'cid': 5848,
      'pid': 5839
    },
    {
      'category': 'Antalya',
      'cid': 5843,
      'pid': 5839
    },
    {
      'category': 'Mersin',
      'cid': 5850,
      'pid': 5839
    },
    {
      'category': 'Southeastern Anatolia',
      'cid': 5840,
      'pid': 5100
    },
    {
      'category': 'United Arab Emirates',
      'cid': 5103,
      'pid': 5090
    },
    {
      'category': 'Yemen',
      'cid': 5581,
      'pid': 5090
    },
    {
      'category': 'Europe',
      'cid': 5104,
      'pid': 5000
    },
    {
      'category': 'Central & Eastern Europe',
      'cid': 5565,
      'pid': 5104
    },
    {
      'category': 'Austria',
      'cid': 5178,
      'pid': 5565
    },
    {
      'category': 'Vienna',
      'cid': 5179,
      'pid': 5178
    },
    {
      'category': 'Baltics',
      'cid': 5556,
      'pid': 5565
    },
    {
      'category': 'Estonia',
      'cid': 5128,
      'pid': 5556
    },
    {
      'category': 'Latvia',
      'cid': 5134,
      'pid': 5556
    },
    {
      'category': 'Lithuania',
      'cid': 5135,
      'pid': 5556
    },
    {
      'category': 'Czechia',
      'cid': 5108,
      'pid': 5565
    },
    {
      'category': 'Prague',
      'cid': 5109,
      'pid': 5108
    },
    {
      'category': 'Eastern Europe',
      'cid': 5105,
      'pid': 5565
    },
    {
      'category': 'Belarus',
      'cid': 5106,
      'pid': 5105
    },
    {
      'category': 'Moldova',
      'cid': 5585,
      'pid': 5105
    },
    {
      'category': 'Ukraine',
      'cid': 5124,
      'pid': 5105
    },
    {
      'category': 'Crimea',
      'cid': 5628,
      'pid': 5124
    },
    {
      'category': 'Kiev',
      'cid': 5619,
      'pid': 5124
    },
    {
      'category': 'Germany',
      'cid': 5188,
      'pid': 5565
    },
    {
      'category': 'Baden-Wuerttemberg',
      'cid': 5490,
      'pid': 5188
    },
    {
      'category': 'Bavaria',
      'cid': 5491,
      'pid': 5188
    },
    {
      'category': 'Munich',
      'cid': 5195,
      'pid': 5491
    },
    {
      'category': 'Berlin',
      'cid': 5189,
      'pid': 5188
    },
    {
      'category': 'Brandenburg',
      'cid': 5494,
      'pid': 5188
    },
    {
      'category': 'Bremen',
      'cid': 5495,
      'pid': 5188
    },
    {
      'category': 'Hamburg',
      'cid': 5194,
      'pid': 5188
    },
    {
      'category': 'Hesse',
      'cid': 5493,
      'pid': 5188
    },
    {
      'category': 'Frankfurt',
      'cid': 5193,
      'pid': 5493
    },
    {
      'category': 'Lower Saxony',
      'cid': 5492,
      'pid': 5188
    },
    {
      'category': 'Mecklenburg-Vorpommern',
      'cid': 5496,
      'pid': 5188
    },
    {
      'category': 'North Rhine-Westphalia',
      'cid': 5489,
      'pid': 5188
    },
    {
      'category': 'Cologne',
      'cid': 5190,
      'pid': 5489
    },
    {
      'category': 'Duesseldorf',
      'cid': 5191,
      'pid': 5489
    },
    {
      'category': 'Essen (Ruhr Area)',
      'cid': 5192,
      'pid': 5489
    },
    {
      'category': 'Rhineland-Palatinate',
      'cid': 5497,
      'pid': 5188
    },
    {
      'category': 'Saarland',
      'cid': 5498,
      'pid': 5188
    },
    {
      'category': 'Saxony',
      'cid': 5499,
      'pid': 5188
    },
    {
      'category': 'Saxony-Anhalt',
      'cid': 5500,
      'pid': 5188
    },
    {
      'category': 'Schleswig-Holstein',
      'cid': 5501,
      'pid': 5188
    },
    {
      'category': 'Thuringia',
      'cid': 5502,
      'pid': 5188
    },
    {
      'category': 'Hungary',
      'cid': 5110,
      'pid': 5565
    },
    {
      'category': 'Budapest',
      'cid': 5111,
      'pid': 5110
    },
    {
      'category': 'Liechtenstein',
      'cid': 5566,
      'pid': 5565
    },
    {
      'category': 'Poland',
      'cid': 5112,
      'pid': 5565
    },
    {
      'category': 'Katowice (Upper Silesian)',
      'cid': 5113,
      'pid': 5112
    },
    {
      'category': 'Krakow',
      'cid': 5114,
      'pid': 5112
    },
    {
      'category': 'Warsaw',
      'cid': 5115,
      'pid': 5112
    },
    {
      'category': 'Wroclaw',
      'cid': 5116,
      'pid': 5112
    },
    {
      'category': 'Łódź',
      'cid': 5117,
      'pid': 5112
    },
    {
      'category': 'Slovakia',
      'cid': 5123,
      'pid': 5565
    },
    {
      'category': 'Slovenia',
      'cid': 5170,
      'pid': 5565
    },
    {
      'category': 'Switzerland',
      'cid': 5200,
      'pid': 5565
    },
    {
      'category': 'Geneva',
      'cid': 5201,
      'pid': 5200
    },
    {
      'category': 'Zurich',
      'cid': 5202,
      'pid': 5200
    },
    {
      'category': 'Mediterranean Europe',
      'cid': 5150,
      'pid': 5104
    },
    {
      'category': 'Cyprus',
      'cid': 5091,
      'pid': 5150
    },
    {
      'category': 'Italy',
      'cid': 5155,
      'pid': 5150
    },
    {
      'category': 'Abruzzo',
      'cid': 5626,
      'pid': 5155
    },
    {
      'category': 'Aosta Valley',
      'cid': 5722,
      'pid': 5155
    },
    {
      'category': 'Basilicata',
      'cid': 5720,
      'pid': 5155
    },
    {
      'category': 'Calabria',
      'cid': 5513,
      'pid': 5155
    },
    {
      'category': 'Campania',
      'cid': 5504,
      'pid': 5155
    },
    {
      'category': 'Naples',
      'cid': 5160,
      'pid': 5504
    },
    {
      'category': 'Salerno & Amalfi Coast',
      'cid': 5771,
      'pid': 5504
    },
    {
      'category': 'Emilia-Romagna',
      'cid': 5508,
      'pid': 5155
    },
    {
      'category': 'Friuli-Venezia Giulia',
      'cid': 5717,
      'pid': 5155
    },
    {
      'category': 'Lazio',
      'cid': 5505,
      'pid': 5155
    },
    {
      'category': 'Rome',
      'cid': 5162,
      'pid': 5505
    },
    {
      'category': 'Liguria',
      'cid': 5511,
      'pid': 5155
    },
    {
      'category': 'Genoa',
      'cid': 5158,
      'pid': 5511
    },
    {
      'category': 'Lombardy',
      'cid': 5503,
      'pid': 5155
    },
    {
      'category': 'Milan',
      'cid': 5159,
      'pid': 5503
    },
    {
      'category': 'Marche',
      'cid': 5716,
      'pid': 5155
    },
    {
      'category': 'Molise',
      'cid': 5721,
      'pid': 5155
    },
    {
      'category': 'Piedmont',
      'cid': 5507,
      'pid': 5155
    },
    {
      'category': 'Turin',
      'cid': 5164,
      'pid': 5507
    },
    {
      'category': 'Puglia',
      'cid': 5509,
      'pid': 5155
    },
    {
      'category': 'Bari',
      'cid': 5156,
      'pid': 5509
    },
    {
      'category': 'Sardinia',
      'cid': 5512,
      'pid': 5155
    },
    {
      'category': 'Sicily',
      'cid': 5163,
      'pid': 5155
    },
    {
      'category': 'Palermo',
      'cid': 5161,
      'pid': 5163
    },
    {
      'category': 'Trentino-Alto Adige',
      'cid': 5718,
      'pid': 5155
    },
    {
      'category': 'Tuscany',
      'cid': 5510,
      'pid': 5155
    },
    {
      'category': 'Florence',
      'cid': 5157,
      'pid': 5510
    },
    {
      'category': 'Umbria',
      'cid': 5719,
      'pid': 5155
    },
    {
      'category': 'Veneto',
      'cid': 5506,
      'pid': 5155
    },
    {
      'category': 'Venice',
      'cid': 5165,
      'pid': 5506
    },
    {
      'category': 'Malta',
      'cid': 5166,
      'pid': 5150
    },
    {
      'category': 'Monaco',
      'cid': 5586,
      'pid': 5150
    },
    {
      'category': 'Portugal',
      'cid': 5167,
      'pid': 5150
    },
    {
      'category': 'Azores & Madeira',
      'cid': 5773,
      'pid': 5167
    },
    {
      'category': 'Lisbon',
      'cid': 5168,
      'pid': 5167
    },
    {
      'category': 'Porto',
      'cid': 5772,
      'pid': 5167
    },
    {
      'category': 'San Marino',
      'cid': 5587,
      'pid': 5150
    },
    {
      'category': 'Spain',
      'cid': 5171,
      'pid': 5150
    },
    {
      'category': 'Andalucia',
      'cid': 5476,
      'pid': 5171
    },
    {
      'category': 'Cádiz',
      'cid': 5616,
      'pid': 5476
    },
    {
      'category': 'Málaga',
      'cid': 5174,
      'pid': 5476
    },
    {
      'category': 'Seville',
      'cid': 5175,
      'pid': 5476
    },
    {
      'category': 'Aragon',
      'cid': 5485,
      'pid': 5171
    },
    {
      'category': 'Asturias',
      'cid': 5487,
      'pid': 5171
    },
    {
      'category': 'Balearic Islands',
      'cid': 5488,
      'pid': 5171
    },
    {
      'category': 'Basque Country',
      'cid': 5481,
      'pid': 5171
    },
    {
      'category': 'Canary Islands',
      'cid': 5482,
      'pid': 5171
    },
    {
      'category': 'Cantabria',
      'cid': 5770,
      'pid': 5171
    },
    {
      'category': 'Castile and León',
      'cid': 5480,
      'pid': 5171
    },
    {
      'category': 'Castile-La Mancha',
      'cid': 5483,
      'pid': 5171
    },
    {
      'category': 'Catalonia',
      'cid': 5477,
      'pid': 5171
    },
    {
      'category': 'Barcelona',
      'cid': 5172,
      'pid': 5477
    },
    {
      'category': 'Extremadura',
      'cid': 5486,
      'pid': 5171
    },
    {
      'category': 'Galicia',
      'cid': 5479,
      'pid': 5171
    },
    {
      'category': 'Madrid',
      'cid': 5173,
      'pid': 5171
    },
    {
      'category': 'Murcia',
      'cid': 5484,
      'pid': 5171
    },
    {
      'category': 'Valencian Community',
      'cid': 5478,
      'pid': 5171
    },
    {
      'category': 'Valencia',
      'cid': 5176,
      'pid': 5478
    },
    {
      'category': 'Vatican City',
      'cid': 5769,
      'pid': 5150
    },
    {
      'category': 'Nordic Countries',
      'cid': 5125,
      'pid': 5104
    },
    {
      'category': 'Denmark',
      'cid': 5126,
      'pid': 5125
    },
    {
      'category': 'Copenhagen',
      'cid': 5127,
      'pid': 5126
    },
    {
      'category': 'Finland',
      'cid': 5129,
      'pid': 5125
    },
    {
      'category': 'Helsinki',
      'cid': 5130,
      'pid': 5129
    },
    {
      'category': 'Greenland',
      'cid': 5679,
      'pid': 5125
    },
    {
      'category': 'Iceland',
      'cid': 5131,
      'pid': 5125
    },
    {
      'category': 'Norway',
      'cid': 5136,
      'pid': 5125
    },
    {
      'category': 'Oslo',
      'cid': 5137,
      'pid': 5136
    },
    {
      'category': 'Sweden',
      'cid': 5138,
      'pid': 5125
    },
    {
      'category': 'Stockholm',
      'cid': 5139,
      'pid': 5138
    },
    {
      'category': 'Southeastern Europe',
      'cid': 5567,
      'pid': 5104
    },
    {
      'category': 'Albania',
      'cid': 5582,
      'pid': 5567
    },
    {
      'category': 'Bosnia and Herzegovina',
      'cid': 5151,
      'pid': 5567
    },
    {
      'category': 'Bulgaria',
      'cid': 5107,
      'pid': 5567
    },
    {
      'category': 'Croatia',
      'cid': 5152,
      'pid': 5567
    },
    {
      'category': 'Greece',
      'cid': 5153,
      'pid': 5567
    },
    {
      'category': 'Aegean Islands',
      'cid': 5831,
      'pid': 5153
    },
    {
      'category': 'Athens',
      'cid': 5154,
      'pid': 5153
    },
    {
      'category': 'Crete',
      'cid': 5825,
      'pid': 5153
    },
    {
      'category': 'Kosovo',
      'cid': 5767,
      'pid': 5567
    },
    {
      'category': 'Montenegro',
      'cid': 5768,
      'pid': 5567
    },
    {
      'category': 'Republic of Macedonia',
      'cid': 5584,
      'pid': 5567
    },
    {
      'category': 'Romania',
      'cid': 5118,
      'pid': 5567
    },
    {
      'category': 'Bucharest',
      'cid': 5119,
      'pid': 5118
    },
    {
      'category': 'Serbia',
      'cid': 5169,
      'pid': 5567
    },
    {
      'category': 'Western Europe',
      'cid': 5177,
      'pid': 5104
    },
    {
      'category': 'Andorra',
      'cid': 5583,
      'pid': 5177
    },
    {
      'category': 'Benelux',
      'cid': 5534,
      'pid': 5177
    },
    {
      'category': 'Belgium',
      'cid': 5180,
      'pid': 5534
    },
    {
      'category': 'Brussels',
      'cid': 5181,
      'pid': 5180
    },
    {
      'category': 'Luxembourg',
      'cid': 5196,
      'pid': 5534
    },
    {
      'category': 'Netherlands',
      'cid': 5197,
      'pid': 5534
    },
    {
      'category': 'Amsterdam',
      'cid': 5198,
      'pid': 5197
    },
    {
      'category': 'Rotterdam',
      'cid': 5199,
      'pid': 5197
    },
    {
      'category': 'France',
      'cid': 5182,
      'pid': 5177
    },
    {
      'category': 'Central France',
      'cid': 5533,
      'pid': 5182
    },
    {
      'category': 'Auvergne',
      'cid': 5680,
      'pid': 5533
    },
    {
      'category': 'Centre',
      'cid': 5681,
      'pid': 5533
    },
    {
      'category': 'Limousin',
      'cid': 5682,
      'pid': 5533
    },
    {
      'category': 'East France',
      'cid': 5531,
      'pid': 5182
    },
    {
      'category': 'Alsace',
      'cid': 5683,
      'pid': 5531
    },
    {
      'category': 'Strasbourg',
      'cid': 5610,
      'pid': 5683
    },
    {
      'category': 'Burgundy',
      'cid': 5684,
      'pid': 5531
    },
    {
      'category': 'Champagne-Ardenne',
      'cid': 5685,
      'pid': 5531
    },
    {
      'category': 'Franche-Comté',
      'cid': 5686,
      'pid': 5531
    },
    {
      'category': 'Lorraine',
      'cid': 5687,
      'pid': 5531
    },
    {
      'category': 'French Overseas (DOM-TOM)',
      'cid': 5608,
      'pid': 5182
    },
    {
      'category': 'North France',
      'cid': 5532,
      'pid': 5182
    },
    {
      'category': 'Lower Normandy',
      'cid': 5688,
      'pid': 5532
    },
    {
      'category': 'Nord-Pas-de-Calais',
      'cid': 5690,
      'pid': 5532
    },
    {
      'category': 'Lille',
      'cid': 5611,
      'pid': 5690
    },
    {
      'category': 'Picardy',
      'cid': 5691,
      'pid': 5532
    },
    {
      'category': 'Upper Normandy',
      'cid': 5689,
      'pid': 5532
    },
    {
      'category': 'South East France',
      'cid': 5528,
      'pid': 5182
    },
    {
      'category': 'Corsica',
      'cid': 5617,
      'pid': 5528
    },
    {
      'category': 'Provence-Alpes-Côte d\'Azur',
      'cid': 5692,
      'pid': 5528
    },
    {
      'category': 'Marseille',
      'cid': 5184,
      'pid': 5692
    },
    {
      'category': 'Nice',
      'cid': 5185,
      'pid': 5692
    },
    {
      'category': 'Rhône-Alpes',
      'cid': 5693,
      'pid': 5528
    },
    {
      'category': 'Lyon',
      'cid': 5183,
      'pid': 5693
    },
    {
      'category': 'South West France',
      'cid': 5527,
      'pid': 5182
    },
    {
      'category': 'Aquitaine',
      'cid': 5694,
      'pid': 5527
    },
    {
      'category': 'Bordeaux',
      'cid': 5613,
      'pid': 5694
    },
    {
      'category': 'Languedoc-Roussillon',
      'cid': 5695,
      'pid': 5527
    },
    {
      'category': 'Montpellier',
      'cid': 5612,
      'pid': 5695
    },
    {
      'category': 'Midi-Pyrénées',
      'cid': 5696,
      'pid': 5527
    },
    {
      'category': 'Toulouse',
      'cid': 5187,
      'pid': 5696
    },
    {
      'category': 'West France',
      'cid': 5530,
      'pid': 5182
    },
    {
      'category': 'Brittany',
      'cid': 5697,
      'pid': 5530
    },
    {
      'category': 'Rennes',
      'cid': 5614,
      'pid': 5697
    },
    {
      'category': 'Pays de la Loire',
      'cid': 5698,
      'pid': 5530
    },
    {
      'category': 'Nantes',
      'cid': 5609,
      'pid': 5698
    },
    {
      'category': 'Poitou-Charentes',
      'cid': 5699,
      'pid': 5530
    },
    {
      'category': 'Île-de-France',
      'cid': 5529,
      'pid': 5182
    },
    {
      'category': 'Paris',
      'cid': 5186,
      'pid': 5529
    },
    {
      'category': 'Ireland',
      'cid': 5132,
      'pid': 5177
    },
    {
      'category': 'Dublin',
      'cid': 5133,
      'pid': 5132
    },
    {
      'category': 'United Kingdom',
      'cid': 5140,
      'pid': 5177
    },
    {
      'category': 'England',
      'cid': 5440,
      'pid': 5140
    },
    {
      'category': 'East Midlands',
      'cid': 5447,
      'pid': 5440
    },
    {
      'category': 'East of England',
      'cid': 5446,
      'pid': 5440
    },
    {
      'category': 'London (UK)',
      'cid': 5147,
      'pid': 5440
    },
    {
      'category': 'North East England',
      'cid': 5451,
      'pid': 5440
    },
    {
      'category': 'North West England',
      'cid': 5450,
      'pid': 5440
    },
    {
      'category': 'Liverpool',
      'cid': 5146,
      'pid': 5450
    },
    {
      'category': 'Manchester',
      'cid': 5148,
      'pid': 5450
    },
    {
      'category': 'South East England',
      'cid': 5444,
      'pid': 5440
    },
    {
      'category': 'South West England',
      'cid': 5445,
      'pid': 5440
    },
    {
      'category': 'Bristol',
      'cid': 5142,
      'pid': 5445
    },
    {
      'category': 'West Midlands',
      'cid': 5448,
      'pid': 5440
    },
    {
      'category': 'Birmingham (UK)',
      'cid': 5141,
      'pid': 5448
    },
    {
      'category': 'Yorkshire',
      'cid': 5449,
      'pid': 5440
    },
    {
      'category': 'Leeds-Bradford',
      'cid': 5145,
      'pid': 5449
    },
    {
      'category': 'Sheffield',
      'cid': 5149,
      'pid': 5449
    },
    {
      'category': 'Northern Ireland',
      'cid': 5443,
      'pid': 5140
    },
    {
      'category': 'Scotland',
      'cid': 5441,
      'pid': 5140
    },
    {
      'category': 'Edinburgh',
      'cid': 5143,
      'pid': 5441
    },
    {
      'category': 'Glasgow',
      'cid': 5144,
      'pid': 5441
    },
    {
      'category': 'Wales',
      'cid': 5442,
      'pid': 5140
    },
    {
      'category': 'Latin America',
      'cid': 5203,
      'pid': 5000
    },
    {
      'category': 'Caribbean',
      'cid': 5204,
      'pid': 5203
    },
    {
      'category': 'Antigua & Barbuda',
      'cid': 5550,
      'pid': 5204
    },
    {
      'category': 'Aruba, Bonaire & Curaçao',
      'cid': 5569,
      'pid': 5204
    },
    {
      'category': 'Bahamas',
      'cid': 5360,
      'pid': 5204
    },
    {
      'category': 'Barbados',
      'cid': 5559,
      'pid': 5204
    },
    {
      'category': 'Cayman Islands',
      'cid': 5570,
      'pid': 5204
    },
    {
      'category': 'Cuba',
      'cid': 5205,
      'pid': 5204
    },
    {
      'category': 'Dominica',
      'cid': 5560,
      'pid': 5204
    },
    {
      'category': 'Dominican Republic',
      'cid': 5206,
      'pid': 5204
    },
    {
      'category': 'Grenada',
      'cid': 5561,
      'pid': 5204
    },
    {
      'category': 'Guadeloupe',
      'cid': 5700,
      'pid': 5204
    },
    {
      'category': 'Haiti',
      'cid': 5562,
      'pid': 5204
    },
    {
      'category': 'Jamaica',
      'cid': 5439,
      'pid': 5204
    },
    {
      'category': 'Martinique',
      'cid': 5701,
      'pid': 5204
    },
    {
      'category': 'Puerto Rico',
      'cid': 5207,
      'pid': 5204
    },
    {
      'category': 'Saint Kitts & Nevis',
      'cid': 5563,
      'pid': 5204
    },
    {
      'category': 'Saint Lucia',
      'cid': 5564,
      'pid': 5204
    },
    {
      'category': 'Saint Vincent & Grenadines',
      'cid': 5572,
      'pid': 5204
    },
    {
      'category': 'Trinidad & Tobago',
      'cid': 5573,
      'pid': 5204
    },
    {
      'category': 'Virgin Islands',
      'cid': 5571,
      'pid': 5204
    },
    {
      'category': 'Central America',
      'cid': 5208,
      'pid': 5203
    },
    {
      'category': 'Belize',
      'cid': 5549,
      'pid': 5208
    },
    {
      'category': 'Costa Rica',
      'cid': 5209,
      'pid': 5208
    },
    {
      'category': 'El Salvador',
      'cid': 5548,
      'pid': 5208
    },
    {
      'category': 'Guatemala',
      'cid': 5547,
      'pid': 5208
    },
    {
      'category': 'Honduras',
      'cid': 5546,
      'pid': 5208
    },
    {
      'category': 'Mexico',
      'cid': 5210,
      'pid': 5208
    },
    {
      'category': 'Baja California',
      'cid': 5792,
      'pid': 5210
    },
    {
      'category': 'Los Cabos',
      'cid': 5813,
      'pid': 5792
    },
    {
      'category': 'Tijuana',
      'cid': 5214,
      'pid': 5792
    },
    {
      'category': 'Central Mexico',
      'cid': 5797,
      'pid': 5210
    },
    {
      'category': 'Cuernavaca (Morelos)',
      'cid': 5814,
      'pid': 5797
    },
    {
      'category': 'Guanajuato',
      'cid': 5801,
      'pid': 5797
    },
    {
      'category': 'Mexico City',
      'cid': 5212,
      'pid': 5797
    },
    {
      'category': 'Mexico State',
      'cid': 5798,
      'pid': 5797
    },
    {
      'category': 'Puebla',
      'cid': 5800,
      'pid': 5797
    },
    {
      'category': 'Veracruz',
      'cid': 5799,
      'pid': 5797
    },
    {
      'category': 'Northern Mexico',
      'cid': 5793,
      'pid': 5210
    },
    {
      'category': 'Chihuahua',
      'cid': 5795,
      'pid': 5793
    },
    {
      'category': 'Nuevo León',
      'cid': 5794,
      'pid': 5793
    },
    {
      'category': 'Monterrey',
      'cid': 5213,
      'pid': 5794
    },
    {
      'category': 'Tamaulipas',
      'cid': 5796,
      'pid': 5793
    },
    {
      'category': 'Pacific Coast (Mexico)',
      'cid': 5802,
      'pid': 5210
    },
    {
      'category': 'Chiapas',
      'cid': 5806,
      'pid': 5802
    },
    {
      'category': 'Guerrero',
      'cid': 5803,
      'pid': 5802
    },
    {
      'category': 'Acapulco',
      'cid': 5815,
      'pid': 5803
    },
    {
      'category': 'Jalisco',
      'cid': 5805,
      'pid': 5802
    },
    {
      'category': 'Guadalajara',
      'cid': 5211,
      'pid': 5805
    },
    {
      'category': 'Puerto Vallarta',
      'cid': 5816,
      'pid': 5805
    },
    {
      'category': 'Michoacán',
      'cid': 5807,
      'pid': 5802
    },
    {
      'category': 'Oaxaca',
      'cid': 5804,
      'pid': 5802
    },
    {
      'category': 'Sinaloa',
      'cid': 5808,
      'pid': 5802
    },
    {
      'category': 'Yucatán Peninsula',
      'cid': 5809,
      'pid': 5210
    },
    {
      'category': 'Quintana Roo',
      'cid': 5817,
      'pid': 5809
    },
    {
      'category': 'Cancún & Riviera Maya',
      'cid': 5819,
      'pid': 5817
    },
    {
      'category': 'Yucatán',
      'cid': 5818,
      'pid': 5809
    },
    {
      'category': 'Mérida',
      'cid': 5820,
      'pid': 5818
    },
    {
      'category': 'Nicaragua',
      'cid': 5545,
      'pid': 5208
    },
    {
      'category': 'Panama',
      'cid': 5215,
      'pid': 5208
    },
    {
      'category': 'South America',
      'cid': 5216,
      'pid': 5203
    },
    {
      'category': 'Argentina',
      'cid': 5217,
      'pid': 5216
    },
    {
      'category': 'Buenos Aires',
      'cid': 5218,
      'pid': 5217
    },
    {
      'category': 'Bolivia',
      'cid': 5540,
      'pid': 5216
    },
    {
      'category': 'Brazil',
      'cid': 5219,
      'pid': 5216
    },
    {
      'category': 'Central West Brazil',
      'cid': 5526,
      'pid': 5219
    },
    {
      'category': 'Brasilia',
      'cid': 5221,
      'pid': 5526
    },
    {
      'category': 'Goiás',
      'cid': 5774,
      'pid': 5526
    },
    {
      'category': 'Mato Grosso',
      'cid': 5786,
      'pid': 5526
    },
    {
      'category': 'Mato Grosso do Sul',
      'cid': 5787,
      'pid': 5526
    },
    {
      'category': 'North Brazil',
      'cid': 5522,
      'pid': 5219
    },
    {
      'category': 'Amazonas',
      'cid': 5788,
      'pid': 5522
    },
    {
      'category': 'Pará',
      'cid': 5789,
      'pid': 5522
    },
    {
      'category': 'Rondônia',
      'cid': 5790,
      'pid': 5522
    },
    {
      'category': 'Tocantins',
      'cid': 5791,
      'pid': 5522
    },
    {
      'category': 'Northeast Brazil',
      'cid': 5523,
      'pid': 5219
    },
    {
      'category': 'Alagoas',
      'cid': 5778,
      'pid': 5523
    },
    {
      'category': 'Bahia',
      'cid': 5631,
      'pid': 5523
    },
    {
      'category': 'Salvador',
      'cid': 5226,
      'pid': 5631
    },
    {
      'category': 'Ceará',
      'cid': 5779,
      'pid': 5523
    },
    {
      'category': 'Fortaleza',
      'cid': 5222,
      'pid': 5779
    },
    {
      'category': 'Maranhão',
      'cid': 5780,
      'pid': 5523
    },
    {
      'category': 'Paraíba',
      'cid': 5781,
      'pid': 5523
    },
    {
      'category': 'Pernambuco',
      'cid': 5782,
      'pid': 5523
    },
    {
      'category': 'Recife',
      'cid': 5224,
      'pid': 5782
    },
    {
      'category': 'Piauí',
      'cid': 5783,
      'pid': 5523
    },
    {
      'category': 'Rio Grande do Norte',
      'cid': 5784,
      'pid': 5523
    },
    {
      'category': 'Sergipe',
      'cid': 5785,
      'pid': 5523
    },
    {
      'category': 'South Brazil',
      'cid': 5524,
      'pid': 5219
    },
    {
      'category': 'Paraná',
      'cid': 5627,
      'pid': 5524
    },
    {
      'category': 'Rio Grande do Sul',
      'cid': 5630,
      'pid': 5524
    },
    {
      'category': 'Porto Alegre',
      'cid': 5223,
      'pid': 5630
    },
    {
      'category': 'Santa Catarina',
      'cid': 5629,
      'pid': 5524
    },
    {
      'category': 'Southeast Brazil',
      'cid': 5525,
      'pid': 5219
    },
    {
      'category': 'Espirito Santo',
      'cid': 5777,
      'pid': 5525
    },
    {
      'category': 'Minas Gerais',
      'cid': 5632,
      'pid': 5525
    },
    {
      'category': 'Belo Horizonte',
      'cid': 5220,
      'pid': 5632
    },
    {
      'category': 'Rio de Janeiro',
      'cid': 5225,
      'pid': 5525
    },
    {
      'category': 'São Paulo',
      'cid': 5227,
      'pid': 5525
    },
    {
      'category': 'Chile',
      'cid': 5228,
      'pid': 5216
    },
    {
      'category': 'Santiago',
      'cid': 5229,
      'pid': 5228
    },
    {
      'category': 'Colombia',
      'cid': 5230,
      'pid': 5216
    },
    {
      'category': 'Bogota',
      'cid': 5231,
      'pid': 5230
    },
    {
      'category': 'Ecuador',
      'cid': 5536,
      'pid': 5216
    },
    {
      'category': 'Galapagos Islands',
      'cid': 5832,
      'pid': 5536
    },
    {
      'category': 'Quito',
      'cid': 5833,
      'pid': 5536
    },
    {
      'category': 'French Guiana',
      'cid': 5544,
      'pid': 5216
    },
    {
      'category': 'Guyana',
      'cid': 5543,
      'pid': 5216
    },
    {
      'category': 'Paraguay',
      'cid': 5541,
      'pid': 5216
    },
    {
      'category': 'Peru',
      'cid': 5232,
      'pid': 5216
    },
    {
      'category': 'Lima',
      'cid': 5233,
      'pid': 5232
    },
    {
      'category': 'Suriname',
      'cid': 5542,
      'pid': 5216
    },
    {
      'category': 'Uruguay',
      'cid': 5234,
      'pid': 5216
    },
    {
      'category': 'Venezuela',
      'cid': 5235,
      'pid': 5216
    },
    {
      'category': 'Caracas',
      'cid': 5236,
      'pid': 5235
    },
    {
      'category': 'Middle East',
      'cid': 5588,
      'pid': 5000
    },
    {
      'category': 'North America',
      'cid': 5237,
      'pid': 5000
    },
    {
      'category': 'Bermuda',
      'cid': 5354,
      'pid': 5237
    },
    {
      'category': 'Canada',
      'cid': 5238,
      'pid': 5237
    },
    {
      'category': 'Atlantic Provinces',
      'cid': 5239,
      'pid': 5238
    },
    {
      'category': 'New Brunswick',
      'cid': 5596,
      'pid': 5239
    },
    {
      'category': 'Newfoundland & Labrador',
      'cid': 5598,
      'pid': 5239
    },
    {
      'category': 'Nova Scotia',
      'cid': 5597,
      'pid': 5239
    },
    {
      'category': 'Halifax',
      'cid': 5240,
      'pid': 5597
    },
    {
      'category': 'Prince Edward Island',
      'cid': 5599,
      'pid': 5239
    },
    {
      'category': 'British Columbia',
      'cid': 5241,
      'pid': 5238
    },
    {
      'category': 'Vancouver',
      'cid': 5242,
      'pid': 5241
    },
    {
      'category': 'Victoria (BC)',
      'cid': 5243,
      'pid': 5241
    },
    {
      'category': 'Canadian Prairies',
      'cid': 5244,
      'pid': 5238
    },
    {
      'category': 'Alberta',
      'cid': 5600,
      'pid': 5244
    },
    {
      'category': 'Calgary',
      'cid': 5245,
      'pid': 5600
    },
    {
      'category': 'Edmonton',
      'cid': 5246,
      'pid': 5600
    },
    {
      'category': 'Manitoba',
      'cid': 5602,
      'pid': 5244
    },
    {
      'category': 'Winnipeg',
      'cid': 5247,
      'pid': 5602
    },
    {
      'category': 'Saskatchewan',
      'cid': 5601,
      'pid': 5244
    },
    {
      'category': 'Northern Territories',
      'cid': 5248,
      'pid': 5238
    },
    {
      'category': 'Ontario',
      'cid': 5249,
      'pid': 5238
    },
    {
      'category': 'Greater Toronto',
      'cid': 5253,
      'pid': 5249
    },
    {
      'category': 'Hamilton-Niagara Peninsula',
      'cid': 5250,
      'pid': 5249
    },
    {
      'category': 'Ottawa-Gatineau',
      'cid': 5252,
      'pid': 5249
    },
    {
      'category': 'Southwestern Ontario',
      'cid': 5251,
      'pid': 5249
    },
    {
      'category': 'Quebec',
      'cid': 5254,
      'pid': 5238
    },
    {
      'category': 'Montreal',
      'cid': 5255,
      'pid': 5254
    },
    {
      'category': 'Quebec City',
      'cid': 5256,
      'pid': 5254
    },
    {
      'category': 'USA',
      'cid': 5257,
      'pid': 5237
    },
    {
      'category': 'Alaska',
      'cid': 5258,
      'pid': 5257
    },
    {
      'category': 'California',
      'cid': 5259,
      'pid': 5257
    },
    {
      'category': 'California Central Coast',
      'cid': 5669,
      'pid': 5259
    },
    {
      'category': 'Lake Tahoe',
      'cid': 5474,
      'pid': 5259
    },
    {
      'category': 'Sacramento Valley',
      'cid': 5263,
      'pid': 5259
    },
    {
      'category': 'San Francisco Bay Area',
      'cid': 5265,
      'pid': 5259
    },
    {
      'category': 'San Joaquin Valley',
      'cid': 5260,
      'pid': 5259
    },
    {
      'category': 'Silicon Valley',
      'cid': 5266,
      'pid': 5259
    },
    {
      'category': 'Southern California',
      'cid': 5535,
      'pid': 5259
    },
    {
      'category': 'Inland Empire',
      'cid': 5262,
      'pid': 5535
    },
    {
      'category': 'Los Angeles',
      'cid': 5261,
      'pid': 5535
    },
    {
      'category': 'Orange County (CA)',
      'cid': 5475,
      'pid': 5535
    },
    {
      'category': 'San Diego',
      'cid': 5264,
      'pid': 5535
    },
    {
      'category': 'Florida',
      'cid': 5267,
      'pid': 5257
    },
    {
      'category': 'Central Florida',
      'cid': 5624,
      'pid': 5267
    },
    {
      'category': 'Deltona-Daytona Beach',
      'cid': 5642,
      'pid': 5624
    },
    {
      'category': 'Greater Orlando',
      'cid': 5270,
      'pid': 5624
    },
    {
      'category': 'Tampa Bay Area',
      'cid': 5271,
      'pid': 5624
    },
    {
      'category': 'Florida Panhandle',
      'cid': 5622,
      'pid': 5267
    },
    {
      'category': 'Tallahassee',
      'cid': 5824,
      'pid': 5622
    },
    {
      'category': 'North Florida',
      'cid': 5623,
      'pid': 5267
    },
    {
      'category': 'Jacksonville',
      'cid': 5268,
      'pid': 5623
    },
    {
      'category': 'Southern Florida',
      'cid': 5638,
      'pid': 5267
    },
    {
      'category': 'Florida Keys',
      'cid': 5822,
      'pid': 5638
    },
    {
      'category': 'Fort Myers-Cape Coral',
      'cid': 5823,
      'pid': 5638
    },
    {
      'category': 'South Florida Metro',
      'cid': 5625,
      'pid': 5638
    },
    {
      'category': 'Broward-Fort Lauderdale',
      'cid': 5641,
      'pid': 5625
    },
    {
      'category': 'Miami-Dade',
      'cid': 5269,
      'pid': 5625
    },
    {
      'category': 'West Palm Beach-Boca Raton',
      'cid': 5272,
      'pid': 5625
    },
    {
      'category': 'Great Plains (USA)',
      'cid': 5273,
      'pid': 5257
    },
    {
      'category': 'Kansas',
      'cid': 5462,
      'pid': 5273
    },
    {
      'category': 'Nebraska',
      'cid': 5470,
      'pid': 5273
    },
    {
      'category': 'North Dakota',
      'cid': 5471,
      'pid': 5273
    },
    {
      'category': 'Oklahoma',
      'cid': 5426,
      'pid': 5273
    },
    {
      'category': 'Oklahoma City',
      'cid': 5274,
      'pid': 5426
    },
    {
      'category': 'Tulsa',
      'cid': 5275,
      'pid': 5426
    },
    {
      'category': 'South Dakota',
      'cid': 5472,
      'pid': 5273
    },
    {
      'category': 'Hawaii',
      'cid': 5276,
      'pid': 5257
    },
    {
      'category': 'Big Island (Hawaii)',
      'cid': 5828,
      'pid': 5276
    },
    {
      'category': 'Kauai',
      'cid': 5829,
      'pid': 5276
    },
    {
      'category': 'Maui',
      'cid': 5827,
      'pid': 5276
    },
    {
      'category': 'Oahu',
      'cid': 5826,
      'pid': 5276
    },
    {
      'category': 'Mid-Atlantic (USA)',
      'cid': 5277,
      'pid': 5257
    },
    {
      'category': 'Delaware',
      'cid': 5463,
      'pid': 5277
    },
    {
      'category': 'Maryland',
      'cid': 5427,
      'pid': 5277
    },
    {
      'category': 'Baltimore Metro',
      'cid': 5279,
      'pid': 5427
    },
    {
      'category': 'New Jersey',
      'cid': 5411,
      'pid': 5277
    },
    {
      'category': 'New York State',
      'cid': 5409,
      'pid': 5277
    },
    {
      'category': 'Albany-Capital District',
      'cid': 5278,
      'pid': 5409
    },
    {
      'category': 'Buffalo-Niagara Falls',
      'cid': 5280,
      'pid': 5409
    },
    {
      'category': 'New York City',
      'cid': 5282,
      'pid': 5409
    },
    {
      'category': 'Rochester-Finger Lakes',
      'cid': 5285,
      'pid': 5409
    },
    {
      'category': 'Pennsylvania',
      'cid': 5410,
      'pid': 5277
    },
    {
      'category': 'Harrisburg-South Central PA',
      'cid': 5281,
      'pid': 5410
    },
    {
      'category': 'Philadelphia',
      'cid': 5283,
      'pid': 5410
    },
    {
      'category': 'Pittsburgh',
      'cid': 5284,
      'pid': 5410
    },
    {
      'category': 'Washington DC',
      'cid': 5286,
      'pid': 5277
    },
    {
      'category': 'Midwest (USA)',
      'cid': 5287,
      'pid': 5257
    },
    {
      'category': 'Illinois',
      'cid': 5428,
      'pid': 5287
    },
    {
      'category': 'Chicago',
      'cid': 5288,
      'pid': 5428
    },
    {
      'category': 'Indiana',
      'cid': 5429,
      'pid': 5287
    },
    {
      'category': 'Indianapolis',
      'cid': 5294,
      'pid': 5429
    },
    {
      'category': 'Iowa',
      'cid': 5458,
      'pid': 5287
    },
    {
      'category': 'Michigan',
      'cid': 5413,
      'pid': 5287
    },
    {
      'category': 'Detroit',
      'cid': 5292,
      'pid': 5413
    },
    {
      'category': 'Grand Rapids-West Michigan',
      'cid': 5293,
      'pid': 5413
    },
    {
      'category': 'Minnesota',
      'cid': 5431,
      'pid': 5287
    },
    {
      'category': 'Minneapolis-Saint Paul',
      'cid': 5297,
      'pid': 5431
    },
    {
      'category': 'Missouri',
      'cid': 5414,
      'pid': 5287
    },
    {
      'category': 'Greater St. Louis',
      'cid': 5298,
      'pid': 5414
    },
    {
      'category': 'Kansas City',
      'cid': 5295,
      'pid': 5414
    },
    {
      'category': 'Ohio',
      'cid': 5412,
      'pid': 5287
    },
    {
      'category': 'Cincinnati',
      'cid': 5289,
      'pid': 5412
    },
    {
      'category': 'Columbus (OH)',
      'cid': 5291,
      'pid': 5412
    },
    {
      'category': 'Greater Cleveland',
      'cid': 5290,
      'pid': 5412
    },
    {
      'category': 'Wisconsin',
      'cid': 5430,
      'pid': 5287
    },
    {
      'category': 'Milwaukee',
      'cid': 5296,
      'pid': 5430
    },
    {
      'category': 'New England',
      'cid': 5299,
      'pid': 5257
    },
    {
      'category': 'Connecticut',
      'cid': 5423,
      'pid': 5299
    },
    {
      'category': 'Greater Hartford',
      'cid': 5301,
      'pid': 5423
    },
    {
      'category': 'New Haven',
      'cid': 5670,
      'pid': 5423
    },
    {
      'category': 'Maine',
      'cid': 5459,
      'pid': 5299
    },
    {
      'category': 'Massachusetts',
      'cid': 5424,
      'pid': 5299
    },
    {
      'category': 'Boston Metro',
      'cid': 5300,
      'pid': 5424
    },
    {
      'category': 'New Hampshire',
      'cid': 5461,
      'pid': 5299
    },
    {
      'category': 'Rhode Island',
      'cid': 5425,
      'pid': 5299
    },
    {
      'category': 'Providence',
      'cid': 5302,
      'pid': 5425
    },
    {
      'category': 'Vermont',
      'cid': 5460,
      'pid': 5299
    },
    {
      'category': 'Pacific Northwest',
      'cid': 5303,
      'pid': 5257
    },
    {
      'category': 'Oregon',
      'cid': 5433,
      'pid': 5303
    },
    {
      'category': 'Portland (OR)',
      'cid': 5304,
      'pid': 5433
    },
    {
      'category': 'Washington State',
      'cid': 5432,
      'pid': 5303
    },
    {
      'category': 'Seattle-Tacoma',
      'cid': 5305,
      'pid': 5432
    },
    {
      'category': 'Rocky Mountains',
      'cid': 5306,
      'pid': 5257
    },
    {
      'category': 'Colorado',
      'cid': 5434,
      'pid': 5306
    },
    {
      'category': 'Denver',
      'cid': 5307,
      'pid': 5434
    },
    {
      'category': 'Idaho',
      'cid': 5466,
      'pid': 5306
    },
    {
      'category': 'Montana',
      'cid': 5465,
      'pid': 5306
    },
    {
      'category': 'Wyoming',
      'cid': 5464,
      'pid': 5306
    },
    {
      'category': 'South (USA)',
      'cid': 5308,
      'pid': 5257
    },
    {
      'category': 'Alabama',
      'cid': 5422,
      'pid': 5308
    },
    {
      'category': 'Birmingham (AL)',
      'cid': 5311,
      'pid': 5422
    },
    {
      'category': 'Arkansas',
      'cid': 5467,
      'pid': 5308
    },
    {
      'category': 'Georgia (USA)',
      'cid': 5420,
      'pid': 5308
    },
    {
      'category': 'Atlanta',
      'cid': 5309,
      'pid': 5420
    },
    {
      'category': 'Kentucky',
      'cid': 5421,
      'pid': 5308
    },
    {
      'category': 'Louisville',
      'cid': 5315,
      'pid': 5421
    },
    {
      'category': 'Louisiana',
      'cid': 5417,
      'pid': 5308
    },
    {
      'category': 'Baton Rouge',
      'cid': 5310,
      'pid': 5417
    },
    {
      'category': 'New Orleans',
      'cid': 5318,
      'pid': 5417
    },
    {
      'category': 'Mississippi',
      'cid': 5469,
      'pid': 5308
    },
    {
      'category': 'North Carolina',
      'cid': 5416,
      'pid': 5308
    },
    {
      'category': 'Charlotte Metro',
      'cid': 5312,
      'pid': 5416
    },
    {
      'category': 'Piedmont Triad',
      'cid': 5313,
      'pid': 5416
    },
    {
      'category': 'Raleigh-Durham',
      'cid': 5320,
      'pid': 5416
    },
    {
      'category': 'South Carolina',
      'cid': 5418,
      'pid': 5308
    },
    {
      'category': 'Greater Charleston (SC)',
      'cid': 5618,
      'pid': 5418
    },
    {
      'category': 'Myrtle Beach & Grand Strand',
      'cid': 5821,
      'pid': 5418
    },
    {
      'category': 'Upstate South Carolina',
      'cid': 5314,
      'pid': 5418
    },
    {
      'category': 'Tennessee',
      'cid': 5419,
      'pid': 5308
    },
    {
      'category': 'Memphis',
      'cid': 5316,
      'pid': 5419
    },
    {
      'category': 'Nashville',
      'cid': 5317,
      'pid': 5419
    },
    {
      'category': 'Virginia',
      'cid': 5415,
      'pid': 5308
    },
    {
      'category': 'Richmond-Petersburg',
      'cid': 5321,
      'pid': 5415
    },
    {
      'category': 'Virginia Beach-Hampton Roads',
      'cid': 5319,
      'pid': 5415
    },
    {
      'category': 'West Virginia',
      'cid': 5468,
      'pid': 5308
    },
    {
      'category': 'Southwest (USA)',
      'cid': 5322,
      'pid': 5257
    },
    {
      'category': 'Arizona',
      'cid': 5435,
      'pid': 5322
    },
    {
      'category': 'Phoenix',
      'cid': 5325,
      'pid': 5435
    },
    {
      'category': 'Tucson',
      'cid': 5328,
      'pid': 5435
    },
    {
      'category': 'Nevada',
      'cid': 5436,
      'pid': 5322
    },
    {
      'category': 'Las Vegas',
      'cid': 5324,
      'pid': 5436
    },
    {
      'category': 'Reno-Sparks',
      'cid': 5326,
      'pid': 5436
    },
    {
      'category': 'New Mexico',
      'cid': 5438,
      'pid': 5322
    },
    {
      'category': 'Albuquerque-Santa Fe',
      'cid': 5323,
      'pid': 5438
    },
    {
      'category': 'Utah',
      'cid': 5437,
      'pid': 5322
    },
    {
      'category': 'Salt Lake City',
      'cid': 5327,
      'pid': 5437
    },
    {
      'category': 'Texas',
      'cid': 5329,
      'pid': 5257
    },
    {
      'category': 'Austin-Round Rock',
      'cid': 5330,
      'pid': 5329
    },
    {
      'category': 'Dallas-Fort Worth',
      'cid': 5331,
      'pid': 5329
    },
    {
      'category': 'El Paso',
      'cid': 5332,
      'pid': 5329
    },
    {
      'category': 'Houston',
      'cid': 5333,
      'pid': 5329
    },
    {
      'category': 'San Antonio',
      'cid': 5334,
      'pid': 5329
    },
    {
      'category': 'Oceania',
      'cid': 5335,
      'pid': 5000
    },
    {
      'category': 'Australia',
      'cid': 5336,
      'pid': 5335
    },
    {
      'category': 'New South Wales',
      'cid': 5452,
      'pid': 5336
    },
    {
      'category': 'Canberra',
      'cid': 5339,
      'pid': 5452
    },
    {
      'category': 'Newcastle (AU)',
      'cid': 5342,
      'pid': 5452
    },
    {
      'category': 'Sydney',
      'cid': 5344,
      'pid': 5452
    },
    {
      'category': 'Northern Territory',
      'cid': 5457,
      'pid': 5336
    },
    {
      'category': 'Queensland',
      'cid': 5453,
      'pid': 5336
    },
    {
      'category': 'Brisbane',
      'cid': 5338,
      'pid': 5453
    },
    {
      'category': 'Cairns',
      'cid': 5357,
      'pid': 5453
    },
    {
      'category': 'Gold Coast',
      'cid': 5340,
      'pid': 5453
    },
    {
      'category': 'South Australia',
      'cid': 5456,
      'pid': 5336
    },
    {
      'category': 'Adelaide',
      'cid': 5337,
      'pid': 5456
    },
    {
      'category': 'Tasmania',
      'cid': 5356,
      'pid': 5336
    },
    {
      'category': 'Victoria (AU)',
      'cid': 5454,
      'pid': 5336
    },
    {
      'category': 'Melbourne',
      'cid': 5341,
      'pid': 5454
    },
    {
      'category': 'Western Australia',
      'cid': 5455,
      'pid': 5336
    },
    {
      'category': 'Perth',
      'cid': 5343,
      'pid': 5455
    },
    {
      'category': 'New Zealand',
      'cid': 5350,
      'pid': 5335
    },
    {
      'category': 'Auckland',
      'cid': 5351,
      'pid': 5350
    },
    {
      'category': 'Christchurch',
      'cid': 5359,
      'pid': 5350
    },
    {
      'category': 'Wellington',
      'cid': 5358,
      'pid': 5350
    },
    {
      'category': 'Pacific Islands',
      'cid': 5589,
      'pid': 5335
    },
    {
      'category': 'Melanesia',
      'cid': 5345,
      'pid': 5589
    },
    {
      'category': 'Fiji',
      'cid': 5346,
      'pid': 5345
    },
    {
      'category': 'Papua New Guinea',
      'cid': 5347,
      'pid': 5345
    },
    {
      'category': 'Micronesia',
      'cid': 5348,
      'pid': 5589
    },
    {
      'category': 'Guam',
      'cid': 5349,
      'pid': 5348
    },
    {
      'category': 'Polynesia',
      'cid': 5352,
      'pid': 5589
    },
    {
      'category': 'Tahiti & Bora Bora',
      'cid': 5568,
      'pid': 5352
    },
    {
      'category': 'Polar Regions',
      'cid': 5353,
      'pid': 5000
    }
  ];

  dimensionList = [
    {
      'width': 375,
      'height': 50
    },
    {
      'width': 414,
      'height': 736
    },
    {
      'width': 736,
      'height': 414
    },
    {
      'width': 468,
      'height': 60
    },
    {
      'width': 728,
      'height': 90
    },
    {
      'width': 1024,
      'height': 90
    },
    {
      'width': 970,
      'height': 90
    },
    {
      'width': 980,
      'height': 120
    },
    {
      'width': 930,
      'height': 180
    },
    {
      'width': 250,
      'height': 250
    },
    {
      'width': 200,
      'height': 200
    },
    {
      'width': 336,
      'height': 280
    },
    {
      'width': 300,
      'height': 250
    },
    {
      'width': 970,
      'height': 250
    },
    {
      'width': 120,
      'height': 600
    },
    {
      'width': 160,
      'height': 600
    },
    {
      'width': 425,
      'height': 600
    },
    {
      'width': 300,
      'height': 600
    },
    {
      'width': 320,
      'height': 50
    },
    {
      'width': 300,
      'height': 50
    },
    {
      'width': 240,
      'height': 400
    },
    {
      'width': 250,
      'height': 360
    },
    {
      'width': 480,
      'height': 32
    },
    {
      'width': 300,
      'height': 1050
    },
    {
      'width': 768,
      'height': 1024
    },
    {
      'width': 1024,
      'height': 768
    },
    {
      'width': 580,
      'height': 400
    },
    {
      'width': 480,
      'height': 320
    },
    {
      'width': 320,
      'height': 480
    },
    {
      'width': 300,
      'height': 100
    },
    {
      'width': 750,
      'height': 300
    },
    {
      'width': 750,
      'height': 200
    },
    {
      'width': 750,
      'height': 100
    },
    {
      'width': 950,
      'height': 90
    },
    {
      'width': 88,
      'height': 31
    },
    {
      'width': 220,
      'height': 90
    },
    {
      'width': 300,
      'height': 31
    },
    {
      'width': 320,
      'height': 100
    },
    {
      'width': 980,
      'height': 90
    },
    {
      'width': 240,
      'height': 133
    },
    {
      'width': 200,
      'height': 446
    },
    {
      'width': 292,
      'height': 30
    },
    {
      'width': 960,
      'height': 90
    },
    {
      'width': 970,
      'height': 66
    },
    {
      'width': 300,
      'height': 57
    },
    {
      'width': 120,
      'height': 60
    }
  ];

  languageList = [
    {
      'name': 'English',
      'code': 'en',
      'id': 1000
    },
    {
      'name': 'Arabic',
      'code': 'ar',
      'id': 1019
    },
    {
      'name': 'Bengali',
      'code': 'bn',
      'id': 1056
    },
    {
      'name': 'Bulgarian',
      'code': 'bg',
      'id': 1020
    },
    {
      'name': 'Catalan',
      'code': 'ca',
      'id': 1038
    },
    {
      'name': 'Chinese (simplified)',
      'code': 'zh_CN',
      'id': 1017
    },
    {
      'name': 'Chinese (traditional)',
      'code': 'zh_TW',
      'id': 1018
    },
    {
      'name': 'Croatian',
      'code': 'hr',
      'id': 1039
    },
    {
      'name': 'Czech',
      'code': 'cs',
      'id': 1021
    },
    {
      'name': 'Danish',
      'code': 'da',
      'id': 1009
    },
    {
      'name': 'Dutch',
      'code': 'nl',
      'id': 1010
    },
    {
      'name': 'Estonian',
      'code': 'et',
      'id': 1043
    },
    {
      'name': 'Filipino',
      'code': 'tl',
      'id': 1042
    },
    {
      'name': 'Finnish',
      'code': 'fi',
      'id': 1011
    },
    {
      'name': 'French',
      'code': 'fr',
      'id': 1002
    },
    {
      'name': 'German',
      'code': 'de',
      'id': 1001
    },
    {
      'name': 'Greek',
      'code': 'el',
      'id': 1022
    },
    {
      'name': 'Hebrew',
      'code': 'iw',
      'id': 1027
    },
    {
      'name': 'Hindi',
      'code': 'hi',
      'id': 1023
    },
    {
      'name': 'Hungarian',
      'code': 'hu',
      'id': 1024
    },
    {
      'name': 'Icelandic',
      'code': 'is',
      'id': 1026
    },
    {
      'name': 'Indonesian',
      'code': 'id',
      'id': 1025
    },
    {
      'name': 'Italian',
      'code': 'it',
      'id': 1004
    },
    {
      'name': 'Japanese',
      'code': 'ja',
      'id': 1005
    },
    {
      'name': 'Korean',
      'code': 'ko',
      'id': 1012
    },
    {
      'name': 'Latvian',
      'code': 'lv',
      'id': 1028
    },
    {
      'name': 'Lithuanian',
      'code': 'lt',
      'id': 1029
    },
    {
      'name': 'Malay',
      'code': 'ms',
      'id': 1102
    },
    {
      'name': 'Norwegian',
      'code': 'no',
      'id': 1013
    },
    {
      'name': 'Persian',
      'code': 'fa',
      'id': 1064
    },
    {
      'name': 'Polish',
      'code': 'pl',
      'id': 1030
    },
    {
      'name': 'Portuguese',
      'code': 'pt',
      'id': 1014
    },
    {
      'name': 'Romanian',
      'code': 'ro',
      'id': 1032
    },
    {
      'name': 'Russian',
      'code': 'ru',
      'id': 1031
    },
    {
      'name': 'Serbian',
      'code': 'sr',
      'id': 1035
    },
    {
      'name': 'Slovak',
      'code': 'sk',
      'id': 1033
    },
    {
      'name': 'Slovenian',
      'code': 'sl',
      'id': 1034
    },
    {
      'name': 'Spanish',
      'code': 'es',
      'id': 1003
    },
    {
      'name': 'Swedish',
      'code': 'sv',
      'id': 1015
    },
    {
      'name': 'Tamil',
      'code': 'ta',
      'id': 1130
    },
    {
      'name': 'Telugu',
      'code': 'te',
      'id': 1131
    },
    {
      'name': 'Thai',
      'code': 'th',
      'id': 1044
    },
    {
      'name': 'Turkish',
      'code': 'tr',
      'id': 1037
    },
    {
      'name': 'Ukrainian',
      'code': 'uk',
      'id': 1036
    },
    {
      'name': 'Urdu',
      'code': 'ur',
      'id': 1041
    },
    {
      'name': 'Vietnamese',
      'code': 'vi',
      'id': 1040
    }
  ];

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  Keylur = true;
  hintText = '';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  countries = [];
  keywords = [];
  cInput: string;
  kInput: string;

  countryList = [];

  languages = [];

  isManualScheduling: boolean;
  typeIsText: boolean;
  typeIsVideo: boolean;
  typeIsAudio: boolean;
  typeIsBanner: boolean;
  typeOfAd: [string];

  tablet: boolean;
  computer: boolean;
  phone: boolean;
  smarttv: boolean;

  startCampaignCriteria: string;
  stopCampaignCriteria: string;
  scheduling: string;

  currentStep = 0;

  stepControl = [];

  newCampaignForm = new FormGroup({
    campaignName: new FormControl('', [
      Validators.required,
      Validators
        .pattern('[A-Za-z0-9!@#$%_\^\&amp;\*\-\.\?;:{}()|+=<>\/\',`~ ]*[A-Za-z][A-Za-z0-9!@#$%_\^\&amp;\*\-\.\?;:{}()|+=<>\/\',`~ ]*')
    ]),
    platforms: new FormGroup({
      google: new FormControl(),
      facebook: new FormControl(),
      twitter: new FormControl(),
    }),
    description: new FormControl('', Validators.required),
    objective: new FormControl('', Validators.required)
  });

  inventoryForm = new FormGroup({
    redirectUrl: new FormControl('', [Validators.required, Validators.pattern('([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')])
  });

  personaForm = new FormGroup({
    genders: new FormControl([], Validators.required),
    ages: new FormControl([], Validators.required),
    interestArea: new FormControl(['3'], Validators.required)
  });

  schedulingForm = new FormGroup({
    startDate: new FormControl(Date.now()),
    endDate: new FormControl(),
    schedule: new FormGroup({
      monday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('monday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
      tuesday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('tuesday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
      wednesday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('wednesday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
      thursday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('thursday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
      friday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('friday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
      saturday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('saturday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
      sunday: new FormGroup({
        mode: new FormControl('exclude', [this.allDayValidator('sunday')]),
        startTime: new FormControl('00:00'),
        endTime: new FormControl('23:59')
      }, { validators: this.endTimeValidator }),
    })
  });

  budgetForm = new FormGroup({
    totalBudget: new FormControl('', [Validators.required, Validators.min(0.000001)]),
    dailyBudget: new FormControl('', [Validators.required, Validators.min(0.000001)]),
    deliveryType: new FormControl('', Validators.required),
    limit: new FormGroup({
      scope: new FormControl(''),
      amount: new FormControl(0),
    }),
    bidAmount: new FormControl('', [Validators.required, Validators.min(0)])
  }, { validators: this.budgetValidator });


  headline1 = '';
  headline2 = '';
  headline3 = '';
  path1 = '';
  path2 = '';
  description = '';

  errorMessage = '';
  errorDimensionMessage = '';


  specialInstructions: string;

  files: File[] = [];
  progress: number;
  hasBaseDropZoneOver = false;
  lastFileAt: Date;
  sendableFormData: FormData;
  httpEmitter: Subscription;
  httpEvent: HttpEvent<{}>;
  minDate: Date;
  minDateStop: Date;
  maxDate: Date;

  filesPreview = [];

  imageData = [];

  insights: object;
  isGettingInsights: boolean;

  twitterMessage: boolean;

  constructor(
    private _router: Router,
    private _api: ApiService,
    private _authService: AuthService,
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.minDate = new Date(Date.now());
    this.minDateStop = new Date(Date.now());
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.minDateStop.setDate(this.minDateStop.getDate() + 2);

    this.schedulingForm.valueChanges.subscribe(
      data => {
        if (this.startCampaignCriteria === 'date') {
          this.minDateStop = new Date(this.schedulingForm.value.startDate);
          const date = new Date(this.schedulingForm.value.startDate);
          this.minDateStop.setDate(date.getDate() + 1);
        } else {
          this.minDateStop.setDate(new Date(Date.now()).getDate() + 2);
        }
      }
    );

    this.scheduling = 'false';
    this.stepControl = [
      {
        index: 1,
        completed: false,
        title: 'Campaign Info'
      },
      {
        index: 2,
        completed: false,
        title: 'Campaign Inventory'
      },
      {
        index: 3,
        completed: false,
        title: 'Campaign Persona'
      },
      {
        index: 4,
        completed: false,
        title: 'Scheduling'
      },
      {
        index: 5,
        completed: false,
        title: 'Targeting'
      },
      {
        index: 6,
        completed: false,
        title: 'Budget and Bidding'
      },
      {
        index: 7,
        completed: false,
        title: 'Additional Specifications'
      },
    ];
    this.typeIsText = false;
    this.typeIsVideo = false;
    this.typeIsAudio = false;
    this.typeIsBanner = false;
    this.isManualScheduling = false;

    this.twitterMessage = false;

    this._activatedRoute.queryParams.subscribe(
      query => {
        if (query.id) {
          this.duplicateCampaign(query.id);
        }
      }
    );

    this.newCampaignForm.valueChanges.subscribe(
      data => {
        if (this.newCampaignForm.value.platforms.twitter) {
          const tokenObject = this._authService.getStoredTokenPayload();
          this._api.getUserById(tokenObject['id']).subscribe(
            (user: any) => {
              if (!user['twitter']) {
                this.twitterMessage = true;
              } else {
                this.twitterMessage = false;
              }
            });
        } else {
          this.twitterMessage = false;
        }
      }
    );

  }

  duplicateCampaign(id) {
    this._api.getCampaignById(id).subscribe(
      campaign => {
        this.typeOfAd = campaign['adTypesList'];
        this.typeOfAd.forEach(e => {
          e === 'text' ? this.typeIsText = true : console.log(e);
          e === 'banner' ? this.typeIsBanner = true : console.log(e);
        });
        this._api.getLocationObjects(campaign['target'].countries).subscribe(
          data => {
            for (const key in data) {
              if (data.hasOwnProperty(key)) {
                const element = data[key];
                this.countries.push(element);
                console.log(this.countries);
              }
            }
          }
        );
        if (campaign['adTypesList'].includes('banner')) {
          this.imageData = campaign['adTypes'].banner;
          campaign['adTypes'].banner.forEach(element => {
            this.filesPreview.push({
              url: element.filename,
              name: element.originalname,
              size: element.size,
              type: element.mimetype
            });
          });
        }
        this.newCampaignForm.patchValue(campaign);
        this.inventoryForm.patchValue(campaign);
        this.personaForm.patchValue(campaign['target']);
        this.budgetForm.patchValue(campaign['budget']);
        this.keywords = campaign['keywords'];
        this.languages = campaign['target'].languages;
        this.specialInstructions = campaign['instructions'];
        if (campaign['adTypesList'].includes('text')) {
          this.headline1 = campaign['adTypes'].text.headline1;
          this.headline2 = campaign['adTypes'].text.headline2;
          this.headline3 = campaign['adTypes'].text.headline3;
          this.path1 = campaign['adTypes'].text.path1;
          this.path2 = campaign['adTypes'].text.path2;
          this.description = campaign['adTypes'].text.description;
        }
        this.inventoryForm.patchValue({ redirectUrl: campaign['redirectUrl'].slice(8, campaign['redirectUrl'].length) });
        this.schedulingForm.patchValue({ schedule: campaign['deployment'].scheduling.schedule });
        if (campaign['deployment'].scheduling.custom) {
          this.scheduling = 'true';
        }
        campaign['target'].devices.forEach(element => {
          switch (element) {
            case 'computer':
              this.computer = true;
              break;
            case 'phone':
              this.phone = true;
              break;
            case 'tablet':
              this.tablet = true;
              break;
            case 'smarttv':
              this.smarttv = true;
              break;
          }
        });
      }
    );
  }

  // allDayValidator(group: FormGroup) {
  //   if (group.get('monday.mode').value === 'allDay') {
  //     group.patchValue({
  //       startTime: '00:00',
  //       endTime: '23:59'
  //     });
  //   }
  //   return null;
  // }

  endTimeValidator(group: FormGroup) {
    const start = new Date('1/1/1999 ' + group.get('startTime').value);
    const end = new Date('1/1/1999 ' + group.get('endTime').value);
    if (end <= start) {
      return { endDateSmaller: true };
    }
    return null;
  }

  allDayValidator(day: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === 'allDay' || control.value === 'exclude') {
        this.patVal(day);
        return null;
      } else {
        this.patVal(day, true);
      }
      return null;
    };
  }

  patVal(day, enable = false) {
    const c = this.schedulingForm ? this.schedulingForm.get('schedule.' + day) : null;
    if (c && !enable) {
      c.patchValue({
        startTime: '00:00',
        endTime: '23:59'
      });
      c.get('startTime').disable();
      c.get('endTime').disable();
    }
    if (c && enable) {
      c.get('startTime').enable();
      c.get('endTime').enable();
    }
  }

  budgetValidator(group: FormGroup) {
    const start = group.get('totalBudget').value;
    const end = group.get('bidAmount').value;
    if (end >= start) {
      group.controls['bidAmount'].setErrors({ 'bidAmountLarger': true });
      return { bidAmountLarger: true };
    }
    if (end === 0) {
      group.controls['bidAmount'].setErrors({ 'bidAmountZero': true });
      return { bidAmountLarger: true };
    }
    if (end < 0) {
      group.controls['bidAmount'].setErrors({ 'bidAmountNegative': true });
      return { bidAmountLarger: true };
    }
    return null;
  }

  changeStartCriterion(event) {
    if (this.startCampaignCriteria === 'date') {
      const date = new Date(this.schedulingForm.value.startDate);
      this.minDateStop.setDate(date.getDate() + 1);
    } else {
      this.minDateStop.setDate(new Date(Date.now()).getDate() + 2);
    }
  }

  onCountryKeyup() {
    if (this.cInput.length > 3) {
      this._api.searchLocation(this.cInput).subscribe(
        data => {
          this.countryList = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const element = data[key];
              this.countryList.push(element);
            }
          }
        }
      );
    }
  }

  getDate() {
    this.filesPreview = [];
    this.files.forEach((file, index) => {
      ((element) => {
        let dataUrl;
        const reader = new FileReader();
        const img = new Image();
        img.src = window.URL.createObjectURL(element);
        img.onload = () => {
          dataUrl = reader.result;

          const width = img.naturalWidth;
          const height = img.naturalHeight;

          const dim = {
            'width': width,
            'height': height
          };

          console.log(dim);

          window.URL.revokeObjectURL(img.src);

          if (!this.dimensionList.some(ele => {
            return JSON.stringify(dim) === JSON.stringify(ele);
          })) {
            this.files.splice(this.files.indexOf(element), 1);
            this.errorDimensionMessage = 'The Image is of Invalid Dimensions';
          } else {
            this.errorDimensionMessage = '';
            this.filesPreview.push({
              url: dataUrl,
              name: element.name,
              size: element.size,
              type: element.type
            });
          }
        };
        reader.readAsDataURL(element);
      })(file);
    });

    return new Date();
  }

  removeFile(i) {
    let dataUrl;
    const reader = new FileReader();
    const element = this.files[i];
    reader.onload = (e) => {
      dataUrl = reader.result;
      this.filesPreview.forEach((ele, ind) => {
        if (ele.name === element.name) {
          this.filesPreview.splice(ind, 1);
          this.files.splice(i, 1);
          this.imageData.forEach((el, il) => {
            if (ele.name === el.originalname) {
              this.imageData.splice(il, 1);
            }
          });
        }
      });
      this.filesPreview.filter((val, index, arr) => {
        return val !== {
          url: dataUrl,
          name: element.name,
          size: element.size,
          type: element.type
        };
      });
    };
    reader.readAsDataURL(element);
    console.log(this.imageData);
  }

  uploadFiles(files: File[]) {

    this.imageData = [];

    const myFormData: FormData = new FormData();
    // console.log(this.sendableFormData);
    this.files.forEach(file => myFormData.append('file', file));
    // this._api.uploadFiles(this.sendableFormData);
    const req = new HttpRequest<FormData>('POST', this.apiUrl + 'users/fileupload', myFormData, {
      reportProgress: true // responseType: 'text'
    });

    return this.httpEmitter = this.http.request(req)
      .subscribe(
        event => {
          this.httpEvent = event;

          if (event instanceof HttpResponse) {
            delete this.httpEmitter;

            event.body['response'].forEach(element => {
              this.imageData.push({
                filename: environment.apiUrl + 'images/uploads/' + element.filename,
                originalname: element.originalname,
                mimetype: element.mimetype,
                size: element.size,
                encoding: element.encoding
              });
            });

            console.log('request done', event.body['response']);
          }
        },
        error => {
          console.log('Error Uploading', error);
        }
      );
  }

  nextStep() {
    switch (this.currentStep) {
      case 0:
        if (this.newCampaignForm.valid) {
          if (this.newCampaignForm.value.platforms.google || this.newCampaignForm.value.platforms.facebook ||
            this.newCampaignForm.value.platforms.twitter) {
              if (!this.twitterMessage) {
                if (this.keywords.length > 0) {
                  this.errorMessage = '';
                  console.log(this.newCampaignForm.value);
                  break;
                } else {
                  this.errorMessage = 'Please Enter atleast One Keyword';
                  return;
                }
              } else {
                this.errorMessage = 'Please Authenticate Twitter or Unselect it';
                  return;
              }
          } else {
            this.errorMessage = 'Please select atleast one Target Platform';
            return;
          }
        } else {
          this.errorMessage = 'Please fill all Required Fields';
          return;
        }
        break;
      case 1:
        if (this.typeOfAd.length > 0 && this.inventoryForm.valid) {
          if (this.typeOfAd.includes('text')) {
            if (!(this.headline1.length > 1 && this.headline1.length <= 30 && this.headline2.length > 1 &&
              this.headline2.length <= 30 && this.headline3.length > 1 && this.headline3.length <= 30 &&
              this.description.length > 1 && this.description.length <= 90 && this.path1.length <= 15 &&
              this.path1.length > 1 && this.path2.length > 1 && this.path2.length <= 15)) {
              this.errorMessage = 'Please fill all Required Fields';
              return;
            } else {
              if (!/^[a-zA-Z0-9\-]*$/.test(this.path1) || !/^[a-zA-Z0-9\-]*$/.test(this.path2)) {
                this.errorMessage = 'Path 1 and Path 2 allows only Alpha-Numeric Characters and " - "';
                return;
              }
              if (!/^[^,]*[A-Za-z][^,]+$/g.test(this.headline1)
                || !/^[^,]*[A-Za-z][^,]+$/g.test(this.headline2)
                || !/^[^,]*[A-Za-z][^,]+$/g.test(this.headline3)) {
                this.errorMessage = 'Please use atleast one alphabet in Headlines. Comma(,) is not allowed.';
                return;
              }
              if (!/^(?!.*[\!=\?\@%\^\*;\~\`,(){}\<\>|]{2}).*$/g.test(this.headline1)
                || !/^(?!.*[\!=\?\@%\^\*;\~\`,(){}\<\>|]{2}).*$/g.test(this.headline2)
                || !/^(?!.*[\!=\?\@%\^\*;\~\`,(){}\<\>|]{2}).*$/g.test(this.headline3)) {
                this.errorMessage = 'Two consecutive special characters not allowed in Headlines.';
                return;
              }
            }
          }
          if (this.typeOfAd.includes('banner')) {
            if (!(this.imageData.length > 0)) {
              this.errorMessage = 'Please Upload atleast One Image';
              return;
            }
          }
          this.errorMessage = '';
          break;
        } else {
          this.errorMessage = 'Please select atleast one Ad Type and Enter a Valid Redirect URL';
          return;
        }
        break;
      case 2:
        if (this.personaForm.valid) {
          this.errorMessage = '';
          break;
        } else {
          this.errorMessage = 'Please fill all Fields';
          return;
        }
        break;
      case 3:
        if (this.startCampaignCriteria && this.stopCampaignCriteria) {
          if (this.scheduling === 'true') {
            if (this.schedulingForm.valid) {
              this.errorMessage = '';
              break;
            } else {
              this.errorMessage = 'Please Check Start and Stop Times in Custom Scheduling';
              return;
            }
          } else {
            this.errorMessage = '';
            break;
          }
        } else {
          this.errorMessage = 'Please Specify both Start and Stop Criterion';
          return;
        }
        break;
      case 4:
        if (this.countries.length > 0 && (this.computer || this.phone || this.tablet || this.smarttv) && this.languages.length > 0) {
          this.errorMessage = '';
          break;
        } else {
          this.errorMessage = 'Select atleast one in each field';
          return;
        }
      case 5:
        if (this.budgetForm.valid) {
          this.errorMessage = '';
          break;
        } else {
          this.errorMessage = 'Please check all fields';
          return;
        }
      case 6:
        break;
      default:
        break;
    }
    this.stepControl[this.currentStep].completed = true;
    this.currentStep++;
  }

  stepNav(i: number) {
    let allDone = true;
    for (let x = i; x >= 0; x--) {
      const element = this.stepControl[x];
      if (!element.completed) {
        allDone = false;
      }
    }
    if (allDone) {
      this.currentStep = i;
    }

  }

  previousStep() {
    this.stepControl[this.currentStep].completed = false;
    this.stepControl[this.currentStep - 1].completed = false;
    this.currentStep--;
  }

  changeTypeOfAd() {
    console.log(this.typeOfAd);
    if (this.typeOfAd.includes('audio')) {
      this.typeIsAudio = true;
    } else {
      this.typeIsAudio = false;
    }
    if (this.typeOfAd.includes('video')) {
      this.typeIsVideo = true;
    } else {
      this.typeIsVideo = false;
    }
    if (this.typeOfAd.includes('banner')) {
      this.typeIsBanner = true;
    } else {
      this.typeIsBanner = false;
    }
    if (this.typeOfAd.includes('text')) {
      this.typeIsText = true;
    } else {
      this.typeIsText = false;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.countries.push(value.trim());
      console.log(this.countries);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  pasteKeyword(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n/)
      .forEach(value => {
        if ((value || '').trim() && /^[^\!=\?\@%\^\*;\~\`,(){}\<\>|]*$/.test(value.trim())) {
          this.keywords.push(value.trim());
          this.hintText = '';
        } else {
          this.hintText = '! = ? @ % ^ * ; ~ ` , ( ) { } < > | are not allowed';
        }
      });
  }

  addKeyword(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim() && /^[^\!=\?\@%\^\*;\~\`,(){}\<\>|]*$/.test(value.trim())) {
      this.keywords.push(value.trim());
      this.hintText = '';
    } else {
      this.hintText = '! = ? @ % ^ * ; ~ ` , ( ) { } < > | are not allowed';
    }
    if (input) {
      input.value = '';
    }
  }

  addSelect(event) {

    // Add our fruit
    if (!this.countries.includes(event.option.value)) {

      this.countries.push(event.option.value);
      console.log(this.countries);
      this.hintText = '';
    } else {
      this.hintText = 'Country Already Added!';
    }

    this.countryInput.nativeElement.value = '';
  }

  remove(fruit): void {
    const index = this.countries.indexOf(fruit);

    if (index >= 0) {
      this.countries.splice(index, 1);
    }
  }

  removeKeyword(word): void {
    const index = this.keywords.indexOf(word);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  createCampaign() {
    this.stripUnicode();
    const countries = [];
    this.countries.forEach(element => {
      countries.push(element.cid);
    });
    const lang = [];
    this.languages.forEach(element => {
      lang.push(element.toString());
    });
    const devices = [];
    let sch = false;
    if (this.computer) {
      devices.push('computer');
    }
    if (this.phone) {
      devices.push('phone');
    }
    if (this.tablet) {
      devices.push('tablet');
    }
    if (this.smarttv) {
      devices.push('smarttv');
    }
    if (this.scheduling === 'true') {
      sch = true;
    }
    const adTypes = {};
    if (this.typeOfAd.includes('text')) {
      adTypes['text'] = {
        headline1: this.headline1,
        headline2: this.headline2,
        headline3: this.headline3,
        path1: this.path1,
        path2: this.path2,
        description: this.description,
      };
    }
    if (this.typeOfAd.includes('banner')) {
      adTypes['banner'] = this.imageData;
    }
    const campaignBody = {
      campaignName: this.newCampaignForm.value.campaignName,
      description: this.newCampaignForm.value.description,
      objective: this.newCampaignForm.value.objective,
      keywords: this.keywords,
      redirectUrl: 'https://' + this.inventoryForm.value.redirectUrl,
      status: 'pending-approval',
      platforms: this.newCampaignForm.value.platforms,
      target: {
        countries: countries,
        devices: devices,
        genders: this.personaForm.value.genders,
        ages: this.personaForm.value.ages,
        interestArea: this.personaForm.value.interestArea,
        languages: this.languages
      },
      deployment: {
        startCriterion: this.startCampaignCriteria,
        stopCriterion: this.stopCampaignCriteria,
        startDate: this.schedulingForm.value.startDate,
        endDate: this.schedulingForm.value.endDate,
        scheduling: {
          custom: sch,
          schedule: this.schedulingForm.value.schedule
        }
      },
      adTypesList: this.typeOfAd,
      adTypes: adTypes,
      budget: this.budgetForm.value,
      instructions: this.specialInstructions
    };

    const tokenObject = this._authService.getStoredTokenPayload();
    const userid = tokenObject['id'];
    // console.log(campaignBody);
    this._api.createCampaign(userid, campaignBody).subscribe(
      data => {
        this._router.navigateByUrl('/dashboard/campaign');
      }
    );

  }

  replaceUnicode(str) {
    str = str.replace(/\u200e/g, '');
    str = str.replace(/\n/g, ' ');
    str = str.replace(/\t/g, ' ');
    return str;
  }

  stripUnicode() {
    this.newCampaignForm.patchValue({
      campaignName: this.replaceUnicode(this.newCampaignForm.value.campaignName),
      description: this.replaceUnicode(this.newCampaignForm.value.description),
    });
    this.inventoryForm.patchValue({
      redirectUrl: this.replaceUnicode(this.inventoryForm.value.redirectUrl),
    });
    this.headline1 = this.replaceUnicode(this.headline1);
    this.headline2 = this.replaceUnicode(this.headline2);
    this.headline3 = this.replaceUnicode(this.headline3);
    this.path1 = this.replaceUnicode(this.path1);
    this.path2 = this.replaceUnicode(this.path2);
    this.description = this.replaceUnicode(this.description);
  }

  getInsights() {
    const tokenObject = this._authService.getStoredTokenPayload();
    const interest = [];
    this.personaForm.value.interestArea.forEach(element => {
      interest.push(element.toString());
    });
    const countries = [];
    this.countries.forEach(element => {
      countries.push(element.cid.toString());
    });
    const platforms = [];
    this.newCampaignForm.value.platforms.google ? platforms.push(1) : platforms.push(0);
    this.newCampaignForm.value.platforms.facebook ? platforms.push(1) : platforms.push(0);
    this.newCampaignForm.value.platforms.twitter ? platforms.push(1) : platforms.push(0);
    const obj = {
      _id: tokenObject['id'],
      keyword: this.keywords,
      interest: interest,
      country: countries,
      platforms: platforms,
      timestamp: Date.now().toString(),
      bid: this.budgetForm.value.bidAmount.toString(),
      count: '0',
      language: '1000'
    };
    console.log(obj);
    this._api.getInsights(obj).subscribe(
      data => {
        this.insights = data;
      }
    );
  }

}

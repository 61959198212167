import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.sass']
})
export class PaymentsComponent implements OnInit {

  tokenObject: object;
  userObject: object;
  isEditVisible: boolean;

  editProfileForm: FormGroup;

  constructor(private _authService: AuthService, private _apiService: ApiService, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.tokenObject = this._authService.getStoredTokenPayload();
    if (this.tokenObject !== null) {
      this._apiService.getUserById(this.tokenObject['id']).subscribe(
        data => {
          this.userObject = data;
          this.userObject['wallet'].rechargeHistory = this.dateSortDesc(this.userObject['wallet'].rechargeHistory);
          console.log(this.userObject);
        }
      );
    }
  }

  dateSortDesc(array) {
    array.sort((a: any, b: any) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    return array;
  }

}

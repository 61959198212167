import { Component, OnInit ,Inject } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/auth.service';
import { LOCAL_STORAGE, WebStorageService } from "angular-webstorage-service";
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-usagesummarypdf',
  templateUrl: './usagesummarypdf.component.html',
  styleUrls: ['./usagesummarypdf.component.sass']
})
export class UsagesummarypdfComponent implements OnInit {
  current : any;
  usagesummarylist:any;
  usagesummarytotalcost:number;
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,private _authService: AuthService, private _apiService: ApiService) {
    this.current = this.getFromLocal("payment_date")  
    this.usagesummary();
    
  }

  ngOnInit() {
  }


  saveInLocal(key, val): void {

    this.storage.set(key, val);
  }

  getFromLocal(key): any {

    return this.storage.get(key);
  }


  usagesummary(){
    this.saveInLocal("payment_date", this.current);
    console.log(this.current);
    let startdate = formatDate(this.current.startDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    let enddate = formatDate(this.current.endDate._d, 'yyyy-MM-dd', 'en-US', '+0530');
    this.usagesummarylist = [];
    let a = {user_id:this.getFromLocal("user_id"),startdate:startdate,enddate:enddate }
    this._apiService.searchgetusasgesummary(a).subscribe(
      (data:any) => {
        if(data.length == 0){
          this.usagesummarylist = [];
          this.usagesummarytotalcost = 0;
        }else{
          this.usagesummarytotalcost = 0;
          var groups = data.reduce(function(obj,item){
            obj[item.product] = obj[item.product] || [];
            obj[item.product].push({product:item.product,platform:item.platform,cost:item.cost,date:item.date});
            return obj;
        }, {});
        this.usagesummarylist = Object.keys(groups).map(function(key){
            return {team: key,total:0,name: groups[key]};
        });
        console.log(this.usagesummarylist);

  var result = [];
  data.reduce(function(res, value) {
    if (!res[value.product]) {
      res[value.product] = { product: value.product, cost: 0 };
      result.push(res[value.product])
    }
    res[value.product].cost += +value.cost;
    return res;
  }, {});

  for(let a = 0 ; a < this.usagesummarylist.length ; a ++){
    for(let b = 0 ; b < result.length ; b ++){
      if(this.usagesummarylist[a].team == result[b].product){
          this.usagesummarylist[a].total = +result[b].cost
      }
    }
  }
  console.log(this.usagesummarylist);
          data.forEach(element => {
            this.usagesummarytotalcost = this.usagesummarytotalcost + +element.cost
          });
          // this.usagesummarylist = data;
        }
      }
      );
      console.log(this.usagesummarylist);
  }
  


}

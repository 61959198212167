import { Component, OnInit, ViewChild } from '@angular/core';
import * as shape from 'd3-shape';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ExportService } from '../export.service';

export interface CampaignObject {
  id: string;
  name: string;
  average_cpm: string;
  average_cpe: string;
  average_cpc: string;
  average_cpv: string;
  bounce_rate: string;
  clicks: string;
  conversions: string;
  cost_micros: string;
  ctr: string;
  impressions: string;
  revenue: number;
  ecpa: number;
  roa: number;
  status: string;
  platforms: object;
}

let CAMPAIGN_DATA: CampaignObject[] = [];

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'name',
    'platforms',
    'impressions',
    'clicks',
    'conversions',
    'bounce_rate',
    'cost_micros',
    'ctr',
    'average_cpc',
    'average_cpm',
    'average_cpe',
    'average_cpv',
    'ecpa',
    'revenue',
    'roa',
    'status'
  ];

  plateformArray = [
    {
      name: 'Google',
      value: 'Google',
      imgPath: 'https://airbots.ai/demo/airbots-saas-platform/assets/platforms/gad.jpg'
    },
    {
      name: 'Facebook',
      value: 'facebook',
      imgPath: 'https://airbots.ai/demo/airbots-saas-platform/assets/platforms/fb.jpg'
    },
    {
      name: 'Twitter',
      value: 'twitter',
      imgPath: 'https://airbots.ai/demo/airbots-saas-platform/assets/platforms/tw.jpg'
    }
  ];

  dataSource = new MatTableDataSource(CAMPAIGN_DATA);

  tokenObject: Object;
  userObject: Object;

  primaryData: Object;
  metricsData: Object;

  showActive: boolean;

  maxDate: Date;
  maxDateStop: Date;

  schedulingForm = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl()
  });

  pageload: boolean;
  isCampaignNull = false;

  campaignsList = [];

  curve = shape.curveMonotoneX;

  timePeriod = { name: 'Last 30 Days', value: 'LAST_30_DAYS' };
  platforms = { name: 'Google', value: 'Google', imgPath: 'https://airbots.ai/demo/airbots-saas-platform/assets/platforms/gad.jpg' };
  datasetType = { name: 'Campaigns', value: 'campaigns' };

  gTypeArray = [
    {
      name: 'Campaigns',
      value: 'campaigns'
    },
    {
      name: 'Creatives',
      value: 'creatives'
    },
    {
      name: 'Keywords',
      value: 'keywords'
    },
  ];

  colorScheme = {
    domain: ['#673AB7', '#E91E63', '#FFC107', '#8BC34A']
  };

  chartColors = {
    Cost: '#673AB7',
    Impressions: '#E91E63',
    Clicks: '#FFC107',
    Conversions: '#8BC34A'
  };

  metricsArray = [
    {
      name: 'Cost',
      value: 0,
      sign: '$',
      data: []
    },
    {
      name: 'Impressions',
      value: 0,
      sign: '',
      data: []
    },
    {
      name: 'Clicks',
      value: 0,
      sign: '',
      data: []
    },
    {
      name: 'Conversions',
      value: 0,
      sign: '',
      data: []
    },
  ];

  primaryAvailableDates = [];
  primaryColorLegend = {
    Cost: '2px solid #673AB7',
    Impressions: '2px solid #E91E63',
    Clicks: '2px solid #FFC107',
    Conversions: '2px solid #8BC34A'
  };

  chartFilter = [];
  chartValues = [];

  placed = [];
  placedList = [];

  data1ToggleStore = [];

  dataX = [];

  data1 = [
    {
      'name': 'Cost',
      'series': [
        {
          'name': 'Mar 27',
          'value': 23
        },
        {
          'name': 'Mar 28',
          'value': 25
        },
        {
          'name': 'Mar 29',
          'value': 20
        },
        {
          'name': 'Mar 30',
          'value': 23
        },
        {
          'name': 'Mar 31',
          'value': 25
        },
        {
          'name': 'Mar 26',
          'value': 20
        },
      ]
    },
    {
      'name': 'Impressions',
      'series': [
        {
          'name': 'Mar 27',
          'value': 12000
        },
        {
          'name': 'Mar 28',
          'value': 12340
        },
        {
          'name': 'Mar 29',
          'value': 8230
        },
        {
          'name': 'Mar 30',
          'value': 12000
        },
        {
          'name': 'Mar 31',
          'value': 12340
        },
        {
          'name': 'Mar 26',
          'value': 8230
        },
      ]
    },
    {
      'name': 'Clicks',
      'series': [
        {
          'name': 'Mar 27',
          'value': 8034
        },
        {
          'name': 'Mar 28',
          'value': 10230
        },
        {
          'name': 'Mar 29',
          'value': 7345
        },
        {
          'name': 'Mar 30',
          'value': 10004
        },
        {
          'name': 'Mar 31',
          'value': 9804
        },
        {
          'name': 'Mar 26',
          'value': 6500
        },
      ]
    },
    {
      'name': 'Conversions',
      'series': [
        {
          'name': 'Mar 27',
          'value': 9000
        },
        {
          'name': 'Mar 28',
          'value': 5000
        },
        {
          'name': 'Mar 29',
          'value': 8888
        },
        {
          'name': 'Mar 30',
          'value': 9999
        },
        {
          'name': 'Mar 31',
          'value': 7898
        },
        {
          'name': 'Mar 26',
          'value': 7900
        },
      ]
    },
  ];

  data3 = [
    {
      'name': 'Session',
      'series': [
        {
          'name': 'Mar 27',
          'value': 23
        },
        {
          'name': 'Mar 28',
          'value': 25
        },
        {
          'name': 'Mar 29',
          'value': 20
        },
        {
          'name': 'Mar 30',
          'value': 23
        },
        {
          'name': 'Mar 31',
          'value': 25
        },
        {
          'name': 'Mar 26',
          'value': 20
        },
      ]
    },
    {
      'name': 'Bounce Rates',
      'series': [
        {
          'name': 'Mar 27',
          'value': 1
        },
        {
          'name': 'Mar 28',
          'value': 3
        },
        {
          'name': 'Mar 29',
          'value': 4
        },
        {
          'name': 'Mar 30',
          'value': 2
        },
        {
          'name': 'Mar 31',
          'value': 1
        },
        {
          'name': 'Mar 26',
          'value': 8
        },
      ]
    },
    {
      'name': 'New Visitors',
      'series': [
        {
          'name': 'Mar 27',
          'value': 5
        },
        {
          'name': 'Mar 28',
          'value': 11
        },
        {
          'name': 'Mar 29',
          'value': 14
        },
        {
          'name': 'Mar 30',
          'value': 8
        },
        {
          'name': 'Mar 31',
          'value': 21
        },
        {
          'name': 'Mar 26',
          'value': 6
        },
      ]
    },
    {
      'name': 'Session Duration',
      'series': [
        {
          'name': 'Mar 27',
          'value': 25
        },
        {
          'name': 'Mar 28',
          'value': 20
        },
        {
          'name': 'Mar 29',
          'value': 29
        },
        {
          'name': 'Mar 30',
          'value': 18
        },
        {
          'name': 'Mar 31',
          'value': 27
        },
        {
          'name': 'Mar 26',
          'value': 23
        },
      ]
    },
  ];

  data4 = [
    {
      'name': 'Search',
      'value': 40632
    },
    {
      'name': 'Display',
      'value': 49737
    },
  ];

  data2 = [
    {
      'name': 'Cost',
      'series': [
        {
          'name': 'Search',
          'value': 23
        },
        {
          'name': 'Display',
          'value': 25
        },
        {
          'name': 'E-Mail',
          'value': 28
        },
      ]
    },
    {
      'name': 'Imps',
      'series': [
        {
          'name': 'Search',
          'value': 12000
        },
        {
          'name': 'Display',
          'value': 12340
        },
        {
          'name': 'E-Mail',
          'value': 12340
        },
      ]
    },
    {
      'name': 'Clicks',
      'series': [
        {
          'name': 'Search',
          'value': 8034
        },
        {
          'name': 'Display',
          'value': 10230
        },
        {
          'name': 'E-Mail',
          'value': 10230
        },
      ]
    },
    {
      'name': 'Conv',
      'series': [
        {
          'name': 'Search',
          'value': 9000
        },
        {
          'name': 'Display',
          'value': 5000
        },
        {
          'name': 'E-Mail',
          'value': 5000
        },
      ]
    },
    {
      'name': 'Rev',
      'series': [
        {
          'name': 'Search',
          'value': 9000
        },
        {
          'name': 'Display',
          'value': 5000
        },
        {
          'name': 'E-Mail',
          'value': 5000
        },
      ]
    },
    {
      'name': 'ROAs',
      'series': [
        {
          'name': 'Search',
          'value': 9000
        },
        {
          'name': 'Display',
          'value': 5000
        },
        {
          'name': 'E-Mail',
          'value': 5000
        },
      ]
    },
  ];

  dollarRate: number;

  keywordsList = [];

  constructor(
    private _authService: AuthService,
    private _apiService: ApiService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _exportService: ExportService) { }

  ngOnInit() {
    this.showActive = false;
    this.pageload = true;

    this.tokenObject = this._authService.getStoredTokenPayload();
    this._apiService.getDollarRate().subscribe(
      rate => {
        this.dollarRate = rate['rates'].INR;
        this._route.queryParams.subscribe(
          query => {
            if (query.id) {
              this._apiService.getCampaignById(query.id).subscribe(
                data => {
                  this.getCampaignArray([data]);
                }
              );
            } else {
              this._apiService.getUserCampaigns(this.tokenObject['id']).subscribe(
                user => {
                  this.userObject = user;
                  this.getCampaignArray(user['campaigns']);
                }
              );
            }
          }
        );
      }
    );

    this.maxDate = new Date(Date.now());
    this.maxDateStop = new Date(Date.now());
    this.maxDate.setDate(this.maxDate.getDate() - 2);
    this.maxDateStop.setDate(this.maxDateStop.getDate() - 1);

    this.schedulingForm.valueChanges.subscribe(
      data => {
        this.maxDate = new Date(this.schedulingForm.value.endDate);
        const date = new Date(this.schedulingForm.value.endDate);
        this.maxDate.setDate(date.getDate() - 1);
      }
    );
  }

  filterGetCampaignName(id) {
    const obj = this.placed.find(o => o.cid === id.toString());
    return obj.name;
  }

  setTypeVal(obj) {
    this.datasetType = obj;
  }

  setSocailVal(obj) {
    this.platforms = obj;
  }
  getCampaignArray(campaigns) {
    this.campaignsList = [];
    campaigns.forEach((element, index) => {
      if (element.placements) {
        if (element.placements.google) {
          if (element.placements.google.campaigns) {
            if (element.placements.google.campaigns.length > 0) {
              this.keywordsList = element.keywords;
              element.placements.google.campaigns.forEach((el, i) => {
                this.campaignsList.push(el.campaign_id);
                this.placed.push({
                  cid: el.campaign_id,
                  name: element.campaignName,
                  id: element._id,
                  platforms: element.platforms
                });
                this.placedList.push(el.campaign_id);
              });
            }
          }
        }
      }
    });
    this.setTimePeriod();
  }

  setTimePeriod() {
    if (this.campaignsList.length > 0) {
      this._apiService.getSampleData(this.campaignsList, this.timePeriod.value).subscribe(
        data => {
          this.primaryData = data;
          this.sortPrimaryData();
          this.tabulateData();
        }
      );
    } else {
      this.isCampaignNull = true;
      this.pageload = false;
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }


  getReport() {
    this.pageload = true;
    const startDate = this.formatDate(this.schedulingForm.value.startDate);
    const endDate = this.formatDate(this.schedulingForm.value.endDate);

    this._apiService.getHistoricalData(this.campaignsList, startDate, endDate).subscribe(
      data => {
        this.primaryData = data;
        this.tabulateData();
        if (this.datasetType.value === 'campaigns') {
          this.sortPrimaryData();
        }
      }
    );

    if (this.datasetType.value === 'creatives') {
      this._apiService.getCreativesHistoricalData(this.campaignsList, startDate, endDate).subscribe(
        data => {
          this.primaryData = data;
          this.sortPrimaryData();
        }
      );
    }
    if (this.datasetType.value === 'keywords') {
      this._apiService.getKeywordsHistoricalData(
        {
          campaigns: this.campaignsList,
          keywords: this.keywordsList
        }, startDate, endDate).subscribe(
          data => {
            this.primaryData = data;
            this.sortPrimaryData();
          }
        );
    }

  }

  togglePrimary(key) {
    let flag = true;
    this.data1.forEach((element) => {
      if (element.name === key) {
        this.data1ToggleStore.push(element);
        this.data1.splice(this.data1.indexOf(element), 1);
        this.colorScheme.domain.splice(this.colorScheme.domain.indexOf(this.chartColors[key]), 1);
        flag = false;
        this.primaryColorLegend[key] = '2px solid #ddd';
        this.data1.forEach((el, i) => {
          this.primaryColorLegend[el.name] = '2px solid ' + this.chartColors[el.name];
        });
      }
    });
    if (flag) {
      this.data1ToggleStore.forEach(ele => {
        if (ele.name === key) {
          this.data1.push(ele);
          this.colorScheme.domain.push(this.chartColors[ele.name]);
          this.data1ToggleStore.splice(this.data1ToggleStore.indexOf(ele), 1);
          this.primaryColorLegend[key] = '2px solid' + this.chartColors[ele.name];
        }
      });
    }
    // this.data1 = [...this.data1];
  }

  axisFormatPrimary = (val) => {
    if (this.primaryAvailableDates.length > 15) {
      if (this.primaryAvailableDates.indexOf(val) % 3 === 0) {
        return val;
      } else {
        return '';
      }
    } else {
      return val;
    }
  }

  computeMetrics(darray) {
    let cost = 0;
    let conv = 0;
    let imps = 0;
    let clicks = 0;
    darray.forEach(element => {
      cost += element.cost_micros / 1000000;
      conv += element.conversions;
      imps += element.impressions;
      clicks += element.clicks;
    });
    this.metricsArray.forEach((element, index) => {
      if (element.name === 'Cost') {
        this.metricsArray[index].value = cost / this.dollarRate;
      }
      if (element.name === 'Clicks') {
        this.metricsArray[index].value = clicks;
      }
      if (element.name === 'Impressions') {
        this.metricsArray[index].value = imps;
      }
      if (element.name === 'Conversions') {
        this.metricsArray[index].value = conv;
      }
    });
  }

  getMetricsAverage(m1, m2, count) {
    const m3 = {};
    for (const key in m1) {
      if (m1.hasOwnProperty(key)) {
        const element = m1[key];
        // m3[key] = ((element * count) + m2[key]) / (count + 1);
        m3[key] = ((element) + m2[key]);
      }
    }
    return m3;
  }

  getTableAverage(m1, m2, count) {
    const m3 = {};
    for (const key in m1) {
      if (m1.hasOwnProperty(key)) {
        const element = m1[key];
        if (key === 'ctr' || key === 'average_cpc') {
          m3[key] = ((element * count) + m2[key]) / (count + 1);
        } else {
          m3[key] = ((element) + m2[key]);
        }
      }
    }
    return m3;
  }

  updateChartData(arrayList, dates) {
    this.primaryAvailableDates = [];
    const cost = {
      name: 'Cost',
      series: []
    };

    const imps = {
      name: 'Impressions',
      series: []
    };

    const clicks = {
      name: 'Clicks',
      series: []
    };

    const conv = {
      name: 'Conversions',
      series: []
    };

    arrayList.forEach(element => {
      const d = new Date(element.date);
      const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
      cost.series.push({ name: str, value: element.metrics.cost_micros / (this.dollarRate * 1000000) });
      imps.series.push({ name: str, value: element.metrics.impressions });
      clicks.series.push({ name: str, value: element.metrics.clicks });
      conv.series.push({ name: str, value: element.metrics.conversions });
    });

    setTimeout(() => {
      this.data1 = [cost, imps, clicks, conv];
      this.metricsArray.forEach(element => {
        if (element.name === 'Cost') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(cost);
        }
        if (element.name === 'Clicks') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(clicks);
        }
        if (element.name === 'Impressions') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(imps);
        }
        if (element.name === 'Conversions') {
          this.metricsArray[this.metricsArray.indexOf(element)].data = [];
          this.metricsArray[this.metricsArray.indexOf(element)].data.push(conv);
        }
      });
      dates.forEach(element => {
        const d = new Date(element);
        const str = d.toLocaleDateString('en-IN', { month: 'short', day: 'numeric' });
        this.primaryAvailableDates.push(str);
      });
      // console.log(this.primaryAvailableDates);
      this.pageload = false;
    }, 3000);
  }

  sortPrimaryData() {
    const availableItems = [];
    const availableItemsObj = [];
    const objAgg = [];
    for (const key in this.primaryData) {
      if (this.primaryData.hasOwnProperty(key)) {
        const element = this.primaryData[key];
        if (this.datasetType.value === 'creatives') {
          if (!availableItems.includes(element.creative.id)) {
            availableItems.push(element.creative.id);
            availableItemsObj.push({
              name: element.creative.id,
              cname: element.creative.id
            });
            objAgg.push({
              id: element.creative.id,
              data: [{
                metrics: element.metrics,
                date: element.date,
              }]
            });
          } else {
            objAgg.forEach((ele, index) => {
              if (ele.id === element.creative.id) {
                objAgg[index].data.push({
                  metrics: element.metrics,
                  date: element.date
                });
              }
            });
          }
        }
        if (this.datasetType.value === 'keywords') {
          if (!availableItems.includes(element.keyword.text)) {
            availableItems.push(element.keyword.text);
            availableItemsObj.push({
              name: element.keyword.text,
              cname: element.keyword.text
            });
            objAgg.push({
              id: element.keyword.text,
              data: [{
                metrics: element.metrics,
                date: element.date,
              }]
            });
          } else {
            objAgg.forEach((ele, index) => {
              if (ele.id === element.keyword.text) {
                objAgg[index].data.push({
                  metrics: element.metrics,
                  date: element.date
                });
              }
            });
          }
        }
        if (this.datasetType.value === 'campaigns') {
          if (!availableItems.includes(element.campaign.name)) {
            availableItems.push(element.campaign.name);
            availableItemsObj.push({
              name: element.campaign.name,
              cname: this.filterGetCampaignName(element.campaign.id)
            });
            objAgg.push({
              id: element.campaign.name,
              data: [{
                metrics: element.metrics,
                date: element.date,
              }]
            });
          } else {
            objAgg.forEach((ele, index) => {
              if (ele.id === element.campaign.name) {
                objAgg[index].data.push({
                  metrics: element.metrics,
                  date: element.date
                });
              }
            });
          }
        }
      }
    }
    this.dataX = objAgg;
    this.chartFilter = [];
    this.chartFilter.push(availableItems[0]);
    this.chartValues = availableItemsObj;
    this.formatData();
  }

  formatData() {
    const availableDates = [];
    const objAgg = [];
    this.dataX.forEach(element => {
      if (this.chartFilter.includes(element.id)) {
        element.data.forEach((el) => {
          if (!availableDates.includes(el.date)) {
            availableDates.push(el.date);
            objAgg.push({
              date: el.date,
              metrics: el.metrics,
              count: 1
            });
          } else {
            objAgg.forEach((ele, ind) => {
              if (ele.date === el.date) {
                objAgg[ind].metrics = this.getMetricsAverage(ele.metrics, el.metrics, ele.count);
                objAgg[ind].count = ele.count + 1;
              }
            });
          }
        });
      }
    });
    objAgg.sort((a, b) => {
      const d1 = new Date(a.date);
      const d2 = new Date(b.date);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    availableDates.sort((a, b) => {
      const d1 = new Date(a);
      const d2 = new Date(b);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    this.updateChartData(objAgg, availableDates);
  }

  tabulateData() {
    const availableCampaigns = [];
    const objAgg = [];
    for (const key in this.primaryData) {
      if (this.primaryData.hasOwnProperty(key)) {
        const element = this.primaryData[key];
        if (!availableCampaigns.includes(element.campaign.id)) {
          availableCampaigns.push(element.campaign.id);
          objAgg.push({
            date: element.date,
            campaign: element.campaign,
            metrics: element.metrics,
            count: 1
          });
        } else {
          objAgg.forEach((ele, i) => {
            if (ele.campaign.id === element.campaign.id) {
              objAgg[i].metrics = this.getTableAverage(ele.metrics, element.metrics, ele.count);
              objAgg[i].count = ele.count + 1;
            }
          });
        }
      }
    }
    objAgg.sort((a, b) => {
      const d1 = new Date(a.date);
      const d2 = new Date(b.date);
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    });
    this.populateTable(objAgg);
  }

  populateTable(campaigns) {
    const microsdollar = this.dollarRate * 1000000;
    CAMPAIGN_DATA = [];
    campaigns.forEach(element => {

      let placedCamp = {};

      this.placed.forEach(placedCampaign => {
        if (placedCampaign['cid'] === element['campaign'].id.toString()) {
          placedCamp = placedCampaign;
        }
      });

      CAMPAIGN_DATA.push({
        id: placedCamp['id'],
        name: placedCamp['name'],
        average_cpm: (element.metrics.average_cpm / microsdollar).toString(),
        average_cpe: (element.metrics.average_cpe / microsdollar).toString(),
        average_cpc: (element.metrics.average_cpc / microsdollar).toString(),
        average_cpv: (element.metrics.average_cpv / microsdollar).toString(),
        bounce_rate: element.metrics.bounce_rate,
        clicks: element.metrics.clicks,
        conversions: element.metrics.conversions,
        cost_micros: (element.metrics.cost_micros / microsdollar).toString(),
        ctr: element.metrics.ctr,
        impressions: element.metrics.impressions,
        status: element.campaign.status,
        revenue: 0,
        ecpa: 0,
        roa: 0,
        platforms: placedCamp['platforms']
      });
    });
    this.dataSource = new MatTableDataSource(CAMPAIGN_DATA);
    this.dataSource.filterPredicate = function (data, filter: string): boolean {

      const filters = JSON.parse(filter);

      console.log(filters);

      return filters.status.indexOf(data.status) !== -1 && data.name.trim().toLowerCase().indexOf(filters.name) !== -1;
    };
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    if (this.showActive) {
      this.dataSource.filter = JSON.stringify({ status: [2], name: filterValue });
    } else {
      this.dataSource.filter = JSON.stringify({ status: [1, 2, 3, 4], name: filterValue });
    }
  }

  export() {
    this._exportService.exportFile(CAMPAIGN_DATA, 'reports');
  }
}

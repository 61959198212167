import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  pdfdate: "2019-09"

  apiUrl = environment.apiUrl;
  apiAnalyticsUrl = environment.analyticsApi;
  insightsUrl = environment.insightsApi;

  constructor(private http: HttpClient) { }


  getUserlist() {
    return this.http.get(this.apiUrl + 'users/listall');
  }

  approve(data) {
    return this.http.post(this.apiUrl + 'users/approve', data);
  }

  unapprove(data) {
    return this.http.post(this.apiUrl + 'users/unapprove', data);
  }


  getDollarRate() {
    return this.http.get(this.apiUrl + 'currency');
  }

  login(data) {
    return this.http.post(this.apiUrl + 'users/login', data);
  }

  googleLogin(token) {
    return this.http.post(this.apiUrl + 'users/login/google', { token: token });
  }

  logout(token) {
    return this.http.post(this.apiUrl + 'users/logout', { token: token });
  }

  validateToken(token) {
    return this.http.post(this.apiUrl + 'users/login/check', { token: token });
  }

  signup(data) {
    return this.http.post(this.apiUrl + 'users/signup', data);
  }

  googleSignup(token) {
    return this.http.post(this.apiUrl + 'users/signup/google', { token: token });
  }

  completeProfileSignup(id, data) {
    return this.http.put(this.apiUrl + 'users/signup/profile/' + id, data);
  }

  completeSurveySignup(id, data) {
    return this.http.put(this.apiUrl + 'users/signup/survey/' + id, data);
  }

  getUserById(id) {
    return this.http.get(this.apiUrl + 'users/' + id);
  }

  forgotPassword(data) {
    return this.http.post(this.apiUrl + 'users/forgotpassword', data);
  }

  resetPassword(data) {
    return this.http.post(this.apiUrl + 'users/resetpwd', data);
  }

  updateUserById(id, data) {
    return this.http.put(this.apiUrl + 'users/' + id, data);
  }

  // CAMPIGN ROUTES

  createCampaign(id, data) {
    return this.http.post(this.apiUrl + 'campaigns/create/' + id, data);
  }

  uploadFiles(files) {
    return this.http.post(this.apiUrl + 'users/fileupload', files, { reportProgress: true });
  }

  uploadCampaignFiles() {

  }

  addFunds(id, data) {
    return this.http.post(this.apiUrl + 'payments/pay/' + id, data);
  }

  getUserCampaigns(id) {
    return this.http.get(this.apiUrl + 'users/campaigns/' + id);
  }

  getCampaignById(id) {
    return this.http.get(this.apiUrl + 'campaigns/' + id);
  }

  getCampaignReport(data) {
    return this.http.post(this.apiUrl + 'campaigns/reports/google/campaign', data);
  }


  createSearchCampaign(data) {
    return this.http.post(this.apiUrl + 'campaigns/place/campaign/search', data);
  }

  createImageCampaign(data) {
    return this.http.post(this.apiUrl + 'campaigns/place/campaign/image', data);
  }

  createTextAdgroup(cid, data) {
    return this.http.post(this.apiUrl + 'campaigns/create/adgroup/text/' + cid, data);
  }

  createImageAdgroup(cid, data) {
    return this.http.post(this.apiUrl + 'campaigns/create/adgroup/image/' + cid, data);
  }

  placeTextAd(userid, adid, data) {
    return this.http.post(this.apiUrl + 'campaigns/place/google/textad/' + userid + '/' + adid, data);
  }

  placeImageAd(userid, adid, data) {
    return this.http.post(this.apiUrl + 'campaigns/place/google/imagead/' + userid + '/' + adid, data);
  }

  startAd(adgrpid, adid) {
    return this.http.get(this.apiUrl + 'campaigns/start/google/' + adgrpid + '/' + adid);
  }

  startCampaign(id, campaignid) {
    return this.http.get(`${this.apiUrl}campaigns/start/google/campaign/${id}/${campaignid}`);
  }

  pauseCampaign(id, campaignid) {
    return this.http.get(`${this.apiUrl}campaigns/pause/google/campaign/${id}/${campaignid}`);
  }

  stopCampaign(id, campaignid) {
    return this.http.get(`${this.apiUrl}campaigns/stop/google/campaign/${id}/${campaignid}`);
  }

  // WALLET ROUTES

  testPay() {
    return this.http.get(this.apiUrl + 'payments/testpay');
  }

  pay(id, data) {
    return this.http.post(this.apiUrl + 'payments/pay/' + id, data);
  }

  // MISC

  searchLocation(search) {
    return this.http.get(this.apiUrl + 'locations/google/' + search);
  }

  getSampleData(campaigns, time_period) {
    return this.http.post(this.apiUrl + 'campaigns/reports/google/time/' + time_period, campaigns);
  }

  getSampleMetricData(campaigns, time_period) {
    return this.http.post(this.apiUrl + 'campaigns/reports/google/metrics/time/' + time_period, campaigns);
  }

  getHistoricalData(campaigns, time_from, time_to) {
    return this.http.post(this.apiUrl + 'campaigns/reports/google/time/' + time_from + '/' + time_to, campaigns);
  }

  getCreativesHistoricalData(campaigns, time_from, time_to) {
    return this.http.post(this.apiUrl + 'campaigns/reports/google/creatives/time/' + time_from + '/' + time_to, campaigns);
  }

  getKeywordsHistoricalData(data, time_from, time_to) {
    return this.http.post(this.apiUrl + 'campaigns/reports/google/keywords/time/' + time_from + '/' + time_to, data);
  }

  getGoogleAnalyticsData(trackingid, from, to) {
    return this.http.get(this.apiUrl + 'analytics/google/' + trackingid + '/' + from + '/' + to);
  }

  registerGoogleAnalytics(id, data) {
    return this.http.post(this.apiUrl + 'users/' + id + '/google/analytics/register', data);
  }

  authenticateAnalytics(id) {
    return this.http.get(this.apiUrl + 'users/analytics/oauth/' + id);
  }

  updateAccountInfo(id, data) {
    return this.http.post(this.apiUrl + 'users/accountinfo/' + id, data);
  }

  twitterAuth(id) {
    return this.http.get(this.apiUrl + 'users/' + id + '/twitter/oauth/');
  }

  getInsights(data) {
    return this.http.post(this.apiUrl + 'insights', data);
  }

  getLocationObjects(array) {
    return this.http.post(this.apiUrl + 'locations/google/array', { countries: array });
  }

  updateFacebookStatus(id, status) {
    return this.http.get(`${this.apiUrl}campaigns/update/facebook/${status}/${id}`);
  }

  updateTwitterStatus(id, status, data) {
    return this.http.post(`${this.apiUrl}campaigns/update/twitter/${status}/${id}`, data);
  }

  ////// billling system/////

  /// Card Details///
  addindcard(data) {
    return this.http.post(this.apiUrl + 'billing/card/createcard', data);
  }

  getCardDetailsList(data) {
    return this.http.post(this.apiUrl + 'billing/card/find', data);
  }

  fetchcarddetails(data) {
    return this.http.post(this.apiUrl + 'billing/card/carddetails', data);
  }

  changecardStatus(data) {
    return this.http.post(this.apiUrl + 'billing/card/changestatus', data);
  }

  removecards(data) {
    return this.http.post(this.apiUrl + 'billing/card/removecard', data);
  }

  /// Billing Preference ////
  getperferencDetails(data) {
    return this.http.post(this.apiUrl + 'billing/billingpreferences/getdata', data);
  }
  addperferenceDetails(data) {
    return this.http.post(this.apiUrl + 'billing/billingpreferences/insert', data);
  }

  /// Usage Summary //
  searchgetusasgesummary(data) {
    return this.http.post(this.apiUrl + 'billing/usersummary/search', data);
  }


  /// Payment Summary ///



  insertpayment(data) {
    return this.http.post(this.apiUrl + 'billing/payment/check', data);
  }

  searchgetpaymentsummary(data) {
    return this.http.post(this.apiUrl + 'billing/payment/search', data);
  }

  // Insert Config ////
  insertconfigcardpri(data) {
    return this.http.post(this.apiUrl + 'billing/paymentconfig/insertprimary', data);
  }
  insertconfigcardsec(data) {
    return this.http.post(this.apiUrl + 'billing/paymentconfig/insertsecondary', data);
  }
  insertconfig(data) {
    return this.http.post(this.apiUrl + 'billing/paymentconfig/updateconfig', data);
  }
  fetchconfig(data) {
    return this.http.post(this.apiUrl + 'billing/paymentconfig/fetchconfigu', data);
  }

  // wallet check///
  fetchwalletamount(data) {
    return this.http.post(this.apiUrl + 'billing/wallet/search', data);
  }

  // Notifiaction //
  getnotificationcount(data) {
    return this.http.post(this.apiUrl + 'billing/notification/search', data);
  }

  updatenotifiaction(data) {
    return this.http.post(this.apiUrl + 'billing/notification/changestatus', data);
  }

  //promocode//
promocodeinster(data){
  return this.http.post(this.apiUrl + 'billing/promocode/checkpromo', data);
}
}

import { Component, OnInit } from '@angular/core';
import { HttpRequest, HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ApiService } from '../../api.service';
import { AuthService } from '../../auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-galleryimageupload',
  templateUrl: './galleryimageupload.component.html',
  styleUrls: ['./galleryimageupload.component.sass']
})
export class GalleryimageuploadComponent implements OnInit {



  platform:any;
  Format:any;
  type:any;




  platforms1:string;


  apiUrl = environment.apiUrl;
   title:string;
   category:string;
   size:string;
   format:string;
   description:string;
   imageurl:string;
   imagefileupload:string;
   //imageuploadingprocess///
   imageData = [];
   files: File[] = [];
   httpEmitter: Subscription;
   httpEvent: HttpEvent<{}>;
   filesPreview = [];
   errorDimensionMessage = '';

   dimensionList = [
    {
      'width': 1170,
      'height': 603
    },
    {
      'width': 414,
      'height': 736
    },
    {
      'width': 225,
      'height': 225
    },
    {
      'width': 512,
      'height': 512
    },
    {
      'width': 728,
      'height': 90
    },
    {
      'width': 1024,
      'height': 90
    },
    {
      'width': 970,
      'height': 90
    },
    {
      'width': 980,
      'height': 120
    },
    {
      'width': 930,
      'height': 180
    },
    {
      'width': 250,
      'height': 250
    },
    {
      'width': 200,
      'height': 200
    },
    {
      'width': 336,
      'height': 280
    },
    {
      'width': 300,
      'height': 250
    },
    {
      'width': 970,
      'height': 250
    },
    {
      'width': 120,
      'height': 600
    },
    {
      'width': 160,
      'height': 600
    },
    {
      'width': 425,
      'height': 600
    },
    {
      'width': 300,
      'height': 600
    },
    {
      'width': 320,
      'height': 50
    },
    {
      'width': 300,
      'height': 50
    },
    {
      'width': 240,
      'height': 400
    },
    {
      'width': 250,
      'height': 360
    },
    {
      'width': 480,
      'height': 32
    },
    {
      'width': 300,
      'height': 1050
    },
    {
      'width': 768,
      'height': 1024
    },
    {
      'width': 1024,
      'height': 768
    },
    {
      'width': 580,
      'height': 400
    },
    {
      'width': 480,
      'height': 320
    },
    {
      'width': 320,
      'height': 480
    },
    {
      'width': 300,
      'height': 100
    },
    {
      'width': 750,
      'height': 300
    },
    {
      'width': 750,
      'height': 200
    },
    {
      'width': 750,
      'height': 100
    },
    {
      'width': 950,
      'height': 90
    },
    {
      'width': 88,
      'height': 31
    },
    {
      'width': 220,
      'height': 90
    },
    {
      'width': 300,
      'height': 31
    },
    {
      'width': 320,
      'height': 100
    },
    {
      'width': 980,
      'height': 90
    },
    {
      'width': 240,
      'height': 133
    },
    {
      'width': 200,
      'height': 446
    },
    {
      'width': 292,
      'height': 30
    },
    {
      'width': 960,
      'height': 90
    },
    {
      'width': 970,
      'height': 66
    },
    {
      'width': 300,
      'height': 57
    },
    {
      'width': 120,
      'height': 60
    }
  ];


  c = {
    "twitter":
    {"creatives":
    {
      "Plain text Tweet":[{"Tweet_copy":280}],
      "Image Website Card":[{
  "Tweet_Copy" : 280,
  "Website_Title_length" : 50,
  "Image_size" : [{"1:1": {"l*w":"800*800"},"1.91:1":{"l*w":"800*800"}}],
  "Aspect_Ratio" :["1.91:1","1:1"],
  "File_Types" : ["png","jpeg"]
  }],
      "Image app card":[{
  "Tweet_Copy" : 280,
  "Website_Title_length" : 50, 
  "Image_size" : [{"1:1": {"l*w":"800*800"},"1.91:1":{"l*w":"800*800"}}],
  "File_Types" : ["png","jpeg"],
  "Title/price": "pulled_from_app_store",
  "call_to_action":["Install"," Open"," Play"," Shop"," Book"," Connect","Order"]
  }
  ],
      "Promoted Video":[
  {
  "Tweet_Copy" : 280,
  "Title": 70,
  "Description" : 200, 
  "File_Types" : ["mp4","mov"],
  "File_size" : [{"max_size":"1gb"}],
  "v_time" : [{"max_time":140}],
  "V_specs":[{
  "Video_Codec":"H264",
  "Frame_Rate":"30",
  "Video_Resolution":[{"portrait":[{"l*w":"720*1280"}]},{"landscape":[{"l*w":"1280*720"}]},{"square":[{"l*w":"720*720"}]}],
  "Minimum_Video_Bitrate":5000,
  "Audio_Codec":"AAC LC",
  "Aspect_Ratio":["16:9","1:1"]
  }],
  "Thumbnail":
  [{
  "Supported_file_types":["png","jpg"],
  "Recommended_aspect_ratio":["16:9","1:1"],
  "min_len":"640*360",
  "max_size":"5mb"
  }]
  }
  ],
  "Single image Tweets & GIFs":
  [{
  "Mobile":
  [
  {"Tweet copy":280},
  {
    "Image size":
  [
    {"l_w":"600*335"},
    {"Aspect_ratio":"16:9"},
    {"Image_file_size":[{"max_size":"15mb"}]},
    {"File types":["png","jpg","gif"]}
  ]
  }],
  "Desktop":
  [
  {"Tweet copy":280},
  {"Image size":
  [
    {"min_len":600},
    {"Aspect_ratio":["1:1","2:1"]},
    {"Image_file_size":[{"max_size":"15mb"}]},
    {"File types":["png","jpg","gif"]}
  ]  
  }]
  }],
      "Multi_image_Tweets":
  [
  {
  
  "Mobile":[{"Tweet copy":280},{"Image size":[{"l*w":"600*335"},{"Aspect ratio":[{"2":"7.8"},{"3":["7.8","4:7"]},{"4":"2:1"}]},{"Image file size":[{"max_size":"3mb"}]},{"File types":["png","jpg"]}],
   
  "Desktop":[{"Tweet copy":280},{"Image size":[{"min_len":600},{"Aspect ratio":"1:1"},{"Image file size":[{"max_size":"3mb"}]},{"File types":["png","jpg"]}]}]
  }]
  }],
      "Video app card":[
  {
  "Tweet_Copy" : 280,
  "Video ratio":["16*9","1*1"],
  "Title":70,
  "Description":200,
  "max_size":"1gb",
  "File types":["mp4","mov"],
  "Video Codec":"H264",
  "Frame_Rate":30,
  "Video_Resolution":[{"portrait":[{"l*w":"720*1280"}]},{"landscape":[{"l*w":"1280*720"}]},{"square":[{"l*w":"720*720"}]}],
  "Video_Bitrate":[{"1080":"6000-10000k"},{"720":"5000-8000k"}],
  "Audio_Codec":"AAC LC"
  }
  ],
      "Video Website Card":[
  {
  "Tweet_Copy" : 280,
  "headline":70,
  "File types":["mp4","mov"],
  "url":"Must begin with http:// or https://",
  "max_size":"1gb",
  "Video_Codec":"H264",
  "Frame_Rate":30,
  "Video_Bitrate":[{"1080":"6000-10000k"},{"720":"5000-8000k"}]
  }],
      "Conversational ads":[
  {
  "Tweet_Copy" : 280,
  "Hashtag":21,
  "Pre_populated_user_Tweet": 256,
  "Headline": 23,
  "Thank_you_text": 23, 
  "Thank_you_URL":23,
  "Video_specs":"16:9",
  "Image_specs":[{"File_size" : "3mb"}, {"aspratio": 1.91},{"l*w": "800*418"},{"File_formats":["jpg","png"]}]
  }
  ],
  "Direct Message Card":[{
  "Tweet_Copy":256,
  "Call_to_action":24,
  "Image_specs":[{"File_size" : "3mb"}, {"aspratio": 1.91},{"l*w": "800*418"},{"File_formats":["jpg","png"]}],
  "Video_specs":[{"Video Codec":"H264"}],
  "Frame_Rate":"30",
  "Video_Resolution":[{"portrait":[{"l*w":"720*1280"}]},{"landscape":[{"l*w":"1280*720"}]},{"square":[{"l*w":"720*720"}]}],
  "Minimum_Video_Bitrate":5000,
  "Audio_Codec":"AAC LC",
  "Aspect_Ratio":"16:9"
  }]
  }
  }
  }
  











   url="https://images.pexels.com/photos/1845147/pexels-photo-1845147.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
  constructor(
    private _router: Router,
    private _api: ApiService,
    private _authService: AuthService,
    private http: HttpClient,
  ) { 

    console.log(this.c);



    
  }

  ngOnInit() {
    this.imagefileupload = "image";
    this.platform = "Google";

    this.filesPreview.push({
      url:this.url
    })
    console.log(this.filesPreview);
  }
uploadBtn(event){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
  
      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result as  string;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  imagetype(data){

    if(data == "image"){
   this.imagefileupload = "image"
    }else{
      this.imagefileupload = "url"
    }

  }



  platforms(data){

  }


uploadingdata(data){
  if(this.platform =='Google'){

  }else if(this.platform =='Twitter'){
    
    if(this.type == "Plain text Tweet"){
            if(this.title.length == 200){
                this.uploadaction();
            }
    }else if(this.type == "Conversational ads"){



    }else if(this.type == "Direct Message Card"){

    }else if(this.type == "Image Website Card"){

    }else if(this.type == "Image app card"){

    }else if(this.type == "Multi_image_Tweets"){

    }else if(this.type == "Single image Tweets & GIFs"){

    }else if(this.type == "Video Website Card"){

    }else if(this.type == "Video app card"){

    }else {
      alert('Please select type');
    }

    




     





















  }else if(this.platform =='Facebook'){

  }else {
    alert("Please select Platform");
  }
}




uploadaction(){
   alert("DONE");
}



  getDate() {
    this.filesPreview = [];
    this.files.forEach((file, index) => {
      ((element) => {
        let dataUrl;
        const reader = new FileReader();
        const img = new Image();
        img.src = window.URL.createObjectURL(element);
        img.onload = () => {
          dataUrl = reader.result;
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          const dim = {
            'width': width,
            'height': height
          };
          console.log(dim);
          window.URL.revokeObjectURL(img.src);
          if (!this.dimensionList.some(ele => {
            return JSON.stringify(dim) === JSON.stringify(ele);
          })) {
            this.files.splice(this.files.indexOf(element), 1);
            this.errorDimensionMessage = 'The Image is of Invalid Dimensions';
          } else {
            this.errorDimensionMessage = '';
            this.filesPreview = [];
            this.filesPreview.push({
              url: dataUrl,
              name: element.name,
              size: element.size,
              type: element.type
            });
          }
        };
        reader.readAsDataURL(element);
      })(file);
    });
    console.log(this.filesPreview);
    return new Date();


  }





  uploadFiles(files: File[]) {
    console.log(files);
    if (files && files[0]) {
      var reader = new FileReader();
  
      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result as  string;
      }
      reader.readAsDataURL(files[0]);
    }


    this.imageData = [];
    const myFormData: FormData = new FormData();
    // console.log(this.sendableFormData);
    console.log(this.files);
    this.files.forEach(file => myFormData.append('file', file));
    // this._api.uploadFiles(this.sendableFormData);
    const req = new HttpRequest<FormData>('POST', this.apiUrl + 'users/fileupload', myFormData, {
      reportProgress: true // responseType: 'text'
    });
    return this.httpEmitter = this.http.request(req)
      .subscribe(
        event => {
          this.httpEvent = event;
          if (event instanceof HttpResponse) {
            delete this.httpEmitter;
            event.body['response'].forEach(element => {
              this.imageData.push({
                filename: environment.apiUrl + 'images/uploads/' + element.filename,
                originalname: element.originalname,
                mimetype: element.mimetype,
                size: element.size,
                encoding: element.encoding
              });
            });
            console.log('request done', event.body['response']);
          }
        },
        error => {
          console.log('Error Uploading', error);
        }
      );
  }
}


import { Component, OnInit } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router'

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.sass']
})
export class BillingComponent implements OnInit {
  
  responsive: boolean;
  navmode: string;
  mouseover = 0;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _router: Router
  ) { }

  ngOnInit() {
    this._breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.loadResponsiveLayout();
      } else {
        this.loadDesktopLayout();
      }
    });
  }

  loadResponsiveLayout() {
    this.responsive = true;
    this.navmode = 'over';
  }

  loadDesktopLayout() {
    this.responsive = false;
    this.navmode = 'side';
  }
mouseOver(event){
  // console.log(event)
// if(event == '1'){
//   console.log('if works')
//  this.mouseover = 0;
// }else if (event !== '1' && event == '0'  ){
//   console.log('else if works')
//   this.mouseover = 1;
// }else if( event == '2' &&  event == '0'){
//   console.log('click works')
//   this.mouseover = 0;
// }
}
}

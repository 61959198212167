import { Component, OnInit, HostBinding } from '@angular/core';
import { ApiService } from '../api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {

  @HostBinding('class') componentCssClass;
  theme: string;

  emailForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required])
  });
  message: string;

  constructor(public overlayContainer: OverlayContainer, private _apiService: ApiService, private _router: Router) {
  }

  ngOnInit() {
    this.theme = 'default-theme';
    this.overlayContainer.getContainerElement().classList.remove("transparent-theme");
    this.overlayContainer.getContainerElement().classList.add('default-theme');
    this.componentCssClass = this.theme;
    if (window.localStorage) {
      if (!localStorage.getItem('firstLoad')) {
        localStorage['firstLoad'] = true;
        window.location.reload();
      }
      else
        localStorage.removeItem('firstLoad');
    }
  }

  reset() {
    if (this.emailForm.valid) {
      this._apiService.forgotPassword(this.emailForm.value).subscribe(
        data => {
          if (data['message'] === 'ok') {
            // console.log()
            this.message = 'An E-Mail has been sent with the Recovery Link.';
          } else {
            this.message = data['message'];
          }
        }
      );
    }
  }

}
